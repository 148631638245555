export enum CanvasActionType {
    EVENTNODE_ADD_ENDEVENT = 'eventnode:add:endEvent',
    EVENTNODE_ADD_INPRECURSOREVENT = 'eventnode:add:inPrecursorEvent',
    EVENTNODE_ADD_PIVOTALEVENT = 'eventnode:add:pivotalEvent',
    EVENTNODE_EDIT_ENDEVENT = 'eventnode:edit:endEvent',
    EVENTNODE_EDIT_INPRECURSOREVENT = 'eventnode:edit:inPrecursorEvent',
    EVENTNODE_EDIT_PIVOTALEVENT = 'eventnode:edit:pivotalEvent',
    EVENTNODE_EDIT_INITIATINGEVENT = 'eventnode:edit:initiatingEvent',

    EVENTNODE_COPY = 'eventnode:copy',
    EVENTNODE_DELETE = 'eventnode:delete',
    EVENTNODE_PASTE = 'eventnode:paste',
    EVENTNODE_REASSIGN_PARENT = 'eventnode:reassign:parent',
    EVENTNODE_FAULTTREE_ADD = 'eventnode:faultTree:add',
    EVENTNODE_FAULTTREE_COPY = 'eventnode:faultTree:copy',
    EVENTNODE_FAULTTREE_DELETE = 'eventnode:faultTree:delete',
    EVENTNODE_FAULTTREE_PASTE = 'eventnode:faultTree:paste',
    EVENTNODE_FAULTTREE_PROPERTIES = 'eventnode:faultTree:properties',
    FAULTTREENODE_ADD_NODE = 'faultTreeNode:add:node',
    FAULTTREENODE_COPY_SUBTREE = 'faultTreeNode:copy:subTree',
    FAULTTREENODE_DELETE_NODE = 'faultTreeNode:delete:node',
    FAULTTREENODE_EDIT_NODE = 'faultTreeNode:edit:node',
    FAULTTREENODE_PASTE_SUBTREE = 'faultTreeNode:paste:subTree',
    FAULTTREENODE_REORDER_CHILDREN = 'faultTreeNode:reorder:children',
    INCIDENT_DNA_CLEAR = 'incidentDNA:clear',
    INCIDENT_DNA_COMMENTS = 'incidentDNA:comments',
    INCIDENT_DNA_FAILURE = 'incidentDNA:failure',
    INCIDENT_DNA_OCCURRENCE_LIST = 'incidentDNA:occurrenceList',
    INCIDENT_DNA_PERFORMANCE_SHAPING_FACTORS = 'incidentDNA:performanceShapingFactors',
    INCIDENT_DNA_STATISTICS = 'incidentDNA:statistics',
    INCIDENT_DNA_SUCCESS = 'incidentDNA:success',
    NODE_QUANTIFY_CHILDREN = 'node:quantify:children',
    NODE_QUANTIFY_BOTTOM_UP = 'node:quantify:bottomUp',
    NODE_QUANTIFY_TOP_DOWN = 'node:quantify:topDown',
    NODE_QUANTIFY_BOOTOM_UP_TO_HERE = 'node:quantify:bottomUpToHere',
    NODE_PROPERTIES = 'node:properties',
    NODE_TAGS = 'node:tags',

    NODE_ADD_CALLOUT = 'node:add:callout',
    NODE_EDIT_CALLOUT = 'node:edit:callout',
    NODE_DELETE_CALLOUT = 'node:delete:callout',

    CANVAS_ZOOM_IN = 'canvas:zoom:in',
    CANVAS_ZOOM_OUT = 'canvas:zoom:out',
    CANVAS_RECENTER = 'canvas:recenter',
    CANVAS_FULLSCREEN = 'canvas:fullScreen', 
    CANVAS_SEARCH = 'canvas:search',
    CANVAS_REDO = 'canvas:redo',
    CANVAS_UNDO = 'canvas:undo',
    CANVAS_TOGGLE_TAGS = 'canvas:toggle:tags',
    CANVAS_TOGGLE_CALLOUTS = 'canvas:toggle:callouts',
    CANVAS_REFRESH = 'canvas:refresh',

    SANITIZE_EVENT_SEQUENCE = 'sanitize:eventSequence',
    SANITIZE_OCCURRENCE_DNA = 'sanitize:occurrenceDNA',
    UNKNOWN = 'unknown',
}

export interface QueryCanvasAction {
    actionType: CanvasActionType;
    query?: string;   // used meanly for search
}