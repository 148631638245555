import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import React from 'react';
import {useWorkspaceContext} from '../../../context/workspaceContext';
import GroupService from '../../../services/group.service';
import Group from '../../../@types/group';
import {ResourceType} from '../../../@types/resource-type';
import {toast} from 'react-toastify';
import DotdotdotButton from '../../form-components/DotdotdotButton.component';
import DeleteResource from '../modals/DeleteResource.component';
import Box from '@mui/material/Box';
import DuplicateResource from '../modals/DuplicateResource.component';
import {getTreeItemDescendantIds} from '../../../utils/resource-utils';
import {excludeGroupAndDescendants} from '../../../utils/group-utils';
import LeaveGroupModal from '../modals/LeaveGroup.component';

export default function GroupActionsMenu(props: {
    group: Group
}): React.ReactElement {
    const [confirmDeleteGroup, setConfirmDeleteGroup] = React.useState<boolean>(false);
    const [confirmDuplicateGroup, setConfirmDuplicateGroup] = React.useState<boolean>(false);
    const [confirmLeaveGroup, setConfirmLeaveGroup] = React.useState<boolean>(false);

    const { setCurrentWorkspaceObject,
        setDeletedWorkspaceObject,
        setWorkspaceObjects, workspaceObjects, getAdditionalResource } = useWorkspaceContext();
    const { group } = props;
 
    function onEdit(): void {
        setCurrentWorkspaceObject({ objectType: ResourceType.GROUPS, object: group });
    }

    function onDelete(): void {
        setConfirmDeleteGroup(true);
    }

    function onDuplicate(): void {
        setConfirmDuplicateGroup(true);
    }

    function onLeave(): void {
        setConfirmLeaveGroup(true);
    }

    function onNewGroup(): void {
        let id = group.id;
        const newGroup = new Group();
        newGroup.setParent(id);
        setCurrentWorkspaceObject({ objectType: ResourceType.GROUPS, object: newGroup });
    }

    return (
        <Box tabIndex={0}  onClick={(event: any): void => {
            event.stopPropagation();
        }}>
            <DotdotdotButton>
                {
                    group.canEdit() && <MenuItem id='edit' onClick={onEdit}>Edit</MenuItem>
                }
                {
                    group.canEdit() && <MenuItem id='new' onClick={onNewGroup}>New Subgroup</MenuItem>
                }
                <MenuItem id='duplicate' onClick={onDuplicate}>Duplicate</MenuItem>
                <Divider />
                {
                    group.canLeave() && <MenuItem id='leave' onClick={onLeave}>Leave Group</MenuItem>
                }

                {
                    group.canEdit() && <MenuItem id='delete' onClick={onDelete}>Delete</MenuItem>
                }
            </DotdotdotButton>
            <LeaveGroupModal
                leaveGroup={confirmLeaveGroup}
                group={group}
                onConfirm={(): void => {
                    setConfirmLeaveGroup(false);
                    let childrenIds = getTreeItemDescendantIds(group,
                        ResourceType.GROUPS,
                        workspaceObjects?.resources ?? []);
                    GroupService.leaveGroupById(group).then(() => {
                        setDeletedWorkspaceObject({
                            objectType: ResourceType.GROUPS,
                            id: group.id!,
                            children: childrenIds
                        });
                        setWorkspaceObjects({ objectType: workspaceObjects?.objectType ?? ResourceType.GROUPS });
                    }).catch((error) => {
                        toast.error(error.message);
                    }
                    );
                }}
                onClose={(): void => {
                    setConfirmLeaveGroup(false);
                }}
            />
            <DeleteResource
                open={confirmDeleteGroup}
                resourceType={ResourceType.GROUPS}
                resourceName={group.name}
                subResources={'sub groups and resources'}
                handleDelete={(): void => {
                    let childrenIds = getTreeItemDescendantIds(group,
                        ResourceType.GROUPS,
                        workspaceObjects?.resources ?? []);
                    GroupService.deleteGroupById(group.id!).then(() => {
                        setDeletedWorkspaceObject({
                            objectType: ResourceType.GROUPS,
                            id: group.id!,
                            children: childrenIds
                        });
                        setWorkspaceObjects({ objectType: workspaceObjects?.objectType ?? ResourceType.GROUPS });
                    }).catch((error) => {
                        toast.error(error.message);
                    }).finally(() => {
                        setConfirmDeleteGroup(false);
                    });

                }}
                onClose={(): void => {
                    setConfirmDeleteGroup(false);
                }}
            />
            <DuplicateResource
                open={confirmDuplicateGroup}
                title={'Duplicate Group - ' + group.name}
                onClose={(): void => { setConfirmDuplicateGroup(false); }}
                onConfirm={
                    (id: number): void => {
                        let targetGroupId = id > 0 ? id : undefined;
                        let sourceGroupId = group.id;
                        setConfirmDuplicateGroup(false);
                        GroupService.duplicateGroup(sourceGroupId!, targetGroupId).then((response) => {
                            toast.info(response.data.status + ' ' + response.data.name);
                        }).catch((error) => {
                            toast.error(error.message);
                        });
                    }
                }
                object={{objectType: ResourceType.GROUPS, object: group}}            
                data={excludeGroupAndDescendants(getAdditionalResource(ResourceType.GROUPS), group, workspaceObjects)}
            />
            
            
        </Box>
    );
}


