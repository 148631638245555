import * as joint from '@joint/plus';

export interface ReassignmentPoint {
    id: number;
    yesOrNo: string;
    uniqueId: string;
}

function getIndexOfPointToDelete(availableReassignmentPoints: Array<ReassignmentPoint>, id: number, yesOrNo: string): number {
    return availableReassignmentPoints.findIndex(element => element.id === id && element.yesOrNo === yesOrNo);
}



function findEventOfInterest(graph: joint.dia.Graph, currentNodeEvent: joint.dia.Element,availableReassignmentPoints: Array<ReassignmentPoint>, eventOfInterestId: number): Array<ReassignmentPoint> {
    let children = graph.getNeighbors(currentNodeEvent, { outbound: true }).filter((node) => node.get('nodeClass') !== 'FAULTTREENODE').filter((cell) => cell.get('nodeClass') !== 'CALLOUTNODE');
    
    let numChildEvents = children.length;
    let indexOfPointToDelete;

    //Remove records from available reassignment points
    if (currentNodeEvent.get('data').parentRelationType === 'YES') {
        indexOfPointToDelete = getIndexOfPointToDelete(availableReassignmentPoints, currentNodeEvent.get('data').parentId, 'YES');
        if (indexOfPointToDelete > -1) {
            availableReassignmentPoints.splice(indexOfPointToDelete, 1);
        }
    } else if (currentNodeEvent.get('data').parentRelationType === 'NO'){
        indexOfPointToDelete = getIndexOfPointToDelete(availableReassignmentPoints, currentNodeEvent.get('data').parentId, 'NO');
        if (indexOfPointToDelete > -1) {
            availableReassignmentPoints.splice(indexOfPointToDelete, 1);
        }                
    }

    if (currentNodeEvent.get('data').type === 'PIVOTAL') {
        availableReassignmentPoints.push(
            {
                id: currentNodeEvent.get('data').id,
                yesOrNo: 'YES',
                uniqueId: currentNodeEvent.get('data').uniqueId
            },
            {
                id: currentNodeEvent.get('data').id,
                yesOrNo: 'NO',
                uniqueId: currentNodeEvent.get('data').uniqueId
            }
        );
    }

    if (currentNodeEvent.get('data').id == eventOfInterestId) {
        indexOfPointToDelete = getIndexOfPointToDelete(availableReassignmentPoints, currentNodeEvent.get('data').id, 'YES');
        if (indexOfPointToDelete > -1) {
            availableReassignmentPoints.splice(indexOfPointToDelete, 1);
        }
        indexOfPointToDelete = getIndexOfPointToDelete(availableReassignmentPoints, currentNodeEvent.get('data').id, 'NO');
        if (indexOfPointToDelete > -1) {
            availableReassignmentPoints.splice(indexOfPointToDelete, 1);
        }
        return availableReassignmentPoints;
    }
    
    if (numChildEvents === 0) {
        return availableReassignmentPoints;
    }

    availableReassignmentPoints = findEventOfInterest(graph, children[0], availableReassignmentPoints, eventOfInterestId);

    if (numChildEvents === 2) {
        availableReassignmentPoints = findEventOfInterest(graph, children[1], availableReassignmentPoints, eventOfInterestId);
    }

    return availableReassignmentPoints;
}



export function reassignParent(graph: joint.dia.Graph, selectedEventId: number): Array<ReassignmentPoint> {
    let availableReassignmentPoints: Array<ReassignmentPoint> = [];
    const [source] = graph.getSources();
    if (!source || source.get('data').type !== 'INITIATING') {
        return [];
    }
    return findEventOfInterest(graph, source, availableReassignmentPoints, selectedEventId);
}
