import {Box} from '@mui/material';
import React, {ReactElement, useContext, useRef, useState} from 'react';
import {useWorkspaceContext, WorkspaceProvider} from '../../context/workspaceContext';
import VerticalIconMenu from './VerticalIconMenu.component';
import WorkspaceHeader from './WorkspaceHeader.component';
import SplitPane, {Pane, SashContent} from 'split-pane-react';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import {resourceIcon} from '../../utils/resource-icons';
import {
    createWorkspaceObject,
    fetchAllResources,
    fetchPagedWorkspaceObjects,
    fetchWorkspaceObject,
    getResourcesAsPage,
    getWorkspaceObjectType,
    getWorkspaceObjectTypeAsNumber
} from '../../utils/resource-utils';
import {camelCaseToWords} from '../../utils/utils';
import TabEditor from './TabEditor.component';
import {ResourceType} from '../../@types/resource-type';
import '../../vendor/css/sash.css'
import ExplorerContainer from './ExplorerContainer.component';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {API_URL} from '../../config/constants';
import {SSE_EVENT_TYPES} from '../../utils/sse-enums';
import JOB_STATUS_TYPES from '../../utils/job-status-enums';
import authService from '../../services/auth.service';
import AuthService from '../../services/auth.service';
import PedigreesService from '../../services/pedigrees-service';
import PhasesOfFlightService from '../../services/phases-of-flight.service';
import occurrenceCategoriesService from '../../services/occurrence-categories.service';
import {OccurrenceCategory, PhaseOfFlight} from '../../@types/event-sequence-summary';
import ResourceBase from '../../@types/resource-base';
import axios from 'axios';
import {UserContext} from '../../context/userContext';
import {useNavigate} from 'react-router-dom';
import {BasicPage, defaultPageLarge} from '../../@types/spring-data-page';

export default function Workspace(): ReactElement {
    return (
        <WorkspaceProvider>
            <WorkspaceContextAware />
            <ToastContainer
                position="bottom-right"
            />
        </WorkspaceProvider>
    )
}

function WorkspaceContextAware(): ReactElement {
    const [panelSizePercent, setPanelSizePercent] = useState<(number | string)[]>(['8%', '16%', '76%']);
    const dimension = { 'height': 'calc(100vh)', 'gridColumn': '1 / -1'};
    const aria = { 'ariaLabel': 'Workspace' };
    const { workspaceObjects, setWorkspaceObjects,
        currentWorkspaceObject, setCurrentWorkspaceObject,
        setOccurrenceCategories, setPhasesOfFlight, setPedigrees, getAdditionalResource,
        additionalResources, addOrUpdateAdditionalResource } = useWorkspaceContext();
    const { signoutUser } = useContext(UserContext);
    const tabIndexRef = useRef<number>(0);
    const sseConnectionRef = useRef<EventSource | null>(null);
    const navigate = useNavigate();

    // Axios interceptors to handle 401 errors
    React.useEffect(() => {
        axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response.status === 401) {
                    return AuthService.fetchOktaProperties().then(() => {
                        signoutUser();
                        navigate('/react/signout');
                        location.reload();
                    }).catch((error: any) => {
                        toast.error(error.message);
                        location.reload();
                    });
                }
                return Promise.reject(error);
            });
    }, [])

    // Set role="separator" on the resizer element so that it is accessible
    React.useEffect(() => {
        // get html element with attribute role="Resizer"
        const resizers = document.querySelectorAll('[role="Resizer"]');
        if (resizers && resizers.length > 0) {
            resizers.forEach((resizer) => {
                // change role to a valid aria role
                resizer.setAttribute('role', 'separator');
            });
        }
    }, []);

    // Connect to SSE server to listen for duplicateGroup, deleteGroup and duplicateSafetyModel events
    // Other events can be added here as needed
    React.useEffect(() => {
        const handleSSEvent = (resourceType: ResourceType, event: any): void => {
            const data = JSON.parse(event.data);
            if (data.status === JOB_STATUS_TYPES.completed) {
                toast.success(data.name + ' completed successfully');
                setWorkspaceObjects({ objectType: resourceType });
            }
        }

        const closeSSEConnection = (): void => {
            if (sseConnectionRef.current) {
                sseConnectionRef.current.removeEventListener(SSE_EVENT_TYPES.duplicateGroup,
                    handleSSEvent.bind(null, ResourceType.GROUPS));
                sseConnectionRef.current.removeEventListener(SSE_EVENT_TYPES.deleteGroup,
                    handleSSEvent.bind(null, ResourceType.GROUPS));
                sseConnectionRef.current.removeEventListener(SSE_EVENT_TYPES.duplicateSafetyModel,
                    handleSSEvent.bind(null, ResourceType.SAFETY_MODELS));
                sseConnectionRef.current.close();
            }
        }

        if (sseConnectionRef.current) {
            closeSSEConnection();
        }

        sseConnectionRef.current = new EventSource(API_URL + 'sse', { withCredentials: true });

        sseConnectionRef.current.addEventListener(SSE_EVENT_TYPES.duplicateGroup,
            handleSSEvent.bind(null, ResourceType.GROUPS));
        sseConnectionRef.current.addEventListener(SSE_EVENT_TYPES.deleteGroup,
            handleSSEvent.bind(null, ResourceType.GROUPS));
        sseConnectionRef.current.addEventListener(SSE_EVENT_TYPES.duplicateSafetyModel,
            handleSSEvent.bind(null, ResourceType.SAFETY_MODELS));


        sseConnectionRef.current.onopen = (): void => {
        }

        sseConnectionRef.current.onerror = (error: any): void => {
            toast.error(error.message);
        }
        
        return (): void => {
            if (sseConnectionRef.current) {
                closeSSEConnection();
            }
        }
    }, []);

    React.useEffect(() => {
        // eslint-disable-next-line no-undef
        window.scrollTo(0, 130);

    }, [workspaceObjects, currentWorkspaceObject]);

    React.useEffect(() => {
        
        PhasesOfFlightService.getPhasesOfFlight()
            .then((phasesOfFlight: PhaseOfFlight[]) => {
                setPhasesOfFlight(phasesOfFlight);
            })
            .catch((error: Error) => {
                toast.error(error.message);
            });
        
    }, [setPhasesOfFlight]);

    React.useEffect(() => {
        occurrenceCategoriesService.getOccurrenceCategories().then((data: OccurrenceCategory[]) => {
            setOccurrenceCategories(data);
        }).catch((error: Error) => {
            toast.error(error.message);
        });
    }, [setOccurrenceCategories]);

    React.useEffect(() => {
        PedigreesService.getPedigrees().then((data: string[]) => {
            setPedigrees(data);
        }).catch((error: Error) => {
            toast.error(error.message);
        });
    }, [setPedigrees]);

    const loadObjects = (setLoadingObjects: CallableFunction, page: BasicPage, groupId?: number, searchTerm?: string): void => {
        let type = workspaceObjects?.objectType ?? ResourceType.GROUPS;
        if (!additionalResources.find(value => value.objectType === type) && (type == ResourceType.GROUPS || type == ResourceType.SAFETY_MODELS)) {
            const fetchAllObjectsPromise: Promise<any> | null = fetchAllResources(type);
            if (fetchAllObjectsPromise) {
                setLoadingObjects(true);
                fetchAllObjectsPromise.then((allObjects) => {
                    addOrUpdateAdditionalResource({ objectType: type, resources: allObjects });
                    if (type === ResourceType.GROUPS && getWorkspaceObjectTypeAsNumber(type) === tabIndexRef.current) {
                        const pagedGroups = getResourcesAsPage(allObjects, page, groupId, searchTerm);
                        setWorkspaceObjects({ objectType: type, resources: pagedGroups.content, page: pagedGroups.page });
                    }
                }).catch((error) => {
                    toast.error(error.message);
                }).finally(() => {
                    setLoadingObjects(false);
                });
            }
        } else if (type === ResourceType.GROUPS) {
            const resources = getAdditionalResource(type);
            if (resources) {
                const pagedGroups = getResourcesAsPage(resources.resources, page, groupId, searchTerm);
                setWorkspaceObjects({objectType: type, resources: pagedGroups.content, page: pagedGroups.page});
            }
        }
        if (type !== ResourceType.GROUPS) {
            const fetchObjectsPromise: Promise<any> | null = fetchPagedWorkspaceObjects(type, page ?? defaultPageLarge, groupId, searchTerm);
            if (fetchObjectsPromise) {
                setLoadingObjects(true);
                fetchObjectsPromise.then((objects) => {
                    // Prevent setting workspace objects if the tab index has changed during the loading of the objects.
                    // This can happen if the user clicks on a different tab while the objects are being fetched which leads to
                    // the workspace objects being loaded into the wrong tab.
                    if (getWorkspaceObjectTypeAsNumber(type) === tabIndexRef.current) {
                        if (objects.page && objects.content) {
                            setWorkspaceObjects({
                                objectType: type, resources: objects.content,
                                page: {
                                    number: objects.page.number,
                                    totalElements: objects.page.totalElements,
                                    size: objects.page.size,
                                    totalPages: objects.page.totalPages
                                }
                            });
                        } else {
                            setWorkspaceObjects({objectType: type, resources: objects});
                        }
                    }
                }).catch((error) => {
                    toast.error(error.message);
                }).finally(() => {
                    setLoadingObjects(false);
                });
            }
        }
    }

    const collapseExpandAllSashes = (): void => {
        let newSizePercent = [...panelSizePercent];
        if (panelSizePercent[0] === 0) {
            newSizePercent[0] = '8%';
            newSizePercent[1] = '16%';
        } else {
            newSizePercent[0] = 0;
            newSizePercent[1] = 0;
        }
        setPanelSizePercent(newSizePercent);
    };

    const collapseExpandSashUsingIndex = (index: number): void => {
        let newSizePercent = [...panelSizePercent];
        if (index === 1) {
            if (panelSizePercent[1] === 0) {
                newSizePercent[0] = '8%';
                newSizePercent[1] = '16%';
            }
            else {
                newSizePercent[0] = 0;
                newSizePercent[1] = 0;
            }
        } else if (index === 0) {
            if (panelSizePercent[0] === 0) {
                newSizePercent[0] = '8%';
                if (panelSizePercent[1] === 0) {
                    newSizePercent[1] = '16%';
                }
            }
            else {
                newSizePercent[0] = 0;
                //newSizePercent[1] = 0;
            }
        }
        setPanelSizePercent(newSizePercent);
    };

    const verticalMenuItems  = [
        {icon: resourceIcon(ResourceType.GROUPS), label: camelCaseToWords(ResourceType.GROUPS)},
        {icon: resourceIcon(ResourceType.SAFETY_MODELS), label: camelCaseToWords(ResourceType.SAFETY_MODELS)},
        {icon: resourceIcon(ResourceType.OCCURRENCE_REPORTS), label: camelCaseToWords(ResourceType.OCCURRENCE_REPORTS)},
        {icon: resourceIcon(ResourceType.TRAFFIC_MODELS), label: camelCaseToWords(ResourceType.TRAFFIC_MODELS)},
        {icon: resourceIcon(ResourceType.OPERATIONAL_CHANGES), label: camelCaseToWords(ResourceType.OPERATIONAL_CHANGES)},
        {icon: resourceIcon(ResourceType.RISK_FORECASTS), label: camelCaseToWords(ResourceType.RISK_FORECASTS)},
        {icon: resourceIcon(ResourceType.WORKSHOPS), label: camelCaseToWords(ResourceType.WORKSHOPS)},
    ];

    if (authService.isSuperUser()) {
        verticalMenuItems.push({icon: resourceIcon(ResourceType.USERS), label: camelCaseToWords(ResourceType.USERS)});
    }


    const onTabClick = (tabIndex: number): void => {
        // Keep track of the tab index so that we can prevent setting the workspace objects if the tab index has changed
        // while fetching the workspace objects.
        tabIndexRef.current = tabIndex;
        let objType = getWorkspaceObjectType(tabIndex);
        setWorkspaceObjects({objectType: objType});
    }

    const onObjectChange = (objectToChange: {objectType: ResourceType, object: any}): void => {
        setCurrentWorkspaceObject(objectToChange);
    }

    const addOrSelectResource = (resource: ResourceBase): void => {
        if (resource.id) {
            fetchWorkspaceObject(resource)?.then((object) => {
                setCurrentWorkspaceObject({objectType: resource.type, object: object});
            }).catch((error) => {
                toast.error(error.message);
            });
        } else {
            setCurrentWorkspaceObject({objectType: resource.type, object: createWorkspaceObject(resource) });
        }
    }
    
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', ...dimension, ...aria}}>
            <WorkspaceHeader onSash={collapseExpandAllSashes} onTabClick={onTabClick} addOrSelectResource={addOrSelectResource}
                loadObjects={loadObjects} />

            <SplitPane
                split='vertical'
                sizes={panelSizePercent}
                onChange={(sizes: number[]): void => (setPanelSizePercent(sizes))}
                sashRender={(index: number, active: boolean): React.ReactNode => (
                    <SashContent
                        className={active ? 'action-sash-wrap' : 'hidden-sash'}
                    >
                        <span className='action' onClick={(): void => { collapseExpandSashUsingIndex(index) }}>
                            {panelSizePercent[index] === 0 ? <ArrowRightIcon /> : <ArrowLeftIcon />}
                        </span>
                        <span/>
                    </SashContent>
                )}
            >
                <Pane>
                    <VerticalIconMenu menuItems={verticalMenuItems} onTabClick={onTabClick} itemIndex={tabIndexRef.current} />
                </Pane>
                <Pane style={{borderRight: '1px solid rgba(0,0,0,0.12)'}}>
                    <ExplorerContainer
                        loadObjects={loadObjects} objects={workspaceObjects} addOrSelectResource={addOrSelectResource}
                        exploreMode={true} setObjects={setWorkspaceObjects}
                    />
                </Pane>
                <Pane style={{marginLeft: '1px'}}>
                    <TabEditor objectToEdit={currentWorkspaceObject} onTabChange={onObjectChange} />
                </Pane>
            </SplitPane>

            <div style={{
                display: 'flex',
                width: '100%',
                height: '36px',
                backgroundColor: '#D9E8F7',
                justifyContent: 'space-between'
            }}>
            </div>
        </Box>
    );
}