import SaveAltIcon from '@mui/icons-material/SaveAlt';

import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress  from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import React, { ReactElement, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { toast } from 'react-toastify';
import { useWorkspaceContext } from '../../../../context/workspaceContext';
import { CanvasEnvironment } from '../../../../utils/canvas/canvas-environment';
import { ResourceType } from '../../../../@types/resource-type';
import EventSequenceService from '../../../../services/event-sequence.service';
import * as CanvasActions from '../../../../utils/canvas/canvas-actions';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { uuid } from '../../../../utils/utils';
import { CanvasActionType } from '../../../../@types/canvas-action-type';


function PaperComponent(props: PaperProps): ReactElement {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}

export default function QuantifyFaultTreeModal(props: { open: boolean, env: CanvasEnvironment | null, onClose: () => void }): ReactElement {
    const { open, env, onClose } = props;
    const [uniqueId, setUniqueId] = React.useState<string>('');
    const [name, setName] = React.useState<string>('');
    const [method, setMethod] = React.useState<string>('');
    const [warning, setWarning] = React.useState<string>('');
    const [updatePedigrees, setUpdatePedigrees] = React.useState<boolean>(false);
    const [isRunning, setIsRunning] = React.useState<boolean>(false);
    const { currentCanvasAction,  setCurrentWorkspaceObject } = useWorkspaceContext();

    const uuidRef = React.useRef(uuid());



    useEffect(() => {
        if (open) {
            const [selectedNode] = env!.selection;
            setUniqueId(selectedNode.get('data').uniqueId);
            setName(selectedNode.get('data').name);
            setUpdatePedigrees(false);
            setIsRunning(false);
            if (!currentCanvasAction) {
                setMethod('Unknown');
                setWarning('Unknown quantification method.');
                return;
            }
            switch (currentCanvasAction.actionType) {
                case CanvasActionType.NODE_QUANTIFY_CHILDREN:
                    setMethod('Children');
                    setWarning('Are you sure you want to quantify the children of the  currently selected fault tree node?');
                    break;
                case CanvasActionType.NODE_QUANTIFY_BOTTOM_UP:
                    setMethod('BOTTOM-UP');
                    setWarning('The probability of every event and fault tree node other than fault tree base events will change as a result of quantification.');
                    break;
                case CanvasActionType.NODE_QUANTIFY_TOP_DOWN:
                    setMethod('TOP-DOWN');
                    setWarning('Are you sure you want to quantify the current fault tree top-down?');
                    break;
                case CanvasActionType.NODE_QUANTIFY_BOOTOM_UP_TO_HERE:
                    setMethod('BOTTOM-UP-TO-HERE');
                    setWarning('The probability of every fault tree node in the subtree other than fault tree base events will change as a result of quantification.');
                    break;
                default:
                    setMethod('Unknown');
                    setWarning('Unknown quantification method.');
                    break;
            }   
            
        }
    }, [open]);

    const handleSave = async (): Promise<any> => {
        const [selectedNode] = env!.selection;
        const id = (method === 'BOTTOM-UP') ? env!.eventSequence?.id : selectedNode.get('data').id;
        let direction = '';
        if (method === 'BOTTOM-UP') {
            direction = 'BOTTOM_UP_ESD';
        } else if (method === 'TOP-DOWN') {
            direction = 'TOP_DOWN';
        } else if (method === 'BOTTOM-UP-TO-HERE') {
            direction = 'BOTTOM_UP';
        } else {
            direction = 'CHILDREN';
        }


        return (direction === 'BOTTOM_UP_ESD') ?  EventSequenceService.autoQuantifyEventSequence(id,  updatePedigrees) : EventSequenceService.autoQuantifySubtree(id,  updatePedigrees, direction);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            fullWidth
            maxWidth="sm">
            <DialogTitle style={{ cursor: 'move'}} id='draggable-dialog-title'>
                {`Quantify ${method === 'BOTTOM-UP-TO-HERE' ? 'BOTTOM-UP' : method}`}
            </DialogTitle>
            <DialogContent dividers>
                <FormGroup>
                    <Stack>
                        <TextField
                            margin='dense'
                            type='text'
                            size='small'
                            id={`quantify-fault-tree-id-${uuidRef.current}`}
                            label='ID'
                            value={uniqueId}
                            variant='outlined'
                            sx={{
                                '& input': {
                                    border: 'none',
                                    cursor: 'not-allowed',
                                },
                            }}
                            InputProps={{
                                readOnly: true,
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `quantify-fault-tree-id-${uuidRef.current}`,
                                'aria-label': 'ID',
                            }}
                            disabled
                        />
                        <hr />
                        <TextField
                            id={`quantify-fault-tree-name-${uuidRef.current}`}
                            label='Name'
                            type='text'
                            margin='dense'
                            size='small'
                            value={name}
                            variant="outlined"
                            sx={{
                                '& input': {
                                    border: 'none',
                                    cursor: 'not-allowed'
                                },
                                
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `quantify-fault-tree-name-${uuidRef.current}`,
                                'aria-label': 'name',
                            }}
                            fullWidth
                            disabled
                        />
                        <hr />
                        {
                            (method === 'BOTTOM-UP' || method === 'BOTTOM-UP-TO-HERE') && <FormControl>
                                <FormControlLabel
                                    id={`quantify-fault-tree-update-pedigree-${uuidRef.current}`}
                                    value={updatePedigrees}
                                    control={<Checkbox size='small' onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setUpdatePedigrees(event.target.checked)} />}
                                    label="Update Pedigrees - The pedigrees of the involved nodes except for base events will be changed to CALCULATED"
                                    labelPlacement="end"
                                    sx={{
                                        '& input': {
                                            border: 'none',
                                        }
                                    }}
                                    style={{
                                        fontFamily: 'Roboto, Helvetica',
                                        fontSize: '0.8rem',
                                        fontWeight: 400,
                                        lineHeight: 1.4,
                                        color: 'rgba(0, 0, 0, 0.6)',
                                        paddingLeft: '0.2rem',
                                    }}

                                />
                            </FormControl>
                        }
                        <hr />
                        <Typography id={`quantify-fault-tree-warning-${uuidRef.current}`} variant="body2" paragraph={true} color='red'>
                            {`Warning: ${warning}`}
                        </Typography>
                        
                    </Stack>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={onClose}
                >
                    Close
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={isRunning ? <CircularProgress size={20} /> : <SaveAltIcon />}
                    onClick={async (): Promise<any> => {
                        setIsRunning(true);
                        try {
                            const response = await handleSave();
                            env!.isDirty = true;
                            setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                        } catch (error: any) {
                            toast.error(error.message);
                        } finally {
                            setIsRunning(false);
                            onClose();
                        }                                    
                    }}
                    disabled={!CanvasActions.canAddOrEditResource(env) || isRunning || method === 'Unknown'}
                >
                    Quantify
                </Button>
            </DialogActions>
        </Dialog>
    )
   
}