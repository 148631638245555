import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import React, {ReactElement} from 'react';

export interface OkCancelModalProps {
    open: boolean;
    title: string;
    okButtonText: string;
    children: React.ReactNode;
    onClose: () => void;
    onConfirm: () => void;
    okToConfirm: () => boolean;
}
export default function OkCancelModal(props: OkCancelModalProps): ReactElement {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const { open, title, okButtonText, children, onConfirm, onClose, okToConfirm } = props;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            <DialogActions>
                <Button id='cancel' size='small' variant='contained' onClick={onClose}>Cancel</Button>
                <Button id='confirm' size='small' variant='contained' color='primary' disabled={!okToConfirm()} onClick={onConfirm}>{okButtonText}</Button>
            </DialogActions>

        </Dialog>
    );
}
