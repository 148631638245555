import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import React from 'react';
import {useWorkspaceContext} from '../../../context/workspaceContext';
import safetyService from '../../../services/safety.service';
import SafetyModel from '../../../@types/safety-model';
import {ResourceType} from '../../../@types/resource-type';
import {toast} from 'react-toastify';
import DotdotdotButton from '../../form-components/DotdotdotButton.component';
import DeleteResource from '../modals/DeleteResource.component';
import DuplicateResource from '../modals/DuplicateResource.component';
import Box from '@mui/material/Box';
import EventSequenceSummary from '../../../@types/event-sequence-summary';
import {createWorkspaceObject, filterOnGroupCanEditResources} from '../../../utils/resource-utils';
import ResourceBase from '../../../@types/resource-base';
import {exportEventSequence, importEventSequence} from '../../../utils/event-sequence-utils';
import {addEventSequence} from '../../../utils/safety-model-utils';
import {ExportEventSequenceSelectorDialogBox} from '../../form-components/ExportEventSequenceSelector.component';

export default function SafetyModelActionsMenu(props: {
    safetyModel: SafetyModel
}): React.ReactElement {
    const [confirmDeleteSafetyModel, setConfirmDeleteSafetyModel] = React.useState<boolean>(false);
    const [confirmDuplicateSafetyModel, setConfirmDuplicateSafetyModel] = React.useState<boolean>(false);
    const [showExportEventSequence, setShowExportEventSequence] = React.useState<boolean>(false);
    const [eventSequenceList, setEventSequenceList] = React.useState<EventSequenceSummary[]>([]);
    const { setCurrentWorkspaceObject,
        setDeletedWorkspaceObject,
        setWorkspaceObjects, getAdditionalResource  } = useWorkspaceContext();
    const { safetyModel } = props;
 
    function onEdit(): void {
        setCurrentWorkspaceObject({ objectType: ResourceType.SAFETY_MODELS, object: safetyModel });
    }

    function onDelete(): void {
        setConfirmDeleteSafetyModel(true);
    }

    function onDuplicate(): void {
        setConfirmDuplicateSafetyModel(true);
    }

    function onNewEventSequence(): void {
        const newEventSequence: EventSequenceSummary = {} as EventSequenceSummary;
        newEventSequence.containerId = safetyModel.id;
        setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCES, object: newEventSequence });
    }

    function updateSafetyModel(eventSequence: any): void {
        let safetyModels = getAdditionalResource(ResourceType.SAFETY_MODELS).resources as SafetyModel[];
        let current = safetyModels.find((sm) => sm.id == safetyModel.id);
        if (current) {
            addEventSequence(current, eventSequence);
            setWorkspaceObjects({ objectType: ResourceType.SAFETY_MODELS, resources: safetyModels });
        } else {
            setWorkspaceObjects({ objectType: ResourceType.SAFETY_MODELS });
        }
    }

    function onImport(): void {
        importEventSequence(safetyModel.id, safetyModel.name, updateSafetyModel);
    }

    function loadEventSequences(): void {
        if (safetyModel.numberEventSequences > 0 && !safetyModel.children) {
            safetyService.getEventSequences(safetyModel.id).then((eventSequences) => {
                (eventSequences as EventSequenceSummary[])?.forEach(es => es.role = safetyModel.group.role());
                setEventSequenceList(eventSequences);
            }).catch((error) => {
                toast.error('Error getting event sequences - ' + error.message);
            });
        } else {
            setEventSequenceList(safetyModel.children ?? []);
        }
    }

    const handleClickNew = (resource: ResourceBase): void => {
        setCurrentWorkspaceObject({objectType: resource.type, object: createWorkspaceObject(resource) });
        setConfirmDuplicateSafetyModel(false);
    };

    return (
        <Box tabIndex={0}  onClick={(event: any): void => {
            event.stopPropagation();
        }}>
            <DotdotdotButton> 
                <MenuItem id='edit' onClick={onEdit}>Edit</MenuItem>
                <MenuItem id='duplicate' onClick={onDuplicate}>Duplicate</MenuItem>
                {
                    safetyModel.group.canAddOrEditResources() && <MenuItem id='delete' onClick={onDelete}>Delete</MenuItem>
                } 
                {
                    safetyModel.group.canAddOrEditResources() && <Divider />
                }
                {
                    safetyModel.group.canAddOrEditResources() && <MenuItem id='new' onClick={onNewEventSequence}>New Event Sequence</MenuItem>
                } 
                {
                    safetyModel.id && safetyModel.group.canAddOrEditResources() && <MenuItem id='import' onClick={onImport}>Import Event Sequence</MenuItem>
                }
                {
                    safetyModel.id && safetyModel.numberEventSequences > 0 && <MenuItem id='export' onClick={(): void => {
                        loadEventSequences();
                        setShowExportEventSequence(true);
                    }}>Export Event Sequence</MenuItem>
                }

            </DotdotdotButton>
            <DeleteResource
                open={confirmDeleteSafetyModel}
                resourceType={ResourceType.SAFETY_MODELS}
                resourceName={safetyModel.name}
                subResources='event sequences contained in this safety model'
                handleDelete={(): void => {
                    safetyService.deleteSafetyModelById(safetyModel.id).then(() => {
                        let childrenIds =  safetyModel.children?.map((child: EventSequenceSummary) => child.id) ?? [];
                        setDeletedWorkspaceObject({
                            objectType: ResourceType.SAFETY_MODELS,
                            id: safetyModel.id,
                            children: childrenIds
                        });
                        setWorkspaceObjects({ objectType: ResourceType.SAFETY_MODELS });
                        
                    }).catch((error) => {
                        toast.error('Error deleting safety model - ' + error.message);
                    }).finally(() => {
                        setConfirmDeleteSafetyModel(false);
                    });
                }}
                onClose={(): void => {
                    setConfirmDeleteSafetyModel(false);
                }}
            />
            <DuplicateResource
                open={confirmDuplicateSafetyModel}
                title={'Duplicate Safety Model - ' + safetyModel.name}
                onClose={(): void => { setConfirmDuplicateSafetyModel(false); }}
                onConfirm={(id: number): void => {
                    let targetGroupId = id > 0 ? id : undefined;
                    let sourceResourceId = safetyModel.id;
                    setConfirmDuplicateSafetyModel(false);
                    
                    safetyService.duplicateSafetyModel(sourceResourceId, targetGroupId).then((response) => {
                        toast.info(response.data.status + ' ' + response.data.name);
                    }).catch((error) => {
                        toast.error('Error duplicating safety model - ' + error.message);
                    }).finally(() => {
                        setConfirmDuplicateSafetyModel(false);
                    });
                }}
                object={{objectType: ResourceType.SAFETY_MODELS, object: safetyModel}}            
                data={filterOnGroupCanEditResources(getAdditionalResource(ResourceType.GROUPS))}
                onClickNew={handleClickNew}
            />    
            <ExportEventSequenceSelectorDialogBox
                open={showExportEventSequence}
                safetyModel={safetyModel}
                eventSequenceList={eventSequenceList}
                onClose={(): void => { setShowExportEventSequence(false); }}
                onExport={(eventSequenceSelected: EventSequenceSummary | undefined): void => {
                    setShowExportEventSequence(false);
                    if (!eventSequenceSelected) {
                        return;
                    }
                    exportEventSequence(eventSequenceSelected);
                }}
            />
        </Box>
    );
}
