import React, {ReactElement} from 'react';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {Typography} from '@mui/material';
import {resourceIcon} from '../../utils/resource-icons';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {BreadCrumbStripProps} from '../../@types/bread-crumb';
import {ResourceType} from '../../@types/resource-type';

export function BreadCrumbStrip(props: BreadCrumbStripProps): ReactElement {
    const [indicationColor, setIndicationColor] = React.useState<string>('none');

    React.useEffect(() => {
        if (props.breadCrumbs && props.breadCrumbs.length > 0 && props.breadCrumbs[props.breadCrumbs.length-1].type === ResourceType.EVENT_SEQUENCE_HISTORY_TREES) {
            setIndicationColor('#800080');
        } else {
            setIndicationColor('none');
        }
    }, []);

    return (
        <Breadcrumbs
            aria-label='breadcrumb'
            separator={<NavigateNextIcon fontSize='small' />}
            sx={{ border: '1px solid', borderColor: 'divider', backgroundColor: indicationColor }}>
            {props.breadCrumbs.map((breadCrumb) => (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 0.5}} key={breadCrumb.id + breadCrumb.label}>
                    {resourceIcon(breadCrumb.type)}
                    <Typography color='text.primary' sx= {{ml: 1}} variant='caption'>
                        {breadCrumb.label}
                    </Typography>
                </Box>
            ))}
        </Breadcrumbs>

    );
}
