import {  ReactElement } from 'react'
import { useCallback } from 'react'

import { SortableItem } from './SortableItem'

export interface Item {
    id: number
    text: string
}

export interface ContainerState {
    cards: Item[]
}

export function SortableGroup(props: { cards: Item[], moveCard: (dragIndex: number, hoverIndex: number) => void}): ReactElement {
    const { cards, moveCard } = props;


    const renderCard = useCallback(
        (card: { id: number; text: string }, index: number) => {
            return (
                <SortableItem
                    key={card.id}
                    index={index}
                    id={card.id}
                    text={card.text}
                    moveCard={moveCard}
                />
            )
        },
        [],
    )

    return (
        <>
            <div>{cards.map((card, i) => renderCard(card, i))}</div>
        </>
    )
}

