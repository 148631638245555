import SaveAltIcon from '@mui/icons-material/SaveAlt';

import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress  from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormGroup from '@mui/material/FormGroup';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import React, { ReactElement, useEffect } from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import {  uuid } from '../../../../utils/utils';
import { toast } from 'react-toastify';
import { useWorkspaceContext } from '../../../../context/workspaceContext';
import { CanvasEnvironment } from '../../../../utils/canvas/canvas-environment';
import { ResourceType } from '../../../../@types/resource-type';
import EventSequenceService from '../../../../services/event-sequence.service';
import * as CanvasActions from '../../../../utils/canvas/canvas-actions';




function PaperComponent(props: PaperProps): ReactElement {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}

export default function EditEventFaultTreeModal(props: { open: boolean, env: CanvasEnvironment | null, onClose: () => void }): ReactElement {
    const { open, env, onClose } = props;
    const [name, setName] = React.useState<string | null>(null);
    const [uniqueId, setUniqueId] = React.useState<string | null>(null);
    const [description, setDescription] = React.useState<string | null>(null);
    const { setCurrentWorkspaceObject } = useWorkspaceContext();
    
    const [isRunning, setIsRunning] = React.useState<boolean>(false);
    const uuidRef = React.useRef(uuid());

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setName(event.target.value);
    };

    const onDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setDescription(event.target.value);
    };

    const validInput = (): boolean => {
        return (name !== null && name.trim() !== '');
    }

    useEffect(() => {
        if (open) {
            const [selectedNode] = env!.selection
            setUniqueId(selectedNode.get('data').faultTree.uniqueId);
            setName(selectedNode.get('data').faultTree.name);
            setDescription(selectedNode.get('data').faultTree.description);
            setIsRunning(false);
        }
    }, [open]);

    const handleSave = async (): Promise<any> => {
        const [selectedNode] = env!.selection;
        let eventFaultTreeObj = {
            uniqueId: uniqueId,
            id: selectedNode.get('data').faultTree.id,
            newUniqueId: uniqueId,
            name: name,
            description: description,
        }
        
        return EventSequenceService.updateEventFaultTree(eventFaultTreeObj);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            fullWidth
            maxWidth="sm">
            <DialogTitle style={{ cursor: 'move'}} id='draggable-dialog-title'>
                Edit Event Fault Tree
            </DialogTitle>
            <DialogContent dividers>
                <FormGroup>
                    <Stack>
                        <TextField
                            margin='dense'
                            type='text'
                            size='small'
                            id={`fault-tree-event-id-${uuidRef.current}`}
                            label='ID'
                            value={uniqueId || ''}
                            variant='outlined'
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                readOnly: true,
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `fault-tree-event-id-${uuidRef.current}`,
                                'aria-label': 'ID',
                            }}
                            disabled
                        />
                        <hr />
                        <TextField
                            id={`fault-tree-event-name-${uuidRef.current}`}
                            label='Name'
                            type='text'
                            margin='dense'
                            size='small'
                            value={name || ''}
                            variant="outlined"
                            onChange={onNameChange}
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `fault-tree-event-name-${uuidRef.current}`,
                                'aria-label': 'name',
                            }}
                            fullWidth
                        />
                        <TextField
                            id={`fault-tree-event-description-${uuidRef.current}`}
                            label='Description'
                            type='text'
                            margin='dense'
                            size='small'
                            variant='outlined'
                            value={description || ''}
                            multiline
                            minRows={3}
                            onChange={onDescriptionChange}
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `fault-tree-event-description-${uuidRef.current}`,
                                'aria-label': 'description',
                            }}
                            fullWidth
                        />
                    </Stack>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={isRunning ? <CircularProgress size={20} /> : <SaveAltIcon />}
                    onClick={async (): Promise<any> => {
                        setIsRunning(true);
                        try {
                            const response = await handleSave();
                            env!.isDirty = true;
                            setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                        } catch (error: any) {
                            toast.error(error.message);
                        } finally {
                            setIsRunning(false);
                            onClose();
                        }                                    
                    }}
                    disabled={!CanvasActions.canAddOrEditResource(env) || !validInput() || isRunning}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
   
}