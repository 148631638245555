import { BreadCrumbsProp } from './bread-crumb';
import Comparable from './comparable';
import {ResourceType} from './resource-type';

export default class TabItem implements Comparable<TabItem>{
    index: number = 0;
    value?: number;
    label: string = '';
    breadCrumbs: BreadCrumbsProp[] = [];
    docId?: number;
    type: ResourceType = ResourceType.UNKNOWN;
    objectToEdit: any;

    constructor(type: ResourceType, docId: number);
    constructor(type: ResourceType, docId: number, index: number, value: number, label: string, breadCrumbs: BreadCrumbsProp[]);

    constructor(...args: any[]) {
        if (args.length === 2) {
            this.docId = args[1];
            this.type = args[0];
        } else if (args.length === 6) {
            this.type = args[0];
            this.docId = args[1];
            this.index = args[2];
            this.value = args[3];
            this.label = args[4];
            this.breadCrumbs = args[5];
        }
    }

    compareTo(other: TabItem): number {
        if (this.type < other.type || !this.docId && other.docId) {
            return -1;
        }
        if (this.type > other.type || this.docId && !other.docId) {
            return 1;
        }
        return this.docId && other.docId ? this.docId - other.docId : 0;
    }
}