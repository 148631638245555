/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as joint from '@joint/plus';
import EventSequence from '../../@types/event-sequence';
import * as canvasActions from './canvas-actions';
import { CanvasController } from './canvas-controller';
import { EsdEvent } from '../../@types/esdEvent';
import { FaultTreeNode } from '../../@types/faultTreeNode';
import TagService from '../../services/tag-service';
import Tag, { TagLinks }  from '../../@types/tag';


export class CanvasEnvironment {
    public controller: CanvasController;
    public paper: joint.dia.Paper;
    public graph: joint.dia.Graph;
    public paperScroller: joint.ui.PaperScroller;
    public selection: joint.dia.Element[] = [];
    public activeTopNode: joint.dia.Element | null = null;
    public eventSequence: EventSequence | null = null;
    public isDirty = false;
    private resizeObserver: ResizeObserver | null = null;
    public allGroupTags: Tag[] = [];
    public esdTagLinks: TagLinks = { eventTags: [], faultTreeNodeTags: [] };
    public isTagsModeActive: boolean = true;
    public isCalloutsModeActive: boolean = false;
    public readonly: boolean = false;

    public HighlighterFrame = joint.dia.HighlighterView.extend({
        tagName: 'rect',
        attributes: {
            'stroke': '#ed2637',
            'stroke-width': 2,
            'fill': 'none',
            'pointer-events': 'none',
        },
    
        // Method called to highlight a CellView
        highlight(cellView: joint.dia.CellView) {
            const { padding = 0 } = this.options;
        
            const bbox = cellView.model.getBBox();
            // Highlighter is always rendered relatively to the CellView origin
            bbox.x = bbox.y = 0;
            // Increase the size of the Highlighter
            bbox.inflate(padding);
            this.vel.attr(bbox.toJSON());
        },
    });

    public contextMenuCallback: (evt: joint.dia.Event, elementView: joint.dia.ElementView) => void;
    public setSelectedISAMNode: (node: EsdEvent | FaultTreeNode) => void;

    constructor(paperElement: Element,
        contextMenuCallback: (evt: joint.dia.Event, elementView: joint.dia.ElementView) => void,
        setSelectedISAMNode: (node: EsdEvent | FaultTreeNode) => void,
        readonly: boolean) {
        this.contextMenuCallback = contextMenuCallback;
        this.setSelectedISAMNode = setSelectedISAMNode;
        this.readonly = readonly;

        this.graph = new joint.dia.Graph({}, { cellNamespace: canvasActions.allshapes });
        this.paper = new joint.dia.Paper({
            model: this.graph,
            gridSize: 1,
            defaultConnectionPoint: {name: 'boundary', args: {offset: 5}},
            defaultAnchor: { name: 'modelCenter' },
            defaultConnector: { name: 'normal' },
            async: true,
            frozen: true,
            interactive: function(cellView: joint.dia.CellView): any {
                if (cellView.model.get('nodeClass') !== 'CALLOUTNODE') return {
                    linkMove: false,
                    labelMove: false,
                    elementMove: false
                };
                return {
                    linkMove: false,
                    labelMove: false,
                    elementMove: true
                };
            
            },
            sorting: joint.dia.Paper.sorting.APPROX,
            cellViewNamespace: {...canvasActions.allshapes},
            background: {
                color: '#F8F9FA',
                image: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAIElEQVQoU2NkIALcuvU/jZEIdQyjCvGGEjh4QAQxYQkAIfcfBcQyCEYAAAAASUVORK5CYII=',
                repeat: 'repeat',
                size: '20px 20px'
            }
        });
        this.paperScroller = new joint.ui.PaperScroller({
            paper: this.paper,
            padding: { top: 50, left: 480 },
            scrollWhileDragging: true,
            inertia: true,
            autoResizePaper: true,
            borderless: true,
            baseWidth: 1000,
            baseHeight: 1000,
            contentOptions: () => {
                const minArea = new joint.g.Rect(0, 0, 4000, 3000);
                const contentArea = minArea.union(this.graph.getBBox()!);
                return {
                    contentArea
                }
            }
        });
        paperElement.appendChild(this.paperScroller.el);
        this.paperScroller.render();
        this.paperScroller.zoom(1.0, { absolute: true });
        

        this.enableVirtualRendering();

        this.controller = new CanvasController(this);

        // disable the default browser context menu
        window.addEventListener('contextmenu', (event) => {
            event.preventDefault();
        });

    }

    enableVirtualRendering() {
        let { paper, paperScroller: scroller, resizeObserver } = this;
        const viewportOverlap = 50;

        let viewportArea: joint.g.Rect;
        function updateViewportArea() {
            viewportArea = scroller.getVisibleArea().inflate(viewportOverlap);
        }

        updateViewportArea();

        // Watch viewport area
        scroller.el.onscroll = function () {
            updateViewportArea();
        };
        paper.on('scale', updateViewportArea);
        paper.on('blank:pointerdblclick', () => {
            // request full screen
            const element = scroller.el.parentNode as HTMLElement;
            if (element.requestFullscreen) {
                element.requestFullscreen().then(() => {
                    updateViewportArea();
                })
            }
        });


        paper.options.viewport = (view: joint.mvc.View<any>) => {
            const { model } = view;
            if (model.get('hidden')) return false;
            const bbox = model.getBBox();
            if (model.isLink()) {
                // Vertical/horizontal links have zero width/height.
                bbox.inflate(1);
            }
            return viewportArea.intersect(bbox) !== null;
        };

        resizeObserver = new ResizeObserver(() => {
            updateViewportArea();
        });

        resizeObserver.observe(scroller.el.parentNode as Element);

    }

    public destroy(): void {
        this.paper.remove();
        this.paperScroller.remove();
        this.resizeObserver?.disconnect();
    }

    public fetchTagsAndTagLinks(): Promise<void> {
        // fetch tags and tag links
        const groupId = this.eventSequence?.group?.id;
        const esdId = this.eventSequence?.id;
        
        return Promise.all([TagService.getGroupTags(groupId!), TagService.getEventSequenceTags(esdId!)]).then((values) => {
            this.allGroupTags = values[0];
            this.esdTagLinks = values[1];
            return;
        }).catch((error) => {
            throw new Error('Tag returned an error: ' + error);
        });
    }
}
