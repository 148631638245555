import * as React from 'react';
import {useState} from 'react';
import ExplorerContainer from '../ExplorerContainer.component';
import Dialog from '@mui/material/Dialog';
import {BasicPage, defaultPageLarge} from '../../../@types/spring-data-page';
import {WorkspaceResource} from '../../../@types/workspace-types';
import {ResourceType} from '../../../@types/resource-type';
import {
    fetchAllResources,
    fetchPagedWorkspaceObjects,
    getResourcesAsPage,
    getResourceTypeToOpen
} from '../../../utils/resource-utils';
import {toast} from 'react-toastify';
import {useWorkspaceContext} from '../../../context/workspaceContext';

export default function OpenResource(props: {
    open: boolean;
    onClose: () => void;
    addOrSelectResource: any;
}): React.ReactElement {
   
    const { open, onClose, addOrSelectResource } = props;
    const [objectsToSelect, setObjectsToSelect] = useState<WorkspaceResource|null>(null);
    const { currentWorkspaceObject, workspaceObjects, additionalResources, getAdditionalResource, addOrUpdateAdditionalResource } = useWorkspaceContext();

    React.useEffect(() => {
        setObjectsToSelect({
            objectType: currentWorkspaceObject ? currentWorkspaceObject.objectType : (workspaceObjects?.objectType ?? ResourceType.GROUPS)
        })
    }, [open]);

    const loadObjects = (setLoadingObjects: CallableFunction, page: BasicPage, groupId?: number, searchTerm?: string): void => {
        let type = getResourceTypeToOpen(objectsToSelect?.objectType ??
            (currentWorkspaceObject ? currentWorkspaceObject.objectType :
                (workspaceObjects ? workspaceObjects.objectType : ResourceType.GROUPS)));
        if (type === ResourceType.GROUPS) {
            const resources = getAdditionalResource(type);
            if (resources) {
                const pagedGroups = getResourcesAsPage(resources.resources, page, groupId, searchTerm);
                setObjectsToSelect({objectType: type, resources: pagedGroups.content, page: pagedGroups.page});
            }
        } else {
            const fetchObjectsPromise: Promise<any> | null = fetchPagedWorkspaceObjects(type, page ?? defaultPageLarge, groupId, searchTerm);
            if (fetchObjectsPromise) {
                setLoadingObjects(true);
                fetchObjectsPromise.then((objects) => {
                    if (objects.page && objects.content) {
                        setObjectsToSelect({
                            objectType: type, resources: objects.content,
                            page: {
                                number: objects.page.number,
                                totalElements: objects.page.totalElements,
                                size: objects.page.size,
                                totalPages: objects.page.totalPages
                            }
                        });
                    } else {
                        setObjectsToSelect({objectType: type, resources: objects});
                    }
                    if (!additionalResources.find(value => value.objectType === ResourceType.SAFETY_MODELS)) {
                        const fetchAllObjectsPromise: Promise<any> | null = fetchAllResources(ResourceType.SAFETY_MODELS);
                        if (fetchAllObjectsPromise) {
                            fetchAllObjectsPromise.then((allObjects) => {
                                addOrUpdateAdditionalResource({objectType: ResourceType.SAFETY_MODELS, resources: allObjects});
                            }).catch((error) => {
                                toast.error(error.message);
                            })
                        }
                    }
                }).catch((error) => {
                    toast.error(error.message);
                }).finally(() => {
                    setLoadingObjects(false);
                });
            }
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={'xs'} PaperProps={{
            sx: {
                minHeight: '80%',
                maxHeight: '80%'
            }
        }}>
            <ExplorerContainer
                loadObjects={loadObjects} objects={objectsToSelect} addOrSelectResource={addOrSelectResource} exploreMode={false} setObjects={setObjectsToSelect}
            />
        </Dialog>
    );
}


    