import * as React from 'react';
import {alpha, styled} from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, {MenuProps} from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

export interface ActionProps {
    name: string;
    disabled: boolean;
    action: () => void;
}
export interface ActionDropdownProps {
    onClick: () => void;
    actions1: ActionProps[];
    actions2: ActionProps[];
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function ActionDropdown(props: ActionDropdownProps): React.ReactElement {
    const { onClick, actions1, actions2 } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open: boolean = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
        onClick();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="action-button"
                aria-controls={open ? 'action-button' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Actions
            </Button>
            <StyledMenu
                id="action-button"
                MenuListProps={{
                    'aria-labelledby': 'action-menu-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {actions1 && actions1.map((action) => (
                    <MenuItem key={action.name} onClick={(): void => {action.action(); handleClose();}} disabled={action.disabled} disableRipple>
                        {action.name}
                    </MenuItem>
                ))}
                {actions2 && actions2.length > 0 && <Divider sx={{ my: 0.5 }} /> }
                {actions2 && actions2.map((action) => (
                    <MenuItem key={action.name} onClick={(): void => {action.action(); handleClose();}} disabled={action.disabled} disableRipple>
                        {action.name}
                    </MenuItem>
                ))}
            </StyledMenu>
        </div>
    );
}