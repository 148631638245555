import DeleteIcon from '@mui/icons-material/Delete';

import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress  from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, { ReactElement, useEffect } from 'react';
import { EsdEvent } from '../../../../@types/esdEvent';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

import { toast } from 'react-toastify';
import { useWorkspaceContext } from '../../../../context/workspaceContext';
import { CanvasEnvironment } from '../../../../utils/canvas/canvas-environment';
import { ResourceType } from '../../../../@types/resource-type';
import EventSequenceService from '../../../../services/event-sequence.service';
import Typography from '@mui/material/Typography';
import { uuid } from '../../../../utils/utils';
import * as CanvasActions from '../../../../utils/canvas/canvas-actions';

function PaperComponent(props: PaperProps): ReactElement {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}

export default function DeleteEventModal(props: { open: boolean, env: CanvasEnvironment | null, onClose: () => void }): ReactElement {
    const { open, env, onClose } = props;

    return env?.selection[0]?.get('data').type === 'INITIATING' ? 
        <DeleteEventNotAllowedOpenModal open={open} onClose={onClose} /> : 
        <DeleteEventAllowedOpModal open={open} env={env} onClose={onClose} />;
}

function DeleteEventAllowedOpModal(props: { open: boolean, env: CanvasEnvironment | null, onClose: () => void }): ReactElement {
    const { open, env, onClose } = props;
    const [uniqueId, setUniqueId] = React.useState<string>('');    
    const [hasChildren, setHasChildren] = React.useState<boolean>(false);
    const [children, setChildren] = React.useState<EsdEvent[]>([]);
    const [isRunning, setIsRunning] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<string>('0');
    const {  setCurrentWorkspaceObject } = useWorkspaceContext();
    const uuidRef = React.useRef(uuid());



    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(event.target.value);
    }
    
    useEffect(() => {
        if (open) {
            const [selectedNode] = env!.selection
            setUniqueId(selectedNode.get('data').uniqueId);
            let childIds = env!.selection[0].get('data').childIds;
            setHasChildren(childIds && childIds.length > 0);
            if (childIds && childIds.length > 0) {
                const childNodes = env!.graph.getNeighbors(selectedNode, { outbound: true }).filter((cell) => cell.get('nodeClass') !== 'CALLOUTNODE');
                setChildren(childNodes.map((node) => node.get('data')));
            } else {
                setChildren([]);
            }
            setValue('0');
            setIsRunning(false);
        }
    }, [open]);

    const handleSave = async (): Promise<any> => {
        const [selectedNode] = env!.selection
        let eventId = selectedNode.get('data').id
        let preserveChildId = parseInt(value);
        return EventSequenceService.deleteEvent(eventId, preserveChildId);
    }


    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            fullWidth
            maxWidth="sm">
            <DialogTitle style={{ cursor: 'move'}} id='draggable-dialog-title'>
                {'Delete Event ' + `${uniqueId}`} 
            </DialogTitle>
            <DialogContent dividers>
                <FormGroup>
                    <Typography>
                        {hasChildren ? 'This event has children. Check the branch that you would like to preserve!' : 'Are you sure you want to delete this event node?'}
                    </Typography>
                    <hr />
                    <FormControl>
                        {hasChildren && <FormLabel id={`radio-group-label-${uuidRef.current}`}>Branch</FormLabel>}
                        <RadioGroup
                            aria-labelledby={`radio-group-label-${uuidRef.current}`}
                            value={value}
                            onChange={handleChange}
                        >
                            {children.map((child) => (
                                <FormControlLabel key={child.id} value={child.id} control={<Radio />} label={`${child.uniqueId} - Branch ${child.parentRelationType}`} />
                            ))}
                            {hasChildren && <FormControlLabel value="0" control={<Radio />} label="Delete all downstream events" />}
                        </RadioGroup>
                    </FormControl>
                </FormGroup>

            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={isRunning ? <CircularProgress size={20} /> : <DeleteIcon />}
                    onClick={async (): Promise<any> => {
                        setIsRunning(true);
                        try {
                            const response = await handleSave();
                            env!.isDirty = true;
                            setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                        } catch (error: any) {
                            toast.error(error.message);
                        } finally {
                            setIsRunning(false);
                            onClose();
                        }                                    
                    }}
                    disabled={!CanvasActions.canAddOrEditResource(env) || isRunning}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
   
}

function DeleteEventNotAllowedOpenModal(props: { open: boolean, onClose: () => void }): ReactElement {
    const { open, onClose } = props;

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>
                {'Delete Event'}
            </DialogTitle>
            <DialogContent dividers>
                <Typography>
                    {'You cannot delete an Initiating event'}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={onClose}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    )
}