import Box from '@mui/material/Box';
import React, { ReactElement } from 'react';


export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
    ownerId: string;  // unique id of the tab that owns this tab panel
}

/* 
    IMPORTANT NOTE:
        ownerId is used to associate this tabpanel with its owner tab for accessibility purposes.
        The owner tab should have an aria-controls attribute with value equal to ownerId + '-tabpanel'
*/

export function TabPanel(props: TabPanelProps): ReactElement {
    const { children, value, index, ownerId, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={ownerId + '-tabpanel'}
            aria-labelledby={ownerId}
            style={{ overflowY: 'auto', width: '100%',  height: '100%', backgroundColor: 'white'}}
            {...other}    
            sx={{
                '&:focus': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
            }}                  
        >
            {(
                <Box sx={{ p: 0}}>
                    {children}
                </Box>
            )}
        </Box>
    );
}
