import {FC, ReactElement, useContext, useEffect} from 'react';
import AuthService from '../services/auth.service';
import OktaAuth from '@okta/okta-auth-js';
import {UserContext} from '../context/userContext';
import IntroComponent from '../components/Intro.component';

const Home: FC<any> = (): ReactElement => {
    const { oktaAuthOptions } = useContext(UserContext);

    useEffect(() => {
        if (!AuthService.getCurrentUser()) {
            AuthService.fetchSystemInfo().then((response) => {
                if (response.data.eimMode && oktaAuthOptions) {
                    const oktaAuth = new OktaAuth(oktaAuthOptions);
                    oktaAuth.setOriginalUri('/isam/react');
                    oktaAuth.token.getWithRedirect().then(
                        () => {
                        }
                    ).catch(() => {
                    });
                }
            });
        }
    }, []);

    // @ts-ignore
    return (
        <IntroComponent />
    );
};

export default Home;
