import React, {useContext, useEffect} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {OktaAuth, OktaAuthOptions, toRelativeUrl} from '@okta/okta-auth-js';
import {Security} from '@okta/okta-react';


// This CSS file can be used to override the FAA default theming
import './App.css';

import HeaderComponent from './components/Header.component';

import {routes as appRoutes} from './routes';
import FooterComponent from './components/Footer.component';
import AuthService from './services/auth.service';
import {UserContext} from './context/userContext';

function App(): JSX.Element {

    const { saveOktaAuthOptions } = useContext(UserContext);
    const [oktaAuth, setOktaAuth] = React.useState<OktaAuth>();
    const restoreOriginalUri = async (oktaAuth: OktaAuth, originalUri: string) : Promise<void> => {
        history.replaceState(history.state, '', toRelativeUrl(originalUri || '/react', window.location.origin));
    };

    // Occurs ONCE, AFTER the initial render.
    useEffect(() => {
        if (oktaAuth === undefined) {
            AuthService.fetchOktaProperties().then((response) => {
                const oktaProps: OktaAuthOptions = {
                    clientId: response.data.clientId,
                    issuer: response.data.issuer,
                    redirectUri: response.data.redirectUri,
                    scopes: response.data.scopes,
                    responseMode: response.data.responseMode,
                    pkce: response.data.pkce,
                    tokenManager: {
                        autoRenew: false
                    }
                }
                saveOktaAuthOptions(oktaProps);
                setOktaAuth(new OktaAuth(oktaProps));
            }).catch((e: any) => {
                // eslint-disable-next-line no-console
                console.log('App.tsx:', e);

                alert('Unable to connect to the services. Please verify the settings and try again. ' + e);
            });
        }
    }, []);

    // @ts-ignore
    return (
        <div className='App'>
            

            {/*
            Main page content goes here. These are loaded from pages. The appropriate page is
            loaded based on the route. We can either write the routes out one-by-one or we
            can use a loop to load the routes from a separate file. In this case, the routes
            are defined in the routes.tsx file.
            */}
            {oktaAuth !== undefined &&
                <Security oktaAuth={oktaAuth} onAuthRequired={() : void => history.replaceState(history.state, '', '/react/signin')} restoreOriginalUri={restoreOriginalUri} >
                    <BrowserRouter basename={'/isam'}>
                        <div style={{display: 'inline-block', width: '100%', verticalAlign: 'top'}}>
                            <HeaderComponent/>
                        </div>
                        <main className={'devkit-main-wrapper'} id={'main-content'}>
                            <Routes>
                                {appRoutes.map((route) => (
                                    (route.component) ?
                                        <Route key={route.key} path={route.path} element={<route.component/>}/> : null
                                ))}
                            </Routes>
                        </main>
                    </BrowserRouter>
                </Security>
            }

            <FooterComponent/>

        </div>
    );
}

export default App;
