import React, { ReactElement } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MenuItem, FormControl, InputLabel } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Group from '../../../@types/group';
import { GroupMember } from '../../../@types/group-member';
import { User } from '../../../context/userContext';
import usersService from '../../../services/users.service';
import {groupRoles } from '../../../utils/role-to-rights'
import { Autocomplete, TextField } from '@mui/material';
import { debounce } from '@mui/material/utils';
import { toast } from 'react-toastify';
import { uuid } from '../../../utils/utils';

export default function MemberInviter(props: {
    groupMembers: GroupMember[],
    group: Group,
    joinGroup: (groupMember: GroupMember) => Promise<GroupMember>,
}): ReactElement {
    const { groupMembers, group } = props;
    const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
    const [inputUserValue, setInputUserValue] = React.useState('');
    const [role, setRole] = React.useState(groupRoles.observer);
    const [users, setUsers] = React.useState<User[]>([]);
    const uuidRef = React.useRef(uuid());

    const fetch = React.useMemo(
        () =>
            debounce(
                (
                    request: { input: string },
                    callback: (results?: readonly User[]) => void,
                ) => {
                    const fetchUsers = async (searchTerm: string): Promise<any> => {
                        try {
                            const myUsers: User[] = await usersService.getUsers(searchTerm);
                            callback(myUsers);
                        }
                        catch (error: any) {
                            toast.error('Could not fetch users to choose from - ' + error.message);
                        }
                    }

                    fetchUsers(request.input); 
                },
                400,
            ),
        [],
    );    

    React.useEffect(() => {
        let active = true;

        if (inputUserValue === '') {
            setUsers(selectedUser ? [selectedUser] : []);
            return undefined;
        }

        fetch({ input: inputUserValue }, (results?: readonly User[]) => {
            if (active) {
                let newOptions: User[] = [];

                if (selectedUser) {
                    newOptions = [selectedUser];
                }   

                if (results) {
                    // Remove existing group members and the super user from the list of users
                    // who can be invited to the group
                    const directMembers = groupMembers.filter(
                        (member) => member.group.id == group.id
                    );
                    const uniqueUserIds = Array.from(new Set(directMembers.map((member) => member.isamUser.id)));
                    newOptions = [...newOptions,
                        ...results.filter((result) => !uniqueUserIds.includes(result.id) && !result.superUser)];
                }

                setUsers(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [selectedUser, inputUserValue, fetch]);



    const handleRoleChange = (event: SelectChangeEvent): void => {
        setRole(event.target.value as string);
    };

    const handleAddUser = (): void => {
        const member: GroupMember = {
            role: role,
            group: group,
            isamUser: selectedUser!
        };
        props.joinGroup(member);
        setSelectedUser(null);
    }

    return (
        <Box sx={{ width: '100%'}}>
            <Stack>
                <Typography variant="h6" component="div">
                    Add a member to the group
                </Typography>
                <br/>
                <Stack direction="row" spacing={2} justifyContent='space-evenly'>
                    <FormControl variant="standard" sx={{ width: '60%' }}>
                        <Autocomplete
                            id={`user-search-${uuidRef.current}`}
                            value={selectedUser}
                            onChange={(event: any, newValue: User | null): void => {
                                setSelectedUser(newValue);
                            }}
                            inputValue={inputUserValue}
                            onInputChange={(event, newInputValue): void => {
                                setInputUserValue(newInputValue);
                            }}
                            sx={{ width: '100%' }}
                            options={users}
                            getOptionLabel={(option): string => option.firstName + ' ' + option.lastName + ' - ' + option.email}
                            filterOptions={(x: any): any => x}
                            autoComplete
                            includeInputInList
                            filterSelectedOptions
                            noOptionsText={inputUserValue != '' && users.length == 0 ? 'No results found' : 'Type to search for a user'}
                            renderInput={(params): ReactElement => (
                                <TextField
                                    {...params}
                                    label="Search for a user to add to the group"
                                    variant='standard'
                                />)}
                        />
                    </FormControl>
                    <FormControl  variant="standard" sx={{width: '30%'}}>
                        <InputLabel id={`role-select-label-${uuidRef.current}`}>Choose a role</InputLabel>
                        <Select
                            labelId="role-select-label"
                            id={`role-select-label-${uuidRef.current}`}
                            value={role}
                            label="Role"
                            onChange={handleRoleChange}
                        >
                            {Object.entries(groupRoles).map(([key, value]) => (
                                <MenuItem key={key} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Button variant="contained" onClick={handleAddUser} disabled={selectedUser === null}>Join Group</Button>
                </Stack>

            </Stack>
            <br />
            
        </Box>
    );
}