import axios from 'axios';

import {API_BASE_URL, API_URL, MEDIA_TYPE_V2} from '../config/constants';
import {User} from '../context/userContext';
import {OktaAuth, OktaAuthOptions} from '@okta/okta-auth-js';
import OktaTokens from '../@types/okta-tokens';

class AuthService {

    signin(username: String, password: String, callback?: CallableFunction | null): Promise<any> {
        return axios
            .post(API_URL + 'auth/signin',
                {
                    'email': username,
                    'password': password
                },
                {
                    withCredentials: true,
                    headers: {'Content-Type': 'application/json'}
                }
            )
            .then((response: any) => {
                if (response.status === 200) {
                    if (callback) {
                        callback(response.data)
                    }
                } else {
                    alert('Signin returned a non-200 code: ' + response.status);
                }
                return response;
            });
    }

    signinWithTokens(tokens: OktaTokens, callback?: CallableFunction | null): Promise<any> {
        return axios
            .post(API_URL + 'auth/oauth2/token/jwt', tokens, {
                withCredentials: true,
                headers: {'Content-Type': MEDIA_TYPE_V2}
            })
            .then((response: any) => {
                if (response.status === 200) {
                    if (callback) {
                        callback(response.data)
                    }
                } else {
                    alert('Signin with JWT token returned a non-200 code: ' + response.status);
                }
                return response;
            }).catch(() => {
                alert('Failed to sign in with Okta Access Token. Please try again.');
            });
    }

    signout(callback?: CallableFunction | null): Promise<any> {
        return axios
            .post(API_URL + 'auth/signout', {},
                {
                    withCredentials: true,
                    headers: {'Content-Type': 'application/json'}
                }
            )
            .then((response: any) => {
                if (response.status === 200 || response.status === 201 || response.status === 204) {
                    this.oktaSignout().catch(() => {
                        //Do nothing
                    });
                    localStorage.removeItem('userProfile');
                    if (callback) {
                        callback();
                    }
                } else {
                    alert('Signout returned a non-200 code: ' + response.status);
                }
                return response;
            })
    }

    async oktaSignout(): Promise<any> {
        if (localStorage.getItem('oktaAuthOptions') !== null) {
            const oktaProps: OktaAuthOptions = JSON.parse(String(localStorage.getItem('oktaAuthOptions')));
            const oktaAuth = new OktaAuth(oktaProps);
            await oktaAuth.signOut({postLogoutRedirectUri: `${window.location.origin}/isam/react`})
                .then(() => {}).catch(() => {});
        }
    }

    register(firstname: String, lastname: String, email: String): Promise<any> {
        return axios.post(API_URL + 'auth/signup', {
            'firstname': firstname,
            'lastname': lastname,
            'email': email
        });
    }

    getCurrentUser() : User | null {
        /**
         * Fetch the current user's data from localstorage. If no user key exists,
         * the method will return null.
         */
        if (localStorage.getItem('userProfile') !== null) {
            return JSON.parse(String(localStorage.getItem('userProfile')));
        } else {
            return null;
        }
    }

    /**
     * Request a password reset link for a user.
     * @param email {string} the user's email
     * @return {Promise} the promise
     */
    requestResetPassword(email: string): Promise<any> {
        return axios.get(`${API_URL}auth/passwordResetRequest/email/${encodeURIComponent(email)}`);
    }

    /**
     * Verify a password reset token for a specific user.
     */
    verifyResetPasswordToken(email: string, token: string): Promise<any> {
        return axios.get(`${API_URL}auth/verifyPasswordResetRequest/email/${encodeURIComponent(email)}/token/${encodeURIComponent(token)}`);
    }

    resetPassword(email: String, token: String, password: String): Promise<any> {
        return axios.post(API_URL + 'auth/passwordReset', {
            'email': email,
            'token': token,
            'newPassword': password
        });
    }

    /**
     * Fetches the system info to check if it's running in EIM mode.
     */
    fetchSystemInfo(): Promise<any> {
        return axios.get(`${API_BASE_URL}systemInfo/system`);
    }

    /**
     * Fetches the OKTA configuration properties.
     */
    fetchOktaProperties(): Promise<any> {
        return axios.get(`${API_BASE_URL}systemInfo/oktaProperties`);
    }

    isAuthenticated() : boolean {
        /**
         * Quick boolean check on whether the user is authenticated. Returns true
         * if the 'userProfile' key is present in localStorage
         */
        return localStorage.getItem('userProfile') !== null;
    }

    isSuperUser(): boolean {
        /**
         * Check if the user is a super user. Returns true if the 'userProfile' key
         * is present in localStorage and the user's role is 'super'
         */
        if (localStorage.getItem('userProfile') !== null) {
            const userProfile = JSON.parse(String(localStorage.getItem('userProfile')));
            return userProfile.superUser;
        } else {
            return false;
        }
    }

}

const authService = new AuthService();
export default authService;
