import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import React from 'react';
import { useWorkspaceContext } from '../../../context/workspaceContext';
import { ResourceType } from '../../../@types/resource-type';
import { toast } from 'react-toastify';
import DotdotdotButton from '../../form-components/DotdotdotButton.component';
import DeleteResource from '../modals/DeleteResource.component';
import DuplicateResource from '../modals/DuplicateResource.component';
import Box from '@mui/material/Box';
import EventSequenceSummary from '../../../@types/event-sequence-summary';
import SafetyModel from '../../../@types/safety-model';
import eventSequenceService from '../../../services/event-sequence.service';
import {createWorkspaceObject, filterOnGroupCanEditResources} from '../../../utils/resource-utils';
import ResourceBase from '../../../@types/resource-base';
import {exportEventSequence} from '../../../utils/event-sequence-utils';

export default function EventSequenceActionsMenu(props: {
    eventSequenceSummary: EventSequenceSummary
}): React.ReactElement {
    const [confirmDeleteEventSequence, setConfirmDeleteEventSequence] = React.useState<boolean>(false);
    const [confirmDuplicateEventSequence, setConfirmDuplicateEventSequence] = React.useState<boolean>(false);
    const { setCurrentWorkspaceObject,
        setDeletedWorkspaceObject,
        setWorkspaceObjects, getAdditionalResource } = useWorkspaceContext();
    const [safetyModel, setSafetyModel] = React.useState<SafetyModel>({id: 0} as SafetyModel);
    const { eventSequenceSummary } = props;

    React.useEffect(() => {
        let safetyModels = getAdditionalResource(ResourceType.SAFETY_MODELS).resources as SafetyModel[];
        let safetyModel = safetyModels.find((safetyModel) => safetyModel.id == eventSequenceSummary.containerId);
        setSafetyModel(safetyModel ? safetyModel : {id: 0} as SafetyModel);
    }
    , [eventSequenceSummary]);

    function onEdit(): void {
        setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCES, object: eventSequenceSummary });
    }

    function onViewTree(): void {
        setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: eventSequenceSummary });
    }

    function onVersionHistory(): void {
        setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_HISTORY, object: eventSequenceSummary });
    }

    function onDelete(): void {
        setConfirmDeleteEventSequence(true);
    }

    function onDuplicate(): void {
        setConfirmDuplicateEventSequence(true);
    }

    const handleClickNew = (resource: ResourceBase): void => {
        setCurrentWorkspaceObject({objectType: resource.type, object: createWorkspaceObject(resource) });
        setConfirmDuplicateEventSequence(false);
    };

    function onExport(): void {
        exportEventSequence(eventSequenceSummary);
    }

    return (
        <Box tabIndex={0}  onClick={(event: any): void => {
            event.stopPropagation();
        }}>
            <DotdotdotButton> 
                <MenuItem id='edit' onClick={onEdit}>Edit</MenuItem>
                <MenuItem id='duplicate' onClick={onDuplicate}>Duplicate</MenuItem>
                <MenuItem id='viewTree' onClick={onViewTree}>View Model Tree</MenuItem>
                <Divider />
                <MenuItem id='history' onClick={onVersionHistory}>Version History</MenuItem>
                <Divider />
                <MenuItem id='export' onClick={onExport}>Export</MenuItem>
                
                {
                    safetyModel.id && safetyModel.group.canEdit() && <Divider />
                }
                
                {
                    safetyModel.id && safetyModel.group.canEdit() && <MenuItem id='delete' onClick={onDelete}>Delete</MenuItem>
                } 
            </DotdotdotButton>
            <DeleteResource
                open={confirmDeleteEventSequence}
                resourceType={ResourceType.EVENT_SEQUENCES}
                resourceName={eventSequenceSummary.name}
                subResources='all occurrence report linked to elements within it'
                handleDelete={(): void => {
                    setConfirmDeleteEventSequence(false);
                    eventSequenceService.deleteEventSequence(eventSequenceSummary.id).then(() => {
                        setDeletedWorkspaceObject({
                            objectType: ResourceType.EVENT_SEQUENCES,
                            id: eventSequenceSummary.id,
                            children: []
                        });
                        setWorkspaceObjects({ objectType: ResourceType.SAFETY_MODELS });
                        
                    }).catch((error) => {
                        toast.error('Error deleting sevent sequence - ' + error.message);
                    }).finally(() => {
                        setConfirmDeleteEventSequence(false);
                    });
                }}
                onClose={(): void => {
                    setConfirmDeleteEventSequence(false);
                }}
            />
            <DuplicateResource
                open={confirmDuplicateEventSequence}
                title={'Duplicate Event Sequence - ' + eventSequenceSummary.name}
                onClose={(): void => { setConfirmDuplicateEventSequence(false); }}
                onConfirm={(destinationSafetyModelId: number): void => {
                    let sourceResourceId = eventSequenceSummary.id;
                    setConfirmDuplicateEventSequence(false);
                    
                    eventSequenceService.duplicateEventSequence(sourceResourceId, destinationSafetyModelId).then(() => {
                        setWorkspaceObjects({ objectType: ResourceType.SAFETY_MODELS });
                    }).catch((error) => {
                        toast.error('Error duplicating event sequence - ' + error.message);
                    }).finally(() => {
                        setConfirmDuplicateEventSequence(false);
                    });
                }}
                object={{objectType: ResourceType.EVENT_SEQUENCES, object: eventSequenceSummary}}            
                data={filterOnGroupCanEditResources(getAdditionalResource(ResourceType.SAFETY_MODELS))}
                onClickNew={handleClickNew}
            />                
        </Box>
    );
}
