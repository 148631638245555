import React, {ReactElement, useRef, useState} from 'react';
import {Box, Button, Pagination, Stack} from '@mui/material';
import {generateTreeItems, getResourceNameWithAscendants} from '../../utils/resource-utils';
import LinearProgress from '@mui/material/LinearProgress';
import SelectList from '../form-components/SelectList.component';
import SearchField from '../form-components/SearchField.component';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import {WorkspaceResource} from '../../@types/workspace-types';
import {ExplorerTreeView} from './ExplorerTreeView.component';
import {ResourceType} from '../../@types/resource-type';
import {TreeViewItem} from '../../@types/tree-view-item';
import FormGroup from '@mui/material/FormGroup/FormGroup';
import {singularizeCamelCase, uuid} from '../../utils/utils';
import SafetyService from '../../services/safety.service';
import EventSequenceSummary from '../../@types/event-sequence-summary';
import {addOrUpdateSafetyModel, makeSafetyModel} from '../../utils/safety-model-utils';
import SafetyModel from '../../@types/safety-model';
import {useWorkspaceContext} from '../../context/workspaceContext';
import {toast} from 'react-toastify';
import {BasicPage, defaultPageLarge} from '../../@types/spring-data-page';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const pageSizes: number[] = [25, 50, 100, 150];

export default function Explorer(props: {
    exploreMode: boolean,
    label: string,
    type: ResourceType,
    objects: WorkspaceResource|null,
    additionalResources?: WorkspaceResource,
    onClickNew: React.MouseEventHandler<HTMLButtonElement>,
    onClickItem: React.EventHandler<any>,
    loadObjects: any,
    setObjects: (objects: WorkspaceResource|null) => void,
}): ReactElement {

    const {exploreMode, objects, additionalResources, onClickNew, onClickItem, loadObjects, setObjects } = props;
    const [objectsLoading, setObjectsLoading] = React.useState<boolean>(false);
    const [searchText, setSearchText] = React.useState<string>('');
    const [selectedResource, setSelectedResource] = React.useState<number>(0);
    const [page, setPage] = useState<BasicPage>(defaultPageLarge);
    const [expanded, setExpanded] = React.useState<string[]>([]);
    const { getAdditionalResource, addOrUpdateAdditionalResource } = useWorkspaceContext();
    const uuidRef = useRef(uuid());

    React.useEffect(() => {
        if (loadObjects && (objects === null || !objects.resources)) {
            setPage(defaultPageLarge);
            loadObjects(setObjectsLoading, defaultPageLarge, selectedResource, searchText);
            setExpanded([]);
        }
    }, [objects]);

    function onSelectChange(event: React.ChangeEvent<{ value: unknown }>): void {
        setSelectedResource(event.target.value as number);
        setPage(defaultPageLarge);
        loadObjects(setObjectsLoading, defaultPageLarge, event.target.value as number, searchText);
        setExpanded([]);
    }

    function onTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
        setSearchText(event.target.value);
        setPage(defaultPageLarge);
        loadObjects(setObjectsLoading, defaultPageLarge, selectedResource, event.target.value);
        setExpanded([]);
    }

    function onPageSizeChange(event: SelectChangeEvent<number>): void {
        let newPage: BasicPage = {...page, size: event.target.value as number, offset: 0};
        setPage(newPage);
        loadObjects(setObjectsLoading, newPage, selectedResource, searchText);
        setExpanded([]);
    }

    const onPageChange = (event: React.ChangeEvent<unknown>, pageNumber: number): void => {
        let newPage = {...page, offset: pageNumber - 1};
        setPage(newPage);
        loadObjects(setObjectsLoading, newPage, selectedResource, searchText);
        setExpanded([]);
    }

    function objects2TreeViewItems(objects: WorkspaceResource | null): TreeViewItem[] {
        return generateTreeItems(objects?.resources ?? [], objects?.objectType ?? ResourceType.UNKNOWN, getAdditionalResource(ResourceType.GROUPS).resources);
    }

    function resources2SelectListItems(resources: any[]|null|undefined): {id: number, name: string}[] {
        let items = [];
        if (resources) {
            for (let i = 0; i < resources.length; i++) {
                const resource = resources[i];
                try {
                    items.push({
                        id: resource.id,
                        name: getResourceNameWithAscendants(resource, ResourceType.GROUPS)
                    });
                } catch (e) {
                    throw new Error('Error converting resources to select list items: ' + e);
                }
            }
        }
        return items.sort((a, b) => a.name.localeCompare(b.name));
    }

    function updateSafetyModelChildren(safetyModel: SafetyModel): SafetyModel {
        if (safetyModel.children !== undefined && safetyModel.children.length > 0) {
            safetyModel.children.forEach(ess => ess.role = safetyModel.group.role());
        }
        return safetyModel;
    }

    return (
        <Box sx={{ display: 'flex', overflowY: 'auto', justifyContent: 'space-between', height: '100%', width: '100%', backgroundColor: '#FAFEFE' }}>
            <Stack sx={{width: '100%', overflowX: 'hidden', p: 0.8}}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 0.5, pr: 0 }}>
                    <Typography variant='h6' color='primary' gutterBottom>{props.label}</Typography>
                    {props.exploreMode && <Button
                        variant='contained'
                        title={`New ${singularizeCamelCase(props.label)}`}
                        size='small'
                        component='button'
                        onClick={onClickNew}
                        startIcon={<AddIcon />}
                    >New</Button>
                    }
                </Box>
                <FormGroup>
                    {props.type !== ResourceType.USERS &&
                        <SelectList
                            value={selectedResource}
                            label={'Select Group'}
                            items={resources2SelectListItems(additionalResources?.resources)}
                            onChange={onSelectChange} />
                    }
                    <SearchField label='' value={searchText} onTextChange={onTextChange} onClick={onTextChange} />
                </FormGroup>
                <React.Fragment>
                    {objectsLoading &&
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress aria-label={objectsLoading? 'progress bar': undefined} />
                        </Box>
                    }

                    <ExplorerTreeView
                        exploreMode={exploreMode}
                        nodes={objects2TreeViewItems(objects)}
                        onClickItem={onClickItem}
                        expanded={expanded}
                        onNodeToggle={(_event: React.SyntheticEvent, nodeIds: string[]): void => {
                            setExpanded(nodeIds);
                        }}
                        onNodeSelect={(_event: React.SyntheticEvent, nodeId: string): void => {
                            const [resourceType, resourceId] = nodeId.split('-');
                            if (resourceType === ResourceType.SAFETY_MODELS) {
                                const selectedSafetyModel: SafetyModel = objects?.resources?.find((resource: SafetyModel) => resource.id === parseInt(resourceId));
                                if (selectedSafetyModel && selectedSafetyModel.numberEventSequences > 0 && !selectedSafetyModel.children) {
                                    setObjectsLoading(true);
                                    SafetyService.getEventSequences(parseInt(resourceId)).then((eventSequences: EventSequenceSummary[]) => {
                                        const updatedSafetyModel = makeSafetyModel(selectedSafetyModel);
                                        eventSequences.forEach( es => es.role = updatedSafetyModel.group.role() );
                                        updatedSafetyModel.children = eventSequences;
                                        setObjects({
                                            objectType: ResourceType.SAFETY_MODELS,
                                            resources: addOrUpdateSafetyModel(updateSafetyModelChildren(updatedSafetyModel), objects?.resources as SafetyModel[]),
                                            page: objects?.page
                                        })
                                        addOrUpdateAdditionalResource({
                                            objectType: ResourceType.SAFETY_MODELS,
                                            resources: addOrUpdateSafetyModel(updatedSafetyModel, objects?.resources as SafetyModel[])
                                        })
                                        setExpanded([...expanded, nodeId]);
                                    }).catch((error) => {
                                        toast.error('Error loading event sequences for safety model ' + resourceId + ': ' + error.message)
                                    }).finally(() => {
                                        setObjectsLoading(false);
                                    });

                                }
                            }
                        }}
                    />
                </React.Fragment>
                <Stack direction={'row'} spacing={0} sx={{ alignSelf: 'center', marginTop: 'auto' }}>
                    <Select
                        variant='standard'
                        role='presentation'
                        labelId={`select-label-${uuidRef.current}`}
                        id={`select-${uuidRef.current}`}
                        value={page.size}
                        onChange={onPageSizeChange}
                        sx={{ fontSize: '14px' }}
                    >
                        {pageSizes.map(size => (
                            <MenuItem key={size} value={size}>{size} per page</MenuItem> ))}
                    </Select>
                    {objects && objects.page && objects.resources &&
                        <Pagination count={objects.page.totalPages} size={'small'} style={{ paddingTop: '2px', fontSize: '13px' }} showFirstButton showLastButton
                            page={page?.offset ? page.offset + 1 : 1} siblingCount={0} boundaryCount={1} onChange={onPageChange}
                        />
                    }
                </Stack>
            </Stack>
        </Box>
    )
}