import {GroupMember} from '../@types/group-member';
import {ResourceType} from '../@types/resource-type';
import {SelectedWorkspaceObject} from '../@types/workspace-types';
import Group from '../@types/group';

export interface GroupRoles {
    observer: string;
    contributor: string;
    analyst: string;
    admin: string;
}

export interface Roles {
    observer: string;
    contributor: string;
    analyst: string;
    admin: string;
    super: string;
}

export interface GroupRights {
    read: string;
    edit: string;
    leave: string;
    manageUser: string;
    addOrEditResources: string;
}

export interface ResourceRights {
    read: string;
    edit: string;
    score: string;
}

export const groupRoles: GroupRoles = {
    observer: 'OBSERVER',
    contributor: 'CONTRIBUTOR',
    analyst: 'ANALYST',
    admin: 'ADMIN'
};

export const roles: Roles = {
    ...groupRoles,
    super: 'SUPER'
};

export const groupRights: GroupRights = {
    read: 'read',
    edit: 'edit',
    leave: 'leave',
    manageUser: 'manageUser',
    addOrEditResources: 'addOrEditResources'
};

export const resourceRights: ResourceRights = {
    read: 'read',
    edit: 'edit',
    score: 'score'
};

const groupRoleWeights = {
    [ groupRoles.observer ]: 100,
    [ groupRoles.contributor ]: 200,
    [ groupRoles.analyst ]: 300,
    [ groupRoles.admin ]: 400
};

export const getRolesAbove = function(roleArg: string | undefined): string[] {
    // If no min role, return all roles
    const role: string = roleArg ?? groupRoles.observer;
    if (role === groupRoles.admin) {
        // If the user is an inherited admin, then that cannot change
        // so set the roles above to be the empty array
        return [];
    }
    const weight = groupRoleWeights[role];
    let roles = [];
    if (weight <= groupRoleWeights[ groupRoles.observer ]) {
        roles.push(groupRoles.observer);
    }
    if (weight <= groupRoleWeights[ groupRoles.contributor ]) {
        roles.push(groupRoles.contributor);
    }
    if (weight <= groupRoleWeights[ groupRoles.analyst ]) {
        roles.push(groupRoles.analyst);
    }
    if (weight <= groupRoleWeights[ groupRoles.admin ]) {
        roles.push(groupRoles.admin);
    }
    return roles;
};

export interface TieBreakerFn {
  (a: GroupMember, b: GroupMember): GroupMember;
}


export const getHighestEffectiveMembership = function (
    member1: GroupMember,
    member2: GroupMember,
    tieBreakerFn: TieBreakerFn
): GroupMember {
    if (
        !member2 ||
        member1.role === undefined ||
        member2.role === undefined
    ) {
        return member1;
    }

    if (groupRoleWeights[member1.role] > groupRoleWeights[member2.role]) {
        return member1;
    } else if (groupRoleWeights[member2.role] > groupRoleWeights[member1.role]) {
        return member2;
    } else if (tieBreakerFn) {
        return tieBreakerFn(member1, member2);
    } else {
        return member1;
    }
};


/**
 * @function roleToGroupRights
 * @description This is used to determine the frontend resource actions that the user can perform given the role
 * @param role 
 * @returns an array of rights or permissions
 */
const roleToResourceRights = (role: string | undefined): Array<string> => {
    const observerRights = [resourceRights.read];
    const contributorRights = [ ...observerRights, resourceRights.score ];
    const analystRights = [ ...contributorRights, resourceRights.edit ];
    const adminRights = analystRights;
    const superRights = analystRights;
    switch(role) {
        case roles.observer:
            return observerRights;
        case roles.contributor:
            return contributorRights;
        case roles.analyst:
            return analystRights;
        case roles.admin:
            return adminRights;
        case roles.super:
            return superRights;
        default:
            return [];
    }
};

/**
 * @function roleToGroupRights
 * @description This is used to determine the frontend group actions that the user can perform given the role
 * @param role 
 * @returns an array of rights or permissions
 */
const roleToGroupRights = (role: string | undefined): Array<string> => {
    const observerRights = [
        groupRights.read,
        groupRights.leave
    ];
    const contributorRights = observerRights;
    const analystRights = [ ...contributorRights, groupRights.addOrEditResources ];
    const adminRights = [ ...analystRights, groupRights.edit, groupRights.manageUser ];
    const superRights = [
        groupRights.read,
        groupRights.edit,
        groupRights.addOrEditResources,
        groupRights.manageUser
    ];
    switch(role) {
        case roles.observer:
            return observerRights;
        case roles.contributor:
            return contributorRights;
        case roles.analyst:
            return analystRights;
        case roles.admin:
            return adminRights;
        case roles.super:
            return superRights;
        default:
            return [];
    }
}

export default function roleToRights (role: string | undefined, resourceType: string): Array<string> {
    if (resourceType === 'group') {
        return roleToGroupRights(role);
    } else {
        return roleToResourceRights(role);
    }
}

export function hasRightToEdit(workspaceObject: SelectedWorkspaceObject, isSuperUser: boolean|undefined): boolean {
    const type = workspaceObject.objectType;
    if (type === ResourceType.GROUPS) {
        const role = isSuperUser ? roles.super : (workspaceObject.object instanceof Group ? workspaceObject.object.role() : 'unknown');
        const rights = roleToRights(role, 'group');
        return rights.includes(groupRights.edit);
    } else if (type === ResourceType.EVENT_SEQUENCE_HISTORY_TREES) {
        return false;
    } else {
        const role = isSuperUser ? roles.super : (workspaceObject.object.group ? workspaceObject.object.group.role() : workspaceObject.object.role);
        const rights = roleToRights(role, 'group');
        return rights.includes(groupRights.addOrEditResources);
    }
}
