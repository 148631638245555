import React, {useEffect, useState} from 'react';
import {Button, FormGroup, MenuItem, Select, Stack, TextField} from '@mui/material';
import Group from '../../../@types/group';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import {uuid} from '../../../utils/utils';
import {useWorkspaceContext} from '../../../context/workspaceContext';
import {ResourceType} from '../../../@types/resource-type';
import {getResourceNameWithAscendants} from '../../../utils/resource-utils';
import ErrorAlertComponent from '../../../components/ErrorAlert.component';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import SafetyModel from '../../../@types/safety-model';
import {addOrUpdateSafetyModel} from '../../../utils/safety-model-utils';
import SafetyService from '../../../services/safety.service';
import {SafetyModelEditorProps} from '../../../@types/editor-props';
import {ResourceAction} from '../../../@types/resource-action';

const SafetyModelEditor: React.FC<SafetyModelEditorProps> = (props: SafetyModelEditorProps) => {
    const { safetyModel: theSafetyModel, isActive, updateTab } = props;
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [exposure, setExposure] = useState(0);
    const [error, setError] = useState<string|null>(null);
    const [safetyModelGroupId, setSafetyModelGroupId] = React.useState<string|null>(null);

    const uuidRef = React.useRef(uuid());
    const { workspaceObjects, setWorkspaceObjects, setCurrentWorkspaceObject, currentAction, setCurrentAction, addOrUpdateAdditionalResource, getAdditionalResource } = useWorkspaceContext();

    const handleParentGroupChange = (event: any): void => {
        setSafetyModelGroupId(event.target.value as string);
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setName(event.target.value);
    };

    const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setDescription(event.target.value);
    };

    const handleExposureChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setExposure(parseInt(event.target.value));
    };

    const formSafetyModel = (): SafetyModel => {
        const safetyModelData = {} as SafetyModel;
        safetyModelData.name = name ?? '';
        safetyModelData.description = description;
        
        if (exposure > 0)
            safetyModelData.exposure = exposure;

        
        if (theSafetyModel && theSafetyModel.id) {
            safetyModelData.id = theSafetyModel.id;
        }
        safetyModelData.group = new Group();
        if (safetyModelGroupId != null) {
            safetyModelData.group.id = parseInt(safetyModelGroupId);
        } else {
            safetyModelData.group.id = groups[0].id;
        }
        return safetyModelData;
    }

    
    const updateSafetyModel = (safetyModel: SafetyModel): void => {
        let safetyModelObject = {objectType: ResourceType.SAFETY_MODELS, object: safetyModel};
        setCurrentWorkspaceObject(safetyModelObject);
        if (workspaceObjects?.objectType === ResourceType.SAFETY_MODELS) {
            setWorkspaceObjects({ objectType: ResourceType.SAFETY_MODELS, resources: addOrUpdateSafetyModel(safetyModel, workspaceObjects.resources as SafetyModel[]) });
            addOrUpdateAdditionalResource({ objectType: ResourceType.SAFETY_MODELS, resources: addOrUpdateSafetyModel(safetyModel, workspaceObjects.resources as SafetyModel[]) });
        }
        updateTab(safetyModelObject);
    }

    const handleSave = (): void => {
        saveSafetyModel();
    }

    const saveSafetyModel = (): void => {
        if (name) {
            setError(null);
            if (theSafetyModel.id) {
                SafetyService.patchSafetyModel(formSafetyModel())
                    .then((safetyModel: SafetyModel) => {
                        updateSafetyModel(safetyModel);
                    })
                    .catch((err: Error) => {
                        setError(err.message);
                    });
            } else {
                SafetyService.createSafetyModel(formSafetyModel())
                    .then((safetyModel: SafetyModel) => {
                        updateSafetyModel(safetyModel);
                    })
                    .catch((err: Error) => {
                        setError(err.message);
                    });
            }
        } else {
            setError('Safety Model name is required');
        }
        
    };

    const isNotEditable = (): boolean => theSafetyModel.id !== null && theSafetyModel.id !== undefined && !theSafetyModel.group.canAddOrEditResources();

    useEffect(() => {
        if (theSafetyModel) {
            if (theSafetyModel.id) {
                setName(theSafetyModel.name);
                setDescription(theSafetyModel.description);
                setExposure(theSafetyModel.exposure ?? 0);
            }
            if (theSafetyModel.group && theSafetyModel.group.id) {
                setSafetyModelGroupId(theSafetyModel.group.id.toString());
            }
            if (isActive && currentAction === ResourceAction.SAVE) {
                setCurrentAction(null);
                saveSafetyModel();
            }
        }

    }, [theSafetyModel]);

    let groups = getAdditionalResource(ResourceType.GROUPS).resources as Group[] || [];
    groups.sort((a, b) => a.name.localeCompare(b.name))

    return (
        <FormGroup className='formStyle'>
            <Stack className='formControl'>
                <TextField
                    label="Safety Model Name"
                    aria-label='safety model name'
                    type='text'
                    variant="outlined"
                    size='small'
                    id={`name-input-${uuidRef.current}`}
                    autoComplete='on'
                    value={name || ''}
                    onChange={handleNameChange}
                    sx={{
                        '& input': {
                            border: 'none',
                        },
                        '& input.Mui-disabled': {
                            cursor: 'not-allowed',
                        }

                    }}
                    InputProps={{
                        'role': 'textbox',
                        'tabIndex': 0,
                        'aria-labelledby': `name-input-${uuidRef.current}`,
                        'aria-label': 'safety model name'
                    }}
                    disabled={isNotEditable()}
                />
                <hr />
                <TextField
                    label='Safety Model Description (Optional)'
                    aria-label='safety model description'
                    type='text'
                    variant='outlined'
                    size='small'
                    id={`description-input-${uuidRef.current}`}
                    autoComplete='on'
                    value={description || ''}
                    onChange={handleDescriptionChange}
                    minRows={3}   
                    multiline
                    sx={{
                        '& input': {
                            border: 'none',
                        },
                        '& textarea.Mui-disabled': {
                            cursor: 'not-allowed',
                        }
                    }}
                    InputProps={{
                        'role': 'textbox',
                        'tabIndex': 0,
                        'aria-labelledby': `description-input-${uuidRef.current}`,
                        'aria-label': 'safety model description'
                    }}
                    disabled={isNotEditable()}
                />
                <hr />
                <TextField
                    label='Exposure (Optional)'
                    aria-label='safety model exposure'
                    type='number'
                    variant='outlined'
                    size='small'
                    id={`exposure-input-${uuidRef.current}`}
                    autoComplete='on'
                    value={exposure || ''}
                    onChange={handleExposureChange}
                    sx={{
                        '& input': {
                            border: 'none',
                        },
                        '& input.Mui-disabled': {
                            cursor: 'not-allowed',
                        }
                    }}
                    InputProps={{
                        'role': 'textbox',
                        'tabIndex': 0,
                        'aria-labelledby': `exposure-input-${uuidRef.current}`,
                        'aria-label': 'safety model exposure'
                    }}
                    disabled={isNotEditable()}
                />
                <hr />
                {groups.length > 0 ?
                    <FormControl
                        disabled={isNotEditable()}
                        sx={{
                            '& .MuiInputBase-input.Mui-disabled': {
                                cursor: 'not-allowed',
                            },
                            '& .MuiSelect-select.Mui-disabled': {
                                cursor: 'not-allowed',
                            },
                        }}
                    >
                        <InputLabel tabIndex={-1} id={`select-label-${uuidRef.current}`} htmlFor={`select-list-${uuidRef.current}`}>Group</InputLabel>
                        <Select
                            role='presentation'
                            labelId={`select-label-${uuidRef.current}`}
                            id={`group-label-${uuidRef.current}`}
                            size='small'
                            label='Group'
                            value={safetyModelGroupId || groups[0]?.id}
                            onChange={handleParentGroupChange}
                            inputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'id': `select-list-${uuidRef.current}`,
                            }}
                        >
                            {isNotEditable() ?
                                <MenuItem key={theSafetyModel.group.id} value={theSafetyModel.group.id} dense>{getResourceNameWithAscendants(theSafetyModel.group, ResourceType.GROUPS)}</MenuItem>
                                :
                                groups.filter(group => group.canAddOrEditResources()).map((group) => (
                                    <MenuItem key={group.id} value={group.id} dense>{getResourceNameWithAscendants(group, ResourceType.GROUPS)}</MenuItem>
                                ))}
                        </Select>

                    </FormControl> : null
                }
                <hr />
               
                <div>
                    <Button className='formBtn' id={`save-safety-model-${uuidRef.current}`}
                        size='small' variant='contained'
                        aria-label='save safety model'
                        title='Save Safety Model'
                        onClick={handleSave}
                        startIcon={<SaveAltIcon />}
                        disabled={isNotEditable()}
                        sx={{
                            '&:disabled': {     
                                cursor: 'not-allowed',
                                pointerEvents: 'all !important',
                            }
                        }}
                    >
                        Save
                    </Button>
                </div>
            
                {error ? <ErrorAlertComponent message={error} />  : null}
            </Stack>
        </FormGroup>
    );
};

export default SafetyModelEditor;
