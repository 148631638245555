import React, {ReactElement, useEffect, useState} from 'react';
import ErrorAlertComponent from '../../../components/ErrorAlert.component';
import Group from '../../../@types/group';
import GroupService from '../../../services/group.service';
import {Button, FormGroup, MenuItem, Select, Stack, TextField} from '@mui/material';
import {useWorkspaceContext} from '../../../context/workspaceContext';
import {addOrUpdateGroup} from '../../../utils/group-utils';
import {ResourceType} from '../../../@types/resource-type';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {getResourceNameWithAscendants} from '../../../utils/resource-utils';
import {uuid} from '../../../utils/utils';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import {ResourceAction} from '../../../@types/resource-action';
import {GroupEditorProps} from '../../../@types/editor-props';

export default function GroupEditor(props: GroupEditorProps): ReactElement {
    const { group, isActive, updateTab } = props;
    const { workspaceObjects, setWorkspaceObjects, currentAction, setCurrentAction, setCurrentWorkspaceObject,addOrUpdateAdditionalResource, getAdditionalResource } = useWorkspaceContext();
    const [name, setName] = useState<string>();
    const [description, setDescription] = useState<string>();
    const [error, setError] = useState<string|null>(null);
    const [parentGroup, setParentGroup] = React.useState('0');
    const uuidRef = React.useRef(uuid());

    const parentGroupChange = (event: any): void => {
        setParentGroup(event.target.value as string);
    };

    const onNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setName(event.target.value);
    };

    const onDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setDescription(event.target.value);
    };

    const formGroup = (): Group => {
        const grpData = new Group();
        grpData.name = name ?? '';
        grpData.description = description;

        if (group && group.id) {
            grpData.id = group.id;
        }
        if (parentGroup != '0') {
            grpData.parent = +parentGroup;
        } else {
            grpData.parent = undefined;
        }
        return grpData;
    }

    const updateGroup = (grp: Group): void => {
        let groupObject = {objectType: ResourceType.GROUPS, object: grp};
        setCurrentWorkspaceObject(groupObject);
        if (workspaceObjects?.objectType === ResourceType.GROUPS) {
            setWorkspaceObjects({ objectType: ResourceType.GROUPS, resources: addOrUpdateGroup(grp, workspaceObjects.resources as Group[]) });
            addOrUpdateAdditionalResource({ objectType: ResourceType.GROUPS, resources: addOrUpdateGroup(grp, workspaceObjects.resources as Group[]) });
        } else {
            let resourcesObject = getAdditionalResource(workspaceObjects?.objectType);
            if (resourcesObject && resourcesObject.resources) {
                let resources = [...resourcesObject.resources];
                resources.forEach((resource) => {
                    if (resource.group?.id === grp.id) {
                        resource.group = grp;
                    }
                });
                setWorkspaceObjects({ objectType: workspaceObjects?.objectType ?? ResourceType.GROUPS, resources: resources });
            } else {
                setWorkspaceObjects({ objectType: workspaceObjects?.objectType ?? ResourceType.GROUPS});
            }
            addOrUpdateAdditionalResource({ objectType: ResourceType.GROUPS, resources: addOrUpdateGroup(grp, groups) });
        }
        updateTab(groupObject);
    }

    const onClickSave = (_event: React.SyntheticEvent): void => {
        saveGroup();
    }

    const saveGroup = (): void => {
        if (name) {
            setError(null);
            if (group.id) {
                GroupService.patchGroup(formGroup())
                    .then((grp: Group) => {
                        updateGroup(grp);
                    })
                    .catch((err: Error) => {
                        setError(err.message);
                    });
            } else {
                GroupService.createGroup(formGroup())
                    .then((grp: Group) => {
                        updateGroup(grp);
                    })
                    .catch((err: Error) => {
                        setError(err.message);
                    });
            }
        } else {
            setError('Group name is required');
        }
    };

    useEffect(() => {
        if (group) {
            if (group.id) {
                setName(group.name);
                setDescription(group.description);
            }
            setParentGroup(group.parent ? group.parent + '' : '0');
            if (isActive && currentAction === ResourceAction.SAVE) {
                setCurrentAction(null);
                saveGroup();
            }
        }
    }, [group, currentAction]);

    let groups = getAdditionalResource(ResourceType.GROUPS).resources as Group[] || [];
    groups.sort((a, b) => a.name.localeCompare(b.name))

    return (
        <FormGroup className='formStyle'>
            <Stack className='formControl'>
                <TextField
                    label='Group Name'
                    aria-label='group name'
                    type='text'
                    variant='outlined'
                    size='small'
                    id={`name-input-${uuidRef.current}`}
                    autoComplete='on'
                    value={name || ''}
                    onChange={onNameChange}
                    sx={{
                        '& input': {
                            border: 'none',
                        }
                    }}
                    InputProps={{
                        'role': 'textbox',
                        'tabIndex': 0,
                        'aria-labelledby': `name-input-${uuidRef.current}`,
                        'aria-label': 'group name'
                    }}
                />
                <hr/>
                <TextField
                    label='Group Description (Optional)'
                    aria-label='group description'
                    type='text'
                    variant='outlined'
                    size='small'
                    id={`group-description-${uuidRef.current}`}
                    autoComplete='on'
                    value={description || ''}
                    onChange={onDescriptionChange}
                    sx={{
                        '& input': {
                            border: 'none',
                        }
                    }}
                    InputProps={{
                        'role': 'textbox',
                        'tabIndex': 0,
                        'aria-labelledby': `group-description-${uuidRef.current}`,
                        'aria-label': 'group description'
                    }}
                />
                <hr />
                <FormControl>
                    <InputLabel tabIndex={-1}  id={`select-label-${uuidRef.current}`} htmlFor={`select-list-${uuidRef.current}`}>Parent Group</InputLabel>
                    <Select
                        role='presentation'
                        labelId={`select-label-${uuidRef.current}`}
                        id={`parent-group-label-${uuidRef.current}`}
                        size='small'
                        label='Parent Group'
                        value={parentGroup || '0'}
                        onChange={parentGroupChange}
                        inputProps={{
                            'role': 'textbox',
                            'tabIndex': 0,
                            'id': `select-list-${uuidRef.current}`,
                        }}
                    >
                        <MenuItem value={0} dense>--Top Level--</MenuItem>
                        {groups.filter((grp) => grp.id != group.id).map((group) => (
                            <MenuItem key={group.id} value={group.id} dense>{getResourceNameWithAscendants(group, ResourceType.GROUPS)}</MenuItem>
                        ))}
                    </Select>

                </FormControl>
                <hr />
                {((group.id && group.canEdit()) || !group.id) &&
                <div>
                    <Button className='formBtn' id={`save-group-${uuidRef.current}`}
                        size='small' variant='contained'
                        aria-label='save group'
                        title='Save Group'
                        onClick={onClickSave}
                        startIcon={<SaveAltIcon />}
                    >Save</Button>
                </div>     
                }
                {error ? <ErrorAlertComponent message={error} />  : null}
            </Stack>
        </FormGroup>
    );
}