import React, {ReactElement} from 'react';

export default function PageTitleComponent(props: {title: string, leftNav?: boolean, printable?: boolean}): ReactElement {

    // This is the grid column for the main content area. If the left navigation is enabled, then the main content
    // area will start at column 7 and span 15 columns. If the left navigation is not enabled, then the main content
    // area will start at column 2 and span 15 columns.
    const gridColumns = props.leftNav ? {'gridColumn': '7 / span 15'} : {'gridColumn': '2 / span 21'};

    function openPrintDialog(): void {
    }

    return (
        <div className='devkit-main-head' style={{...gridColumns, 'textAlign': 'center'}}>
            <h1>{props.title}</h1>
            {
                props.printable ?
                    <div className='devkit-global-icon-wrapper' id='print-icon' role='button' tabIndex={0}
                        onKeyPress={openPrintDialog}
                        onClick={openPrintDialog}
                    >
                        <span className='fas fa-print' aria-hidden='true' title='Print' aria-label='Print'
                            aria-haspopup='true'/><span className='devkit-icon-text'>Print</span>
                    </div> : null
            }
        </div>

    );

}