import {ReactElement} from 'react';
import logo from '../vendor/images/FAA-logo.svg';

export default function FooterComponent(): ReactElement {

    return (
        <div>
            <div className='devkit-version-wrapper'>
                <p>v.10.0.0</p>
            </div>
            <div className='devkit-bottom-bar-wrapper' style={{'marginTop': '0'}}>
                <div className='devkit-logo-wrapper'>
                    <img className='devkit-logo-faa' src={logo} alt='FAA logo'
                        aria-describedby='devkit-logo-title' width='60' height='60' />
                    <div className='devkit-logo-title' id='devkit-logo-title'>
                        Federal Aviation Administration
                    </div>
                </div>
            </div>
        </div>
    )

}
