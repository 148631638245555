import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserHistory } from 'history';
import './vendor/css/devkitAll.css'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UserProvider from './context/userContext';

export const history = createBrowserHistory({
    // @ts-ignore
    basename: process.env.PUBLIC_URL
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    // <UserProvider>
    //     <App />
    // </UserProvider>

    <React.StrictMode>
        <UserProvider>
            <App />
        </UserProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
