export const DEFAULT_SIZE_SM = 5;
export const DEFAULT_SIZE = 10;
export const DEFAULT_SIZE_LG = 50;

export interface BasicPage {
    size: number;
    offset: number;
    direction: string;
    sort: string;
}

export interface PageInfo {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
}

export default interface SpringDataPage<T> {
    content: T[];
    page: PageInfo;
}

export const emptyPage: SpringDataPage<any> = {
    content: [],
    page: {
        number: 0,
        size: 0,
        totalElements: 0,
        totalPages: 0
    } as PageInfo
}

export const defaultPageSmall: BasicPage = {
    size: DEFAULT_SIZE_SM,
    offset: 0,
    direction: 'DESC',
    sort: 'id',
};

export const defaultPage: BasicPage = {
    size: DEFAULT_SIZE,
    offset: 0,
    direction: 'ASC',
    sort: 'name',
};

export const defaultPageLarge: BasicPage = {
    size: DEFAULT_SIZE_LG,
    offset: 0,
    direction: 'ASC',
    sort: 'name',
};
