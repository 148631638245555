import {isEmpty, isInteger, isNumeric} from './utils';

class ValidationResult {
    isValid: boolean;
    reason: string;

    constructor(isValid = false, reason = '') {
        this.isValid = isValid;
        this.reason = reason;
    }
}

// This result will be returned when the catch block is triggered
const unexplainedResult: ValidationResult = new ValidationResult(false, '');

// This result will be returned when the value is valid
const trueResult: ValidationResult = new ValidationResult(true, '');

export class Validator {
    callback: Function;
    params: any[];

    constructor(callback: Function, params: any[]) {
        this.callback = callback;
        this.params = params;
    }

    validate(): ValidationResult {
        return this.callback(...this.params);
    }
}

export class Validators {
    validatorArray: Validator[];

    constructor(validatorArray: Validator[] = []) {
        this.validatorArray = validatorArray;
    }

    validate(): ValidationResult {
        return this.validatorArray.reduce((prev: ValidationResult, currentValidator: Validator) => {
            return prev.isValid ? currentValidator.validate() : { isValid: false, reason: prev.reason };
        }, trueResult);
    }
}

/** ********************************************************
 * Base-level validation functions
 ******************************************************** */

export const noShorterThan = (value: string, max: number, reason?: string): ValidationResult | typeof unexplainedResult => {
    try {
        reason = reason || `must be at least ${max} character(s) long`;
        if (value.length >= max) {
            return trueResult;
        } else {
            return new ValidationResult(false, reason);
        }
    } catch(err) {
        return unexplainedResult;
    }
};

export const noLongerThan = (value: string, max: number, reason?: string): ValidationResult | typeof trueResult => {
    try {
        reason = reason || `must be no longer than ${max} character(s)`;
        if (value.length <= max) {
            return trueResult;
        } else {
            return new ValidationResult(false, reason);
        }
    } catch(err) {
        return unexplainedResult;
    }
};

export const wellFormedEmail = (value: string, reason?: string): ValidationResult | typeof trueResult => {
    try {
        reason = reason || 'must be a valid email';
        if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
            return trueResult;
        } else {
            return new ValidationResult(false, reason)
        }
    } catch(err) {
        return unexplainedResult;
    }
};

export const isInt = (value: string, allowBlank: boolean, reason?: string): ValidationResult | typeof trueResult => {
    try {
        reason = reason || 'must be a valid integer';
        if (isEmpty(value)) {
            return trueResult;
        }
        else if (isInteger(parseInt(value))) {
            return trueResult;
        } else {
            return new ValidationResult(false, reason)
        }
    } catch(err) {
        return unexplainedResult;
    }
};

export const isFloat = (value: string, allowBlank: boolean, reason?: string): ValidationResult | typeof trueResult => {
    try {
        reason = reason || 'must be a valid number';
        if (isEmpty(value)) {
            return trueResult;
        }
        else if (isNumeric(value)) {
            return trueResult;
        } else {
            return new ValidationResult(false, reason)
        }
    } catch(err) {
        return unexplainedResult;
    }
};

/** ****************************************************
 * Mid-level validation functions
 **************************************************** */

export const validateLengthBetweenRange = (value: any, min: number, max: number, minReason?: string, maxReason?: string): ValidationResult => {
    const validators: Validators = new Validators([
        new Validator(noShorterThan, [ value, min, minReason ]),
        new Validator(noLongerThan, [ value, max, maxReason ])
    ]);
    return validators.validate();
};

/** **************************************************
 * Top-level validation functions
 ************************************************** */

export const validateStringWithMinMax = (min: number, max: number): (value: string) => ValidationResult => {
    return function validateIt(value: string) {
        return validateLengthBetweenRange(value, min, max);
    };
};

export const validateVarchar255 = (value: string, min: number = 0, max: number = 255): ValidationResult => {
    return validateLengthBetweenRange(value, min, max);
};

export const validateName = (value: string, min: number = 2, max: number = 40): ValidationResult => {
    return validateLengthBetweenRange(value, min, max);
};

export const validateDescription = (value: string, min: number = 2, max: number = 1024): ValidationResult => {
    return validateLengthBetweenRange(value, min, max);
};

export const validateGroupName = (value: string): ValidationResult => {
    const min: number = 1;
    const max: number = 48;
    return validateName(value, min, max);
};

/**
 * Gets the part of the email before the @ sign
 * @param {string} emailAddress the full email address
 * @returns a string
 */
// Issue #494: max length of email address local part (before @) is 64 characters
const getLocalPartOfEmail = (emailAddress: string | any): string | any => {
    if (typeof emailAddress !== 'string') {
        return emailAddress;
    }
    return emailAddress.split('@')[0];
};

export const validateEmail = (value: string): ValidationResult => {
    const max: number = 254;
    const localPartMax: number = 64;
    const localMaxReason: string = `(the part before the @ sign) must be shorter than ${localPartMax + 1} characters`;
    const validators: Validators = new Validators([
        new Validator(wellFormedEmail, [ value ]),
        new Validator(noLongerThan, [ value, max ]),
        new Validator(noLongerThan, [ getLocalPartOfEmail(value), localPartMax, localMaxReason ])
    ]);
    return validators.validate();
};

export const passwordMinLength: number = 8;
export const passwordMaxLength: number = 20;
export const passwordHint: string = `Password must contain ${passwordMinLength}-${passwordMaxLength} characters`;

export const validatePassword = (value: string, minReason?: string, maxReason?: string): ValidationResult => {
    const min: number = 8;
    const max: number = 20;
    return validateLengthBetweenRange(value, min, max, minReason ?? passwordHint, maxReason ?? passwordHint);
};

export const validatePresent = (value: string): ValidationResult => {
    const min: number = 1;
    const max: number = 100000;
    return validateLengthBetweenRange(value, min, max);
};

export const validateInt = (value: number): ValidationResult => {
    const validators: Validators = new Validators([
        new Validator(isInt, [ value, true ])
    ]);
    return validators.validate();
};

export const validateFloat = (value: number): ValidationResult => {
    const validators: Validators = new Validators([
        new Validator(isFloat, [ value, true ])
    ]);
    return validators.validate();
};
