import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React, {ReactElement, useContext, useRef} from 'react';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {format} from 'date-fns';
import EventSequenceVersion from '../../../@types/esd-version-props';
import EventSequenceService from '../../../services/event-sequence.service';
import {EventSequenceSummaryEditorProps} from '../../../@types/editor-props';
import SpringDataPage, {BasicPage, defaultPageSmall} from '../../../@types/spring-data-page';
import {useWorkspaceContext} from '../../../context/workspaceContext';
import {ResourceType} from '../../../@types/resource-type';
import EditIcon from '@mui/icons-material/Edit';
import {IconButton, styled, TablePagination} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import * as jsondiffpatch from 'jsondiffpatch';
// @ts-ignore
import * as htmlFormatter from 'jsondiffpatch/formatters/html';
import 'jsondiffpatch/formatters/styles/html.css';
import DOMPurify from 'isomorphic-dompurify';
import {toast} from 'react-toastify';
import parse from 'html-react-parser';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import {getValuesOnly, uuid} from '../../../utils/utils';
import {hasRightToEdit} from '../../../utils/role-to-rights';
import {UserContext} from '../../../context/userContext';
import DiffResource from '../modals/DiffResource.component';
import ActionDropdown from '../../form-components/ActionDropdown.component';
import OkCancelModal from '../../form-components/OkCancelModal.component';

enum ComparisonType {
    LATEST = 'Latest',
    PREVIOUS = 'Previous',
    MARKED = 'Marked'
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.selected,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function EsdHistoryEditor(props: EventSequenceSummaryEditorProps): ReactElement {
    const { eventSequenceSummary, isActive } = props;
    const { currentWorkspaceObject, setCurrentWorkspaceObject } = useWorkspaceContext();
    const { user } = useContext(UserContext);
    const uuidRef = useRef(uuid());
    const [visibleVersions, setVisibleVersions] = React.useState<EventSequenceVersion[]>([]);
    const [currentPage, setCurrentPage] = React.useState<SpringDataPage<EventSequenceVersion>|null>(null);
    const [activeVersion, setActiveVersion] = React.useState<EventSequenceVersion|null>(null);
    const [page, setPage] = React.useState<BasicPage>(defaultPageSmall);
    const [invisibleVersion, setInvisibleVersion] = React.useState<EventSequenceVersion|null>(null);
    const [editComment, setEditComment] = React.useState<boolean>(false);
    const [selectedVersion, setSelectedVersion] = React.useState<EventSequenceVersion|null>(null);
    const [comment, setComment] = React.useState<string>('');
    const [diffView, setDiffView] = React.useState<boolean>(false);
    const [markedVersion, setMarkedVersion] = React.useState<EventSequenceVersion|null>(null);
    const [compareType, setCompareType] = React.useState<ComparisonType|null>(null);
    const [confirmRestore, setConfirmRestore] = React.useState<boolean>(false);
    const [searchTerm, setSearchTerm] = React.useState<string>('');

    const fetchVersionPage = (): void => {
        const fetch = EventSequenceService.fetchEventSequenceVersions(eventSequenceSummary.id, page, searchTerm);
        if (fetch) {
            fetch.then((data) => {
                if (data.content && data.content.length > 0) {
                    const versions = data.content.map((version, index) => {
                        const revision = data.page.totalElements - (page.size * page.offset) - index;
                        const prettyDiff = version?.diff?.map((d) => getValuesOnly(d)).join();
                        return {
                            ...version,
                            revision: revision,
                            prettyDiff: prettyDiff
                        };
                    });
                    setCurrentPage( {...data, content: versions });
                    setVisibleVersions(versions);
                    const bottomVersion = data.content[data.content.length - 1];
                    if (bottomVersion.precedingVersionId) {
                        EventSequenceService.fetchEventSequenceVersion(eventSequenceSummary.id, bottomVersion.precedingVersionId)
                            .then((version) => {
                                setInvisibleVersion(version);
                            }).catch((error) => {
                                toast.error('Failed fetching event version with error ', error);
                            });
                    } else {
                        setInvisibleVersion(null);
                    }
                } else {
                    setCurrentPage({content: [], page: data.page } );
                    setVisibleVersions([]);
                }
            }).catch((error) => {
                toast.error('Failed fetching event sequence version history, with error ', error);
            });
        }
    };
    
    React.useEffect(() => {
        if (eventSequenceSummary && isActive) {
            const fetchLatest = EventSequenceService.fetchEventSequenceActiveVersion(eventSequenceSummary.id);
            if (fetchLatest) {
                fetchLatest.then((data) => {
                    if (!activeVersion || activeVersion.id !== data.id) {
                        setPage(defaultPageSmall);
                        fetchVersionPage();
                    }
                    setActiveVersion(data);
                }).catch((error) => {
                    toast.error('Failed fetching event sequence active version, with error ', error);
                });
            }
        }
    }, [eventSequenceSummary, isActive]);

    React.useEffect(() => {
        if (eventSequenceSummary) {
            fetchVersionPage();
        }
    }, [page, searchTerm]);
    
    function jsonDiff2Human(params: (EventSequenceVersion|null)[]): string {
        let delta = diffDelta(params);
        if (delta !== undefined) {
            return DOMPurify.sanitize(htmlFormatter.format(delta, null));
        } else {
            return '';
        }
    }

    function diffDelta(params: (EventSequenceVersion|null)[]): jsondiffpatch.Delta | undefined {
        const [left, right] = params;
        const earlier = left === null ? left : (right === null ? right : (right.id > left.id ? left : right));
        const later = earlier === left ? right : left;
        jsondiffpatch.create({});
        return jsondiffpatch.diff( earlier !== null ? earlier.eventSequenceDTO : '', later !== null ? later.eventSequenceDTO : '');
    }

    function findVersionToCompare(): EventSequenceVersion|null {
        switch (compareType) {
            case ComparisonType.LATEST:
                return activeVersion;
            case ComparisonType.PREVIOUS:
                return findPreviousVersion(selectedVersion);
            case ComparisonType.MARKED:
                return markedVersion;
            default:
                return null;
        }
    }

    function goToEsd(): void {
        setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: eventSequenceSummary });
    }

    const handleChangePage = (event: unknown, newPage: number): void => {
        setPage({
            size: page.size,
            offset: newPage,
            direction: page.direction,
            sort: page.sort,
        });
    };

    const updateCommentText = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setComment(event.target.value);
    }

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPage({
            size: +event.target.value,
            offset: 0,
            direction: page.direction,
            sort: page.sort,
        });
    };

    function handleEditComment(esv: EventSequenceVersion, comment: string): void {
        setSelectedVersion(esv);
        setComment(comment);
        setEditComment(true);
    }

    function updateComment(): void {
        EventSequenceService.postTag(eventSequenceSummary.id, selectedVersion!.id, comment)
            .then((esv) => {
                currentPage?.content.forEach((version) => {
                    if (version.id === esv.id) {
                        version.comment = esv.comment;
                    }
                });
                visibleVersions.forEach((version) => {
                    if (version.id === esv.id) {
                        version.comment = esv.comment;
                    }
                })
                setEditComment(false);
            }).catch((error) => {
                toast.error('Failed to update comment for version ' + selectedVersion + ' with error ', error);
            });
    }

    function restoreEsd(): void {
        EventSequenceService.restoreEventSequence(eventSequenceSummary.id, selectedVersion!.id, comment)
            .then((esd) => {
                setPage({
                    size: page.size,
                    offset: 0,
                    direction: page.direction,
                    sort: page.sort,
                });
                setCurrentWorkspaceObject({objectType: ResourceType.EVENT_SEQUENCE_TREES, object: esd});
            }).catch((error) => {
                toast.error('Failed to restore event sequence to version ' + selectedVersion!.id + ' with error ', error);
            });
    }

    const onSearchTextChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setSearchTerm(event.target.value);
        setPage({...page, offset: 0});
    }

    const findVersion = (esvId: number): EventSequenceVersion|null => {
        if (invisibleVersion?.id === esvId) return invisibleVersion;
        return currentPage?.content.find((esv) => esv.id === esvId) ?? null;
    }

    const findPreviousVersion = (esv: EventSequenceVersion|null): EventSequenceVersion|null => {
        return esv && esv.precedingVersionId ? findVersion(esv.precedingVersionId) : null;
    }

    return (
        <>
            <Box sx={{height: '100%', p: 1}}>
                <Box sx={{width: '100%'}}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 0.5, pr: 0 }}>
                        <Typography variant="h6" component="div" width='50%'>
                            Version History - {eventSequenceSummary.name} ({eventSequenceSummary.uniqueId})
                        </Typography>
                        <div style={{'justifyContent': 'right'}}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 0.5, pr: 0 }}>
                                <TextField
                                    label='Search'
                                    aria-label='search field'
                                    type='text'
                                    variant='outlined'
                                    size='small'
                                    id={`search-box-${uuidRef.current}`}
                                    onChange={onSearchTextChange}
                                    sx={{
                                        ml: 1, mr: 1, mt: 1,
                                    }}

                                    InputProps={{
                                        'role': 'searchbox',
                                        'tabIndex': 0,
                                        'aria-labelledby': `search-box-${uuidRef.current}`,
                                        'aria-label': 'search',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton aria-label='search icon' onClick={():void  => {}}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TablePagination
                                    component="div"
                                    count={currentPage ? currentPage.page.totalElements : 0}
                                    page={page.offset}
                                    onPageChange={handleChangePage}
                                    rowsPerPageOptions={[5, 10, 20, 30]}
                                    rowsPerPage={page.size}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </Box>
                        </div>
                    </Box>
                    <Stack spacing={2} direction="column" justifyContent='space-evenly'>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Result Number</TableCell>
                                        <TableCell align="left">Action</TableCell>
                                        <TableCell align="left">Date</TableCell>
                                        <TableCell align="left">User</TableCell>
                                        <TableCell align="left">Comment</TableCell>
                                        <TableCell align="left">Summary</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {visibleVersions && visibleVersions.map((esv) => (
                                        <StyledTableRow
                                            key={esv.revision}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}, verticalAlign: 'top' }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {esv.revision}
                                            </TableCell>
                                            <TableCell align="left">
                                                {activeVersion && esv.id === activeVersion.id ?
                                                    <a tabIndex={1} onClick={goToEsd}>
                                                        <span className='far fa-user' aria-hidden='true' title='Latest'
                                                            aria-label='Latest'/>
                                                        <span className='devkit-tool-bar-text'
                                                            aria-label='Latest'>Latest</span>
                                                    </a>
                                                    :
                                                    <ActionDropdown
                                                        onClick={(): void => { setSelectedVersion(esv) }}
                                                        actions1={[
                                                            {
                                                                name: 'Compare with Latest Revision',
                                                                disabled: false,
                                                                action: (): void => { setCompareType(ComparisonType.LATEST); setDiffView(true); }
                                                            },
                                                            {
                                                                name: 'Compare with Previous Revision',
                                                                disabled: esv.precedingVersionId === null || esv.precedingVersionId === undefined,
                                                                action: (): void => { setCompareType(ComparisonType.PREVIOUS); setDiffView(true); }
                                                            },
                                                            {
                                                                name: 'Compare with Marked Revision',
                                                                disabled: markedVersion === null || markedVersion.id === selectedVersion?.id,
                                                                action: (): void => { setCompareType(ComparisonType.MARKED); setDiffView(true); }
                                                            }
                                                        ]}
                                                        actions2={[
                                                            {
                                                                name: 'Mark For Comparison',
                                                                disabled: markedVersion !== null && markedVersion.id === esv.id,
                                                                action: (): void => { setMarkedVersion(esv); toast.info('Marked Version: ' + esv.id); }
                                                            },
                                                            {
                                                                name: 'View',
                                                                disabled: false,
                                                                action: (): void => { setCurrentWorkspaceObject({objectType: ResourceType.EVENT_SEQUENCE_HISTORY_TREES, object: selectedVersion}); }
                                                            },
                                                            {
                                                                name: 'Comment',
                                                                disabled: !currentWorkspaceObject || !hasRightToEdit(currentWorkspaceObject, user?.superUser),
                                                                action: (): void => { handleEditComment(esv, esv.comment); }
                                                            },
                                                            {
                                                                name: 'Restore',
                                                                disabled: !currentWorkspaceObject || !hasRightToEdit(currentWorkspaceObject, user?.superUser),
                                                                action: (): void => { setConfirmRestore(true); }
                                                            }
                                                        ]}
                                                    />
                                                }
                                            </TableCell>
                                            <TableCell>{format(new Date(esv.created), 'MMMM do, yyyy H:mma')}</TableCell>
                                            <TableCell>{esv.userId}</TableCell>
                                            <TableCell>
                                                {currentWorkspaceObject && hasRightToEdit(currentWorkspaceObject, user?.superUser) && <IconButton size='small'
                                                    style={{position: 'relative', float: 'right'}}
                                                    component='button'
                                                    edge='end'
                                                    onClick={(): void => { handleEditComment(esv, esv.comment); }}>
                                                    <EditIcon sx={{width: 25, height: 30}}/>
                                                </IconButton>}
                                                {esv.comment}
                                            </TableCell>
                                            <TableCell style={{height: '150px', width: '400px'}}
                                                className='isam-grow-container'>
                                                <div style={{height: '150px', width: '400px', overflow: 'auto'}}>
                                                    { parse(jsonDiff2Human([findPreviousVersion(esv), esv])) }
                                                </div>
                                                <div style={{display: 'flex', 'justifyContent': 'right'}}>
                                                    <LaunchIcon fontSize={'small'} color={'primary'} onClick={(): void => { setSelectedVersion(esv); setCompareType(ComparisonType.PREVIOUS); setDiffView(true); }} />
                                                </div>
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Stack>
                </Box>
            </Box>
            <OkCancelModal
                open={editComment}
                title={`Update comment for event sequence version ${selectedVersion?.id ?? ''}:`}
                onClose={(): void => { setEditComment(false); }}
                okButtonText='Save'
                onConfirm={updateComment}
                okToConfirm={(): boolean => { return currentWorkspaceObject !== null && hasRightToEdit(currentWorkspaceObject, user?.superUser); }}
            >
                <TextField
                    autoFocus
                    margin='dense'
                    id='confirm-text-input'
                    variant='filled'
                    size='small'
                    defaultValue={comment}
                    onChange={updateCommentText}
                    fullWidth
                />
            </OkCancelModal>
            <DiffResource
                open={diffView}
                resourceA={compareType + ' (' + findVersionToCompare()?.id + ')'}
                resourceB={`Selected (${selectedVersion?.id})`}
                delta={diffDelta([findVersionToCompare(), selectedVersion])}
                onClose={(): void => { setDiffView(false) }}
                handleRestore={(): void => { setConfirmRestore(true) }}
                okToRestore={ currentWorkspaceObject !== null && hasRightToEdit(currentWorkspaceObject!, user?.superUser) }
            />
            <OkCancelModal
                open={confirmRestore}
                okButtonText='Restore'
                title={`Are you sure you want to restore the model back to Version ${selectedVersion?.id ?? ''}? This action may result in the permanent loss of incident links contained in this model.`}
                onClose={(): void => { setConfirmRestore(false); }}
                onConfirm={(): void => { setConfirmRestore(false); restoreEsd(); }}
                okToConfirm={(): boolean => { return currentWorkspaceObject !== null && hasRightToEdit(currentWorkspaceObject, user?.superUser); }}
            >
                <TextField
                    autoFocus
                    margin='dense'
                    id='confirm-text-input'
                    label='Comment'
                    variant='filled'
                    size='small'
                    defaultValue={selectedVersion?.comment}
                    onChange={updateCommentText}
                    disabled={currentWorkspaceObject === null || !hasRightToEdit(currentWorkspaceObject, user?.superUser)}
                    fullWidth
                />
            </OkCancelModal>
        </>
    );
}