import * as joint from '@joint/plus';
import Callout, { CalloutPresentation } from '../@types/callout';
import Tag from '../@types/tag';
import '@joint/plus/joint-plus.css';
import { pickContrastingTextColor } from './canvas/colors';
import * as canvasUtils from './canvas/node-utils'

export class BaseLink extends joint.shapes.standard.Link {
    defaults(): any {
        const prop: any = {
            type: 'isam.BaseLink',
            attrs: {
                line: {
                    connection: true,
                    stroke: '#000',
                    strokeWidth: 2,
                    strokeLinejoin: 'round',
                    targetMarker: null,
                }
            }
        };
        return joint.util.defaultsDeep(prop, super.defaults);
    }

    static create(event1: any, event2: any, label?: string) : BaseLink {

        const link = new this({
            z: -1,
            source: {
                id: event1.id,
                selector: (event1.get('nodeClass') === 'FAULTTREENODE' ? 'gate': 'body'),
                anchor: {
                    name: label ? 'right' : 'bottom',                    
                }
            },
            target: {
                id: event2.id,
                selector: (event2.get('nodeClass') === 'FAULTTREENODE' ? 'idBody': 'body'),
                anchor: {
                    name: event2.get('nodeClass') === 'FAULTTREENODE' ? 'top' : 'left',
                }
            },
        });
        
        if (label) {
            link.appendLabel({
                attrs: {
                    text: {
                        text: label
                    }
                },
                position: {
                    distance: -8.7,
                    offset: -15,
                    args: {
                        keepGradient: true,
                    }
                }
            });
        }

        return link;
    }
}

class BaseNode extends joint.dia.Element {
    defaults(): any {
        return  {
            ...super.defaults,
            type: 'isam.BaseNode',
            z: 3,
            size: {width: 300,height: 150},
            attrs: {
                root: {
                    pointerEvents: 'bounding-box',
                },
                gate: {
                    gateType: 'none',
                    stroke: '#666',
                    strokeWidth: 2,
                    transform: 'translate(calc(w / 2), calc(h + 40))',
                    fillRule: 'nonzero',
                    fill: 'transparent',
                    cursor: 'pointer',
                },
            }
        }
    }

    markup = [
        {
            tagName: 'path',
            selector: 'gate'
        },
    ]

    gateTypes = {
        or: 'M -20 0 C -20 -15 -10 -30 0 -30 C 10 -30 20 -15 20 0 C 10 -6 -10 -6 -20 0',
        xor: 'M -20 0 C -20 -15 -10 -30 0 -30 C 10 -30 20 -15 20 0 C 10 -6 -10 -6 -20 0 M -20 0  0 -30 M 0 -30 20 0',
        and: 'M -20 0 C -20 -25 -10 -30 0 -30 C 10 -30 20 -25 20 0 Z',
        priority_and:
            'M -20 0 C -20 -25 -10 -30 0 -30 C 10 -30 20 -25 20 0 Z M -20 0 0 -30 20 0',
        inhibit: 'M -10 0 -20 -15 -10 -30 10 -30 20 -15 10 0 Z',
        transfer: 'M -20 0 20 0 0 -30 z',
    }

    gate(type: string): any {
        if (type === undefined) return this.attr('gate/gateType');
        //console.log('gate:', type)
        return this.attr('gate', {
            gateType: type,
            title: type.toUpperCase() + ' Gate',
        })
    }

    static attributes = {
        'gate-type' : {
            set: function(type: string): any {
                //console.log('gateType:', type)
                //@ts-ignore
                const data = this.model.gateTypes[type];
               
                return { d: data ? data + ' M 0 -30 0 -40': 'M 0 0 0 0'};
            },
        },
    }


}

export class Node extends BaseNode {
    defaults(): any {
        const prop =  {
            //...super.defaults,
            type: 'isam.Node',
            attrs: {
                idBody: {
                    width: 'calc(w)',
                    height: 20,
                    strokeWidth: 1,
                    stroke: '#000000',
                    fill: '#FFFFFF'
                },
                idLabel: {
                    x: 4,
                    y: 10,
                    textVerticalAnchor: 'middle',
                    fontSize: 18,
                    fontWeight: 'bold'
                },
                cicttLabel: {
                    x: 'calc(w - 10)',
                    y: 10,
                    textAnchor: 'end', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                    fontSize: 18,
                },
                body: {
                    x: 0,
                    y: 20,
                    width: 'calc(w)',
                    height: 'calc(h-40)',
                    strokeWidth: 1,
                    stroke: '#000000',
                    fill: '#FFFFFF'
                },
                label: {
                    x: 5,
                    y: 'calc(h/2)',
                    textAnchor: 'start', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                    fontSize: 18,
                    textWrap: {
                        height: 'calc(h-40)',
                        width: -40,
                        ellipsis: true,
                    }
                },
                statusBody: {
                    x:0,
                    y: 'calc(h-20)',
                    width: 'calc(w)',
                    height: 20,
                    strokeWidth: 1,
                    stroke: '#000000',
                    fill: '#FFFFFF'
                },
                probabilityLabel: {
                    x: '5',
                    y: 'calc(h-10)',
                    textAnchor: 'start', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                    fontSize: 18,
                },
                pedigreeLabel: {
                    x: 'calc(0.5*w)',
                    y: 'calc(h-10)',
                    textAnchor: 'middle', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                    fontSize: 18,
                },
                biasLabel: {
                    x: 'calc(w-10)',
                    y: 'calc(h-10)',
                    textAnchor: 'end', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                    fontSize: 18,
                },
            }
        }
        return joint.util.defaultsDeep(prop, super.defaults());
    }

    markup = [
        {
            tagName: 'path',
            selector: 'gate'
        },
        {
            tagName: 'rect',
            selector: 'idBody'
        },
        {
            tagName: 'rect',
            selector: 'body',
        },
        {
            tagName: 'rect',
            selector: 'statusBody',
        },
        {
            tagName: 'text',
            selector: 'idLabel'
        },
        {
            tagName: 'text',
            selector: 'cicttLabel'
        },
        {
            tagName: 'text',
            selector: 'label'
        },
        {
            tagName: 'text',
            selector: 'probabilityLabel'
        },
        {
            tagName: 'text',
            selector: 'pedigreeLabel'
        },
        {
            tagName: 'text',
            selector: 'biasLabel'
        },  
    ]


    static create(idLabel: string, description: string, probability: string, pedigree: string, bias: string, cictt: string): Node {
        return new this({
            attrs: {
                idLabel: { text: idLabel },
                cicttLabel: { text: cictt },
                label: {text: description},
                probabilityLabel: {text: probability},
                pedigreeLabel: {text: pedigree},
                biasLabel: {text: bias}
            }
        })
    }
}

export class Barrier extends Node {
    defaults(): any {
        const prop = {
            type: 'isam.Barrier',
            attrs: {
                idBody: {
                    fill: '#bdecbd'
                },
                body: {
                    fill: '#bdecbd'
                },
                statusBody: {
                    fill: '#bdecbd'
                }    
            }
        }
        return joint.util.defaultsDeep(prop, super.defaults());
    }
}

export class InitiatingNode extends Node {
    defaults(): any {
        const prop = {
            type: 'isam.InitiatingNode',
            attrs: {
                idBody: {
                    fill: '#eeff9e'
                },
                statusBody: {
                    fill: '#eeff9e'
                }    
            }
        }
        return joint.util.defaultsDeep(prop, super.defaults());
    }

    markup = [
        {
            tagName: 'rect',
            selector: 'idBody'
        },
        {
            tagName: 'rect',
            selector: 'body',
        },
        {
            tagName: 'rect',
            selector: 'statusBody',
        },
        {
            tagName: 'text',
            selector: 'idLabel'
        },
        {
            tagName: 'text',
            selector: 'cicttLabel'
        },
        {
            tagName: 'text',
            selector: 'label'
        },
        {
            tagName: 'text',
            selector: 'probabilityLabel'
        },
        {
            tagName: 'text',
            selector: 'pedigreeLabel'
        },
        {
            tagName: 'text',
            selector: 'biasLabel'
        },  
    ]

}


export class PivotalNode extends Node {
    defaults(): any {
        const prop = {
            type: 'isam.PivotalNode',
            attrs: {
                idBody: {
                    fill: '#c6e2cc'
                },
                statusBody: {
                    fill: '#c6e2cc'
                }    
            }
        }
        return joint.util.defaultsDeep(prop, super.defaults());
    }
 
    markup = [
        {
            tagName: 'rect',
            selector: 'idBody'
        },
        {
            tagName: 'rect',
            selector: 'body',
        },
        {
            tagName: 'rect',
            selector: 'statusBody',
        },
        {
            tagName: 'text',
            selector: 'idLabel'
        },
        {
            tagName: 'text',
            selector: 'cicttLabel'
        },
        {
            tagName: 'text',
            selector: 'label'
        },
        {
            tagName: 'text',
            selector: 'probabilityLabel'
        },
        {
            tagName: 'text',
            selector: 'pedigreeLabel'
        },
        {
            tagName: 'text',
            selector: 'biasLabel'
        },  
    ]
    
}


export class PositiveNode extends Node {
    defaults(): any {
        const prop = {
            type: 'isam.PositiveNode',
            attrs: {
                idBody: {
                    fill: '#0f0'
                },
                statusBody: {
                    fill: '#0f0'
                }    
            }
        }
        return joint.util.defaultsDeep(prop, super.defaults());
    }

    markup = [
        {
            tagName: 'rect',
            selector: 'idBody'
        },
        {
            tagName: 'rect',
            selector: 'body',
        },
        {
            tagName: 'rect',
            selector: 'statusBody',
        },
        {
            tagName: 'text',
            selector: 'idLabel'
        },
        {
            tagName: 'text',
            selector: 'cicttLabel'
        },
        {
            tagName: 'text',
            selector: 'label'
        },
        {
            tagName: 'text',
            selector: 'probabilityLabel'
        },
        {
            tagName: 'text',
            selector: 'pedigreeLabel'
        },
        {
            tagName: 'text',
            selector: 'biasLabel'
        },  
    ]

}

export class NegativeNode extends Node {
    defaults(): any {
        const prop = {
            type: 'isam.NegativeNode',
            attrs: {
                idBody: {
                    fill: '#f99'
                },
                statusBody: {
                    fill: '#f99'
                }    
            }
        }
        return joint.util.defaultsDeep(prop, super.defaults());
    }
    markup = [
        {
            tagName: 'rect',
            selector: 'idBody'
        },
        {
            tagName: 'rect',
            selector: 'body',
        },
        {
            tagName: 'rect',
            selector: 'statusBody',
        },
        {
            tagName: 'text',
            selector: 'idLabel'
        },
        {
            tagName: 'text',
            selector: 'cicttLabel'
        },
        {
            tagName: 'text',
            selector: 'label'
        },
        {
            tagName: 'text',
            selector: 'probabilityLabel'
        },
        {
            tagName: 'text',
            selector: 'pedigreeLabel'
        },
        {
            tagName: 'text',
            selector: 'biasLabel'
        },  
    ]

}

export class NeutralNode extends Node {
    defaults(): any {
        const prop = {
            type: 'isam.NeutralNode',
            attrs: {
                idBody: {
                    fill: '#ff0'
                },
                statusBody: {
                    fill: '#ff0'
                }    
            }
        }
        return joint.util.defaultsDeep(prop, super.defaults());
    }
    markup = [
        {
            tagName: 'rect',
            selector: 'idBody'
        },
        {
            tagName: 'rect',
            selector: 'body',
        },
        {
            tagName: 'rect',
            selector: 'statusBody',
        },
        {
            tagName: 'text',
            selector: 'idLabel'
        },
        {
            tagName: 'text',
            selector: 'cicttLabel'
        },
        {
            tagName: 'text',
            selector: 'label'
        },
        {
            tagName: 'text',
            selector: 'probabilityLabel'
        },
        {
            tagName: 'text',
            selector: 'pedigreeLabel'
        },
        {
            tagName: 'text',
            selector: 'biasLabel'
        },  
    ]

}


export class Precursor extends BaseNode {
    defaults(): any {
        const prop =  {
            type: 'isam.Precursor',
            attrs: {
                idLabel: {
                    x: 'calc(w/2)',
                    y: 10,
                    textAnchor: 'middle', 
                    textVerticalAnchor: 'middle',
                    fontSize: 18,
                    fontWeight: 'bold'
                },
                cicttLabel: {
                    x: 'calc(w - 40)',
                    y: 10,
                    textAnchor: 'middle', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                    fontSize: 18,
                },
                body: {
                    cx: 'calc(w / 2)',
                    cy: 'calc(h / 2)',
                    rx: 'calc(w / 2)',
                    ry: 'calc(h / 2)',
                    strokeWidth: 1,
                    stroke: '#000000',
                    fill: '#eeff9e'
                },
                label: {
                    x: 5,
                    y: 'calc(h/2)',
                    textAnchor: 'start', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                    fontSize: 18,
                    textWrap: {
                        height: 'calc(h-40)',
                        width: -40,
                        ellipsis: true,
                    }
                },
                probabilityBody: {
                    x: 0,
                    y: 'calc(h-20)',
                    width: 100,
                    height: 20,
                    fill: 'white',
                    stroke: '#000000',
                },
                probabilityLabel: {
                    x: '50',
                    y: 'calc(h-10)',
                    textAnchor: 'middle', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',  
                    fontSize: 18,
                },
                pedigreeLabel: {
                    x: 'calc(0.5*w)',
                    y: 'calc(h-10)',
                    textAnchor: 'middle', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                    fontSize: 18,
                },
                biasBody: {
                    x: 'calc(w - 80)',
                    y: 'calc(h-20)',
                    width: 80,
                    height: 20,
                    fill: 'white',
                    stroke: '#000000',
                },
                biasLabel: {
                    x: 'calc(w - 40)',
                    y: 'calc(h-10)',
                    textAnchor: 'middle', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                    fontSize: 18,
                },
            }
        }
        return joint.util.defaultsDeep(prop, super.defaults());
    }

    markup = [
        {
            tagName: 'path',
            selector: 'gate'
        },
        {
            tagName: 'ellipse',
            selector: 'body',
        },
        {
            tagName: 'text',
            selector: 'idLabel'
        },
        {
            tagName: 'text',
            selector: 'cicttLabel'
        },
        {
            tagName: 'text',
            selector: 'label'
        },
        {
            tagName: 'rect',
            selector: 'probabilityBody'
        },
        {
            tagName: 'text',
            selector: 'probabilityLabel'
        },
        {
            tagName: 'text',
            selector: 'pedigreeLabel'
        },
        {
            tagName: 'rect',
            selector: 'biasBody'
        },
        {
            tagName: 'text',
            selector: 'biasLabel'
        },  
    ]


    static create(idLabel: string, description: string, probability: string, pedigree: string, bias: string, cictt: string): Precursor {
        return new this({
            attrs: {
                idLabel: { text: idLabel },
                cicttLabel: { text: cictt },
                label: {text: description},
                probabilityLabel: {text: probability},
                pedigreeLabel: {text: pedigree},
                biasLabel: {text: bias}
            }
        })
    }
}

export class HouseNode extends BaseNode {
    defaults(): any {
        const prop =  {
            type: 'isam.HouseNode',
            attrs: {
                idBody: {
                    'd': 'M 0 15 calc(w/2) 0 calc(w) 15 calc(w) 20 0 20 Z',
                    strokeWidth: 1,
                    stroke: '#000000',
                    fill: '#475877'

                },
                idLabel: {
                    x: 'calc(w/2)',
                    y: 10,
                    textAnchor: 'middle', 
                    textVerticalAnchor: 'middle',
                    fontWeight: 'bold'
                },
                body: {
                    x: 0,
                    y: 20,
                    width: 'calc(w)',
                    height: 'calc(h-40)',
                    strokeWidth: 1,
                    stroke: '#000000',
                    fill: '#475877'
                },
                label: {
                    x: 5,
                    y: 'calc(h/2)',
                    textAnchor: 'start', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                    textWrap: {
                        height: 'calc(h-40)',
                        width: -40,
                        ellipsis: true,
                    }
                },
                statusBody: {
                    x:0,
                    y: 'calc(h-20)',
                    width: 'calc(w)',
                    height: 20,
                    strokeWidth: 1,
                    stroke: '#000000',
                    fill: '#475877'
                },
                probabilityLabel: {
                    x: '5',
                    y: 'calc(h-10)',
                    textAnchor: 'start', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                },
                pedigreeLabel: {
                    x: 'calc(0.5*w)',
                    y: 'calc(h-10)',
                    textAnchor: 'middle', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                },
                biasLabel: {
                    x: 'calc(w-10)',
                    y: 'calc(h-10)',
                    textAnchor: 'end', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                },
            }
        }
        return joint.util.defaultsDeep(prop, super.defaults());
    }

    markup = [
        {
            tagName: 'path',
            selector: 'gate'
        },
        {
            tagName: 'path',
            selector: 'idBody'
        },
        {
            tagName: 'rect',
            selector: 'body',
        },
        {
            tagName: 'rect',
            selector: 'statusBody',
        },
        {
            tagName: 'text',
            selector: 'idLabel'
        },
        {
            tagName: 'text',
            selector: 'label'
        },
        {
            tagName: 'text',
            selector: 'probabilityLabel'
        },
        {
            tagName: 'text',
            selector: 'pedigreeLabel'
        },
        {
            tagName: 'text',
            selector: 'biasLabel'
        },  
    ]


    static create(idLabel: string, description: string, probability: string, pedigree: string, bias: string): HouseNode {
        return new this({
            attrs: {
                idLabel: {text: idLabel},
                label: {text: description},
                probabilityLabel: {text: probability},
                pedigreeLabel: {text: pedigree},
                biasLabel: {text: bias}
            }
        })
    }
}

export class TransferNode extends BaseNode {
    defaults(): any {
        const prop = {
            type: 'isam.TransferNode',
            attrs: {
                idLabel: {
                    x: 'calc(w/2)',
                    y: 40,
                    textAnchor: 'middle', 
                    textVerticalAnchor: 'middle',
                    fontWeight: 'bold'
                },
                body: {
                    d: 'M 0 calc(h) calc(w / 2) 0 calc(w) calc(h) Z',
                    strokeWidth: 1,
                    stroke: '#000000',
                    fill: '#82CAFA'
                },
                label: {
                    x: 'calc(w/2)',
                    y: 'calc(h/2)',
                    textAnchor: 'middle', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                    textWrap: {
                        height: 'calc(h/2)',
                        width: 'calc(w/2)',
                        ellipsis: true,
                    }
                },
                probabilityLabel: {
                    x: '50',
                    y: 'calc(h-10)',
                    textAnchor: 'middle', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',  
                },
                pedigreeLabel: {
                    x: 'calc(0.5*w)',
                    y: 'calc(h-10)',
                    textAnchor: 'middle', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                },
                biasLabel: {
                    x: 'calc(w - 40)',
                    y: 'calc(h-10)',
                    textAnchor: 'middle', 
                    textVerticalAnchor: 'middle',
                    fontFamily: 'sans-serif',
                },
            },        
        }
        return joint.util.defaultsDeep(prop, super.defaults());
    }

    markup = [
        {
            tagName: 'path',
            selector: 'gate'
        },
        {
            tagName: 'path',
            selector: 'body',
        },
        {
            tagName: 'text',
            selector: 'idLabel'
        },
        {
            tagName: 'text',
            selector: 'label'
        },
        {
            tagName: 'text',
            selector: 'probabilityLabel'
        },
        {
            tagName: 'text',
            selector: 'pedigreeLabel'
        },
        {
            tagName: 'text',
            selector: 'biasLabel'
        },  
    ]

    static create(idLabel: string, description: string, probability: string, pedigree: string, bias: string): TransferNode {
        return new this({
            attrs: {
                idLabel: {text: idLabel},
                label: {text: description},
                probabilityLabel: {text: probability},
                pedigreeLabel: {text: pedigree},
                biasLabel: {text: bias}
            }
        })
    }

}

export const ExpandButton = joint.elementTools.Button.extend({
    options: {
        x: 'calc(w / 2)',
        y: 'calc(h + 10)',
        action: (evt: any, view: any, buttonView: joint.elementTools.Button ) => {
            view.paper.trigger('element:expand', view, evt, buttonView);
        },
        markup: [
            {
                tagName: 'rect',
                selector: 'button',
                attributes: {
                    'fill': '#cad8e3',
                    'x': -8,
                    'y': -8,
                    'width': 16,
                    'height': 16,
                    'cursor': 'pointer',
                }
            },
            {
                tagName: 'path',
                selector: 'icon',
                attributes: {
                    'fill': 'none',
                    'stroke': '#131e29',
                    'stroke-width': 2,
                    'pointer-events': 'none',
                }
            }
        ]
    },
    update() {
        // @ts-ignore
        joint.elementTools.Button.prototype.update.call(this, arguments);
        this.childNodes.icon.setAttribute('d', this.getIconPath());
    },
    getIconPath() {    
        if (this.relatedView.model.get('collapsed')) {
            return 'M -4 0 4 0 M 0 -4 0 4';
        } else {
            return 'M -4 0 4 0';
        }
    }
    
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function lineTextSvgPath(d: any): string {
    return  'M' + [d.x + d.w, d.y + d.h/2]
    + 'L' + [d.x, d.y + d.h/2];   
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function sideRectangleBalloonSvgPath(d: any): string {
    return  'M' + [d.x, d.y + d.h/2]
    + 'L' + [d.x, d.y]
    + ' ' + [d.x + d.w, d.y]
    + ' ' + [d.x + d.w, d.y + d.h]
    + ' ' + [d.x, d.y + d.h]
    + ' ' + [d.x, d.y + d.h/2];
}

function rectangleBalloonSvgPath(d: any): string {
    return  'M' + [d.x + d.w/8, d.y + d.h + 10]
    + 'L' + [d.x + d.w/4, d.y + d.h]
    + ' ' + [d.x, d.y + d.h]
    + ' ' + [d.x, d.y]
    + ' ' + [d.x + d.w, d.y]
    + ' ' + [d.x + d.w, d.y + d.h]
    + ' ' + [d.x + 3*d.w/8, d.y + d.h]
    + ' ' + [d.x + d.w/8, d.y + d.h + 10];
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function cloudBalloonSvgPath(d: any): string {
    return  'M' + [d.x + d.w/8, d.y + d.h]
    + 'A 20 10 15 0 1' + [d.x, d.y + d.h - d.w/8]
    + 'A 5 10 20 0 1' + [d.x, d.y + d.w/8]
    + 'A 20 10 0 0 1' + [d.x + d.w/8, d.y]
    + 'A 20 10 -10 0 1' + [d.x + d.w/2, d.y]
    + 'A 20 10 10 0 1' + [d.x + d.w, d.y + d.w/8]
    + 'A 5 10 0 0 1' + [d.x + d.w, d.y + d.h - d.w/8]
    + 'A 20 10 0 0 1' + [d.x + 7*d.w/8, d.y + d.h]
    + 'A 20 10 0 0 1' + [d.x + 5*d.w/8, d.y + d.h]
    + 'A 20 10 0 0 1' + [d.x +3*d.w/8, d.y + d.h]
    + 'A 20 10 0 0 1' + [d.x + d.w/8, d.y + d.h];
}

// write a javascript function that breaks text into equal parts seperated by <br> tag
function breakText(text: string, maxChars: number, maxLines: number): string {
    let words = text.split(' ');
    let lines: string[] = [];
    let line = '';
    words.forEach((word) => {
        if (line.length + word.length < maxChars) {
            line += word + ' ';
        } else {
            if (line.length > 0)
                lines.push(line);
            if (word.length < maxChars) {
                line = word + ' ';
            } else {
                // slice word into equal parts
                let parts = Math.ceil(word.length / maxChars);
                for (let i = 0; i < parts; i++) {
                    lines.push(word.slice(i * maxChars, (i + 1) * maxChars));
                }
            }
        }
    });
    lines.push(line);
    if (lines.length > maxLines) {
        lines = lines.slice(0, maxLines);
        lines[maxLines - 1] = lines[maxLines - 1].slice(0, maxChars - 3) + '...';
    }
    return lines.join('<br>');
}


// eslint-disable-next-line @typescript-eslint/no-unused-vars
const tooltip = new joint.ui.Tooltip({
    rootTarget: document.body,
    target: '[data-tooltip]',
    padding: 10,
});

class CalloutEffect extends joint.highlighters.list {
    
    createListItem(callout: Callout, { width, height }: joint.dia.Size, currentTag: SVGElement): SVGElement {
        let listItem = currentTag;
        if (!listItem) {
            listItem = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        }
        listItem.setAttribute('cursor', 'pointer');
        
        listItem.setAttribute('id', 'callout-' + callout.id.toString());
        listItem.setAttribute('class', 'callout');
        
        listItem.innerHTML = `
            <path d="${rectangleBalloonSvgPath({x: 0, y: 0, w: width, h: height})}" fill="white" stroke="black"  />
        `;

        listItem.dataset.tooltip = breakText(callout.text, 40, 6);
        listItem.dataset.tooltipPosition = 'bottom';

        return listItem;
    }
    
}


class TagsEffect extends joint.highlighters.list {
    
    createListItem(tag: Tag, { width, height }: joint.dia.Size, currentTag: SVGElement): SVGElement {
        let listItem = currentTag;
        let bgColor = tag.presentation.color || 'none';
        let color = 'red';
        let textColor = tag.presentation.color ? pickContrastingTextColor(tag.presentation.color) : 'black';
        if (!listItem) {
            listItem = document.createElementNS('http://www.w3.org/2000/svg', 'g');
        }
        listItem.setAttribute('cursor', 'pointer');
        
        listItem.setAttribute('id', 'tag-' + tag.id!.toString());
        listItem.setAttribute('class', 'tag');
        
        listItem.innerHTML = `
            <rect x=0 y=0 width="${width}" height="${height}" fill="${bgColor}" stroke="${color}" stroke-width="0.1" />
            <text x="${width / 2}" y="${height / 2}" dominant-baseline="middle" text-anchor="middle" fill="${textColor}">${tag.presentation.unicode ?? ''}</text>
        `;

        listItem.dataset.tooltip = tag.name;
        listItem.dataset.tooltipPosition = 'top';
        return listItem;
    }
    
}
function adjustTagUnicode(tag: Tag): Tag {
    if (!tag.presentation.unicode) return tag;
    let codes = tag.presentation.unicode!.split('-').map((code: string) => parseInt(`0x${code})`));
    let adjustedUnicode = String.fromCodePoint(...codes);
    let newPresentation = { ...tag.presentation, unicode: adjustedUnicode };
    return { ...tag, presentation: newPresentation };
}

export function setTagIcons(paper: joint.dia.Paper, element: joint.dia.Element, tags: Tag[]): void {
    const width = element.size().width;
    const tagSize = 20;
    const tagGap = 5;
    const maxNumberOfTags = Math.round(width / (tagSize + tagGap));

    element.removeProp('tags');
    tags.forEach((tag, index) => {
        if (index <= maxNumberOfTags)
            element.prop(['tags', Number(index)], adjustTagUnicode(tag));
    });

    TagsEffect.add(element.findView(paper), 'root', 'tags', {
        attribute: 'tags',
        position: 'bottom-right',
        margin: { right: 0, bottom: -40 },
        size: tagSize,
        gap: tagGap
        
    })
}

export function setCalloutIcon(paper: joint.dia.Paper, element: joint.dia.Element): void {
    let properties = element.get('data');
    element.removeProp('callout');
    if (properties && properties.callout) {
        let presentation: CalloutPresentation = JSON.parse(properties.callout.presentation);
        let callout: Callout = {} as Callout;
        callout.id = properties.id;
        callout.uniqueId = properties.uniqueId;
        callout.name = properties.name;
        callout.isEvent = canvasUtils.isEvent(properties.type);
        callout.color = presentation.color;
        callout.shape = presentation.shape;
        callout.show = presentation.show;
        callout.x_factor = presentation.x_factor;
        callout.y_factor = presentation.y_factor;
        callout.authorName = presentation.authorName;
        callout.authorEmail = presentation.authorEmail;
        callout.text = properties.callout.content;


        element.prop(['callout', 0], callout);

        CalloutEffect.add(element.findView(paper), 'root', 'callout', {
            attribute: 'callout',
            position: 'top',
            margin: { top: -30 },
            size: { width:40, height:20},
            gap: 5
        })
    }

}

export function setCalloutOpenForm(graph: joint.dia.Graph, paper: joint.dia.Paper, element: joint.dia.Element): void {
    if (element.get('hidden')) return;
    let properties = element.get('data');
    let callouts = graph.getSuccessors(element, { outbound: true }).filter((cell) => cell.isElement() && cell.get('nodeClass') === 'CALLOUTNODE');
    callouts.forEach((callout) => {
        callout.remove();
    });
    if (properties && properties.callout && properties.callout.presentation) {
        let presentation: CalloutPresentation = JSON.parse(properties.callout.presentation);
        let callout: Callout = {} as Callout;
        callout.id = properties.id;
        callout.uniqueId = properties.uniqueId;
        callout.name = properties.name;
        callout.isEvent = canvasUtils.isEvent(properties.type);
        callout.color = presentation.color;
        callout.shape = presentation.shape;
        callout.show = presentation.show;
        callout.x_factor = presentation.x_factor;
        callout.y_factor = presentation.y_factor;
        callout.authorName = presentation.authorName;
        callout.authorEmail = presentation.authorEmail;
        callout.text = properties.callout.content;

        if (!callout.show) return setCalloutIcon(paper, element);
        let elementWidth = element.size().width;
        let elementHeight = element.size().height;
        let elementX = element.position().x;
        let elementY = element.position().y;
        let calloutWidth = elementWidth / 2;
        let calloutHeight = 3*elementHeight / 4;
        let calloutX = callout.x_factor ? elementX + elementWidth * callout.x_factor : elementX + 3 * elementWidth / 4;
        let calloutY = callout.y_factor ? elementY + elementHeight * callout.y_factor : elementY - elementHeight;
        let fontColor = pickContrastingTextColor(callout.color);

        const path = new joint.shapes.standard.Path();
        path.position(calloutX, calloutY);
        path.resize(calloutWidth, calloutHeight);
        path.attr('label/fill', callout.shape === 'Line Text'  ? 'none' : callout.color);
        if (callout.shape === 'Line Text') {
            path.attr('body/d', lineTextSvgPath({x: 0, y: 0, w: calloutWidth, h: calloutHeight}));
        } else if (callout.shape === 'Cloud') {
            path.attr('body/d', cloudBalloonSvgPath({x: 0, y: 0, w: calloutWidth, h: calloutHeight}));
        } else {
            path.attr('body/d', sideRectangleBalloonSvgPath({x: 0, y: 0, w: calloutWidth, h: calloutHeight}));
        }
        path.attr('body/fill', callout.shape === 'Line Text'  ? 'transparent' : callout.color);

        path.attr('label/text', callout.text);
        if (callout.shape === 'Line Text') {
            path.attr('label/y', calloutHeight / 2 - 20);
        }
        path.attr('labe;/fontFamily', 'sans-serif');
        if (callout.shape === 'Line Text') {
            path.attr('label/textWrap', { width: calloutWidth - 10, height: calloutHeight - 60, ellipsis: true });
        } else {
            path.attr('label/textWrap', { width: calloutWidth - 10, height: calloutHeight - 10, ellipsis: true });
        }
        path.attr('label/fill', fontColor);
        path.attr('z', 1000);
        path.set('nodeClass', 'CALLOUTNODE')
        path.set('data', { type: 'Callout', x: calloutX, y: calloutY, width: elementWidth, height: elementHeight, callout: callout });

        path.addTo(graph);

        const link = new joint.shapes.standard.Link({
            z: -1000,
            source: { id: element.id },
            target: { id: path.id },
        });
        link.set('id', 'callout-link-' + element.id);
        link.attr('line/targetMarker', 'none');
        link.attr('line/strokeDasharray', '5 5');
        link.addTo(graph);


    }
    
}

