import React, {ReactElement, useContext, useState} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {Divider} from '@mui/material';
import {useWorkspaceContext} from '../../../context/workspaceContext';
import {ResourceType} from '../../../@types/resource-type';
import ResourceBase from '../../../@types/resource-base';
import OpenResource from '../modals/OpenResource.component';
import {ResourceAction} from '../../../@types/resource-action';
import GroupService from '../../../services/group.service';
import {toast} from 'react-toastify';
import DuplicateResource from '../modals/DuplicateResource.component';
import {excludeGroupAndDescendants} from '../../../utils/group-utils';
import {
    createWorkspaceObject,
    deleteResourceById,
    duplicateResourceById,
    filterOnGroupCanEditResources,
    getSingularResourceLabel,
    getTreeItemDescendantIds
} from '../../../utils/resource-utils';
import DeleteResource from '../modals/DeleteResource.component';
import {UserContext} from '../../../context/userContext';
import {hasRightToEdit} from '../../../utils/role-to-rights';
import LeaveGroupModal from '../modals/LeaveGroup.component';
import EventSequenceSummary from '../../../@types/event-sequence-summary';
import {WorkspaceResource} from '../../../@types/workspace-types';
import {CanvasActionType} from '../../../@types/canvas-action-type';
import SanitizeModal from '../../form-components/SanitizeModal.component';
import {exportEventSequence, importEventSequence} from '../../../utils/event-sequence-utils';
import SafetyModel from '../../../@types/safety-model';
import {addEventSequence} from '../../../utils/safety-model-utils';
import {ExportEventSequenceSelectorDialogBox} from '../../form-components/ExportEventSequenceSelector.component';
import SafetyService from '../../../services/safety.service';
import Group from '../../../@types/group';

export default function ResourceMenu(props: { label: string, addOrSelectResource: any, loadObjects: any }): ReactElement {
    const { currentWorkspaceObject, setCurrentWorkspaceObject, setCurrentAction, setCurrentCanvasAction , setDeletedWorkspaceObject, workspaceObjects, setWorkspaceObjects, getAdditionalResource } = useWorkspaceContext();
    const { user } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openResource, setOpenResource] = React.useState<boolean>(false);
    const [duplicateResource, setDuplicateResource] = React.useState<boolean>(false);
    const [sanitizeResource, setSanitizeResource] = React.useState<boolean>(false);
    const [leaveGroup, setLeaveGroup] = React.useState<boolean>(false);
    const [deleteResource, setDeleteResource] = React.useState<boolean>(false);
    const [exportResource, setExportResource] = React.useState<boolean>(false);
    const [eventSequenceList, setEventSequenceList] = React.useState<EventSequenceSummary[]>([]);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void =>  {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const onClickOpen = (): void =>  {
        setOpenResource(true);
        handleClose();
    }

    const handleOpenClose = (): void => {
        setOpenResource(false);
    }

    const handleOpenResource = (resource: ResourceBase): void => {
        props.addOrSelectResource(resource);
        setOpenResource(false);
    }
    const onClickNew = (): void => {
        props.addOrSelectResource(new ResourceBase(workspaceObjects?.objectType ?? ResourceType.GROUPS));
        handleClose();
    };

    const onClickSave = (): void => {
        setCurrentAction(ResourceAction.SAVE);
        handleClose();
    }

    const onClickDuplicate = (): void =>  {
        setDuplicateResource(true);
        handleClose();
    }

    const onClickSanitize = (): void =>  {
        setSanitizeResource(true);
        handleClose();
    }

    const onClickExport = (): void => {
        if (currentWorkspaceObject?.objectType === ResourceType.EVENT_SEQUENCES || currentWorkspaceObject?.objectType === ResourceType.EVENT_SEQUENCE_TREES) {
            exportEventSequence(currentWorkspaceObject?.object);
        } else if (currentWorkspaceObject?.objectType === ResourceType.SAFETY_MODELS) {
            loadEventSequences(currentWorkspaceObject.object);
            setExportResource(true);
        }
        handleClose();
    }

    const onClickDelete = (): void =>  {
        setDeleteResource(true);
        handleClose();
    }

    const onClickLeave = (): void =>  {
        setLeaveGroup(true);
        handleClose();
    }
    
    const onClickClose = (): void => {
        setCurrentAction(ResourceAction.CLOSE);
        handleClose();
    }

    const onClickImport = (): void => {
        handleClose();
        let safetyModel = getSafetyModel();
        if (safetyModel) {
            importEventSequence(safetyModel.id, safetyModel.name, (eventSequence: any) => {
                addEventSequence(safetyModel, eventSequence);
                if (workspaceObjects?.objectType === ResourceType.SAFETY_MODELS) {
                    setWorkspaceObjects({objectType: ResourceType.SAFETY_MODELS});
                }
            });
        }
    }

    const onClickUndo = (): void => {
        setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_UNDO });
        handleClose();
    }

    const onClickRedo = (): void => {
        setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_REDO });
        handleClose();
    }

    const onClickHistory = (): void => {
        setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_HISTORY, object: currentWorkspaceObject?.object });
        handleClose();
    }

    const onClickViewModel = (): void => {
        setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: currentWorkspaceObject?.object });
        handleClose();
    }

    const isResourceNotSavable = (): boolean => {
        if (currentWorkspaceObject?.object.group) {
            let group = new Group();
            Object.assign(group, currentWorkspaceObject.object.group);
            currentWorkspaceObject.object.group = group;
        }
        return currentWorkspaceObject === null || currentWorkspaceObject.objectType === ResourceType.EVENT_SEQUENCE_HISTORY ||
            currentWorkspaceObject.objectType === ResourceType.EVENT_SEQUENCE_HISTORY_TREES ||
            (currentWorkspaceObject.object?.id && !hasRightToEdit(currentWorkspaceObject, user?.superUser));
    }

    const isResourceNotEditable = (): boolean => {
        return currentWorkspaceObject === null || !currentWorkspaceObject.object?.id || currentWorkspaceObject.objectType === ResourceType.EVENT_SEQUENCE_HISTORY ||
            currentWorkspaceObject.objectType === ResourceType.EVENT_SEQUENCE_HISTORY_TREES || !hasRightToEdit(currentWorkspaceObject, user?.superUser);
    }

    const handleClickNewInDuplicate = (resource: ResourceBase): void => {
        setCurrentWorkspaceObject({objectType: resource.type, object: createWorkspaceObject(resource) });
        setDuplicateResource(false);
    };

    const generateDuplicateData = (): WorkspaceResource => {
        if (currentWorkspaceObject?.objectType === ResourceType.GROUPS) {
            return excludeGroupAndDescendants(getAdditionalResource(ResourceType.GROUPS), currentWorkspaceObject.object, workspaceObjects);
        } else if (currentWorkspaceObject?.objectType === ResourceType.SAFETY_MODELS) {
            return filterOnGroupCanEditResources(getAdditionalResource(ResourceType.GROUPS));
        } else {
            return filterOnGroupCanEditResources(getAdditionalResource(ResourceType.SAFETY_MODELS));
        }
    }

    const getSafetyModel = (): SafetyModel|undefined => {
        if (currentWorkspaceObject?.objectType === ResourceType.SAFETY_MODELS) {
            return currentWorkspaceObject.object;
        } else if (currentWorkspaceObject?.objectType === ResourceType.EVENT_SEQUENCES || currentWorkspaceObject?.objectType === ResourceType.EVENT_SEQUENCE_TREES) {
            let safetyModels = getAdditionalResource(ResourceType.SAFETY_MODELS).resources as SafetyModel[];
            return safetyModels.find((sm) => sm.id == currentWorkspaceObject.object.containerId);
        }
    }

    const loadEventSequences = (safetyModel: SafetyModel): void => {
        if (safetyModel.numberEventSequences > 0 && !safetyModel.children) {
            SafetyService.getEventSequences(safetyModel.id).then((eventSequences) => {
                (eventSequences as EventSequenceSummary[])?.forEach(es => es.role = safetyModel.group.role());
                setEventSequenceList(eventSequences);
            }).catch((error) => {
                toast.error('Error getting event sequences - ' + error.message);
            });
        } else {
            setEventSequenceList(safetyModel.children ?? []);
        }
    }

    // @ts-ignore
    return (
        <>
            <Button
                id="resource-menu-button"
                aria-controls={open ? 'resource-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                aria-label="Resource Menu"
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon/>}
                style={{ color: 'white', textTransform: 'none', }}
                sx={{
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                }}                
            >
                {props.label}
            </Button> 
            <Menu
                id="resource-menu"
                aria-labelledby="resource-menu-button"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'resource-menu-button',
                }}  
            >
                <MenuItem onClick={onClickNew} disabled={currentWorkspaceObject?.objectType === ResourceType.EVENT_SEQUENCE_HISTORY_TREES} dense>New</MenuItem>
                <MenuItem onClick={onClickOpen} disabled={currentWorkspaceObject?.objectType === ResourceType.EVENT_SEQUENCE_HISTORY_TREES} dense>Open</MenuItem>
                <Divider />
                <MenuItem onClick={onClickSave} disabled={ isResourceNotSavable() } dense>Save</MenuItem>
                <MenuItem onClick={onClickDuplicate} disabled={ currentWorkspaceObject == undefined || currentWorkspaceObject.objectType === ResourceType.EVENT_SEQUENCE_HISTORY_TREES} dense>Duplicate</MenuItem>
                {(currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCES || currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCE_HISTORY) &&
                    <MenuItem onClick={onClickViewModel} disabled={ currentWorkspaceObject == undefined } dense>View Model Tree</MenuItem>
                }
                {currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCE_TREES &&
                    <MenuItem
                        onClick={onClickSanitize}
                        disabled={isResourceNotEditable()}
                        dense
                    >
                        Sanitize
                    </MenuItem>
                }
                {currentWorkspaceObject?.objectType !== ResourceType.EVENT_SEQUENCE_HISTORY_TREES && <Divider /> }
                {((currentWorkspaceObject?.objectType == ResourceType.SAFETY_MODELS  && currentWorkspaceObject.object.numberEventSequences > 0) ||
                        currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCES || currentWorkspaceObject?.objectType === ResourceType.EVENT_SEQUENCE_HISTORY || currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCE_TREES) &&
                    <MenuItem onClick={onClickExport} dense>Export Event Sequence</MenuItem>
                }
                {(currentWorkspaceObject?.objectType == ResourceType.SAFETY_MODELS || currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCES
                        || currentWorkspaceObject?.objectType === ResourceType.EVENT_SEQUENCE_HISTORY || currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCE_TREES) &&
                    <MenuItem onClick={onClickImport} dense>Import Event Sequence</MenuItem>
                }
                {currentWorkspaceObject?.objectType == ResourceType.GROUPS &&
                    <MenuItem onClick={onClickLeave} disabled={ currentWorkspaceObject == undefined } dense>Leave Group</MenuItem>
                }
                <Divider />
                <MenuItem onClick={onClickDelete} disabled={ isResourceNotEditable() } dense>Delete</MenuItem>
                <Divider />
                {currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCE_TREES && <MenuItem disabled={!hasRightToEdit(currentWorkspaceObject, user?.superUser)} onClick={onClickUndo} dense>Undo</MenuItem>}
                {currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCE_TREES && <MenuItem disabled={!hasRightToEdit(currentWorkspaceObject, user?.superUser)} onClick={onClickRedo} dense>Redo</MenuItem>}
                {(currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCES || currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCE_TREES) &&
                    <MenuItem onClick={onClickHistory} dense>History</MenuItem>}
                <MenuItem onClick={onClickClose} disabled={ currentWorkspaceObject == undefined } dense>Close</MenuItem>
            </Menu>
            <OpenResource open={openResource} onClose={handleOpenClose} addOrSelectResource={handleOpenResource} />
            {currentWorkspaceObject &&
                <DuplicateResource
                    open={duplicateResource}
                    title={'Duplicate ' + getSingularResourceLabel(currentWorkspaceObject.objectType) + ' - ' + currentWorkspaceObject.object?.name}
                    onClose={(): void => { setDuplicateResource(false); }}
                    onConfirm={
                        (id: number): void => {
                            let targetGroupId = id > 0 ? id : undefined;
                            if (currentWorkspaceObject) {
                                let sourceGroupId = currentWorkspaceObject.object.id;
                                let duplicateResourcePromise = duplicateResourceById(currentWorkspaceObject.objectType, sourceGroupId!, targetGroupId);
                                if (duplicateResourcePromise) {
                                    setDuplicateResource(false);
                                    duplicateResourcePromise.then((response) => {
                                        toast.info(response.data.status + ' ' + response.data.name);
                                    }).catch((error) => {
                                        toast.error(error.message);
                                    });
                                }
                            }
                        }
                    }
                    object={currentWorkspaceObject}
                    data={generateDuplicateData()}
                    onClickNew={handleClickNewInDuplicate}
                />
            }
            {currentWorkspaceObject && hasRightToEdit(currentWorkspaceObject, user?.superUser) &&
                <SanitizeModal
                    open={sanitizeResource}
                    title={'Sanitize ' + getSingularResourceLabel(ResourceType.EVENT_SEQUENCES) + ' - ' + currentWorkspaceObject.object?.name}
                    onClose={(): void => { setSanitizeResource(false); }}
                    onConfirm={
                        (sanitizeQtt: boolean, sanitizeODna: boolean): void => {
                            if (currentWorkspaceObject) {
                                // let the canvas deal with the update
                                // so that all canvas actions are in one place
                                if (sanitizeODna) {
                                    setCurrentCanvasAction({ actionType: CanvasActionType.SANITIZE_OCCURRENCE_DNA });
                                }
                                if (sanitizeQtt) {
                                    setCurrentCanvasAction({ actionType: CanvasActionType.SANITIZE_EVENT_SEQUENCE });
                                }
                            }
                        }
                    }
                    object={currentWorkspaceObject}

                />
            }
            {currentWorkspaceObject && hasRightToEdit(currentWorkspaceObject, user?.superUser) &&
                <DeleteResource
                    open={deleteResource}
                    resourceType={currentWorkspaceObject.objectType}
                    resourceName={currentWorkspaceObject.object.name}
                    subResources={'sub groups and resources'}
                    handleDelete={(): void => {
                        let deleteResourcePromise = deleteResourceById(currentWorkspaceObject?.objectType, currentWorkspaceObject.object.id);
                        if (deleteResourcePromise) {
                            deleteResourcePromise.then(() => {
                                let childrenIds;
                                if (currentWorkspaceObject?.objectType === ResourceType.GROUPS) {
                                    childrenIds = getTreeItemDescendantIds(currentWorkspaceObject?.object,
                                        ResourceType.GROUPS,
                                        workspaceObjects?.resources ?? []);
                                } else {
                                    childrenIds = currentWorkspaceObject.object.children?.map((child: EventSequenceSummary) => child.id) ?? [];
                                }
                                setDeletedWorkspaceObject({
                                    objectType: currentWorkspaceObject?.objectType,
                                    id: currentWorkspaceObject.object.id!,
                                    children: childrenIds
                                });
                                setWorkspaceObjects({objectType: workspaceObjects?.objectType ?? ResourceType.GROUPS});
                            }).catch((error) => {
                                toast.error(error.message);
                            }).finally(() => {
                                setDeleteResource(false);
                            });
                        }
                    }}
                    onClose={(): void => {
                        setDeleteResource(false);
                    }}
                />
            }
            {currentWorkspaceObject && currentWorkspaceObject.objectType === ResourceType.GROUPS &&
                <LeaveGroupModal
                    leaveGroup={leaveGroup}
                    group={currentWorkspaceObject.object}
                    onConfirm={(): void => {
                        setLeaveGroup(false);
                        let group = currentWorkspaceObject?.object;
                        let childrenIds = getTreeItemDescendantIds(group,
                            ResourceType.GROUPS,
                            workspaceObjects?.resources ?? []);
                        GroupService.leaveGroupById(group).then(() => {
                            setDeletedWorkspaceObject({
                                objectType: ResourceType.GROUPS,
                                id: group.id!,
                                children: childrenIds
                            });
                            setWorkspaceObjects({ objectType: workspaceObjects?.objectType ?? ResourceType.GROUPS });
                        }).catch((error) => {
                            toast.error(error.message);
                        }
                        );
                    }}
                    onClose={(): void => {
                        setLeaveGroup(false);
                    }}
                />
            }
            {currentWorkspaceObject && currentWorkspaceObject.objectType === ResourceType.SAFETY_MODELS &&
                <ExportEventSequenceSelectorDialogBox
                    open={exportResource}
                    safetyModel={currentWorkspaceObject.object}
                    eventSequenceList={eventSequenceList}
                    onClose={(): void => { setExportResource(false); }}
                    onExport={(eventSequenceSelected: EventSequenceSummary | undefined): void => {
                        setExportResource(false);
                        if (!eventSequenceSelected) {
                            return;
                        }
                        exportEventSequence(eventSequenceSelected);
                    }}
                />
            }
        </>
    );
}