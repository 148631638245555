import {ButtonProps} from '../../@types/button-props';
import { ReactElement } from 'react';
import Button from '@mui/material/Button';
import LoginIcon from '@mui/icons-material/Login';

export default function ButtonComponent(props: ButtonProps): ReactElement {

    const type = props.type ? props.type : 'primary';
    const size = props.size ? props.size : '';

    return (
        <Button className={type + ' ' + size}
            variant='contained'
            size='small'
            component='button'
            onClick={props.onClick}
            startIcon={<LoginIcon />}
            sx={{textTransform: 'none'}}
        >
            {props.label}
        </Button>
    )

}