import GroupService from '../services/group.service';
import EventSequenceService from '../services/event-sequence.service';
import SafetyService from '../services/safety.service';
import ResourceBase from '../@types/resource-base';
import {
    getGroupNameWithAscendants,
    getGroupTreeItemDescendantIds,
    getPathToRootAsArrayOfString,
    groupsTreeItems
} from './group-utils';
import {BreadCrumbsProp} from '../@types/bread-crumb';
import {ResourceType} from '../@types/resource-type';
import Group from '../@types/group';
import usersService from '../services/users.service';
import {userTreeItems} from './user-utils';
import {User} from '../context/userContext';
import EventSequence from '../@types/event-sequence';
import SafetyModel from '../@types/safety-model';
import {
    getEventSequencePathToRootAsArrayOfString,
    getSafetyModelPathToRootAsArrayOfString,
    makeSafetyModel,
    safetyModelsTreeItems
} from './safety-model-utils';
import {WorkspaceResource} from '../@types/workspace-types';
import EventSequenceSummary from '../@types/event-sequence-summary';
import {singularizeCamelCase} from './utils';
import OccurrenceService from '../services/occurrence.service';
import SpringDataPage, {BasicPage, emptyPage, PageInfo} from '../@types/spring-data-page';
import {occurrenceTreeItems} from './occurrence-utils';

export function getWorkspaceObjectType(value: number): ResourceType {
    switch (value) {
        case 0:
            return ResourceType.GROUPS;
        case 1:
            return ResourceType.SAFETY_MODELS;
        case 2:
            return ResourceType.OCCURRENCE_REPORTS;
        case 3:
            return ResourceType.TRAFFIC_MODELS;
        case 4:
            return ResourceType.OPERATIONAL_CHANGES;
        case 5:
            return ResourceType.RISK_FORECASTS;
        case 6:
            return ResourceType.WORKSHOPS;
        case 7:
            return ResourceType.USERS;
        case 8:
            return ResourceType.EVENT_SEQUENCES;
        default:
            return ResourceType.GROUPS;
    }
}

export function getWorkspaceObjectTypeAsNumber(value: ResourceType): number {
    switch (value) {
        case ResourceType.GROUPS:
            return 0;
        case ResourceType.SAFETY_MODELS:
            return 1;
        case ResourceType.OCCURRENCE_REPORTS:
            return 2;
        case ResourceType.TRAFFIC_MODELS:
            return 3;
        case ResourceType.OPERATIONAL_CHANGES:
            return 4;
        case ResourceType.RISK_FORECASTS:
            return 5;
        case ResourceType.WORKSHOPS:
            return 6;
        case ResourceType.USERS:
            return 7;
        case ResourceType.EVENT_SEQUENCES:
            return 8;
        default:
            return 0;
    }
}

export function getWorkspaceObjectLabel(value: number): string {
    switch (value) {
        case 0:
            return 'Groups';
        case 1:
            return 'Safety Models';
        case 2:
            return 'Occurrence Reports';
        case 3:
            return 'Traffic Models';
        case 4:
            return 'Operational Changes';
        case 5:
            return 'Risk Forecasts';
        case 6:
            return 'Workshops';
        case 7:
            return 'Admin';
        case 8:
            return 'Event Sequences';
        default:
            return 'Groups';
    }
}

export function getChildrenIdOfSelectedWorkspaceObject(type: ResourceType, object: any): number[] {
    switch (type) {
        case ResourceType.GROUPS:
            return (object as Group).getChildren();
        default:
            return [];
    }
}


// @ts-ignore
export function fetchPagedWorkspaceObjects(type: ResourceType, page: BasicPage, groupId?: number|null, searchTerm?: string): Promise<any[]|SpringDataPage<any>>|null {
    switch (type) {
        case ResourceType.GROUPS:
            return GroupService.getGroups();
        case ResourceType.SAFETY_MODELS:
            return SafetyService.fetchPagedSafetyModels(page, groupId, searchTerm);
        case ResourceType.TRAFFIC_MODELS:
            return null;
        case ResourceType.WORKSHOPS:
            return null;
        case ResourceType.OCCURRENCE_REPORTS:
            return OccurrenceService.fetchOccurrences({...page, sort: 'dateTime', direction: 'DESC'}, groupId, searchTerm);
        case ResourceType.OPERATIONAL_CHANGES:
            return null;
        case ResourceType.RISK_FORECASTS:
            return null;
        case ResourceType.USERS:
            return usersService.getPagedUsers({size: page.size, offset: page.offset, sort: 'lastName', direction: page.direction }, null, searchTerm);
        default:
            return null;
    }
}

export function fetchAllResources(type: ResourceType): Promise<any[]>|null {
    switch (type) {
        case ResourceType.GROUPS:
            return GroupService.getGroups();
        case ResourceType.SAFETY_MODELS:
            return SafetyService.getSafetyModels();
        case ResourceType.TRAFFIC_MODELS:
            return null;
        case ResourceType.WORKSHOPS:
            return null;
        case ResourceType.OCCURRENCE_REPORTS:
            return null;
        case ResourceType.OPERATIONAL_CHANGES:
            return null;
        case ResourceType.RISK_FORECASTS:
            return null;
        case ResourceType.USERS:
            return usersService.getAllUsers();
        default:
            return null;
    }
}

export function getResourcesAsPage(resources: any[]|undefined, page: BasicPage, groupId: number|undefined, searchTerm: string|undefined): SpringDataPage<any> {
    if (!resources || resources.length === 0) return emptyPage;
    let filteredResources = resources;
    if (groupId) {
        filteredResources = resources.filter((resource) => resource.id === groupId);
    }
    if (searchTerm && searchTerm.trim() !== '') {
        filteredResources = filteredResources.filter((resource) => resource.name.toLowerCase().includes(searchTerm.trim().toLowerCase()));
    }
    filteredResources = topLevelResources(filteredResources).sort((a, b) => a.compareTo(b));
    let pagedResources = filteredResources;
    if (filteredResources.length > page.size) {
        const start = page.size * page.offset;
        pagedResources = filteredResources.slice(start, start + page.size);
    }
    const pageInfo: PageInfo = {
        number: page.offset,
        size: pagedResources.length,
        totalElements: filteredResources.length,
        totalPages: Math.ceil(filteredResources.length / page.size)
    }
    return {
        content: addChildren(pagedResources, resources),
        page: pageInfo
    };
}

function addChildren(resultedResources: any[], resources: any[]): any[] {
    let resourcesWithChildren = resultedResources;
    resourcesWithChildren.forEach((resource) => {
        if (resource.children && resource.children.length > 0) {
            resourcesWithChildren = resourcesWithChildren.concat(addChildren(resources.filter((res) => resource.children.includes(res.id)), resources));
        }
    })
    return resourcesWithChildren;
}

function topLevelResources(resources: any[]): any[] {
    return resources.filter((resource) =>
        !resource.parent || !resources.find((parent) => parent.id === resource.parent)
    );
}

export function duplicateResourceById(type: ResourceType, sourceResourceId: number | string, targetGroupId: number | string | undefined): Promise<any>|null {
    switch (type) {
        case ResourceType.GROUPS:
            return GroupService.duplicateGroup(sourceResourceId, targetGroupId);
        case ResourceType.SAFETY_MODELS:
            return SafetyService.duplicateSafetyModel(sourceResourceId, targetGroupId);
        default:
            return null;
    }
}

export function deleteResourceById(type: ResourceType, id: number): Promise<any>|null {
    switch (type) {
        case ResourceType.GROUPS:
            return GroupService.deleteGroupById(id);
        case ResourceType.SAFETY_MODELS:
            return SafetyService.deleteSafetyModelById(id);
        case ResourceType.EVENT_SEQUENCES:
        case ResourceType.EVENT_SEQUENCE_TREES:
            return EventSequenceService.deleteEventSequence(id);
        default:
            return null;
    }
}

export function createWorkspaceObject(resource: ResourceBase): any {
    switch (resource.type) {
        case ResourceType.GROUPS:
            return (new Group()).setParent(resource.parentId);
        case ResourceType.SAFETY_MODELS:
            return makeSafetyModel({group: { id: resource.parentId }} as SafetyModel);
        case ResourceType.EVENT_SEQUENCES:
            return {} as EventSequenceSummary;
        case ResourceType.EVENT_SEQUENCE_TREES:
            return {} as EventSequence;
        case ResourceType.TRAFFIC_MODELS:
            return null;
        case ResourceType.WORKSHOPS:
            return null;
        case ResourceType.OCCURRENCE_REPORTS:
            return null;
        case ResourceType.OPERATIONAL_CHANGES:
            return null;
        case ResourceType.RISK_FORECASTS:
            return null;
        case ResourceType.USERS:
            return {} as User;
        default:
            return null;
    }
}

export function fetchWorkspaceObject(resource: ResourceBase): Promise<any>|null {
    switch (resource.type) {
        case ResourceType.GROUPS:
            return resource.id ? GroupService.getGroupById(resource.id) : null;
        case ResourceType.SAFETY_MODELS:
            return resource.id ? SafetyService.getSafetyModelById(resource.id) : null;
        case ResourceType.EVENT_SEQUENCES:
            return resource.id ? EventSequenceService.getEventSequenceSummaryById(resource.id) : null;
        case ResourceType.EVENT_SEQUENCE_TREES:
            return resource.id ? EventSequenceService.getEventSequenceById(resource.id) : null;
        case ResourceType.TRAFFIC_MODELS:
            return null;
        case ResourceType.WORKSHOPS:
            return null;
        case ResourceType.OCCURRENCE_REPORTS:
            return null;
        case ResourceType.OPERATIONAL_CHANGES:
            return null;
        case ResourceType.RISK_FORECASTS:
            return null;
        case ResourceType.USERS:
            return resource.id ?  usersService.getUserById(resource.id) : null;
        default:
            return null;
    }
}

export function getTreeItemDescendantIds(object: any, type: ResourceType, resources: any[]): number[] {
    switch (type) {
        case ResourceType.GROUPS:
            return getGroupTreeItemDescendantIds(object as Group, resources as Group[]);
        default:
            return [];
    }
}

export function generateTreeItems(objects: any[], type: ResourceType, groups?: Group[]): any[] {
    switch (type) {
        case ResourceType.GROUPS:
            return groupsTreeItems(objects);
        case ResourceType.USERS:
            return userTreeItems(objects);
        case ResourceType.SAFETY_MODELS:
            return safetyModelsTreeItems(objects);
        case ResourceType.OCCURRENCE_REPORTS:
            return occurrenceTreeItems(objects, groups);
        default:
            return [];
    }
}

export function getBreadCrumbsProps(object: any, type: ResourceType): BreadCrumbsProp[] {
    switch (type) {
        case ResourceType.GROUPS:
            return getPathToRootAsArrayOfString(object);
        case ResourceType.SAFETY_MODELS:
            return getSafetyModelPathToRootAsArrayOfString(object);
        case ResourceType.EVENT_SEQUENCES:
        case ResourceType.EVENT_SEQUENCE_TREES:
            return getEventSequencePathToRootAsArrayOfString(object);
        case ResourceType.EVENT_SEQUENCE_HISTORY_TREES:
            return [...getEventSequencePathToRootAsArrayOfString(object.eventSequenceDTO), {
                label: 'Version ' + object.id,
                type: ResourceType.EVENT_SEQUENCE_HISTORY_TREES,
                id: object.id
            }];
        default:
            return [];
    }
}

export function getResourceNameWithAscendants(object: any, type: ResourceType): string {
    switch (type) {
        case ResourceType.GROUPS:
            return getGroupNameWithAscendants(object);
        default:
            return object.name ?? '';
    }
}

export function filterOnGroupCanEdit(data: WorkspaceResource): WorkspaceResource {
    let resources = data.resources;
    if (resources) {
        if (data.objectType === ResourceType.GROUPS) {
            resources = resources.filter((grp: Group) => grp.canEdit());
        }
        return {objectType: ResourceType.GROUPS, resources: resources};
    }
    return data;
}

export function filterOnGroupCanEditResources(data: WorkspaceResource): WorkspaceResource {
    let resources = data.resources;
    if (resources) {
        if (data.objectType === ResourceType.GROUPS) {
            resources = resources.filter((grp: Group) => grp.canAddOrEditResources());
        }
        else if (data.objectType === ResourceType.SAFETY_MODELS) {
            resources = resources.filter((sm: SafetyModel) => sm.group.canAddOrEditResources());
        }
        return {objectType: data.objectType, resources: resources};
    }
    return data;
}

export function getSingularResourceLabel(type: ResourceType|undefined): string {
    let resourceType = type;
    if (resourceType === ResourceType.EVENT_SEQUENCE_TREES || resourceType === ResourceType.EVENT_SEQUENCE_HISTORY) {
        resourceType = ResourceType.EVENT_SEQUENCES;
    }
    if (resourceType === ResourceType.EVENT_SEQUENCE_HISTORY_TREES) {
        resourceType = ResourceType.EVENT_SEQUENCE_HISTORY;
    }
    return singularizeCamelCase(resourceType);
}

export function getResourceTypeToOpen(type: ResourceType|undefined): ResourceType {
    let resourceType = type ?? ResourceType.UNKNOWN;
    if (type === ResourceType.EVENT_SEQUENCES || type === ResourceType.EVENT_SEQUENCE_HISTORY || type === ResourceType.EVENT_SEQUENCE_TREES || type === ResourceType.EVENT_SEQUENCE_HISTORY_TREES) {
        resourceType = ResourceType.SAFETY_MODELS;
    }
    return resourceType;
}
