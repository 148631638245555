import React, { ReactElement, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Paper,
    TextField,
    Tooltip
} from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel';

import Draggable from 'react-draggable';
import { PaperProps } from '@mui/material/Paper';

import { pickContrastingTextColor } from '../../../../utils/canvas/colors';
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SearchIcon from '@mui/icons-material/Search';
import { CanvasEnvironment } from '../../../../utils/canvas/canvas-environment';
import TagService from '../../../../services/tag-service';
import Tag, { TagLinks, TagPresentation } from '../../../../@types/tag';
import * as canvasUtils from '../../../../utils/canvas/node-utils'
import * as canvasActions from '../../../../utils/canvas/canvas-actions';


const colorSwatches = [
    { color: '#009966', name: 'Green-cyan' },
    { color: '#8fbc8f', name: 'Dark sea green' },
    { color: '#3cb371', name: 'Medium sea green' },
    { color: '#00b140', name: 'Green screen' },
    { color: '#013220', name: 'Dark green' },
    { color: '#6699cc', name: 'Blue-gray' },
    { color: '#0000ff', name: 'Blue' },
    { color: '#e6e6fa', name: 'Lavendar' },
    { color: '#9400d3', name: 'Dark violet' },
    { color: '#330066', name: 'Deep violet' },
    { color: '#808080', name: 'Gray' },
    { color: '#36454f', name: 'Charcoal grey' },
    { color: '#f7e7ce', name: 'Champagne' },
    { color: '#c21e56', name: 'Rose red' },
    { color: '#cc338b', name: 'Magenta-pink' },
    { color: '#dc143c', name: 'Crimson' },
    { color: '#ff0000', name: 'Red' },
    { color: '#cd5b45', name: 'Dark coral' },
    { color: '#eee600', name: 'Titanium yellow' },
    { color: '#ed9121', name: 'Carrot orange' },
    { color: '#c39953', name: 'Aztec Gold' },
];


const unicode = (codePoint: string | undefined): string => {
    if (codePoint === undefined) return ' ';
    let codes = codePoint.split('-').map((code) => parseInt(`0x${code})`));
    return String.fromCodePoint(...codes);
}

const intersection = (a: Tag[], b: TagLinks, nodeId: number, isEvent: boolean): Tag[] => {
    const tagIds = isEvent ?  b.eventTags.filter((et) => et.eventId === nodeId).map((et) => et.tagId) : b.faultTreeNodeTags.filter((ft) => ft.faultTreeNodeId === nodeId).map((ft) => ft.tagId);
    const setB = new Set(tagIds);
    return [...a.filter((x) => setB.has(x.id!))];
}

function PaperComponent(props: PaperProps): ReactElement {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}



const TagsComponentModal = (props: { open: boolean, env: CanvasEnvironment | null, onClose: () => void }): ReactElement => {
    const { open, env, onClose } = props;
    const [isRunning, setIsRunning] = React.useState<boolean>(false);
    const [checked, setChecked] = useState<Tag[]>([]);
    const [leftItems, setLeftItems] = useState<Tag[]>([]);
    const [isEditingTag, setIsEditingTag] = useState<boolean>(false);
    const [editedTag, setEditedTag] = useState<Tag | null>(null);
 
    const handleToggle = (value: Tag) => async () => {
        const currentIndex = checked.map((tag) => tag.id).indexOf(value.id);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        const node = env?.selection[0].get('data');

        try {
            const isEventNode = canvasUtils.isEvent(node.type);
            const elementUpdatedTag = await TagService.updateNodeTagLinks(node.id, newChecked.map((tag) => tag.id!), isEventNode);
            setChecked(elementUpdatedTag);
            if (isEventNode) {
                const existingTagLinks = env?.esdTagLinks?.eventTags.filter((et) => et.eventId !== node.id) ?? [];
                const newTagLinks = elementUpdatedTag.map((tag) => ({eventId: node.id, tagId: tag.id!}));
                env!.esdTagLinks!.eventTags = [...existingTagLinks, ...newTagLinks];
            } else {
                const existingTagLinks = env?.esdTagLinks?.faultTreeNodeTags.filter((et) => et.faultTreeNodeId !== node.id) ?? [];
                const newTagLinks = elementUpdatedTag.map((tag) => ({faultTreeNodeId: node.id, tagId: tag.id!}));
                env!.esdTagLinks!.faultTreeNodeTags = [...existingTagLinks, ...newTagLinks];
            }
            canvasActions.addTagsToNode(env!, env?.selection[0]!);
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const deleteTag = (tag: Tag) => async () => {
        setIsRunning(true);
        TagService.deleteTag(tag.id!).then(() => {
            const node = env?.selection[0].get('data');
            const isEventNode = canvasUtils.isEvent(node.type);
            if (isEventNode) {
                env!.esdTagLinks!.eventTags = env?.esdTagLinks?.eventTags.filter((et) => et.tagId !== tag.id) ?? [];
            } else {
                env!.esdTagLinks!.faultTreeNodeTags = env?.esdTagLinks?.faultTreeNodeTags.filter((et) => et.tagId !== tag.id) ?? [];
            }

            env!.allGroupTags = env!.allGroupTags.filter((t) => t.id !== tag.id);
            const found = checked.find((t) => t.id === tag.id);
            if (found) {
                canvasActions.addTagsToNode(env!, env?.selection[0]!);
            }

            setLeftItems(env!.allGroupTags);
            setChecked(checked.filter((t) => t.id !== tag.id));
        }).catch((error) => {
            toast.error(error.message);
        }).finally(() => {
            setIsRunning(false);
        });
    }

    const addTag = (name: string, tagPresentation: TagPresentation): void => {
        let presentation: TagPresentation = tagPresentation;
        const tag: Tag = {name,presentation,group: { id: env?.eventSequence?.group?.id! }
        };
        setIsRunning(true);
        TagService.addTag(tag).then((newTag) => {
            env!.allGroupTags.push(newTag);
            setLeftItems(env!.allGroupTags);
        }).catch((error) => {
            toast.error(error.message);
        }).finally(() => {
            setIsRunning(false);
        });

    }

    const updateTag = (tag: Tag, tagPresentation: TagPresentation): void => {
        tag.presentation = tagPresentation;
        setIsRunning(true);
        TagService.updateTag(tag).then(() => {
            env!.allGroupTags = env!.allGroupTags.map((t) => t.id === tag.id ? tag : t);
            setLeftItems(env!.allGroupTags);
            const found = checked.find((t) => t.id === tag.id);
            if (found) {
                canvasActions.addTagsToNode(env!, env?.selection[0]!);
            }
        }).catch((error) => {
            toast.error(error.message);
        }).finally(() => {
            setIsRunning(false);
        });
    }


    const onSearchTag = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const searchValue = event.target.value.toLowerCase();
        if (searchValue === '') {
            setLeftItems(env!.allGroupTags);
            return;
        }
        setLeftItems(env!.allGroupTags.filter((tag) => tag.name.toLowerCase().includes(searchValue)));
    }

    const customList = (items: Tag[], disabled?: boolean): ReactElement => (
        <Paper elevation={0}>
            <Box  sx={{ display: 'flex', justifyContent: 'space-between', p: 0.5 }}>
                <TextField
                    label="Filter"
                    variant="outlined"
                    fullWidth
                    onChange={onSearchTag}
                    sx={{mt: 1 , mb: 1, mr: 1}}
                    InputProps={{
                        startAdornment: <SearchIcon />
                    }}
                />
                <Tooltip title="New Tag">
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 1, mb: 1, mr: 1 }}
                        size='small'
                        startIcon={<AddIcon fontSize='small' />}
                        disabled={isRunning}
                        onClick={(): void => { setEditedTag(null);  setIsEditingTag(true) }}>
                        New
                    </Button>
                </Tooltip>
            </Box>
            <Divider />
            <List
                dense
                component="div"
                role="list"
                sx={{
                    height: 300,
                    overflow: 'auto',
                    marginTop: 1,
                    '&.MuiListItem-divider': {
                        borderBottom: '2px solid rgba(0,0,0,0.1)'
                    }
                }}
                subheader={
                    <ListSubheader
                        inset
                        sx={{
                            color: '#333333',
                            bgcolor: '#EBF2F9',
                            fontSize: 14,
                            '&.MuiListSubheader-inset': {
                                marginBottom: '2px'
                            },      
                        }}

                    >
                        {`Existing Tag List (${items.length}/${checked.length})`}
                    </ListSubheader>

                }
            >
                {items.sort((a, b) => a.name.toLowerCase() <= b.name.toLowerCase() ? -1 : 1).map((value: Tag) => {
                    const labelId = `list-item-${value.id}-label`;
                    const unicodeLabel = value.presentation.unicode ? unicode(value.presentation.unicode) : ' ';
                    const bgColor = value.presentation.color;
                    return (
                        <ListItem
                            key={value.id}
                            role="listitem"
                            divider
                            secondaryAction={
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 0.5 }}>
                                    <Tooltip title="Edit">
                                        <IconButton
                                            edge="end"
                                            aria-label="edit"
                                            onClick={(): void => { setEditedTag(value);  setIsEditingTag(true) }}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Delete">
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={deleteTag(value)}
                                            color="error"
                                        >
                                            <DeleteIcon  />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            }
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.map((tag) => tag.id).indexOf(value.id) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    disabled={disabled}
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    onClick={handleToggle(value)}
                                />
                                {bgColor ? <div style={{width: 24, height: 24, margin: 16, backgroundColor: bgColor, textAlign: 'center', color: pickContrastingTextColor(bgColor)}}>{unicodeLabel}</div> : <div style={{width: 24, height: 24, margin: 16, textAlign: 'center'}}>{unicodeLabel}</div>}
                            </ListItemIcon>
                            
                            <ListItemText id={labelId} primary={`${value.name}`} />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );

    const TagPicker = (props: { open: boolean, onClose: () => void }): ReactElement => {
        const { open, onClose } = props;
        const [chosenEmoji, setChosenEmoji] = useState<string | null>(null);
        const [chosenCode, setChosenCode] = useState<string | null>(null);
        const [chosenColor, setChosenColor] = useState<string | null>(null);
        const [tagName, setTagName] = useState<string | null>(null);

        const reactions = ['2460', '2461', '2462', '24B6', '24B7', '24B8', '003F', '272E', '274B', '058D', '233F', '2328', '260F', '2296', '2622', '2708',
            '083A', '2190', '2191', '2192', '2193', '23F1', '2600']
            
        const onColorClick = (colorSwatch: { color: string, name: string }): void => {
            setChosenColor(colorSwatch.color);
        };

        const onEmojiClick = (code: string): void => {
            setChosenCode(code);
            setChosenEmoji(unicode(code));
        };

        const onTagNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
            setTagName(event.target.value);
        };

        useEffect(() => {
            if (editedTag) {
                setTagName(editedTag.name);
                if (editedTag.presentation.unicode) {
                    setChosenCode(editedTag.presentation.unicode);
                    setChosenEmoji(unicode(editedTag.presentation.unicode));
                } else {
                    setChosenCode(null);
                    setChosenEmoji(null);
                    setChosenColor(null);
                }

                if (editedTag.presentation.color) {
                    setChosenColor(editedTag.presentation.color);
                } else {
                    setChosenColor(null);
                }
            }
        }, [open]);

        return (
            <Dialog
                open={open}
                onClose={onClose}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>{editedTag ? 'Update Tag' : 'Create Tag' }</DialogTitle>
                <DialogContent dividers>
                    <Box component="form">
                        <TextField
                            label="Name"
                            value={tagName || ''}
                            variant="outlined"
                            onChange={onTagNameChange}
                            fullWidth
                            required
                        />
                        <hr />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
                                    {colorSwatches.map((colorSwatch) => (
                                        <Box key={colorSwatch.name} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1,  cursor: 'pointer', backgroundColor: colorSwatch.color, width: 20, height: 20 }} onClick= {(): void => onColorClick(colorSwatch)}  >
                                        </Box>
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper  sx={{display: 'flex', width: '100%', alignItems: 'center', p: '2px 4px'}}>
                                    <InputBase
                                        placeholder="No Color Chosen"
                                        sx={{ flex: 1, ml: 1 }}                                       
                                        value={chosenColor ? colorSwatches.find((colorSwatch) => colorSwatch.color === chosenColor)?.name  : 'No Color Chosen'}
                                    />
                                    <Divider sx={{ height: 28, m: 0.2 }}  orientation="vertical"/>
                                    <IconButton onClick={(): void => setChosenColor(null)}><CancelIcon /></IconButton>
                                </Paper>
                            </Grid>
                        </Grid>
                        <hr />
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={1}>
                                    {reactions.map((reaction) => (
                                        <Box key={reaction} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 1,  cursor: 'pointer' }} onClick= {(): void => onEmojiClick(reaction)} >
                                            {unicode(reaction)}
                                        </Box>
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper sx={{display: 'flex', width: '100%', alignItems: 'center', p: '2px 4px'}}>
                                    <InputBase
                                        placeholder="No Tag Chosen"
                                        sx={{ flex: 1, ml: 1 }}
                                        value={chosenEmoji ? chosenEmoji : 'No Tag Chosen'}
                                    />
                                    <Divider sx={{ height: 28, m: 0.2 }}  orientation="vertical"/>
                                    <IconButton  onClick={(): void => { setChosenEmoji(null); setChosenCode(null); }}><CancelIcon /></IconButton>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>                    
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" disabled={isRunning} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button variant="contained"
                        color="primary"
                        disabled={isRunning || !(chosenEmoji || chosenColor) || !tagName}
                        onClick={(): void =>
                        {
                            editedTag ? updateTag({ ...editedTag, name: tagName! }, { unicode: chosenCode ?? undefined, color: chosenColor ?? undefined }) : addTag(tagName!, { unicode: chosenCode ?? undefined, color: chosenColor ?? undefined });
                            onClose();
                        }
                        }
                    >
                        {editedTag ? 'Update' : 'Create'}
                    </Button>

                </DialogActions>
            </Dialog>

            
        );
    }

    useEffect(() => {
        if (open) {
            const groupId = env?.eventSequence?.group?.id;
            const esdId = env?.eventSequence?.id;
            const selectedNode = env?.selection[0];
            if (!env || !groupId || !esdId || !selectedNode) {
                return;
            }
            setLeftItems(env.allGroupTags);
            setChecked(intersection(env.allGroupTags, env.esdTagLinks, selectedNode.get('data').id, canvasUtils.isEvent(selectedNode.get('data').type)));
        }
    }, [open]);


    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="md"
            PaperComponent={PaperComponent}
        >
            <DialogTitle style={{ cursor: 'move'}} id='draggable-dialog-title'>Manage Tags</DialogTitle>
            <DialogContent dividers>
                {customList(leftItems, isRunning)}
                <TagPicker open={isEditingTag} onClose={(): void => {setIsEditingTag(false)}}/>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="secondary" disabled={isRunning} onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
        
    );
};

export default TagsComponentModal;