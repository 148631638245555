import axios from 'axios';
import {API_URL} from '../config/constants';
import SpringDataPage, {BasicPage} from '../@types/spring-data-page';
import {getErrorMessage} from '../utils/utils';
import Occurrence from '../@types/occurrence';


class OccurrenceService {
    static occurrenceUrlV2 = API_URL + 'api/v2/occurrences';

    formOccurrence(occurrence: any): Occurrence {
        let occur: Occurrence = {} as Occurrence;
        occur.id = occurrence.id;
        occur.uniqueId = occurrence.uniqueId;
        occur.displayName = occurrence.displayName;
        occur.dateTime = occurrence.dateTime;
        occur.location = occurrence.location;
        occur.created = occurrence.created;
        occur.source = occurrence.occurrenceSource;
        occur.rawReport = occurrence.rawReport;
        occur.group = {id: occurrence.group.id};
        return occur;
    }

    formOccurrencesPagePromise(occurrencesData: any): Promise<SpringDataPage<Occurrence>> {
        let occurrencesPage: SpringDataPage<Occurrence> = {} as SpringDataPage<Occurrence>;
        if (occurrencesData) {
            if (occurrencesData._embedded?.occurrenceDTOList) {
                occurrencesPage.content = occurrencesData._embedded.occurrenceDTOList?.map((occurrence: any) => this.formOccurrence(occurrence));
            } else {
                occurrencesPage.content = [];
            }
            occurrencesPage.page = occurrencesData.page;
        }
        return Promise.resolve(occurrencesPage);
    }

    fetchOccurrences(page: BasicPage, groupId?: number|null, searchTerm?: string): Promise<SpringDataPage<Occurrence>> {
        let url = `${OccurrenceService.occurrenceUrlV2}?`
            + `size=${page.size}&`
            + `page=${page.offset}&`
            + `sort=${page.sort},${page.direction}`;
        if (searchTerm && searchTerm !== '') url += `&searchTerm=${searchTerm}`;
        if (groupId) url += `&groupId=${groupId}`;
        return axios
            .get(url, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {
                return this.formOccurrencesPagePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to get occurrences: ' + getErrorMessage(err));
            })
    }
}

export default new OccurrenceService();