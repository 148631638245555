/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import EventSequence from '../../@types/event-sequence';
import getPedigreeAbbrev from '../pedigree-utils';
import { FaultTree } from '../../@types/faultTree';
import { FaultTreeNode } from '../../@types/faultTreeNode';
import * as joint from '@joint/plus';
import { Node, Precursor, Barrier, BaseLink,
    NegativeNode, PositiveNode, PivotalNode,  
    InitiatingNode, HouseNode, TransferNode,
    ExpandButton, NeutralNode, setTagIcons, setCalloutIcon, setCalloutOpenForm
} from '../isam-nodes'
import { toast } from 'react-toastify';
import { CanvasEnvironment } from './canvas-environment';
import { EsdEvent } from '../../@types/esdEvent';
import * as CanvasUtils from './node-utils';
import { EventTag, FaultTreeNodeTag } from '../../@types/tag';


export const allshapes = {
    ...joint.shapes,
    isam: { 
        Node, 
        Barrier,
        BaseLink, 
        Precursor, 
        NegativeNode, 
        PositiveNode, 
        PivotalNode,  
        InitiatingNode,
        HouseNode,
        TransferNode
    }
};

function getProbOrFrequencyValueGivenMode(quantificationMode: string, ftNode: FaultTreeNode) {
    let qval = ((ftNode.nodeClass === 'PRECURSOR') || (ftNode.nodeClass === 'INDUCED')) ?
        ftNode.frequency : ftNode.probability;
    let label = ((ftNode.nodeClass === 'PRECURSOR') || (ftNode.nodeClass === 'INDUCED')) ? 'F' : 'P';
    if ((quantificationMode === 'MODE_PROBABILITY') &&
    ((ftNode.nodeClass === 'PRECURSOR') || (ftNode.nodeClass === 'INDUCED'))) {
        qval = ftNode.probability !== undefined ? ftNode.probability : ftNode.frequency;
        label = 'P';
    }
    if ((quantificationMode === 'MODE_FREQUENCY') &&
    ((ftNode.nodeClass === 'PRECURSOR') || (ftNode.nodeClass === 'INDUCED'))) {
        qval = ftNode.frequency !== undefined ? ftNode.frequency : ftNode.probability;
        label = 'F' ;
    }

    if (qval === undefined) {
        console.log('quantificationMode:', quantificationMode);
        console.log('ftNode:', ftNode)
    }
    return { label, qval };
}


export function addTagsToNode(canvasEnvironment: CanvasEnvironment, element: joint.dia.Element) {
    const { allGroupTags, esdTagLinks, paper } = canvasEnvironment;
    const { eventTags, faultTreeNodeTags } = esdTagLinks;
    let tagLinks: EventTag[] | FaultTreeNodeTag[] = [];

    if (CanvasUtils.isEvent(element.get('data').type)) {
        tagLinks = eventTags.filter((tagLink) => tagLink.eventId === element.get('data').id);
    } else {
        tagLinks = faultTreeNodeTags.filter((tagLink) => tagLink.faultTreeNodeId === element.get('data').id);
    }
    let tagLinksId = tagLinks.map((tagLink) => tagLink.tagId);
    let tags = allGroupTags.filter((tag) => tagLinksId.includes(tag.id!));
    setTagIcons(paper, element, tags);
}

export function addTagsToNodes(canvasEnvironment: CanvasEnvironment, elements: joint.dia.Element[]) {
    const { allGroupTags, esdTagLinks, paper } = canvasEnvironment;
    const { eventTags, faultTreeNodeTags } = esdTagLinks;
    let tagLinks: EventTag[] | FaultTreeNodeTag[] = [];

    elements.forEach((element) => {
        if (element.get('nodeClass') === 'CALLOUTNODE') return;
        if (CanvasUtils.isEvent(element.get('data').type)) {
            tagLinks = eventTags.filter((tagLink) => tagLink.eventId === element.get('data').id);
        } else {
            tagLinks = faultTreeNodeTags.filter((tagLink) => tagLink.faultTreeNodeId === element.get('data').id);
        }
        let tagLinksId = tagLinks.map((tagLink) => tagLink.tagId);
        let tags = allGroupTags.filter((tag) => tagLinksId.includes(tag.id!));
        setTagIcons(paper, element, tags);
    });
}

export function toggleTagsMode(canvasEnvironment: CanvasEnvironment) {
    if (!canvasEnvironment.isTagsModeActive) {
        canvasEnvironment.fetchTagsAndTagLinks().then(() => {
            canvasEnvironment.isTagsModeActive = !canvasEnvironment.isTagsModeActive;
            addTagsToNodes(canvasEnvironment, canvasEnvironment.graph.getElements());
        }).catch((error) => {
            toast.error(error);
        });
    } else {
        canvasEnvironment.isTagsModeActive = !canvasEnvironment.isTagsModeActive;
        canvasEnvironment.graph.getElements().forEach((element) => {
            element.removeProp('tags');
        });
    }
}

export function addCalloutsToNodes(canvasEnvironment: CanvasEnvironment, elements: joint.dia.Element[]) {
    const { graph, paper } = canvasEnvironment;
    elements.forEach((element) => {
        if (canvasEnvironment.isCalloutsModeActive) {
            setCalloutOpenForm(graph, paper, element);
            
        } else {
            setCalloutIcon(paper, element);
        }
        
    });
}

export function toggleCalloutsMode(canvasEnvironment: CanvasEnvironment) {
    if (canvasEnvironment.isCalloutsModeActive) {
        let callouts = canvasEnvironment.graph.getElements().filter((element) => element.get('nodeClass') === 'CALLOUTNODE');
        callouts.forEach((callout) => {
            callout.remove();
        });
    } else {
        canvasEnvironment.graph.getElements().forEach((element) => {
            element.removeProp('callout');
        });
    }
    canvasEnvironment.isCalloutsModeActive = !canvasEnvironment.isCalloutsModeActive;
    addCalloutsToNodes(canvasEnvironment, canvasEnvironment.graph.getElements());
}


export function addTools(paper: joint.dia.Paper, elements: any[]) {
    const toolName = 'expand-tools';
    elements.forEach((element) => {
        if (element.get('nodeClass') === 'CALLOUTNODE')  return;
        if (element.get('nodeClass') === 'FAULTTREENODE' && !element.get('data').childIds) return;
        if (element.get('nodeClass') !== 'FAULTTREENODE' && !element.get('data').faultTree) return;
        const view = element.findView(paper);
        if (view.hasTools(toolName)) return;
        const toolsView = new joint.dia.ToolsView({
            name: toolName,
            tools: [
                new ExpandButton()
            ]
        });
        view.addTools(toolsView);
    })
}

export function resizePaper(paper: joint.dia.Paper) {
    paper.fitToContent({
        useModelGeometry: true,
        allowNewOrigin: 'any',
        allowNegativeBottomRight: true,
        padding: { top: 50, left: 480 },
        contentArea: new joint.g.Rect(0, 0, 4000, 3000).union(paper.model.getBBox()!),

    });
}

export function layoutAndFocus(treeLayout: joint.layout.TreeLayout, paperScroller: joint.ui.PaperScroller, paper: joint.dia.Paper,focusPoint?: { x: number, y: number }) {
    treeLayout.layout();
    var center = focusPoint || treeLayout.getLayoutBBox()!.center();
    resizePaper(paper);
    paperScroller.center(center.x, center.y);
}



export function buildESDGraphFromObject(eventSequence: EventSequence, cellsGraph: joint.dia.Graph): joint.dia.Graph {
    const parentChildrenMap = new Map<string, string[]>();

    cellsGraph.resetCells([]);
    if (!eventSequence || !eventSequence.events) return cellsGraph;
    const eventSequenceUniqueId = eventSequence.uniqueId;
    try {
        eventSequence.events.forEach(event => {
            let eventUniqueId = event.uniqueId.replace(eventSequenceUniqueId, '');
            let qval = ['INITIATING', 'END', 'INPRECURSOR'].includes(event.type) ? event.frequency : event.probability;
            let label = ['INITIATING', 'END', 'INPRECURSOR'].includes(event.type) ? 'F' : 'P';
            let pedigree = getPedigreeAbbrev(event.pedigree);
            let qvalLabel = `${label}=${Number.parseFloat(qval).toExponential(2)}`;
            let bias = '';
            let cictt = event.occurrenceCategory ?? '';
            let node;
            if (event.type === 'INITIATING') {
                node = InitiatingNode.create(eventUniqueId, event.name, qvalLabel, pedigree, bias, cictt);
            } else if (event.type === 'PIVOTAL') {
                node = PivotalNode.create(eventUniqueId, event.name, qvalLabel, pedigree, bias, cictt);
            } else if (event.type === 'END') {
                if (event.outcome === 'POSITIVE') {
                    node = PositiveNode.create(eventUniqueId, event.name, qvalLabel, pedigree, bias, cictt);
                } else if (event.outcome === 'NEGATIVE') {
                    node = NegativeNode.create(eventUniqueId, event.name, qvalLabel, pedigree, bias, cictt);
                } else {
                    node = NeutralNode.create(eventUniqueId, event.name, qvalLabel, pedigree, bias, cictt);
                }
            } else {
                throw new Error('Unknown event type: ' + event.type);
            }
            node.set({ data: event });
            node.set('id', 'event-' + event.id);
            node.attr('body/title', event.name);
            node.set('nodeClass', 'EVENTNODE')
            cellsGraph.addCell(node);
            if (event.type === 'INITIATING') {
                parentChildrenMap.set('event-' + event.id, []);
            } else {
                let parent = parentChildrenMap.get('event-' + event.parentId);
                if (parent) {
                    parent.push('event-' + event.id);
                } else {
                    parentChildrenMap.set('event-' + event.parentId, ['event-' + event.id]);
                }
            }
        });

        parentChildrenMap.forEach((children, parentId) => {
            const parent = cellsGraph.getCell(parentId);
            if (parent) {
                children.forEach((childId) => {
                    const child = cellsGraph.getCell(childId);
                    if (child) {
                        const link = BaseLink.create(parent, child, child.get('data').parentRelationType);
                        link.set('id', 'link-' + parent.get('id') + '-' + child.get('id'));
                        cellsGraph.addCell(link);
                    }
                });
            }
        });

    } catch (e: any) {
        // eslint-disable-next-line no-console
        console.error('Error building ESDE events: ', e);
        toast.error(e.message);
    }

    
    return cellsGraph;
}

export function buildftGraphFromObject(eventSequence: EventSequence, faultTree: FaultTree): joint.dia.Graph {
    const cellsGraph = new joint.dia.Graph({}, { cellNamespace: allshapes });
    const parentChildrenMap = new Map<string, string[]>();

    if (!eventSequence || !eventSequence.events) return cellsGraph;

    const eventSequenceUniqueId = eventSequence.uniqueId;
    
    if (!faultTree) return cellsGraph;
    let faultTreeNodes: Array<FaultTreeNode> = faultTree.faultTreeNodes;
    let quantifcationMode = eventSequence.quantificationMode;
    if (!faultTreeNodes) return cellsGraph;
    try {
        faultTreeNodes.forEach((ftNode : FaultTreeNode) => {
            let nodeUniqueId = ftNode.uniqueId.replace(eventSequenceUniqueId, '');
            let nodeType = ftNode.nodeClass;
            let bias = ftNode.bias;
            let pedigree = getPedigreeAbbrev(ftNode.pedigree);
            let { label, qval } = getProbOrFrequencyValueGivenMode(quantifcationMode, ftNode);
            let qvalLabel = `${label}=${Number.parseFloat(qval.toString()).toExponential(2)}`;

            let node;
            if (nodeType === 'PRECURSOR') {
                node = Precursor.create(nodeUniqueId, ftNode.name, qvalLabel, pedigree, `B=${bias}`, ftNode.occurrenceCategory ?? '');
            } else if (nodeType === 'BARRIER') {
                node = Barrier.create(nodeUniqueId, ftNode.name, qvalLabel, pedigree, `B=${bias}`, ftNode.occurrenceCategory ?? '');
            } else {
                node = Node.create(nodeUniqueId, ftNode.name, qvalLabel, pedigree, `B=${bias}`, ftNode.occurrenceCategory ?? '');
            }
            if (ftNode.type !== '') {
                node.gate(ftNode.type.toLowerCase());
            }
            node.set({ data: ftNode });
            node.set('id', 'ft-' + ftNode.id);
            node.attr('body/title', ftNode.name);
            node.set('nodeClass', 'FAULTTREENODE')
            cellsGraph.addCell(node, { dry: true});
            if (ftNode.parentId) {
                let parent = parentChildrenMap.get('ft-' + ftNode.parentId);
                if (parent) {
                    parent.push('ft-' + ftNode.id);
                } else {
                    parentChildrenMap.set('ft-' + ftNode.parentId, ['ft-' + ftNode.id]);
                }
            }
        });

        parentChildrenMap.forEach((children, parentId) => {
            const parent = cellsGraph.getCell(parentId);
            if (parent) {
                children.forEach((childId) => {
                    const child = cellsGraph.getCell(childId);
                    if (child) {
                        const link = BaseLink.create(parent, child);
                        link.set('id', 'link-' + parent.get('id') + '-' + child.get('id'));
                        cellsGraph.addCell(link, {dry: true});
                    }
                });
            }
        });

    } catch (e: any) {
        // eslint-disable-next-line no-console
        console.error('Error building fault tree: ', e);
        toast.error(e.message);
    }

    return cellsGraph;
}


export function toDendogram(graph: joint.dia.Graph<joint.dia.Graph.Attributes, joint.dia.ModelSetOptions>, source: joint.dia.Element<joint.dia.Element.Attributes, joint.dia.ModelSetOptions>) {
    let elements: Array<joint.dia.Element> = [];
    let xs: Array<number> = [];
    let ys: Array<number> = [];

    //graph post-order traversal
    graph.bfs(source, (element: joint.dia.Element, distance: number): boolean => {
        if (element.get('nodeClass') !== 'FAULTTREENODE') {
            elements.push(element);
            xs.push(element.position().x);
            ys.push(element.position().y);
        }
        return true;
    });

    let maxX = Math.max(...xs);
    elements.reverse().forEach((element) => {
        let children = graph.getNeighbors(element, { outbound: true }).filter((cell) => cell.get('nodeClass') !== 'CALLOUTNODE');
        if (children && children.length > 0) {
            element.position(element.position().x, children[0].position().y);
        } else {
            element.position(maxX, element.position().y);
        }
    });
    graph.getLinks().forEach((link) => {
        let sourceCenter = graph.getCell(link.source().id!).getBBox().center();
        let targetCenter = graph.getCell(link.target().id!).getBBox().center();
        if (sourceCenter.y === targetCenter.y) {
            link.vertices([]);
        } else {
            link.vertices([
                {
                    x: sourceCenter.x + 200,
                    y: sourceCenter.y
                },
                {
                    x: sourceCenter.x + 200,
                    y: targetCenter.y
                }
            ]);
        }
    });
    
}

export function createFtGraphAndExpand(canvasEnvironment: CanvasEnvironment, element: joint.dia.Element, graph: joint.dia.Graph, paper: joint.dia.Paper, paperScroller: joint.ui.PaperScroller, viewportRect: any) {
    const tempGraph = buildftGraphFromObject(canvasEnvironment.eventSequence!, element.get('data').faultTree);
    const fTTreeLayout = new joint.layout.TreeLayout({
        graph: tempGraph,
        direction: 'B',
        siblingGap: 50,
        parentGap: 150,
        filter: function (siblings) {
            // Layout will skip elements which have been collapsed
            return siblings.filter(function (sibling) {
                return !sibling.get('hidden');
            });
        },
    });
    let [source] = graph.getSources();
    let maxy = 300;
    graph.getSuccessors(source).forEach((cell) => {
        if (cell.get('nodeClass') !== 'FAULTTREENODE') {
            if (cell.position().y > maxy) {
                maxy = cell.position().y;
            }
        }
    });

    let [target] = tempGraph.getSources();
    if (target) {
        const yoffset = maxy > 300 ? maxy : 300;
        target.position(element.position().x, yoffset + 250);
    }
    element.set({ collapsed: false });
    paper.freeze();
    layoutAndFocus(fTTreeLayout, paperScroller, paper, viewportRect.center());

    graph.addCells(tempGraph.getCells());
    //tempGraph.clear();
    
    if (target) {
        const link = BaseLink.create(element, target);
        link.attr({
            line: {
                stroke: '#000000',
                strokeWidth: 2,
                strokeDasharray: '5 5'
            }
        });
        link.set('id', 'link-' + element.get('id') + '-' + target.get('id'));
        graph.addCell(link);
        paperScroller.centerElement(target);
    }

    addTools(paper, graph.getElements());
    if (canvasEnvironment.isTagsModeActive) {
        addTagsToNodes(canvasEnvironment, graph.getElements());
    }
    addCalloutsToNodes(canvasEnvironment, graph.getElements());
    
    resizePaper(paper);
    paper.unfreeze();
    graph.translate(0.0001, 0.0001); // trick to force update of collapse/expand button status
}

export function alterVisibilityOfBranchNodes(graph: joint.dia.Graph, element: joint.dia.Element) {
    let successorElements = [];
    if (element.get('nodeClass') !== 'FAULTTREENODE') {
        const [topFaultTreeNode] = graph.getNeighbors(element).filter((cell) => cell.get('nodeClass') === 'FAULTTREENODE');
        successorElements = [topFaultTreeNode, ...graph.getSuccessors(topFaultTreeNode)];
    } else {
        successorElements = graph.getSuccessors(element);
    }
    const [successor] = successorElements;

    if (!successor) {
        return;
    }
    const shouldExpand = !successor.get('hidden');
    const neighborElements = successorElements.filter(node => graph.isNeighbor(element, node));
    const successorCells = graph.getSubgraph([
        element,
        ...(shouldExpand ? successorElements : neighborElements)
    ]);
    successorCells.forEach((cell) => {
        if (cell === element) {
            cell.set({
                hidden: false,
                collapsed: shouldExpand,
            })
        } else {
            cell.set({ hidden: shouldExpand });
            if (cell.isElement()) {
                cell.set({ collapsed: !shouldExpand });
            }
        }
    });
}

export function toggleBranch(element: joint.dia.Element, graph: joint.dia.Graph, paper: joint.dia.Paper, paperScroller: joint.ui.PaperScroller, viewportRect: any) {
    alterVisibilityOfBranchNodes(graph, element);
                
    paper.freeze();
    const ftGraph = new joint.dia.Graph({}, { cellNamespace: allshapes }); 
    const ftCells: Array<joint.dia.Cell> = [];
    graph.getElements().forEach((element) => {
        if (element.get('hidden')) return;
        if (element.get('nodeClass') === 'FAULTTREENODE') {
            ftCells.push(element);
        } 
    });
    ftGraph.resetCells(graph.getSubgraph(ftCells));
    const fTTreeLayout = new joint.layout.TreeLayout({
        graph: ftGraph,
        direction: 'B',
        siblingGap: 50,
        parentGap: 150,
        filter: function(siblings) {
            // Layout will skip elements which have been collapsed
            return siblings.filter(function(sibling) {
                return !sibling.get('hidden');
            });
        },        
    });            
    layoutAndFocus(fTTreeLayout, paperScroller, paper, viewportRect.center());
    graph.translate(0.0001, 0.0001);  // trick to force update of collapse/expand button status
    paper.unfreeze();    
}

export function setSelection(service: CanvasEnvironment, selection: joint.dia.Element[]) {
    const { paper, selection: previousSelection } = service;    
    previousSelection.forEach(cell => {
        const cellView = cell.findView(paper);
        if (cellView) {
            cellView.vel.removeClass('selected');
        }
    });
    service.selection = selection;
    selection.forEach(cell => {
        const cellView = cell.findView(paper);
        if (cellView) {
            cellView.vel.addClass('selected');
        }
    });
    const [element] = selection;
    // Let the top menu knows the selected node:
    // The Top menu needs to know in order to enable/disable menu items accordingly    
    service.setSelectedISAMNode(element ? element.get('data') : null);  
}

export function recenter(canvasEnvironment: CanvasEnvironment) {
    const { graph, paperScroller } = canvasEnvironment;   
    const [source] = graph.getSources();
    paperScroller.centerElement(source);
}

export function zoomIn(canvasEnvironment: CanvasEnvironment) {
    const { paperScroller } = canvasEnvironment;   
    paperScroller.zoom(0.1, {  min:0.1, max: 4, grid: 0.1 })
}

export function zoomOut(canvasEnvironment: CanvasEnvironment) {
    const { paperScroller } = canvasEnvironment;   
    paperScroller.zoom(-0.1, {  min:0.1, max: 4, grid: 0.1 })
}

export function fullScreen(canvasEnvironment: CanvasEnvironment) {
    const { paper } = canvasEnvironment;   
    paper.trigger('blank:pointerdblclick');
}

export function canEdit(env: CanvasEnvironment | null): boolean {
    if (!env || env.readonly || !env.eventSequence || !env.eventSequence.group) {
        return false;
    }

    return CanvasUtils.canEdit(env.eventSequence);
}

export function canAddOrEditResource(env: CanvasEnvironment | null): boolean {
    if (!env || env.readonly || !env.eventSequence || !env.eventSequence.group) {
        return false;
    }

    return CanvasUtils.canAddOrEditResource(env.eventSequence);
}

export function canPasteEvent(env: CanvasEnvironment | null, event: EsdEvent) {
    if (!canEdit(env)) {
        return false;
    }
    let type = event.type;
    let childIds = event.childIds;

    // If the user selects an END event, it can't be pasted here
    if (type === 'END') {
        return false;
    }

    // If the clipboard has no content then nothing can be pasted
    if (!localStorage.copyContents) {
        return false;
    }
    // If the copied object is something other than an event then it can't be pasted here
    const copyContents = JSON.parse(localStorage.copyContents);
    if (copyContents.type !== 'event') {
        return false
    }

    if (type === 'INITIATING') {
        // If the initiating event is full with 1 child then it can't be pasted here
        return !(childIds && childIds.length === 1);
    }

    if (type === 'PIVOTAL') {
        // If the pivotal event is full with 2 children then it can't be pasted here
        return !(childIds && childIds.length === 2);
    }

    return false
}

export function canPasteEventFaultTree(env: CanvasEnvironment | null, event: EsdEvent) {
    if (!canEdit(env)) {
        return false;
    }

    return CanvasUtils.canPasteFaultTree(event);
}

export function search(env: CanvasEnvironment | null, query: string | null) {
    if (!env || !query) {
        return;
    }
    const { graph, paper,  paperScroller, HighlighterFrame } = env;
    const [source] = graph.getSources();
    graph.bfs(source, (element: joint.dia.Element, distance: number): boolean => {
        if (element.get('nodeClass') === 'FAULTTREENODE') return true;
        let uniqueId = element.get('data').uniqueId;
        let name = element.get('data').name;
        if (uniqueId.toLowerCase().includes(query.toLowerCase()) || name.toLowerCase().includes(query.toLowerCase())) {
            HighlighterFrame.add(element.findView(paper), 'body', 'frame', { padding: 10 });
            paperScroller.centerElement(element);
            return false;
        }
        return true;
    });
}

export function clearSearch(env: CanvasEnvironment | null) {
    if (!env) {
        return;
    }
    const { HighlighterFrame } = env;
    HighlighterFrame.clear();
}

export function getTopLeftPaddingOfElement(env: CanvasEnvironment, element: joint.dia.Element) {
    let { x, y } = element.position().difference(env.paperScroller.getVisibleArea().topLeft());
    let { sx, sy } = env.paper.scale();
    return { left: x * sx, top: y * sy };
}

export function getTopLeftPaddingOfElementAndParent(env: CanvasEnvironment, element: joint.dia.Element) {
    let { x, y } = element.position().difference(env.paperScroller.getVisibleArea().topLeft());
    let { sx, sy } = env.paper.scale();
    let [parent] = env.graph.getPredecessors(element);
    if (parent) {
        let { x: px, y: py } = parent.position().difference(env.paperScroller.getVisibleArea().topLeft());
        return { left: x * sx, top: y * sy, parentLeft: px * sx, parentTop: py * sy };
    }
    return { left: x * sx, top: y * sy , parentLeft: 200, parentTop: 50};
}


