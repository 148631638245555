import React, {FC, MouseEventHandler, ReactElement, useState} from 'react';
import PageTitle from '../components/PageTitle.component';
import TextInputComponent from '../components/form-components/TextInput.component';
import PageContentWrapper from '../components/PageContentWrapper.component';
import {TextInputType} from '../@types/text-input-types';
import FormWrapper from '../components/form-components/FormWrapper';
import ButtonComponent from '../components/form-components/Button.component';
import {useNavigate} from 'react-router-dom';
import AuthService from '../services/auth.service';
import ErrorAlertComponent from '../components/ErrorAlert.component';
import {validateEmail} from '../utils/validator';

const ForgotPassword: FC<any> = (): ReactElement => {

    const navigate = useNavigate();

    const [email, setEmail] = useState<string>();
    const [error, setError] = useState<string|null>(null);

    function onClick(_ignored: MouseEventHandler<HTMLButtonElement>): void {
        if (email) {
            let validateResult = validateEmail(email);
            if (!validateResult.isValid) {
                setError(validateResult.reason);
                return;
            }
            setError(null);
            AuthService.requestResetPassword(email)
                .then(() => {
                    navigate('/react/reset-password-email-sent');
                })
                .catch(() => {
                    setError('An unexpected error occurred. Please verify your email and try again later.');
                });
        } else {
            setError('Email is required');
        }
    }

    return (
        <>

            <PageTitle title={'Reset Password'} />

            <PageContentWrapper>
                <FormWrapper width={400} align={'center'}>
                    <TextInputComponent id={'email'} label={'Email Address'} type={TextInputType.email} value={email}
                        onChange={setEmail} required={true} />
                    <ButtonComponent id={'reset-password'} label={'Reset Password'} size={'large'} onClick={onClick} />
                    {error ? <ErrorAlertComponent message={error} />  : null}
                </FormWrapper>
            </PageContentWrapper>

        </>
    );

};

export default ForgotPassword;
