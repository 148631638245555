import React, { ReactElement } from 'react';

import GroupsIcon from '@mui/icons-material/Groups';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import AdbIcon from '@mui/icons-material/Adb';
import ArticleIcon from '@mui/icons-material/Article';
import FlightIcon from '@mui/icons-material/Flight';
import FlightLand from '@mui/icons-material/FlightLand';
import HistoryIcon from '@mui/icons-material/History';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import TaskIcon from '@mui/icons-material/Task';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { ResourceType } from '../@types/resource-type';
import { AdminPanelSettings } from '@mui/icons-material';

export function resourceIcon(type: ResourceType): ReactElement {
    switch (type) {
        case ResourceType.USERS:
            return <AdminPanelSettings fontSize="small" />;
        case ResourceType.GROUPS:
            return <GroupsIcon fontSize="small" />;
        case ResourceType.EVENT_SEQUENCES:
            return <Avatar sx={{ width: 24, height: 24 }}><Typography variant='caption'>ES</Typography></Avatar>;
        case ResourceType.EVENT_SEQUENCE_TREES:
            return <FlightLand fontSize="small" />;
        case ResourceType.EVENT_SEQUENCE_HISTORY:
        case ResourceType.EVENT_SEQUENCE_HISTORY_TREES:
            return <HistoryIcon fontSize="small" />;
        case ResourceType.UNKNOWN:
        case ResourceType.SAFETY_MODELS:
            return <AccountTreeIcon  fontSize = "small"/>;
        case ResourceType.OCCURRENCE_REPORTS:
            return <ArticleIcon  fontSize = "small"/>;
        case ResourceType.TRAFFIC_MODELS:
            return <FlightIcon  fontSize = "small"/>;
        case ResourceType.OPERATIONAL_CHANGES:
            return <NetworkCheckIcon  fontSize = "small"/>;
        case ResourceType.WORKSHOPS:
            return <TaskIcon  fontSize = "small"/>;
        case ResourceType.RISK_FORECASTS:
            return <TrendingDownIcon  fontSize = "small"/>;
        default:
            return <AdbIcon  fontSize = "small"/>;
    }
}
