/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useRef, useEffect, useCallback } from 'react';
import * as joint from '@joint/plus';
import Backdrop from '@mui/material/Backdrop';
import { CanvasActionType } from '../../../@types/canvas-action-type';
import EventSequence from '../../../@types/event-sequence';
import * as canvasActions from '../../../utils/canvas/canvas-actions';
import * as canvasUtils from '../../../utils/canvas/node-utils'
import { CanvasEnvironment } from '../../../utils/canvas/canvas-environment';
import FaultTreeContextMenu from '../../workspace/menus/FaultTreeContextMenu';
import { useWorkspaceContext } from '../../../context/workspaceContext';
import CircularProgress from '@mui/material/CircularProgress';
import AddEndEventModal from '../modals/canvas/AddEndEvent.component';
import AddEventFaultTreeModal from '../modals/canvas/AddEventFaultTree';
import AddFaultTreeNodeModal from '../modals/canvas/AddFaultTreeNode.component';
import AddPivotalEventModal from '../modals/canvas/AddPivotalEvent.component';
import DeleteEventModal from '../modals/canvas/DeleteEvent.component';
import DeleteEventFaultTreeModal from '../modals/canvas/DeleteEventFaultTree';
import DeleteFaultTreeNodeModal from '../modals/canvas/DeleteFaultTreeNode.component';
import EditEndEventModal from '../modals/canvas/EditEndEvent.component';
import EditInitiatingEventModal from '../modals/canvas/EditInitiatingEvent.component';
import EditPivotalEventModal from '../modals/canvas/EditPivotalEvent.component';
import EditEventFaultTreeModal from '../modals/canvas/EditEventFaultTree';
import EditFaultTreeNodeModal from '../modals/canvas/EditFaultTreeNode.component';
import EventSequenceService from '../../../services/event-sequence.service';
import QuantifyFaultTreeModal from '../modals/canvas/QuantifyFaultTree.component';
import ReassignParentEventModal from '../modals/canvas/ReassignParent.component';
import ReorderChildrenModal from '../modals/canvas/ReorderChildren.component';
import { ResourceType } from '../../../@types/resource-type';
import { toast } from 'react-toastify';
import TagsComponentModal from '../modals/canvas/Tags.Component';
import CalloutComponentModal from '../modals/canvas/Callout.component';
import DeleteCalloutComponentModal from '../modals/canvas/DeleteCallout.component';

interface EventSequenceProps {
    eventSequence: EventSequence;
    isActive: boolean;
    readonly: boolean;
}

function AddChangesToGraph(eventSequence: EventSequence, canvasEnvironment: CanvasEnvironment) {
    const collapsedIds = [];
    if (canvasEnvironment.activeTopNode) {
        const [topFaultTreeNode] = canvasEnvironment.graph.getNeighbors(canvasEnvironment.activeTopNode).filter((cell) => cell.get('nodeClass') === 'FAULTTREENODE');
        const faultTreeBranch = canvasEnvironment.graph.getSubgraph([canvasEnvironment.activeTopNode, topFaultTreeNode, ...canvasEnvironment.graph.getSuccessors(topFaultTreeNode)]);
        collapsedIds.push(...faultTreeBranch.filter((cell) => cell.isElement() && cell.get('collapsed')).map((cell) => cell.id));
        if (!collapsedIds.includes(canvasEnvironment.activeTopNode.id)) {
            collapsedIds.splice(0,0,canvasEnvironment.activeTopNode.id);
        }
    }
    canvasEnvironment.activeTopNode = null;
    let { left, top, parentLeft, parentTop } = (canvasEnvironment.selection.length > 0) ?
        canvasActions.getTopLeftPaddingOfElementAndParent(canvasEnvironment, canvasEnvironment.selection[0]) :
        { left: 50, top: 50, parentLeft: 50, parentTop: 50 };
    const parentIds = canvasEnvironment.selection.map((cell) => canvasEnvironment.graph.getPredecessors(cell)[0]?.id ).filter((id) => !!id);
    const ids = canvasEnvironment.selection.map((cell) => cell.id);
    canvasActions.setSelection(canvasEnvironment, []);
    importGraphFromObject(eventSequence, canvasEnvironment, false);
    collapsedIds.forEach((id) => {
        const cell = canvasEnvironment.graph.getCell(id);
        if (cell && cell.isElement()) {
            const cellView = canvasEnvironment.paper.findViewByModel(cell);
            if (cellView) {
                canvasEnvironment.paper.trigger('element:expand', cellView);
            }
        }
    });
    const selection: Array<joint.dia.Element> = [];
    ids.forEach((id) => {
        const cell = canvasEnvironment.graph.getCell(id);
        if (cell && cell.isElement()) {
            selection.push(cell);
        }
    });

    if (selection.length > 0) {
        if (canvasEnvironment.paperScroller.isElementVisible(selection[0])) {
            canvasEnvironment.paperScroller.positionElement(selection[0], 'top-left', { padding: { top: top, left: left } });
        } else {
            canvasEnvironment.paperScroller.positionElement(selection[0], 'top-left', { padding: { top: 50, left: left } });
        }
    } else if (parentIds.length > 0 && parentIds[0] != null) {
        const parent = canvasEnvironment.graph.getCell(parentIds[0]);
        if (parent && parent.isElement()) {
            selection.push(parent);
            if (canvasEnvironment.paperScroller.isElementVisible(parent)) {
                canvasEnvironment.paperScroller.positionElement(parent, 'top-left', { padding: { top: parentTop, left: parentLeft } });
            } else {
                canvasEnvironment.paperScroller.positionElement(parent, 'top-left', { padding: { top: 50, left: parentLeft } });
            }
        }
    } else {
        const [source] = canvasEnvironment.graph.getSources();
        canvasEnvironment.paperScroller.positionElement(source, 'top-left', { padding: { top: 50, left: 200 } });
    }
    canvasActions.setSelection(canvasEnvironment, selection); 
    canvasEnvironment.isDirty = false;
}

function importGraphFromObject(eventSequence: EventSequence, canvasEnvironment: CanvasEnvironment, positioning: boolean = true) {
    const { graph, paper, paperScroller } = canvasEnvironment;
    canvasEnvironment.eventSequence = eventSequence;
    paper.freeze();
    canvasActions.buildESDGraphFromObject(eventSequence, graph);
    const esdTreeLayout = new joint.layout.TreeLayout({
        graph,
        direction: 'R',
        siblingGap: 50,
        parentGap: 100,
    });
    let [source] = graph.getSources();
    graph.getSuccessors(source).forEach((cell) => {
        if (cell.get('nodeClass') !== 'FAULTTREENODE') {
            cell.set({ collapsed: true });
        }
    });
    source.set({ collapsed: true });
    let viewportOverlap = 50;
    let viewportRect: any = paperScroller.getVisibleArea().inflate(viewportOverlap);

    canvasActions.layoutAndFocus(esdTreeLayout, paperScroller, paper, viewportRect.center());
    // Convert ESD to dendogram as in the Ember Version
    canvasActions.toDendogram(graph, source);
    if (positioning) {
        paperScroller.positionElement(source, 'top-left', { padding: { top: 50, left: 200 } });  
    }

    if (canvasEnvironment.isTagsModeActive) {
        canvasEnvironment.fetchTagsAndTagLinks().then(() => {
            canvasActions.addTagsToNodes(canvasEnvironment, graph.getElements());
        }).catch((error) => {
            toast.error(error);
        });
    }
    canvasActions.addCalloutsToNodes(canvasEnvironment, graph.getElements());
    canvasActions.addTools(paper, graph.getElements());
    
    paper.unfreeze();
    canvasEnvironment.selection = [];
    canvasEnvironment.isDirty = false;
}


export default function JointJsCanvasEditor(props: EventSequenceProps) : React.ReactElement {
    const containerRef = useRef(null);
    const canvasEnvironmentRef = useRef<CanvasEnvironment | null>(null);
    const { eventSequence, isActive, readonly } = props;
    const [isRunning, setIsRunning] = React.useState<boolean>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | joint.dia.ElementView>(null);
    const [canvasEnvironment, setCanvasEnvironment] = React.useState<CanvasEnvironment | null>(null);
    const [openAddEventFaultTreeModal, setOpenAddEventFaultTreeModal] = React.useState<boolean>(false);
    const [openAddEventFaultTreeNodeModal, setOpenAddEventFaultTreeNodeModal] = React.useState<boolean>(false);
    const [openAddPivotalEventModal, setOpenAddPivotalEventModal] = React.useState<boolean>(false);
    const [openAddEndEventModal, setOpenAddEndEventModal] = React.useState<boolean>(false);
    const [openEditEventFaultTreeModal, setOpenEditEventFaultTreeModal] = React.useState<boolean>(false);
    const [openEditFaultTreeNodeModal, setOpenEditFaultTreeNodeModal] = React.useState<boolean>(false);
    const [openDeleteEventFaultTreeModal, setOpenDeleteEventFaultTreeModal] = React.useState<boolean>(false);
    const [openDeleteFaultTreeNodeModal, setOpenDeleteFaultTreeNodeModal] = React.useState<boolean>(false);
    const [openEditEndEventModal, setOpenEditEndEventModal] = React.useState<boolean>(false);
    const [openEditInitiatingEventModal, setOpenEditInitiatingEventModal] = React.useState<boolean>(false);
    const [openEditPivotalEventModal, setOpenEditPivotalEventModal] = React.useState<boolean>(false);
    const [openDeleteEventModal, setOpenDeleteEventModal] = React.useState<boolean>(false);
    const [openQuantifyFaultTreeModal, setOpenQuantifyFaultTreeModal] = React.useState<boolean>(false);
    const [openReassignParentModal, setOpenReassignParentModal] = React.useState<boolean>(false);
    const [openReorderChildrenModal, setOpenReorderChildrenModal] = React.useState<boolean>(false);
    const [openTagsModal, setOpenTagsModal] = React.useState<boolean>(false);
    const [openCalloutModal, setOpenCalloutModal] = React.useState<boolean>(false);
    const [openDeleteCalloutModal, setOpenDeleteCalloutModal] = React.useState<boolean>(false);
    const { currentCanvasAction, setCurrentCanvasAction, setCurrentCanvasSelectedNode,
        setCurrentWorkspaceObject } = useWorkspaceContext();
    const open = Boolean(anchorEl);
    const openContextMenu = useCallback((event: joint.dia.Event, targetElement: joint.dia.ElementView): void => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(targetElement);
    }, []);
    const closeContextMenu = useCallback((): void => {
        setAnchorEl(null);
    }, []);


    const handleProperties = () => {
        if (!canvasEnvironment) return;
        const [element] = canvasEnvironment.selection;
        if (!element) return;
        const type = element.get('data').type;
        if (type === 'PIVOTAL') {
            setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_EDIT_PIVOTALEVENT });
        } else if (type === 'INITIATING') {
            setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_EDIT_INITIATINGEVENT });
        } else if (type === 'END') {
            setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_EDIT_ENDEVENT });
        } else if (type === 'INPRECURSOR') {
            setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_EDIT_INPRECURSOREVENT });
        } else if (canvasUtils.isFaultTreeNode(type)) {
            setCurrentCanvasAction({ actionType: CanvasActionType.FAULTTREENODE_EDIT_NODE });
        }
    }

    const handleNodeDeleltion = () => {
        if (!canvasEnvironment) return;
        const [element] = canvasEnvironment.selection;
        if (!element) return;
        const type = element.get('data').type;
        if (type === 'PIVOTAL' || type === 'INITIATING' || type === 'END' || type === 'INPRECURSOR') {
            setOpenDeleteEventModal(true);
        } else if (canvasUtils.isFaultTreeNode(type)) {
            setOpenDeleteFaultTreeNodeModal(true);
        }
    }

    
    useEffect(() => {
        if (isActive && currentCanvasAction!== null && currentCanvasAction.actionType !== CanvasActionType.UNKNOWN) {
            if (!canvasEnvironment) return;
            switch (currentCanvasAction.actionType) {
                case CanvasActionType.CANVAS_RECENTER:
                    canvasActions.recenter(canvasEnvironment);
                    break;
                case CanvasActionType.CANVAS_ZOOM_IN:
                    canvasActions.zoomIn(canvasEnvironment);
                    break;
                case CanvasActionType.CANVAS_ZOOM_OUT:
                    canvasActions.zoomOut(canvasEnvironment);
                    break;
                case CanvasActionType.CANVAS_FULLSCREEN:
                    canvasActions.fullScreen(canvasEnvironment);
                    break;
                case CanvasActionType.CANVAS_SEARCH:
                    if (currentCanvasAction.query)
                        canvasActions.search(canvasEnvironment, currentCanvasAction.query);
                    break;
                case CanvasActionType.CANVAS_TOGGLE_TAGS:
                    canvasActions.toggleTagsMode(canvasEnvironment);
                    break;
                case CanvasActionType.CANVAS_TOGGLE_CALLOUTS:
                    canvasActions.toggleCalloutsMode(canvasEnvironment);
                    break;
                case CanvasActionType.CANVAS_REDO:
                    setIsRunning(true);
                    EventSequenceService.redo(canvasEnvironment.eventSequence!.id).then((response) => {
                        canvasEnvironment.isDirty = true;
                        setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                        toast.success('Redo completed successfully');
                    }).catch((error) => {
                        toast.error(error);
                    }).finally(() => {
                        setIsRunning(false);
                    });
                    break;
                case CanvasActionType.CANVAS_UNDO:
                    setIsRunning(true);
                    EventSequenceService.undo(canvasEnvironment.eventSequence!.id).then((response) => {
                        canvasEnvironment.isDirty = true;
                        setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                        toast.success('Undo completed successfully');
                    }).catch((error) => {
                        toast.error(error);
                    }).finally(() => {
                        setIsRunning(false);
                    });
                    break;
                case CanvasActionType.CANVAS_REFRESH:
                    if (canvasEnvironment.eventSequence) {
                        setIsRunning(true);
                        EventSequenceService.getEventSequenceById(canvasEnvironment.eventSequence!.id).then((response) => {
                            canvasEnvironment.isDirty = true;
                            setCurrentWorkspaceObject({
                                objectType: ResourceType.EVENT_SEQUENCE_TREES,
                                object: response
                            });
                        }).catch((error) => {
                            toast.error(error);
                        }).finally(() => {
                            setIsRunning(false);
                        });
                    }
                    break;
                case CanvasActionType.NODE_PROPERTIES:
                    handleProperties();
                    break;
                case CanvasActionType.NODE_ADD_CALLOUT:
                case CanvasActionType.NODE_EDIT_CALLOUT:
                    setOpenCalloutModal(true);
                    break;
                case CanvasActionType.NODE_DELETE_CALLOUT:
                    setOpenDeleteCalloutModal(true);
                    break;
                case CanvasActionType.NODE_TAGS:
                    setOpenTagsModal(true);
                    break;
                case CanvasActionType.EVENTNODE_ADD_PIVOTALEVENT:
                    setOpenAddPivotalEventModal(true);
                    break;
                case CanvasActionType.EVENTNODE_ADD_ENDEVENT:
                    setOpenAddEndEventModal(true);
                    break;
                case CanvasActionType.EVENTNODE_DELETE:
                case CanvasActionType.FAULTTREENODE_DELETE_NODE:
                    handleNodeDeleltion();
                    break;
                case CanvasActionType.EVENTNODE_EDIT_PIVOTALEVENT:
                    setOpenEditPivotalEventModal(true);
                    break;
                case CanvasActionType.EVENTNODE_EDIT_INITIATINGEVENT:
                    setOpenEditInitiatingEventModal(true);
                    break;
                case CanvasActionType.EVENTNODE_EDIT_ENDEVENT:
                    setOpenEditEndEventModal(true);
                    break;
                case CanvasActionType.EVENTNODE_REASSIGN_PARENT:
                    setOpenReassignParentModal(true);
                    break;
                case CanvasActionType.EVENTNODE_COPY:
                    if (canvasEnvironment.selection.length > 0) {
                        const [copiedNode] = canvasEnvironment.selection;
                        localStorage.copyContents = JSON.stringify({
                            type: 'event',
                            id: copiedNode.get('data').id
                        });
                    }
                    break;
                case CanvasActionType.EVENTNODE_PASTE:
                    if (
                        canvasEnvironment.selection.length > 0 &&
                        canvasActions.canPasteEvent(canvasEnvironment, canvasEnvironment.selection[0].get('data'))
                    ) {
                        let children = canvasEnvironment.graph.getNeighbors(canvasEnvironment.selection[0], { outbound: true }).filter((cell) => cell.get('nodeClass') !== 'CALLOUTNODE');
                        let parentRelationType = (children && children.length === 1 && children[0].get('data').parentRelationType === 'YES') ? 'NO' : 'YES';
                        setIsRunning(true);
                        EventSequenceService.copyEvent(
                            JSON.parse(localStorage.copyContents).id,
                            canvasEnvironment.selection[0].get('data').id,
                            parentRelationType
                        ).then((response) => {
                            canvasEnvironment.isDirty = true;
                            setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                            toast.success('Event copied successfully');
                        }).catch((error) => {
                            toast.error(error);
                        }).finally(() => {
                            setIsRunning(false);
                        });                        
                    }
                    break;
                case CanvasActionType.EVENTNODE_FAULTTREE_ADD:
                    // setOpenAddEventFaultTreeModal(true);
                    if (canvasEnvironment.selection.length > 0) {
                        setIsRunning(true);
                        const eventId = canvasEnvironment.selection[0].get('data').id;
                        EventSequenceService.addEventFaultTree(eventId).then((response) => {
                            canvasEnvironment.isDirty = true;
                            setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response }); 
                        }).catch((error) => {
                            toast.error(error);
                        }).finally(() => {
                            setIsRunning(false);
                        });
                    }
                    break;
                case CanvasActionType.EVENTNODE_FAULTTREE_DELETE:
                    setOpenDeleteEventFaultTreeModal(true);
                    break;
                case CanvasActionType.EVENTNODE_FAULTTREE_PROPERTIES:
                    setOpenEditEventFaultTreeModal(true);
                    break;
                case CanvasActionType.EVENTNODE_FAULTTREE_COPY:
                    if (canvasEnvironment.selection.length > 0) {
                        const [copiedNode] = canvasEnvironment.selection;
                        localStorage.copyContents = JSON.stringify({
                            type: 'fault_tree',
                            id: copiedNode.get('data').faultTree.id,
                            containPrecursor: copiedNode.get('data').faultTree.faultTreeNodes.some((node: any) => node.nodeClass === 'PRECURSOR')
                        });
                    }
                    break;
                case CanvasActionType.EVENTNODE_FAULTTREE_PASTE:
                    if (
                        canvasEnvironment.selection.length > 0 &&
                        canvasActions.canPasteEventFaultTree(canvasEnvironment, canvasEnvironment.selection[0].get('data'))
                    ) {
                        setIsRunning(true);
                        EventSequenceService.copyEventFaultTree(
                            JSON.parse(localStorage.copyContents).id,
                            canvasEnvironment.selection[0].get('data').id
                        ).then((response) => {
                            canvasEnvironment.isDirty = true;
                            setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                            toast.success('Event Fault Tree copied successfully');
                        }).catch((error) => {
                            toast.error(error);
                        }).finally(() => {
                            setIsRunning(false);
                        });                        
                    }
                    break;
                case CanvasActionType.FAULTTREENODE_ADD_NODE:
                    setOpenAddEventFaultTreeNodeModal(true);
                    break;
                case CanvasActionType.FAULTTREENODE_COPY_SUBTREE:
                    if (canvasEnvironment.selection.length > 0) {
                        const [copiedNode] = canvasEnvironment.selection;
                        localStorage.copyContents = JSON.stringify({
                            type: 'fault_tree_node',
                            id: copiedNode.get('data').id
                        });
                    }
                    break;            
                case CanvasActionType.FAULTTREENODE_EDIT_NODE:
                    setOpenEditFaultTreeNodeModal(true);
                    break;
                case CanvasActionType.FAULTTREENODE_PASTE_SUBTREE:
                    if (
                        canvasActions.canAddOrEditResource(canvasEnvironment) &&
                        canvasEnvironment.selection.length > 0 &&
                        canvasUtils.canPasteFaultTreeNode(canvasEnvironment.selection[0].get('data'))
                    ) {
                        setIsRunning(true);
                        EventSequenceService.copyEventFaultTreeNode(
                            JSON.parse(localStorage.copyContents).id,
                            canvasEnvironment.selection[0].get('data').id
                        ).then((response) => {
                            canvasEnvironment.isDirty = true;
                            setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                            toast.success('Fault Tree Node copied successfully');
                        }).catch((error) => {
                            toast.error(error);
                        }).finally(() => {
                            setIsRunning(false);
                        });                        
                    }
                    break;
                case CanvasActionType.FAULTTREENODE_REORDER_CHILDREN:
                    setOpenReorderChildrenModal(true);
                    break;
                case CanvasActionType.NODE_QUANTIFY_CHILDREN:
                case CanvasActionType.NODE_QUANTIFY_BOTTOM_UP:
                case CanvasActionType.NODE_QUANTIFY_TOP_DOWN:
                case CanvasActionType.NODE_QUANTIFY_BOOTOM_UP_TO_HERE:
                    setOpenQuantifyFaultTreeModal(true);
                    break;
                case CanvasActionType.SANITIZE_EVENT_SEQUENCE:
                    if (!canvasEnvironment || !canvasEnvironment.eventSequence) return;
                    setIsRunning(true);
                    EventSequenceService.sanitizeEventSequence(canvasEnvironment.eventSequence.id).then((response) => {
                        canvasEnvironment.isDirty = true;
                        setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                        toast.success('Event Sequence sanitized successfully');
                    }).catch((error) => {
                        toast.error(error);
                    }).finally(() => {
                        setIsRunning(false);
                    });
                    break;
                case CanvasActionType.SANITIZE_OCCURRENCE_DNA:
                    if (!canvasEnvironment || !canvasEnvironment.eventSequence) return;
                    setIsRunning(true);
                    EventSequenceService.sanitizeOccurrenceDna(canvasEnvironment.eventSequence.id).then((response) => {
                        canvasEnvironment.isDirty = true;
                        setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                        toast.success('Occurrence DNA sanitized successfully');
                    }).catch((error) => {
                        toast.error(error);
                    }).finally(() => {
                        setIsRunning(false);
                    });
                    break;
                default:
                    break;
            }

            setTimeout(() => {
                setCurrentCanvasAction({ actionType: CanvasActionType.UNKNOWN });
            }, 100);
        }
    }, [currentCanvasAction, isActive, canvasEnvironment]);

    useEffect(() => {
        if (canvasEnvironmentRef.current === null) {
            canvasEnvironmentRef.current = new CanvasEnvironment(containerRef.current!, openContextMenu, setCurrentCanvasSelectedNode, readonly);
            setCanvasEnvironment(canvasEnvironmentRef.current);
        }
        return () => {
            if (canvasEnvironmentRef.current === null) return;
            canvasEnvironmentRef.current.destroy()
            canvasEnvironmentRef.current = null;
            setCurrentCanvasSelectedNode(null);
        };

    }, [canvasEnvironmentRef, openContextMenu, containerRef]);

    useEffect(() => 
    {
        if (!containerRef || !containerRef.current) return;
        if (!eventSequence || !eventSequence.events) return;


        if (canvasEnvironmentRef.current === null) {
            canvasEnvironmentRef.current = new CanvasEnvironment(containerRef.current, openContextMenu, setCurrentCanvasSelectedNode, readonly);
            setCanvasEnvironment(canvasEnvironmentRef.current);
        }

        const canvasEnvironment = canvasEnvironmentRef.current;
        if (!canvasEnvironment.eventSequence || canvasEnvironment.eventSequence.id === 0) {
            // eslint-disable-next-line no-console
            console.log('Importing graph from object:', eventSequence);
            importGraphFromObject(eventSequence, canvasEnvironment);
        } else if (canvasEnvironment.isDirty) {
            AddChangesToGraph(eventSequence, canvasEnvironment);
        }
    }, [eventSequence]);

    useEffect(() => {
        if (isActive && canvasEnvironment && canvasEnvironment.eventSequence && canvasEnvironment.eventSequence.id !== 0) {
            const [element] = canvasEnvironment.selection;
            // Let the top menu knows the selected node:
            // The Top menu needs to know in order to enable/disable menu items accordingly
            setCurrentCanvasSelectedNode(element ? element.get('data') : null);  
        }
    }, [isActive, canvasEnvironment]);

    return (
        <div ref={containerRef} className="canvas">
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isRunning}> 
                <CircularProgress color='inherit' />
            </Backdrop>
            

            <FaultTreeContextMenu elementView={anchorEl} open={open} handleClose={closeContextMenu} env={canvasEnvironment} />
            <AddEndEventModal open={openAddEndEventModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenAddEndEventModal(false)} />
            <AddEventFaultTreeModal open={openAddEventFaultTreeModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenAddEventFaultTreeModal(false)} />
            <AddFaultTreeNodeModal open={openAddEventFaultTreeNodeModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenAddEventFaultTreeNodeModal(false)} />
            <AddPivotalEventModal open={openAddPivotalEventModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenAddPivotalEventModal(false)} />
            <DeleteEventModal open={openDeleteEventModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenDeleteEventModal(false)} />
            <DeleteEventFaultTreeModal open={openDeleteEventFaultTreeModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenDeleteEventFaultTreeModal(false)} />
            <DeleteFaultTreeNodeModal open={openDeleteFaultTreeNodeModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenDeleteFaultTreeNodeModal(false)} />
            <EditEndEventModal open={openEditEndEventModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenEditEndEventModal(false)} />
            <EditInitiatingEventModal open={openEditInitiatingEventModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenEditInitiatingEventModal(false)} />
            <EditPivotalEventModal open={openEditPivotalEventModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenEditPivotalEventModal(false)} />
            <EditEventFaultTreeModal open={openEditEventFaultTreeModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenEditEventFaultTreeModal(false)} />
            <EditFaultTreeNodeModal open={openEditFaultTreeNodeModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenEditFaultTreeNodeModal(false)} />
            <QuantifyFaultTreeModal open={openQuantifyFaultTreeModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenQuantifyFaultTreeModal(false)} />
            <ReassignParentEventModal open={openReassignParentModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenReassignParentModal(false)} />
            <ReorderChildrenModal open={openReorderChildrenModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenReorderChildrenModal(false)} />
            <TagsComponentModal open={openTagsModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenTagsModal(false)} />
            <CalloutComponentModal open={openCalloutModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenCalloutModal(false)} />
            <DeleteCalloutComponentModal open={openDeleteCalloutModal && (canvasEnvironment != null) && (canvasEnvironment.selection[0]?.get('data') != null)} env={canvasEnvironment} onClose={() => setOpenDeleteCalloutModal(false)} />
        </div>

    );
}


