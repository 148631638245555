import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import Chip from '@mui/material/Chip';
import EmailIcon from '@mui/icons-material/Email';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ErrorAlertComponent from '../../../components/ErrorAlert.component';
import FormGroup from '@mui/material/FormGroup';
import React, {ReactElement} from 'react';

import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import SystemInfoService from '../../../services/system-info.service';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {uuid} from '../../../utils/utils';


export default function HelpAboutModal(props: { open: boolean, onClose: () => void }): ReactElement {
    const {open, onClose} = props;
    const [systemInfoName, setSystemInfoName] = React.useState<string>('');
    const [systemInfoVersion, setSystemInfoVersion] = React.useState<string>('');
    const [systemInfoDescription, setSystemInfoDescription] = React.useState<string>('');
    const [systemInfoReleaseNote, setSystemInfoReleaseNote] = React.useState<string>('');
    const [projectManagerName, setProjectManagerName] = React.useState<string>('');
    const [projectManagerEmail, setProjectManagerEmail] = React.useState<string>('');
    const [supportContactName, setSupportContactName] = React.useState<string>('');
    const [supportContactEmail, setSupportContactEmail] = React.useState<string>('');
    const [buildVersion, setBuildVersion] = React.useState<string>('');
    const [buildUserEmail, setBuildUserEmail] = React.useState<string>('');
    const [buildTime, setBuildTime] = React.useState<string>('');
    const [commitId, setCommitId] = React.useState<string>('');

    const [isRunning, setIsRunning] = React.useState<boolean>(false);

    const [error, setError] = React.useState<string | null>(null);

    const uuidRef = React.useRef(uuid());


    React.useEffect(() => {
        setIsRunning(true);
        SystemInfoService.getSystemSettings().then((all) => {
            setSystemInfoName(all.systemInfo.name);
            setSystemInfoVersion(all.systemInfo.version);
            setSystemInfoDescription(all.systemInfo.description);
            setSystemInfoReleaseNote(all.systemInfo.releaseNotes);

            setProjectManagerName(all.supportInfo.projectManagerName);
            setProjectManagerEmail(all.supportInfo.projectManagerEmail);
            setSupportContactName(all.supportInfo.supportPocName);
            setSupportContactEmail(all.supportInfo.supportPocEmail);

            setBuildVersion(all.gitInfo.buildVersion);
            setBuildUserEmail(all.gitInfo.buildUserEmail);
            setBuildTime(all.gitInfo.buildTime);
            setCommitId(all.gitInfo.commitId);
        }).catch((err) => {
            setError(err.message);
        }).finally(() => {
            setIsRunning(false);
        });
    }, []);


    return (
        <Dialog open={open} onClose={onClose} fullScreen>
            <DialogTitle>
                <Chip icon={<ArrowCircleLeftIcon/>} size='small' color='primary' variant='outlined'
                    label='Back to Workspace' onClick={onClose}/>

                <Typography variant='h3' color='primary' gutterBottom>Release Notes</Typography>
                {isRunning &&
                  <Stack sx={{width: '100%', color: 'grey.500', justifyContent: 'center'}} spacing={2} direction='row'>
                      <CircularProgress color='inherit'/>
                      <CircularProgress color='inherit'/>
                      <CircularProgress color='inherit'/>
                  </Stack>
                }
            </DialogTitle>
            <DialogContent dividers>
                <Stack className='formControl' sx={{width: '90%'}}>

                    <FormGroup className='formStyle'>
                        <Typography variant='h4' color='primary' gutterBottom>
                            System Information
                        </Typography>
                        <TextField
                            autoFocus
                            margin='dense'
                            id={`system-info-name-${uuidRef.current}`}
                            label='System Name'
                            variant='outlined'
                            size='small'
                            value={systemInfoName}
                            sx={{
                                '& input': {
                                    border: 'none',
                                },
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#A0A0A0',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `system-info-name-${uuidRef.current}`,
                                'aria-label': 'system name'
                            }}
                            fullWidth
                            disabled
                        />
                        <hr/>
                        <TextField
                            margin='dense'
                            id={`system-info-Version-${uuidRef.current}`}
                            label='Version'
                            variant='outlined'
                            size='small'
                            value={systemInfoVersion}
                            sx={{
                                '& input': {
                                    border: 'none',
                                },
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#A0A0A0',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `system-info-version-${uuidRef.current}`,
                                'aria-label': 'system version'
                            }}

                            fullWidth
                            disabled
                        />
                        <hr/>
                        <TextField
                            margin='dense'
                            id={`system-info-description-${uuidRef.current}`}
                            label='System Description'
                            variant='outlined'
                            size='small'
                            value={systemInfoDescription}
                            sx={{
                                '& input': {
                                    border: 'none',
                                },
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#A0A0A0',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `system-info-description-${uuidRef.current}`,
                                'aria-label': 'system description'
                            }}

                            fullWidth
                            disabled
                        />
                        <hr/>
                        <TextField
                            margin='dense'
                            id={`system-info-release-note-${uuidRef.current}`}
                            label='Release Notes'
                            variant='outlined'
                            size='small'
                            value={systemInfoReleaseNote}
                            sx={{
                                '& input': {
                                    border: 'none',
                                },
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#A0A0A0',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `system-info-release-note-${uuidRef.current}`,
                                'aria-label': 'release notes'
                            }}

                            fullWidth
                            disabled
                        />

                    </FormGroup>

                    <FormGroup className='formStyle'>
                        <Typography variant='h4' color='primary' gutterBottom>
                            Project Information
                        </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={8}>
                                <TextField
                                    margin='dense'
                                    id={`system-info-project-manager-name-${uuidRef.current}`}
                                    label='Project Manager'
                                    variant='outlined'
                                    size='small'
                                    value={projectManagerName}
                                    sx={{
                                        '& input': {
                                            border: 'none',
                                        },
                                        '& .MuiInputBase-input.Mui-disabled': {
                                            WebkitTextFillColor: '#A0A0A0',
                                        }
                                    }}
                                    InputProps={{
                                        'role': 'textbox',
                                        'tabIndex': 0,
                                        'aria-labelledby': `system-info-project-manager-name-${uuidRef.current}`,
                                        'aria-label': 'project manager name'
                                    }}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Link href={`mailto:${projectManagerEmail}`}>
                                            <IconButton color="primary" title='send email'>
                                                <EmailIcon/>
                                            </IconButton>
                                        </Link>
                                    }
                                    label={<Typography color='primary'>{projectManagerEmail}</Typography>}
                                    labelPlacement='end'
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    margin='dense'
                                    id={`system-info-support-contact-name-${uuidRef.current}`}
                                    label='Support Contact'
                                    variant='outlined'
                                    size='small'
                                    value={supportContactName}
                                    sx={{
                                        '& input': {
                                            border: 'none',
                                        },
                                        '& .MuiInputBase-input.Mui-disabled': {
                                            WebkitTextFillColor: '#A0A0A0',
                                        }
                                    }}
                                    InputProps={{
                                        'role': 'textbox',
                                        'tabIndex': 0,
                                        'aria-labelledby': `system-info-support-contact-name-${uuidRef.current}`,
                                        'aria-label': 'support contact name'
                                    }}
                                    fullWidth
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormControlLabel
                                    control={
                                        <Link href={`mailto:${supportContactEmail}`}>
                                            <IconButton color="primary" title='send email'>
                                                <EmailIcon/>
                                            </IconButton>
                                        </Link>
                                    }
                                    label={<Typography color='primary'>{supportContactEmail}</Typography>}
                                    labelPlacement='end'
                                />
                            </Grid>
                        </Grid>

                        <hr/>


                    </FormGroup>

                    <FormGroup className='formStyle'>
                        <Typography variant='h4' color='primary' gutterBottom>
                            Build Information
                        </Typography>
                        <TextField
                            margin='dense'
                            id={`build-info-version-${uuidRef.current}`}
                            label='Build Number'
                            variant='outlined'
                            size='small'
                            value={buildVersion}
                            sx={{
                                '& input': {
                                    border: 'none',
                                },
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#A0A0A0',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `build-info-version-${uuidRef.current}`,
                                'aria-label': 'build version number'
                            }}

                            fullWidth
                            disabled
                        />
                        <hr/>
                        <TextField
                            margin='dense'
                            id={`built-by-${uuidRef.current}`}
                            label='Built By'
                            variant='outlined'
                            size='small'
                            value={buildUserEmail}
                            sx={{
                                '& input': {
                                    border: 'none',
                                },
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#A0A0A0',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `built-by-${uuidRef.current}`,
                                'aria-label': 'built by'
                            }}

                            fullWidth
                            disabled
                        />
                        <hr/>
                        <TextField
                            margin='dense'
                            id={`build-time-${uuidRef.current}`}
                            label='Build Time'
                            variant='outlined'
                            size='small'
                            value={buildTime}
                            sx={{
                                '& input': {
                                    border: 'none',
                                },
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#A0A0A0',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `build-time-${uuidRef.current}`,
                                'aria-label': 'build time'
                            }}

                            fullWidth
                            disabled
                        />
                        <hr/>
                        <TextField
                            margin='dense'
                            id={`commit-id-${uuidRef.current}`}
                            label='Commit ID'
                            variant='outlined'
                            size='small'
                            value={commitId}
                            sx={{
                                '& input': {
                                    border: 'none',
                                },
                                '& .MuiInputBase-input.Mui-disabled': {
                                    WebkitTextFillColor: '#A0A0A0',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `commit-id-${uuidRef.current}`,
                                'aria-label': 'commit id'
                            }}

                            fullWidth
                            disabled
                        />
                    </FormGroup>
                </Stack>

                {error ? <ErrorAlertComponent message={error}/> : null}
            </DialogContent>
            <DialogActions>
                <Button id={`cancel-help-about-${uuidRef.current}`}
                    size='small' variant='outlined'
                    aria-label='cancel help about'
                    title='Cancel'
                    startIcon={<CancelIcon/>}
                    onClick={onClose}
                    sx={{textTransform: 'none'}}
                >
                    Cancel
                </Button>
            </DialogActions>

        </Dialog>
    );
}