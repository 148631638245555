import * as React from 'react';
import * as jsondiffpatch from 'jsondiffpatch';
import parse from 'html-react-parser';
import DialogContent from '@mui/material/DialogContent';
import Checkbox from '@mui/material/Checkbox';
import OkCancelModal from '../../form-components/OkCancelModal.component';
import DOMPurify from 'isomorphic-dompurify';
// @ts-ignore
import * as htmlFormatter from 'jsondiffpatch/formatters/html';
// @ts-ignore
import * as annotatedFormatter from 'jsondiffpatch/formatters/annotated';
import 'jsondiffpatch/formatters/styles/html.css';
import 'jsondiffpatch/formatters/styles/annotated.css';
import { FormControlLabel } from '@mui/material';

export default function DiffResource(props: {
    open: boolean;
    resourceA: string;
    resourceB: string;
    delta: jsondiffpatch.Delta | undefined;
    onClose: () => void;
    handleRestore: () => void;
    okToRestore: boolean;
    
}): React.ReactElement {
   
    const { open, resourceA, resourceB, delta, handleRestore, onClose, okToRestore } = props;
    const [annotated, setAnnotated] = React.useState<boolean>(false);

    React.useEffect(() => {
        setAnnotated(false);
    }, [resourceA, resourceB]);

    return (
        <OkCancelModal
            open={open}
            title={`Comparing ${resourceA} with ${resourceB}`}
            okButtonText='Restore'
            onClose={onClose}
            onConfirm={(): void => { handleRestore(); onClose(); }}
            okToConfirm={(): boolean => { return okToRestore; }}
        >
            <DialogContent>
                <FormControlLabel control={<Checkbox
                    edge='start'
                    title='Show Annotated View'
                    checked={annotated}
                    tabIndex={-1}
                    disableRipple
                    onClick={(): void => {
                        setAnnotated(!annotated);
                    }}
                />} label="Show Annotated View" />
                <br/>
                <div style={{overflow: 'auto'}}>
                    {annotated ?
                        parse(DOMPurify.sanitize(annotatedFormatter.format(delta, null)))
                        :
                        parse(DOMPurify.sanitize(htmlFormatter.format(delta, null)))
                    }
                </div>
            </DialogContent>
        </OkCancelModal>
    );
}


    