
/**
 * This is a pedigree dictionary that associates the
 * short name and quantification status with the pedigree.
 *
 * Quantification status 'hints' at the state of the
 * quantification of the node or event. For example, if an
 * event has a pedigree of US_QUANTIFIED, then the quantification
 * status is set to true, meaning that the event has been
 * quantified. NOTE: this does not mean that the event has
 * actually been quantified!!!
 */
export const pedigreeInfo = Object.freeze({
    'US_QUANTIFIED': {'abbr': 'UQ', 'quantified': true},
    'CALCULATED': {'abbr': 'CL', 'quantified': false},
    'WORLD_QUANTIFIED': {'abbr': 'WQ', 'quantified': true},
    'NOT_QUANTIFIED': {'abbr': 'NQ', 'quantified': false},
    'EXPERT_JUDGMENT': {'abbr': 'UJ', 'quantified': true},
    'SIMULATED': {'abbr': 'SM', 'quantified': true},
    'ECAC_QUANTIFIED': {'abbr': 'EQ', 'quantified': true},
    'ECAC_APPORTIONED': {'abbr': 'EA', 'quantified': false},
    'ECAC_EXPERT_JUDGMENT': {'abbr': 'EJ', 'quantified': true},
    'ANSP_QUANTIFIED': {'abbr': 'AQ', 'quantified': true},
    'ANSP_APPORTIONED': {'abbr': 'AA', 'quantified': false},
    'ANSP_EXPERT_JUDGMENT': {'abbr': 'AJ', 'quantified': true},
    'WHAT_IF': {'abbr': 'WI', 'quantified': true}
});

/**
 * Returns the short name for a given pedigree
 * @param pedigree The pedigree (cannot be null)
 * @returns {string} The short name of the pedigree or '' if none exists
 */
export default function getPedigreeAbbrev(pedigree: string): string {
    try {
        return pedigreeInfo[pedigree as keyof typeof pedigreeInfo].abbr;
    } catch(e) {
        return '' as string;
    }
}

/**
 * Returns the quantified attribute of the given pedigree.
 *
 * @param pedigree The pedigree (cannot be null)
 * @returns {null|boolean} The quantified attribute of the pedigree (true or false) or
 * null if the given pedigree does not exist
 */
export function getPedigreeQuantified(pedigree: string): boolean|null {
    try {
        return pedigreeInfo[pedigree as keyof typeof pedigreeInfo].quantified;
    } catch(e) {
        return null;
    }
}
