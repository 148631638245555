import React, {useContext, useRef, useState} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import {CanvasEnvironment} from '../../../utils/canvas/canvas-environment';
import {useWorkspaceContext} from '../../../context/workspaceContext';
import {CanvasActionType} from '../../../@types/canvas-action-type';
import * as canvasActions from '../../../utils/canvas/canvas-actions'
import * as CanvasUtils from '../../../utils/canvas/node-utils'
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {EsdEvent} from '../../../@types/esdEvent';
import {hasRightToEdit} from '../../../utils/role-to-rights';
import {UserContext} from '../../../context/userContext';


export default function EventMenu(): React.ReactElement {
    const { currentWorkspaceObject, currentCanvasSelectedNode, setCurrentCanvasAction } = useWorkspaceContext();
    const { user } = useContext(UserContext);
    const canvasEnvironmentRef = useRef<CanvasEnvironment | null>(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const canPerform = (perform: (event: EsdEvent) => boolean): boolean => {
        return hasRightToEdit(currentWorkspaceObject!, user?.superUser) && currentCanvasSelectedNode !== null && perform(currentCanvasSelectedNode as EsdEvent);
    }

    const canPerformInEnv = (perform: (env: CanvasEnvironment|null, event: EsdEvent) => boolean): boolean => {
        return hasRightToEdit(currentWorkspaceObject!, user?.superUser) && currentCanvasSelectedNode !== null && perform(canvasEnvironmentRef.current, currentCanvasSelectedNode as EsdEvent);
    }

    const setCanvasAction = (type: CanvasActionType): void => {
        setCurrentCanvasAction({ actionType: type });
        handleClose();
    }

    const notInprecursor = (event: EsdEvent): boolean => {
        return event.type !== 'INPRECURSOR';
    }

    const notInitiating = (event: EsdEvent): boolean => {
        return event.type !== 'INITIATING';
    }

    const notInitiatingOrInprecursor = (event: EsdEvent): boolean => {
        return event.type !== 'INITIATING' && event.type !== 'INPRECURSOR';
    }

    return (
        <>
            <Button
                id="event-menu-button"
                aria-controls={open ? 'event-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                aria-label="Event Menu"
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                style={{ color: 'white', textTransform: 'none', }}
                sx={{
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                }}
            >
                Event
            </Button>
            <Menu
                id="event-menu"
                aria-labelledby="event-menu-button"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'event-menu-button',
                }}
            >
                <MenuItem onClick={(): void => { setCanvasAction(CanvasActionType.EVENTNODE_ADD_PIVOTALEVENT) }} disabled={!(canPerform(CanvasUtils.canCreatePivotalEvent))} dense>Add Pivotal Event</MenuItem>
                <MenuItem onClick={(): void => { setCanvasAction(CanvasActionType.EVENTNODE_ADD_ENDEVENT) }} disabled={!(canPerform(CanvasUtils.canCreateEndEvent))} dense>Add End Event</MenuItem>
                <Divider />
                <MenuItem onClick={(): void => { setCanvasAction(CanvasActionType.EVENTNODE_COPY) }} disabled={!(canPerform(notInprecursor))} dense>Copy Event</MenuItem>
                <MenuItem onClick={(): void => { setCanvasAction(CanvasActionType.EVENTNODE_PASTE) }} disabled={!(canPerformInEnv(canvasActions.canPasteEvent))} dense>Paste Event</MenuItem>
                <MenuItem onClick={(): void => { setCanvasAction(CanvasActionType.EVENTNODE_DELETE) }} disabled={!(canPerform(notInitiating))} dense>Delete Event</MenuItem>
                <Divider />
                <MenuItem onClick={(): void => { setCanvasAction(CanvasActionType.EVENTNODE_REASSIGN_PARENT) }} disabled={!(canPerform(notInitiatingOrInprecursor))} dense>Reassign Parent</MenuItem>
                <MenuItem onClick={(): void => { setCanvasAction(CanvasActionType.NODE_PROPERTIES) }} disabled={!hasRightToEdit(currentWorkspaceObject!, user?.superUser) || currentCanvasSelectedNode === null} dense>Event Properties</MenuItem>
            </Menu>
        </>
    );
}
