/* eslint-disable no-console */
import axios from 'axios';
import {API_URL, MEDIA_TYPE_V2} from '../config/constants';
import EventSequence from '../@types/event-sequence';
import EventSequenceSummary from '../@types/event-sequence-summary';
import {getErrorMessage} from '../utils/utils';
import SafetyService from './safety.service';
import {makeGroup} from '../utils/group-utils';
import {makeEventSequenceSummary} from '../utils/safety-model-utils';
import SpringDataPage, {BasicPage} from '../@types/spring-data-page';
import EventSequenceVersion from '../@types/esd-version-props';

class EventSequenceService {
    static eventSequenceUrlV1 = API_URL + 'safety/eventSequences';
    static eventSequenceUrlV2 = API_URL + 'api/v2/eventSequences';
    static eventSequenceDuplicateUrlV2 = API_URL + 'api/v2/eventSequences/copyAndPaste/sourceId/{sourceId}/destinationId/{destinationId}';
    static eventSequenceExportUrl = API_URL + 'safety/eventSequences/{id}/export';
    static eventSequenceImportUrl = API_URL + 'safety/eventSequences/safetyModel/{id}/import';
    static eventSequenceSanitizeUrlV2 = API_URL + 'api/v2/eventSequences/sanitize';
    static occurrenceDnaSanitizeUrl = API_URL + 'occurrence/v1/links/eventSequence/sanitize';

    formEventSequenceSummayPromise(eventSequenceSummary: any): Promise<EventSequenceSummary> {
        let ess = makeEventSequenceSummary(eventSequenceSummary);
        if (ess.role) {
            return Promise.resolve(ess);
        }
        return SafetyService.getSafetyModelById(ess.containerId).then((object) => {
            ess.role = object.group.role();
            return ess;
        });
    }

    formEventSequencePromise(eventSequence: any): Promise<EventSequence> {
        let es: EventSequence = {} as EventSequence;
        es.id = eventSequence.id;
        es.uniqueId = eventSequence.uniqueId;
        es.name = eventSequence.name;
        es.description = eventSequence.description;
        es.exposureFactor = eventSequence.exposureFactor;
        es.phasesOfFlight = eventSequence.phasesOfFlight;
        es.occurrenceCategory = eventSequence.occurrenceCategory;
        es.containerId = eventSequence.containerId;
        es.quantificationMode = eventSequence.quantificationMode;
        es.events = eventSequence.events;
        es.initiatingEventId = eventSequence.initiatingEventId;
        if (eventSequence.group) {
            es.group = makeGroup(eventSequence.group);
            es.role = es.group.role();
            return Promise.resolve(es);
        }

        return SafetyService.getSafetyModelById(es.containerId).then((object) => {
            es.group = object.group;
            es.role = object.group.role();
            return es;
        });
    }

    formEventSequenceHistoryPagePromise(esHistoryData: any): Promise<SpringDataPage<EventSequenceVersion>> {
        let esHistoryPage: SpringDataPage<EventSequenceVersion> = {} as SpringDataPage<EventSequenceVersion>;
        if (esHistoryData) {
            if (esHistoryData._embedded?.eventSequenceVersionedDataDTOList) {
                esHistoryPage.content = esHistoryData._embedded.eventSequenceVersionedDataDTOList;
            } else {
                esHistoryData.content = [];
            }
            esHistoryPage.page = esHistoryData.page;
        }
        return Promise.resolve(esHistoryPage);
    }

    getEventSequenceById(eventSequenceId: number) : Promise<any> {
        return axios
            .get(EventSequenceService.eventSequenceUrlV2 + '/' + eventSequenceId, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 }
            })
            .then(response => {
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to get event sequence: ' + getErrorMessage(err));
            })
    }

    getEventSequenceSummaryById(eventSequenceId: number) : Promise<any> {
        return axios
            .get(EventSequenceService.eventSequenceUrlV2 + '/' + eventSequenceId, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 }
            })
            .then(response => {
                return this.formEventSequenceSummayPromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to get event sequence: ' + getErrorMessage(err));
            })
    }

    importEventSequence(safetyModelId: number, file: File): Promise<any> {
        let formData = new FormData();
        formData.append('file', file);
        return axios
            .post(EventSequenceService.eventSequenceImportUrl.replace('{id}', safetyModelId.toString()), formData, {
                withCredentials: true,
                headers: { 'Content-Type': 'multipart/form-data' }
            })
            .then(response => {
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to import event sequence: ' + getErrorMessage(err));
            })
    }

    updateEventSequence(eventSequence: EventSequence) : Promise<any> {
        return axios
            .patch(EventSequenceService.eventSequenceUrlV2, eventSequence, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 }
            })
            .then(response => {
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to update event sequence: ' + getErrorMessage(err));
            })
    }

    deleteEventSequence(eventSequenceId: number) : Promise<any> {
        return axios
            .delete(EventSequenceService.eventSequenceUrlV2 + '/' + eventSequenceId, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 }
            })
            .then(() => {
                return;
            })
            .catch(err => {
                throw new Error('Failed to delete event sequence: ' + getErrorMessage(err));
            })
    }

    duplicateEventSequence(sourceId: number, destinationId: number): Promise<any> {
        return axios
            .post(EventSequenceService.eventSequenceDuplicateUrlV2.replace('{sourceId}', sourceId.toString()).replace('{destinationId}', destinationId.toString()), {}, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 }
            })
            .then(response => {
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to duplicate event sequence: ' + getErrorMessage(err));
            })
    }

    sanitizeEventSequence(eventSequenceId: number): Promise<any> {
        return axios
            .post(EventSequenceService.eventSequenceSanitizeUrlV2, { eventSequenceId }, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 }
            })
            .then(response => {
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to sanitize event sequence: ' + getErrorMessage(err));
            })
    }

    sanitizeOccurrenceDna(eventSequenceId: number): Promise<any> {
        return axios
            .post(EventSequenceService.occurrenceDnaSanitizeUrl, { eventSequenceId }, {
                withCredentials: true
            })
            .then(() => { return; })
            .catch(err => {
                throw new Error('Failed to sanitize occurrence links to event sequence: ' + getErrorMessage(err));
            })
    }

    exportEventSequence(eventSequenceId: number): Promise<any> {
        return axios
            .get(EventSequenceService.eventSequenceExportUrl.replace('{id}', eventSequenceId.toString()), {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {
                return response;
            })
            .catch(err => {
                throw new Error('Failed to export event sequence: ' + getErrorMessage(err));
            })
    }

    addEventToEventSequence(eventSequenceId: number, eventObj: any): Promise<any> {
        return axios
            .post(API_URL + 'safety/eventSequences/' + eventSequenceId + '/events', eventObj, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to add event to event sequence: ' + getErrorMessage(err));
            })
    }

    addEventFaultTree(eventId: number): Promise<any> {
        let url = API_URL + 'safety/events/' + eventId + '/faultTrees';
        return axios
            .post(url, {}, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                
                // eslint-disable-next-line no-console
                console.log('thrown error', err);
                throw new Error('Failed to add fault tree: ' + getErrorMessage(err));
            })
    }

    addFaultTreeNode(faultTreeId: number, faultTreeNodeObj: any): Promise<any> {
        return axios
            .post(API_URL + 'safety/faultTrees/' + faultTreeId + '/faultTreeNodes', faultTreeNodeObj, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to add fault tree node: ' + getErrorMessage(err));
            })
    }


    copyEvent(sourceId: number, destinationId: number, parentRelationType: string): Promise<any> {
        let url = API_URL + 'safety/v1/events/copyAndPaste/sourceId/' + sourceId + '/destinationId/' + destinationId + '/parentRelationType/' + parentRelationType;
        return axios
            .post(url, {}, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                
                // eslint-disable-next-line no-console
                console.log('thrown error', err);
                throw new Error('Failed to copy event to destination: ' + getErrorMessage(err));
            })
    }

    copyEventFaultTree(sourceId: number, destinationId: number): Promise<any> {
        let url = API_URL + 'safety/faultTrees/copyAndPaste/sourceId/' + sourceId + '/destinationId/' + destinationId;
        return axios
            .post(url, {}, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                
                // eslint-disable-next-line no-console
                console.log('thrown error', err);
                throw new Error('Failed to copy event fault tree to destination: ' + getErrorMessage(err));
            })
    }

    copyEventFaultTreeNode(sourceId: number, destinationId: number): Promise<any> {
        let url = API_URL + 'safety/faultTreeNodes/copyAndPaste/sourceId/' + sourceId + '/destinationId/' + destinationId;
        return axios
            .post(url, {}, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                
                // eslint-disable-next-line no-console
                console.log('thrown error', err);
                throw new Error('Failed to copy event fault tree node: ' + getErrorMessage(err));
            })
    }


    deleteEvent(eventId: number, preserveChildId?: number): Promise<any> {
        let url = API_URL + 'safety/events/' + eventId;
        if (preserveChildId) {
            url += '?preserve=' + preserveChildId;
        }

        return axios
            .delete(url, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                
                // eslint-disable-next-line no-console
                console.log('thrown error', err);
                throw new Error('Failed to delete event: ' + getErrorMessage(err));
            })
    }

    deleteEventFaultTree(eventFaultTreeId: number): Promise<any> {
        let url = API_URL + 'safety/faultTrees/' + eventFaultTreeId;

        return axios
            .delete(url, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                
                // eslint-disable-next-line no-console
                console.log('thrown error', err);
                throw new Error('Failed to delete event fault tree: ' + getErrorMessage(err));
            })
    }

    deleteFaultTreeNode(faultTreeNodeId: number): Promise<any> {
        let url = API_URL + 'safety/faultTreeNodes/' + faultTreeNodeId;

        return axios
            .delete(url, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                
                // eslint-disable-next-line no-console
                console.log('thrown error', err);
                throw new Error('Failed to delete event: ' + getErrorMessage(err));
            })
    }

    redo(sourceId: number): Promise<any> {
        let url = API_URL + 'safety/v1/eventSequences/' + sourceId + '/redo';
        return axios
            .post(url, {}, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to redo changes: ' + getErrorMessage(err));
            })
    }

    undo(sourceId: number): Promise<any> {
        let url = API_URL + 'safety/v1/eventSequences/' + sourceId + '/undo';
        return axios
            .post(url, {}, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {     
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to undo changes: ' + getErrorMessage(err));
            })
    }


    updateEvent(eventObj: any, autonumber: boolean): Promise<any> {
        let url = API_URL + 'safety/events/' + eventObj.id;
        url += '?autonumber=' + autonumber;
        return axios
            .patch(url, eventObj, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to update event: ' + getErrorMessage(err));
            })
    }

    updateEventFaultTree(eventFaultTreeObj: any): Promise<any> {
        let url = API_URL + 'safety/faultTrees/';
        return axios
            .patch(url, eventFaultTreeObj, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to update event fault tree: ' + getErrorMessage(err));
            })
    }

    updateFaultTreeNode(eventObj: any, autonumber: boolean): Promise<any> {
        let url = API_URL + 'safety/faultTreeNodes';
        url += '?autonumber=' + autonumber;
        return axios
            .patch(url, eventObj, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to update fault tree node: ' + getErrorMessage(err));
            })
    }
    
    reorderFaultTreeNodeChildren(faultTreeNodeId: number, childIds: Array<number>): Promise<any> {
        let url = API_URL + 'safety/faultTreeNodes/' + faultTreeNodeId + '/reorder';
        return axios
            .patch(url, childIds, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to reorder: ' + getErrorMessage(err));
            })
    }

    autoQuantifyEventSequence(esdId: number, updatePedigrees: boolean): Promise<any> {
        let url = API_URL + 'safety/eventSequences/' + esdId + '/autoquantify';
        url = updatePedigrees ? url + '?updatePedigrees=true' : url + '?updatePedigrees=false';
        return axios
            .patch(url, {}, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to quantify: ' + getErrorMessage(err));
            })
    }

    autoQuantifySubtree(faultTreeNodeId: number, updatePedigrees: boolean, direction: string): Promise<any> {
        let url = API_URL + 'safety/faultTreeNodes/' + faultTreeNodeId + '/autoquantify/subtree';
        let depth = -1;
        if (direction === 'CHILDREN') {
            direction = 'TOP_DOWN';
            depth = 1;
        }
        url += '?direction=' + direction + '&depth=' + depth;
        if (direction === 'BOTTOM_UP') {
            url = updatePedigrees ? url + '&updatePedigrees=true' : url + '&updatePedigrees=false';
        }

        return axios
            .patch(url, {}, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {       
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to quantify: ' + getErrorMessage(err));
            })
    }

    fetchEventSequenceVersions(esdId: number, page: BasicPage, searchTerm?: string): Promise<SpringDataPage<EventSequenceVersion>> {
        let url = `${EventSequenceService.eventSequenceUrlV2}/${esdId}/versionHistory?`
            + `size=${page.size}&`
            + `page=${page.offset}&`
            + `direction=${page.direction.toUpperCase()}&`
            + `sort=${page.sort}`;
        if (searchTerm && searchTerm !== '') url += `&searchTerm=${searchTerm}`;
        return axios
            .get(url, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {
                return  this.formEventSequenceHistoryPagePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to get event sequence version history: ' + getErrorMessage(err));
            })
    }

    fetchEventSequenceVersion(esdId: number, versionId: number): Promise<EventSequenceVersion> {
        const url = `${EventSequenceService.eventSequenceUrlV2}/${esdId}/versionHistory/${versionId}`
        return axios
            .get(url, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/hal+json' }
            })
            .then(response => {
                return Promise.resolve(response.data as EventSequenceVersion);
            })
            .catch(err => {
                throw new Error('Failed to get event sequence history for version: ' + getErrorMessage(err));
            })
    }

    fetchEventSequenceActiveVersion(esdId: number): Promise<EventSequenceVersion> {
        const url = `${EventSequenceService.eventSequenceUrlV2}/${esdId}/activeVersion`
        return axios
            .get(url, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/hal+json' }
            })
            .then(response => {
                return Promise.resolve(response.data as EventSequenceVersion);
            })
            .catch(err => {
                throw new Error('Failed to get event sequence active version: ' + getErrorMessage(err));
            })
    }

    postTag(esdId: number, versionId: number, tag: string): Promise<EventSequenceVersion> {
        let url = `${EventSequenceService.eventSequenceUrlV2}/${esdId}/tagVersion/${versionId}?tag=${encodeURIComponent(tag)}`;
        return axios
            .post(url, {}, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 }
            })
            .then(response => {
                return Promise.resolve(response.data as EventSequenceVersion);
            })
            .catch(err => {
                throw new Error('Failed to add comment to event sequence version: ' + getErrorMessage(err));
            })
    }

    restoreEventSequence(esdId: number, versionId: number, comment: string): Promise<EventSequence> {
        let url = `${EventSequenceService.eventSequenceUrlV2}/${esdId}/restoreVersion/${versionId}?comment=${encodeURIComponent(comment)}`;
        return axios
            .post(url, {}, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 }
            })
            .then(response => {
                return this.formEventSequencePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to restore event sequence: ' + getErrorMessage(err));
            })
    }
}

export default new EventSequenceService();

