import React, {ReactElement, useEffect, useState} from 'react';
import { User } from '../../../context/userContext';
import {useWorkspaceContext} from '../../../context/workspaceContext';
import { uuid } from '../../../utils/utils';
import { ResourceType } from '../../../@types/resource-type';
import { addOrUpdateUser } from '../../../utils/user-utils';
import UserService from '../../../services/users.service';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Stack from '@mui/material/Stack'; 
import ErrorAlertComponent from '../../../components/ErrorAlert.component';
import authService from '../../../services/auth.service';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import {UserEditorProps} from '../../../@types/editor-props';

export default function UserEditor(props: UserEditorProps): ReactElement {
    const { user, updateTab } = props;
    const { workspaceObjects, setWorkspaceObjects, setCurrentWorkspaceObject } = useWorkspaceContext();

    const [firstName, setFirstName] = useState<string>();
    const [lastName, setLastName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const [isActive, setIsActive] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [editMode, setEditMode] = useState(false);
    const uuidRef = React.useRef(uuid());

    const onFirstNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setFirstName(event.target.value);
    };

    const onLastNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setLastName(event.target.value);
    };

    const onEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setEmail(event.target.value);
    };

    const formUser = (): User => {
        const userData = {} as User;
        userData.firstName = firstName ?? '';
        userData.lastName = lastName ?? '';
        userData.email = email ?? '';

        if (user && user.id) {
            userData.id = user.id;
            userData.isActive = isActive;
        }
        return userData;
    }

    const updateUser = (usr: User): void => {
        let userObject = {objectType: ResourceType.USERS, object: usr};
        setCurrentWorkspaceObject(userObject);
        if (workspaceObjects?.objectType === ResourceType.USERS) {
            setWorkspaceObjects({ objectType: ResourceType.USERS, resources: addOrUpdateUser(usr, workspaceObjects.resources as User[]) });
        }
        updateTab(userObject);
    }

    const onClickSave = (_event: React.SyntheticEvent): void => {
        if (firstName && lastName && email) {
            setError(null);
            if (editMode) {
                UserService.patchUser(formUser()).then((usr: User) => {
                    updateUser(usr);
                }).catch((error: Error) => {
                    setError(error.message);
                });
            } else {
                UserService.createUser(formUser()).then((usr: User) => {
                    updateUser(usr);
                    setEditMode(true);
                }).catch((error: Error) => {
                    setError(error.message);
                });
            }
                
        } else {
            setError('Please fill in all fields');
        }
    }
    useEffect(() => {
        if (user) {
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setEmail(user.email);
            setIsActive(user.isActive);
            if (user.id) {
                setEditMode(true);
            }
        }
    }, [user]);

    return (
        <FormGroup className='formStyle' sx={{ p: 2 }}>
            <Stack className='formControl'>
                <Typography variant='h6' gutterBottom>{editMode ? 'Edit User' : 'New User'}</Typography>
                <Stack direction='row' spacing={2} justifyContent='space-between'>
                    <Typography variant='body1' gutterBottom>
                        You can manage the groups of the user
                        and indicate whether or not the user
                        should be a super user.
                    </Typography>
                

                    <Stack className='formControl'>
                        <TextField
                            id={`first-name-input-${uuidRef.current}`}
                            label='First Name'
                            aria-label='user first name'
                            type='text'   
                            variant='outlined'
                            size='small'
                            autoComplete='on'
                            value={firstName || ''}
                            onChange={onFirstNameChange}
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `first-name-input-${uuidRef.current}`,
                                'aria-label': 'user first name'
                            }}
                        />
                        <hr />
                        <TextField
                            id={`last-name-input-${uuidRef.current}`}
                            label='Last Name'
                            aria-label='user last name'
                            type='text'   
                            variant='outlined'
                            size='small'
                            autoComplete='on'
                            value={lastName || ''}
                            onChange={onLastNameChange}
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `last-name-input-${uuidRef.current}`,
                                'aria-label': 'user last name'
                            }}
                        />
                        <hr />
                        <TextField
                            id={`email-address-input-${uuidRef.current}`}
                            label='Email Address'
                            aria-label='user email address'
                            type='text'   
                            variant='outlined'
                            size='small'
                            autoComplete='on'
                            value={email || ''}
                            onChange={onEmailChange}
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `email-address-input-${uuidRef.current}`,
                                'aria-label': 'user email address'
                            }}
                        />
                        <hr />
                        
                        {user && user.id &&
                        <FormControl>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox checked={isActive} onChange={():void => setIsActive(!isActive)} />
                                    }
                                    label='Active'
                                    sx={{ ml: 1 }}
                                />
                            </FormGroup>
                        </FormControl>
                        
                        }

                        {authService.isSuperUser() &&
                        <div>
                            <Button className='formBtn' id={`save-user-${uuidRef.current}`}
                                size='small' variant='contained'
                                aria-label='save user'
                                title='Save User'
                                onClick={onClickSave}
                                startIcon={<SaveAltIcon />}
                            >Save</Button>
                        </div>                   
                        }
                        {error ? <ErrorAlertComponent message={error} />  : null}
                    </Stack>
                </Stack>
            </Stack>
        </FormGroup>
    );
}
