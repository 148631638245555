/**
 *
 * @param props
 * @param props.width   - The width of the form
 * @param props.align   - The alignment of the form.
 * @constructor
 */
import {ReactElement} from 'react';

export default function FormWrapper(props: {width?: number, align?: string, children: any}): ReactElement {

    // Unpack props
    const {width, align, children} = props;

    // Build the form stying
    const style = {
        width: width ? width : '100%',
        margin: align === 'center' ? '0 auto' : '0'
    }

    return (
        <div className='devkit-text-input-form-wrapper' style={style}>
            <form className={'devkit-sampleform'}>
                <fieldset className={'devkit-text-input-fieldset-wrapper'}>
                    {children}
                </fieldset>
            </form>
        </div>
    );

}