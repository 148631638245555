import React, {ReactElement, useState} from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import {useWorkspaceContext} from '../../../context/workspaceContext';
import {ResourceType} from '../../../@types/resource-type';
import Menu from '@mui/material/Menu';
import {ResourceAction} from '../../../@types/resource-action';

export default function EditMenu(): ReactElement {
    const {currentWorkspaceObject, setCurrentAction } = useWorkspaceContext();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void =>  {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const onClickProperties = (): void => {
        setCurrentAction(ResourceAction.PROPERTIES);
        handleClose();
    }

    const onClickMembers = (): void => {
        setCurrentAction(ResourceAction.MEMBERS);
        handleClose();
    }

    return (
        <>
            <Button
                id="edit-menu-button"
                aria-controls={open ? 'edit-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                aria-label="Edit Menu"
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                style={{ color: 'white', textTransform: 'none', }}
                sx={{
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                }}
            >
                Edit
            </Button>
            {
                currentWorkspaceObject && currentWorkspaceObject.objectType === ResourceType.GROUPS &&
                <Menu
                    id="edit-menu"
                    aria-labelledby="edit-menu-button"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'edit-menu-button',
                    }}
                >
                    <MenuItem onClick={onClickProperties} dense>Properties</MenuItem>
                    <Divider />
                    <MenuItem onClick={onClickMembers} dense>Members</MenuItem>
                </Menu>
            }
        </>
    );
}
