import React from 'react';
import { FormControl } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { groupRoles, getRolesAbove } from '../../../utils/role-to-rights';
import { GroupMember,  GroupMemberModelWithMinRole } from '../../../@types/group-member';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const allRoles = [
    groupRoles.observer,
    groupRoles.contributor,
    groupRoles.analyst,
    groupRoles.admin,
];


export default function RoleSelector(props: {
    groupMember:  GroupMemberModelWithMinRole,
    onChange: (groupMember: GroupMember, role: string) => void
}): React.ReactElement {
    const { groupMember, onChange } = props;
    const [role, setRole] = React.useState(groupMember.role);
    const [possibleRoles, setPossibleRoles] = React.useState<string[]>([]);

    const handleRoleChange = (event: SelectChangeEvent): void => {
        setRole(event.target.value as string);
        onChange(groupMember, event.target.value as string);
    };

    React.useEffect(() => {
        setPossibleRoles(getRolesAbove(groupMember.minRole?.role));
    }, [groupMember]);

    return (
        <FormControl  variant="standard" sx={{width: '100%'}}>
            <Select
                value={role}
                onChange={handleRoleChange}
                disabled={possibleRoles.length === 0}
            >
                {allRoles.map((role: string, index: number) => {
                    return (
                        <MenuItem key={index} value={role} disabled={!possibleRoles.includes(role)}>{role}</MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
}