import Box from '@mui/material/Box';
import {BreadCrumbStrip} from '../form-components/BreadCrumbStrip.component';
import CloseIcon from '@mui/icons-material/Close';
import Icon from '@mui/material/Icon';
import {getBreadCrumbsProps} from '../../utils/resource-utils';
import {getCurrentTabIndex, getTabItems, updateCurrentTabIndex, updateTabItems} from '../../utils/tab-utils';
import {makeGroup} from '../../utils/group-utils';
import {makeSafetyModel} from '../../utils/safety-model-utils';
import GroupEditorController from './editors/GroupEditorController';
import {newCamelCaseToWordWithNoS, uuid} from '../../utils/utils';
import React, {ReactElement} from 'react';
import {resourceIcon} from '../../utils/resource-icons';
import {ResourceType} from '../../@types/resource-type';
import {SelectedWorkspaceObject} from '../../@types/workspace-types';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import {TabPanel} from '../form-components/TabPanel.component';
import TabItem from '../../@types/tab-item';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import {useWorkspaceContext} from '../../context/workspaceContext';
import UserEditor from './editors/UserEditor';
import SafetyModelEditor from './editors/SafetyModelEditor';
import EventSequenceSummaryEditor from './editors/EventSequenceSummaryEditor';
import EventSequenceTreeEditor from './editors/EventSequenceTreeEditor';
import {ResourceAction} from '../../@types/resource-action';
import EsdHistoryEditor from './editors/EsdHistoryEditor';
import EventSequenceTreeViewer from './editors/EventSequenceTreeViewer';

interface TabEditorProps {
    objectToEdit: SelectedWorkspaceObject|null;
    onTabChange: (object: any) => void;
}



/*
    This is a generic Tab Editor component that can be used to edit any type of resource.
*/
export default function TabEditor(props: TabEditorProps): ReactElement {
    const {objectToEdit, onTabChange} = props;
    const [tabItems, setTabItems] = React.useState<TabItem[]>(getTabItems());
    const [currentTabIndex, setCurrentTabIndex] = React.useState<number>(getCurrentTabIndex());
    const { deletedWorkspaceObject, currentAction, setCurrentAction, getAdditionalResource } = useWorkspaceContext();
    const uuidRef = React.useRef(uuid());

    const addAdditionalInformation = (tabObject: { objectType: ResourceType, object: any }): any => {
        const { objectType, object } = tabObject;
        if (objectType === ResourceType.EVENT_SEQUENCES || objectType === ResourceType.EVENT_SEQUENCE_TREES) {
            let newObject = Object.assign({}, object);
            newObject.safetyModel = getAdditionalResource(ResourceType.SAFETY_MODELS)?.resources?.find((safetyModel: any) => safetyModel.id === object.containerId);
            return newObject;
        }
        else if (objectType === ResourceType.EVENT_SEQUENCE_HISTORY_TREES) {
            let newObject = Object.assign({}, object);
            let esd = object.eventSequenceDTO;
            esd.safetyModel = getAdditionalResource(ResourceType.SAFETY_MODELS)?.resources?.find((safetyModel: any) => safetyModel.id === esd.containerId);
            if (!esd.group) { esd.group = esd.safetyModel.group; }
            newObject.eventSequenceDTO = esd;
            return newObject;
        }
        return object;
    }

    const getTabLabel = (tabObject: {objectType: ResourceType, object: any }): string => {
        let label = '';
        if (tabObject.object.name && tabObject.object.name !== '') {
            label = tabObject.object.name;
        } else if (tabObject.objectType === ResourceType.EVENT_SEQUENCE_HISTORY_TREES) {
            label = tabObject.object.eventSequenceDTO.name;
        } else {
            label = newCamelCaseToWordWithNoS(tabObject.objectType)
        }
        return label;
    }

    const handleTabChange = (event: React.SyntheticEvent, newTabIndex: number): void => {
        setCurrentTabIndex(newTabIndex);
        updateCurrentTabIndex(newTabIndex);
        onTabChange({objectType: tabItems[newTabIndex].type, object: tabItems[newTabIndex].objectToEdit});
    };

    const handleTabClose = (event: React.SyntheticEvent, tabToDelete: TabItem): void => {
        event.stopPropagation();
        closeTab(tabToDelete);
    };

    const closeTab = (tabToDelete: TabItem): void => {
        let newTabs: TabItem[] = [];
        tabItems.filter((tab) => tab.index != tabToDelete.index).forEach((tab) => {
            if (tab.index > tabToDelete.index) {
                tab.index = tab.index - 1;
            }
            newTabs.push(Object.assign({}, tab));
        });
        setTabItems(newTabs);
        updateTabItems(newTabs);
        let newTabIndex = tabToDelete.index == 0 ? 0 : tabToDelete.index - 1;
        setCurrentTabIndex(newTabIndex);
        updateCurrentTabIndex(newTabIndex);
        onTabChange(newTabs[newTabIndex] ? {objectType: newTabs[newTabIndex].type, object: newTabs[newTabIndex].objectToEdit} : null);
    };

    const handleUpdateTab = (tabObject: {objectType: ResourceType, object: any}): void => {
        let tabToUpdate = tabItems[currentTabIndex];
        tabToUpdate.label = tabObject.object.name;
        tabToUpdate.docId = tabObject.object.id;
        tabToUpdate.breadCrumbs = getBreadCrumbsProps(addAdditionalInformation(tabObject), tabObject.objectType);
        tabToUpdate.objectToEdit = tabObject.object;
        let newTabs: TabItem[] = [];
        tabItems.forEach(tab => {
            if (tab.type == tabToUpdate.type && tab.index == tabToUpdate.index) {
                newTabs.push(tabToUpdate);
            } else {
                newTabs.push(Object.assign({}, tab))
            }});
        setTabItems(newTabs);
        updateTabItems(newTabs);
    }

    React.useEffect(() => {
        if (deletedWorkspaceObject) {
            let newTabs: TabItem[] = [];
            let ids = [deletedWorkspaceObject.id, ...(deletedWorkspaceObject.children ?? [])];

            tabItems.filter((tab) => ids.findIndex((id) => (tab.docId == id) || (tab.objectToEdit.group?.id == id) ) === -1 ).forEach((tab) => {
                newTabs.push(Object.assign({}, tab));
            });
            newTabs.forEach((tab, index) => {
                tab.index = index;
            });

            setTabItems(newTabs);
            updateTabItems(newTabs);
            let newTabIndex = currentTabIndex == 0 ? 0 :
                (currentTabIndex >= newTabs.length ? newTabs.length - 1 : currentTabIndex);
            setCurrentTabIndex(newTabIndex);
            updateCurrentTabIndex(newTabIndex);
            onTabChange(newTabs[newTabIndex] ? { objectType: newTabs[newTabIndex].type, object: newTabs[newTabIndex].objectToEdit } : null);
        }
    }, [deletedWorkspaceObject]);


    React.useEffect(() => {
        if (objectToEdit && objectToEdit.object) {
            const tab = tabItems.find((tab) => tab.docId == objectToEdit.object.id && tab.type == objectToEdit.objectType);
            if (tab) {
                tab.objectToEdit = objectToEdit.object;
                setTabItems([...tabItems]);
                setCurrentTabIndex(tab.index);
                updateCurrentTabIndex(tab.index);
            } else {
                const newObjectToEdit = {objectType: objectToEdit.objectType, object: addAdditionalInformation(objectToEdit)};
                const newTab = new TabItem(
                    newObjectToEdit.objectType,
                    newObjectToEdit.object.id ?? null,
                    tabItems.length,
                    tabItems.length,
                    getTabLabel(newObjectToEdit),
                    getBreadCrumbsProps(newObjectToEdit.object, newObjectToEdit.objectType));
                newTab.objectToEdit = newObjectToEdit.object;
                let newTabs = [...tabItems, newTab];
                setTabItems(newTabs);
                updateTabItems(newTabs);
                setCurrentTabIndex(newTab.index);
                updateCurrentTabIndex(newTab.index);
            }
        } else if (tabItems && tabItems.length > 0) {
            setCurrentTabIndex(currentTabIndex ?? 0);
            onTabChange({objectType: tabItems[currentTabIndex].type, object: tabItems[currentTabIndex].objectToEdit});
        }
    }, [objectToEdit]);

    React.useEffect(() => {
        if (currentAction === ResourceAction.CLOSE && tabItems && tabItems.length > 0) {
            setCurrentAction(null);
            closeTab(tabItems[currentTabIndex]);
        }
    }, [currentAction]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%'}}>
            <Tabs
                id={`tab-editor-${uuidRef.current}`}
                variant='scrollable'
                value={currentTabIndex}
                onChange={handleTabChange}
                sx={{
                    backgroundColor: tabItems.length > 0 ? '#E5E4E2' : '#fff',
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },
                    '& .Mui-selected': {
                        backgroundColor: '#fff',
                        borderRadius: '8px 8px 0px 0px',
                        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.75)',
                        borderBottom: 'none',
                        borderLeft: '2px solid #E5E4E2',
                        borderRight: '2px solid #E5E4E2',
                        borderTop: '2px solid #E5E4E2',

                    }
                }}
            >
                {tabItems.map((tab: any) => (
                    <Tab key={tab.index}
                        id={`tab-editor-tab-${uuidRef.current}-${tab.index}`}
                        tabIndex={0}
                        aria-label={`tab ${tab.label} at index ${tab.index}`}
                        aria-controls={`tab-editor-tab-${uuidRef.current}-${tab.index}-tabpanel`}
                        sx={{
                            backgroundColor: '#E5E4E2'
                        }}
                        label={
                            <span style={{ display: 'flex', width: '75%'}}>
                                {resourceIcon(tab.type)}
                                <Typography  variant='body2' sx={{flexGrow: 1, textTransform: 'none', textAlign: 'left', paddingLeft: '5px'}}>{tab.label}</Typography>
                            </span>
                        }  
                        
                        icon={tab.index === currentTabIndex ?
                            <CloseIcon sx={{ width: 16, height: 16, position: 'absolute', right: 0, top: 0 }}
                                onClick={(event: any): void => handleTabClose(event, tab)}
                            /> : <Icon />
                        }
                        iconPosition='end'
                        disableRipple
                        wrapped
                    />
                ))}
            </Tabs>
            {tabItems.map((tab: any) => (
                <TabPanel key={tab.index} value={currentTabIndex} index={tab.index} ownerId={`tab-editor-tab-${uuidRef.current}-${tab.index}`}>
                    <Stack spacing={2}>
                        <BreadCrumbStrip breadCrumbs={tab.breadCrumbs} />
                        {tab.type === ResourceType.GROUPS &&
                            <GroupEditorController group={makeGroup(tab.objectToEdit)} isActive={tab.index === currentTabIndex} updateTab={handleUpdateTab} />
                        }
                        {tab.type === ResourceType.SAFETY_MODELS &&
                            <SafetyModelEditor safetyModel={makeSafetyModel(tab.objectToEdit)} isActive={tab.index === currentTabIndex} updateTab={handleUpdateTab} />
                        }
                        {tab.type === ResourceType.EVENT_SEQUENCES &&
                            <EventSequenceSummaryEditor eventSequenceSummary={tab.objectToEdit} isActive={tab.index === currentTabIndex} updateTab={handleUpdateTab} />
                        }
                        {tab.type === ResourceType.EVENT_SEQUENCE_HISTORY &&
                            <EsdHistoryEditor eventSequenceSummary={tab.objectToEdit} isActive={tab.index === currentTabIndex} updateTab={handleUpdateTab} />
                        }
                        {tab.type === ResourceType.EVENT_SEQUENCE_TREES &&
                            <EventSequenceTreeEditor eventSequenceSummary={tab.objectToEdit} isActive={tab.index === currentTabIndex} updateTab={handleUpdateTab} />
                        }
                        {tab.type === ResourceType.EVENT_SEQUENCE_HISTORY_TREES &&
                            <EventSequenceTreeViewer esv={tab.objectToEdit} isActive={tab.index === currentTabIndex} />
                        }
                        {tab.type === ResourceType.USERS &&
                            <UserEditor user={tab.objectToEdit} isActive={tab.index === currentTabIndex} updateTab={handleUpdateTab} />
                        }
                    </Stack>
                </TabPanel>
            ))}
        </Box>
    );
}
