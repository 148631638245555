// These are the supported text input types. Note here that you cannot export an enum from a .d.ts file.
// I don't fully appreciate why ... but the solution is to export the enum from a .ts file and import it
// into the .d.ts file.

export enum TextInputType {
    text = 'text',
    number = 'number',
    password = 'password',
    email = 'email',
    tel = 'tel',
    url = 'url',
    date = 'date',
    time = 'time'
}

