import SaveAltIcon from '@mui/icons-material/SaveAlt';

import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress  from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import React, { ReactElement, useEffect } from 'react';
import Select , { SelectChangeEvent } from '@mui/material/Select';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { isNumeric, uuid } from '../../../../utils/utils';
import { toast } from 'react-toastify';
import { OccurrenceCategory } from '../../../../@types/event-sequence-summary';
import { useWorkspaceContext } from '../../../../context/workspaceContext';
import { CanvasEnvironment } from '../../../../utils/canvas/canvas-environment';
import { ResourceType } from '../../../../@types/resource-type';
import EventSequenceService from '../../../../services/event-sequence.service';
import * as CanvasActions from '../../../../utils/canvas/canvas-actions';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { isFrequencyMode, isInduced, isPrecursor } from '../../../../utils/canvas/node-utils';

interface SendObject {
    id: string;
    uniqueId: string;
    name: string;
    description: string;
    probability: number;
    frequency: number;
    probabilityUnit: string;
    pedigree: string;
    type: string;
    parentId?: string;
    nodeClass: string;
    nodeType: string;
    occurrenceCategory: string | null;
    childIds: Array<number>;
    presentation: string;
    fixed: boolean;
    bias: number;
    callout: any;
}

function PaperComponent(props: PaperProps): ReactElement {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}

export default function EditFaultTreeNodeModal(props: { open: boolean, env: CanvasEnvironment | null, onClose: () => void }): ReactElement {
    const { open, env, onClose } = props;
    const [name, setName] = React.useState<string | null>(null);
    const [description, setDescription] = React.useState<string | null>(null);
    const [probability, setProbability] = React.useState<number>(0);
    const [frequency, setFrequency] = React.useState<number>(0);
    const [probabilityUnit, setProbabilityUnit] = React.useState<string | null>(null);
    const [pedigree, setPedigree] = React.useState<string | null>(null);
    const [nodeClass, setNodeClass] = React.useState<string | null>(null);
    const [nodeType, setNodeType] = React.useState<string | null>(null);
    const [occurrenceCategory, setOccurrenceCategory] = React.useState<string | null>(null);
    const [nodeClasses, setNodeClasses] = React.useState<string[]>(['NODE', 'BARRIER', 'CIRCUMSTANTIAL']);
    const [fixed, setFixed] = React.useState<boolean>(false);
    const [bias, setBias] = React.useState<number>(1);
    const [frequencyQuantified, setFrequencyQuantified] = React.useState<boolean>(false);
    const [uniqueId, setUniqueId] = React.useState<string>('');
    const [isRunning, setIsRunning] = React.useState<boolean>(false);
    const { occurrenceCategories, pedigrees, setCurrentWorkspaceObject } = useWorkspaceContext();
    const uuidRef = React.useRef(uuid());

    const nodeTypes = ['AND', 'OR', 'XOR', 'BASE_EVENT'];

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setName(event.target.value);
    };

    const onDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setDescription(event.target.value);
    };

    const onProbabilityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (frequencyQuantified) {
            setFrequency(parseFloat(event.target.value));
        } else {
            setProbability(parseFloat(event.target.value));
        }
    };

    const onUnitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setProbabilityUnit(event.target.value);
    };

    const onPedigreeChange = (event: SelectChangeEvent): void => {
        setPedigree(event.target.value);
    };

    const onNodeClassChange = (event: SelectChangeEvent): void => {
        setNodeClass(event.target.value);
        setFrequencyQuantified(isFrequencyMode(env!.eventSequence!.quantificationMode) &&
            (isInduced(event.target.value) || isPrecursor(event.target.value)));
    };

    const onNodeTypeChange = (event: SelectChangeEvent): void => {
        setNodeType(event.target.value);
    };

    const onOccurrenceCategoryChange = (event: SelectChangeEvent): void => {
        setOccurrenceCategory(event.target.value);
    };

    const validInput = (): boolean => {
        return (name !== null && name.trim() !== '' && !outsideRangeConstraint());
    }

    const outsideRangeConstraint = (): boolean => {
        return frequencyQuantified ? frequency < 0 || !isNumeric(frequency)
            : probability < 0 || probability > 1 || !isNumeric(probability);
    }
    useEffect(() => {
        if (open) {
            const [selectedNode] = env!.selection;
            setUniqueId(selectedNode.get('data').uniqueId);
            setName(selectedNode.get('data').name);
            setDescription(selectedNode.get('data').description);
            setProbability(selectedNode.get('data').probability);
            setProbabilityUnit(selectedNode.get('data').probabilityUnit);
            setPedigree(selectedNode.get('data').pedigree);
            setNodeClass(selectedNode.get('data').nodeClass);
            setNodeType(selectedNode.get('data').type);
            setOccurrenceCategory(selectedNode.get('data').occurrenceCategory);
            setBias(selectedNode.get('data').bias);
            setFixed(selectedNode.get('data').fixed);
            setIsRunning(false);
            let esdUniqueId = env!.eventSequence!.uniqueId;
            let quantificationMode = env!.eventSequence!.quantificationMode;
            let truncatedUniqueId = selectedNode.get('data').uniqueId.replace(esdUniqueId, '');
            if (truncatedUniqueId.split('.')[0].slice(-2) !== 'a1') {
                setNodeClasses(['NODE', 'BARRIER', 'CIRCUMSTANTIAL']);
            } else {
                setNodeClasses(['NODE', 'PRECURSOR', 'INDUCED', 'BARRIER', 'CIRCUMSTANTIAL']);
            }
            setFrequencyQuantified(isFrequencyMode(quantificationMode));
        }
    }, [open]);

    const handleSave = async (): Promise<any> => {
        let data = {} as SendObject;
        data['id'] = env!.selection[0].get('data').id.toString();
        data['uniqueId'] = uniqueId;
        data.name = name!;
        data.description = description!;
        data.probability = probability;
        data.frequency = frequency;
        data.probabilityUnit = probabilityUnit!;
        data.fixed = fixed;
        data.bias = bias;
        data.pedigree = pedigree!;
        data.type = nodeType!;
        data.parentId = env!.selection[0].get('data').parentId?.toString();
        data.nodeClass = nodeClass!;
        data.nodeType = nodeType!;
        data.occurrenceCategory = occurrenceCategory ?  occurrenceCategory:  null;
        let childIds = env!.selection[0].get('data').childIds;
        data.childIds = childIds ?? [];

        //
        //NB: it looks like ISAM patching works differently for Fault Tree Node and Event.
        //    For Fault tree node properties patching, you need to add the callout to the data object,
        //    otherwise the callout is removed.
        //    For Event, you don't need to add the callout to the data object as the backend will not interfere with the callout
        //

        data.callout = env!.selection[0].get('data').callout;

        // data.presentation = env!.selection[0].get('data').presentation;

    
        return EventSequenceService.updateFaultTreeNode(data, false);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            fullWidth
            maxWidth="sm">
            <DialogTitle style={{ cursor: 'move'}} id='draggable-dialog-title'>
                Edit Fault Tree Node
            </DialogTitle>
            <DialogContent dividers>
                <FormGroup>
                    <Stack>
                        <TextField
                            margin='dense'
                            type='text'
                            size='small'
                            id={`fault-tree-node-id-${uuidRef.current}`}
                            label='ID'
                            value={uniqueId}
                            variant='outlined'
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                readOnly: true,
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `fault-tree-node-id-${uuidRef.current}`,
                                'aria-label': 'ID',
                            }}
                            disabled
                        />
                        <hr />
                        <TextField
                            id={`fault-tree-node-name-${uuidRef.current}`}
                            label='Name'
                            type='text'
                            margin='dense'
                            size='small'
                            value={name || ''}
                            variant="outlined"
                            onChange={onNameChange}
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `fault-tree-node-name-${uuidRef.current}`,
                                'aria-label': 'name',
                            }}
                            fullWidth
                        />
                        <TextField
                            id={`fault-tree-node-description-${uuidRef.current}`}
                            label='Description'
                            type='text'
                            margin='dense'
                            size='small'
                            variant='outlined'
                            value={description || ''}
                            multiline
                            minRows={3}
                            onChange={onDescriptionChange}
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `fault-tree-node-description-${uuidRef.current}`,
                                'aria-label': 'description',
                            }}
                            fullWidth
                        />
                    </Stack>
                    <hr />
                    <Stack direction="row" spacing={2} justifyContent='start'>
                        <FormControl sx={{ width: '30%' }}>
                            <InputLabel tabIndex={-1} id={`fault-tree-node-occurrence-category-label-${uuidRef.current}`} htmlFor={`fault-tree-node-occurrence-category-list-${uuidRef.current}`}>Occurrence Category</InputLabel>
                            <Select
                                role='presentation'
                                labelId={`fault-tree-node-occurrence-category-label-${uuidRef.current}`}
                                id={`fault-tree-node-occurrence-category-select-label-${uuidRef.current}`}
                                size='small'
                                label='Occurrence Category'
                                value={occurrenceCategory || '0'}
                                onChange={onOccurrenceCategoryChange}
                                inputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'id': `fault-tree-node-occurrence-category-list-${uuidRef.current}`,
                                }}
                            >
                                <MenuItem value={0} dense>-- None --</MenuItem>
                                {occurrenceCategories && occurrenceCategories.map((category: OccurrenceCategory) => (
                                    <MenuItem key={category.occurrenceCategoryShortName} value={category.occurrenceCategoryShortName} dense>{category.occurrenceCategoryName}</MenuItem>
                                ))}
                            </Select>                            
                        </FormControl>
                        <FormControl sx={{ width: '30%' }}>
                            <TextField
                                error={outsideRangeConstraint()}
                                helperText={outsideRangeConstraint() ? 'Value outside range' : ''}
                                id={`fault-tree-node-probability-${uuidRef.current}`}
                                label={frequencyQuantified ? 'Frequency' : 'Probability'}
                                type='number'
                                size='small'
                                variant='outlined'
                                value={isNaN(frequencyQuantified ? frequency : probability) ? '' : (frequencyQuantified ? frequency : probability)}
                                onChange={onProbabilityChange}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                inputProps={{
                                    min: 0,
                                    max: frequencyQuantified ? 1 : Number.MAX_VALUE,
                                    step: 0.000000001
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `fault-tree-node-probability-${uuidRef.current}`,
                                    'aria-label': 'probability',
                                }}
                                fullWidth
                            />
                        </FormControl>
                        <FormControl sx={{ width: '30%' }}>
                            <TextField
                                id={`fault-tree-node-unit-${uuidRef.current}`}
                                label='Unit'
                                type='text'
                                size='small'
                                variant='outlined'
                                value={probabilityUnit || ''}
                                onChange={onUnitChange}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `fault-tree-node-unit-${uuidRef.current}`,
                                    'aria-label': 'unit',
                                }}
                                fullWidth
                            />
                        </FormControl>

                    </Stack>
                    <hr />
                    <Stack direction="row" spacing={2} justifyContent='start'>
                        <FormControl sx={{ width: '30%' }}>
                            <InputLabel tabIndex={-1} id={`fault-tree-node-pedigree-label-${uuidRef.current}`} htmlFor={`fault-tree-node-pedigree-list-${uuidRef.current}`}>Pedigree</InputLabel>
                            <Select
                                role='presentation'
                                labelId={`fault-tree-node-pedigree-label-${uuidRef.current}`}
                                id={`fault-tree-node-pedigree-select-label-${uuidRef.current}`}
                                size='small'
                                label='Pedigree'
                                value={pedigree || '0'}
                                onChange={onPedigreeChange}
                                inputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'id': `fault-tree-node-pedigree-list-${uuidRef.current}`,
                                }}
                            >
                                <MenuItem value={0} dense>-- None --</MenuItem>
                                {pedigrees && pedigrees.map((pedigree: string) => (
                                    <MenuItem key={pedigree} value={pedigree} dense>{pedigree}</MenuItem>
                                ))}
                            </Select>                            
                        </FormControl>
                        <FormControl sx={{ width: '30%' }}>
                            <InputLabel tabIndex={-1} id={`fault-tree-node-node-class-label-${uuidRef.current}`} htmlFor={`fault-tree-node-node-class-list-${uuidRef.current}`}>Node Class</InputLabel>
                            <Select
                                role='presentation'
                                labelId={`fault-tree-node-node-class-label-${uuidRef.current}`}
                                id={`fault-tree-node-node-class-select-label-${uuidRef.current}`}
                                size='small'
                                label='Node Class'
                                value={nodeClass || '0'}
                                onChange={onNodeClassChange}
                                inputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'id': `fault-tree-node-node-class-list-${uuidRef.current}`,
                                }}
                            >
                                <MenuItem value={0} dense>-- None --</MenuItem>
                                {nodeClasses.map((nodeClass: string, index: number) => (
                                    <MenuItem key={index} value={nodeClass} dense>{nodeClass}</MenuItem>
                                ))}
                            </Select>                            
                        </FormControl>
                        <FormControl sx={{ width: '30%' }}>
                            <InputLabel tabIndex={-1} id={`fault-tree-node-type-label-${uuidRef.current}`} htmlFor={`fault-tree-node-type-list-${uuidRef.current}`}>Type</InputLabel>
                            <Select
                                role='presentation'
                                labelId={`fault-tree-node-type-label-${uuidRef.current}`}
                                id={`fault-tree-node-type-select-label-${uuidRef.current}`}
                                size='small'
                                label='Type'
                                value={nodeType || 'BASE_EVENT'}
                                onChange={onNodeTypeChange}
                                inputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'id': `fault-tree-node-type-list-${uuidRef.current}`,
                                }}
                            >
                                {nodeTypes.map((type: string, index: number) => (
                                    <MenuItem key={index} value={type} dense>{type}</MenuItem>
                                ))}
                            </Select>                            
                        </FormControl>

                    </Stack>
                    <hr />
                    <Stack direction="row" spacing={2} justifyContent='start'>
                        <FormControl sx={{ width: '30%' }}>
                            <FormControlLabel
                                id={`fault-tree-node-fixed-${uuidRef.current}`}
                                
                                control={<Checkbox checked={fixed} onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setFixed(event.target.checked)} />}
                                label="Fixed"
                                labelPlacement="start"
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }} 
                                style={{
                                    fontFamily: 'Roboto, Helvetica',
                                    fontSize: '0.8rem',
                                    fontWeight: 400,
                                    lineHeight: 1.4,
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    paddingLeft: '0.2rem',
                                }}
                            />
                        </FormControl>

                        <FormControl sx={{ width: '30%' }}>
                            <TextField
                                id={`fault-tree-node-bias-${uuidRef.current}`}
                                label='Bias'
                                type='number'
                                size='small'
                                variant='outlined'
                                value={bias}
                                onChange={(event): void => setBias(parseFloat(event.target.value))}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `fault-tree-node-bias-${uuidRef.current}`,
                                    'aria-label': 'bias',
                                }}
                                fullWidth
                            />
                        </FormControl>
                    </Stack>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={isRunning ? <CircularProgress size={20} /> : <SaveAltIcon />}
                    onClick={async (): Promise<any> => {
                        setIsRunning(true);
                        try {
                            const response = await handleSave();
                            env!.isDirty = true;
                            setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                        } catch (error: any) {
                            toast.error(error.message);
                        } finally {
                            setIsRunning(false);
                            onClose();
                        }                                    
                    }}
                    disabled={!CanvasActions.canAddOrEditResource(env) || !validInput() || isRunning}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
   
}