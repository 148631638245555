import axios from 'axios';
import {API_URL, MEDIA_TYPE_V2} from '../config/constants';
import SafetyModel from '../@types/safety-model';
import EventSequenceSummary from '../@types/event-sequence-summary';
import {makeEventSequenceSummary, makeSafetyModel} from '../utils/safety-model-utils';
import {getErrorMessage} from '../utils/utils';
import SpringDataPage, {BasicPage} from '../@types/spring-data-page';

class SafetyService {
    static safetyModelUrlV1 = API_URL + 'safety/safetyModels';
    static safetyModelUrlV2 = API_URL + 'api/v2/safetyModels';
    static safetyModelDuplicateUrl = API_URL + 'api/v2/safetyModels/copy-and-paste';

    formEventSequenceSummary(eventSequenceSummary: any): EventSequenceSummary {
        return makeEventSequenceSummary(eventSequenceSummary);
    }

    formEventSequenceSummaries(eventSequenceSummariesData: any): EventSequenceSummary[] {
        const eventSequenceSummaries: EventSequenceSummary[] = [];
        if (eventSequenceSummariesData && eventSequenceSummariesData.length > 0) {
            eventSequenceSummariesData.forEach((eventSequenceSummary: any) => {
                let ess: EventSequenceSummary = this.formEventSequenceSummary(eventSequenceSummary);
                eventSequenceSummaries.push(ess);
            })
        }
        return eventSequenceSummaries;
    }

    formSafetyModel(safetyModel: any): SafetyModel {
        return makeSafetyModel(safetyModel);
    }

    formSafetyModels(safetyModelsData: any): SafetyModel[] {
        const safetyModels: SafetyModel[] = [];
        if (safetyModelsData && safetyModelsData.length > 0) {
            safetyModelsData.forEach((safetyModel: any) => {
                let sm: SafetyModel = this.formSafetyModel(safetyModel);
                safetyModels.push(sm);
            })
        }
        return safetyModels;
    }

    formSafetyModelsPagePromise(safetyModelsData: any): Promise<SpringDataPage<SafetyModel>> {
        let safetyModelsPage: SpringDataPage<SafetyModel> = {} as SpringDataPage<SafetyModel>;
        if (safetyModelsData) {
            if (safetyModelsData._embedded?.safetyModelDTOList) {
                safetyModelsPage.content = safetyModelsData._embedded.safetyModelDTOList?.map((sm: any) => this.formSafetyModel(sm));
            } else {
                safetyModelsPage.content = [];
            }
            safetyModelsPage.page = safetyModelsData.page;
        }
        return Promise.resolve(safetyModelsPage);
    }

    addEventSequenceToSafetyModel(safetyModelId: number, eventSequence: EventSequenceSummary): Promise<any> {
        return axios
            .post(SafetyService.safetyModelUrlV2 + '/' + safetyModelId + '/eventSequences', eventSequence, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 },
            })
            .then(response => {
                return this.formEventSequenceSummary(response.data);
            })
            .catch(err => {
                throw new Error('Add event sequence to safety model failed with error: ' + getErrorMessage(err));
            })
    }

    getEventSequences(safetyModelId: number) : Promise<any> {
        return axios
            .get(SafetyService.safetyModelUrlV2 + '/' + safetyModelId + '/eventSequences?summary=true', {
                withCredentials: true
            })
            .then(response => {
                return response.data._embedded ? this.formEventSequenceSummaries(response.data._embedded.eventSequenceSummaryDTOList) : [];
            })
            .catch(err => {
                throw new Error('Failed to get event sequences: ' + getErrorMessage(err));
            })
    }

    fetchPagedSafetyModels(page: BasicPage, groupId?: number|null, searchTerm?: string): Promise<SpringDataPage<SafetyModel>> {
        let url = `${SafetyService.safetyModelUrlV2}/paged?`
            + `size=${page.size}&`
            + `page=${page.offset}&`
            + `sort=${page.sort},${page.direction}`;
        if (searchTerm && searchTerm !== '') url += `&searchTerm=${searchTerm}`;
        if (groupId) url += `&groupId=${groupId}`;
        return axios
            .get(url, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/hal+json' }
            })
            .then(response => {
                return this.formSafetyModelsPagePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to get paged safety models: ' + getErrorMessage(err));
            })
    }

    getSafetyModels() : Promise<any> {
        return axios
            .get(SafetyService.safetyModelUrlV2, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 }
            })
            .then(response => {
                return response.data._embedded ? this.formSafetyModels(response.data._embedded.safetyModelDTOList) : [];
            })
            .catch(err => {
                throw new Error('Failed to get safety models: ' + getErrorMessage(err));
            })
    }


    getSafetyModelById(id: number): Promise<SafetyModel> {
        return axios
            .get(SafetyService.safetyModelUrlV2 + '/' + id, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 },
            })
            .then(response => {
                return this.formSafetyModel(response.data);
            })
            .catch(err => {
                throw new Error(`Get safetyModel ${id} returned an error: ` + getErrorMessage(err));
            })
    }

    deleteSafetyModelById(id: number): Promise<void> {
        return axios
            .delete(SafetyService.safetyModelUrlV2 + '/' + id, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 },
            })
            .then(() => {
                return;
            })
            .catch(err => {
                throw new Error(`Delete safetyModel ${id} failed with error: ` + getErrorMessage(err));
            })
    }

    duplicateSafetyModel(sourceResourceId: number | string, targetGroupId: number | string | undefined): Promise<any> {
        const data = targetGroupId !== undefined ? {
            sourceResourceId: sourceResourceId,
            targetGroupId: targetGroupId
        } : {
            sourceResourceId: sourceResourceId
        };
            
        return axios.post(SafetyService.safetyModelDuplicateUrl, data , {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then((response: any) => {
            return response;
        }).catch((error: any) => {
            throw new Error(`Duplicate safety model ${sourceResourceId} failed with error: ` + getErrorMessage(error));
        });
    }


    createSafetyModel(safetyModel: SafetyModel): Promise<any> {
        return axios
            .post(SafetyService.safetyModelUrlV2, safetyModel, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 },
            })
            .then(response => {
                return this.formSafetyModel(response.data);
            })
            .catch(err => {
                throw new Error('Create safetyModel failed with error: ' + getErrorMessage(err));
            })
    }


    /* path returns an empty body, so we need to get the safety model again */
    patchSafetyModel(safetyModel: SafetyModel): Promise<any> {
        return axios
            .patch(SafetyService.safetyModelUrlV2 , safetyModel, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 },
            })
            .then(() => {
                return this.getSafetyModelById(safetyModel.id);
            })
            .catch(err => {
                throw new Error(`Patch safetyModel ${safetyModel.id} failed with error: ` + getErrorMessage(err));
            })
    }

}

export default new SafetyService();
