import {Box, ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import OkCancelModal from './OkCancelModal.component';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

import React, {ReactElement, useEffect } from 'react';
import {singularizeCamelCase} from '../../utils/utils';
import Checkbox from '@mui/material/Checkbox';
import {SelectedWorkspaceObject} from '../../@types/workspace-types';
import {ResourceType} from '../../@types/resource-type';

export interface SanitizeModalProps {
    open: boolean;
    title: string;
    object: SelectedWorkspaceObject | null,
    onClose: () => void;
    onConfirm: (sanitizeQtt: boolean, sanitizeODna: boolean) => void;
}
export default function SanitizeModal(props: SanitizeModalProps): ReactElement {
    const { open, title, object, onConfirm, onClose } = props;
    const [sanitizeQuantification, setSanitizeQuantification] = React.useState<boolean>(false);
    const [sanitizeOccurrenceDna, setSanitizeOccurrenceDna] = React.useState<boolean>(false);

    useEffect(() => {
        if (open) {
            setSanitizeQuantification(false);
            setSanitizeOccurrenceDna(false);
        }
    }, [open]);

    const handleSanitizeOptionChange = (index: number) => (): void => {
        if (index === 1) {
            setSanitizeQuantification(!sanitizeQuantification);
        } else if (index === 2) {
            setSanitizeOccurrenceDna(!sanitizeOccurrenceDna);
        }
    };

    const resourceType = (type: ResourceType): string => {
        return singularizeCamelCase(type).toLowerCase();
    }

    return (
        <OkCancelModal
            open={open}
            title={title}
            okButtonText='Sanitize'
            onClose={onClose}
            onConfirm={(): void => { onConfirm(sanitizeQuantification, sanitizeOccurrenceDna); onClose(); }}
            okToConfirm={(): boolean => { return sanitizeQuantification || sanitizeOccurrenceDna}}
        >
            <DialogContent>
                <DialogContentText>
                    Sanitizing {resourceType(ResourceType.EVENT_SEQUENCES)} {object?.object.name} will:
                </DialogContentText>
                
                <Box component="ul"
                    style={{
                        listStyleType: 'upper-roman',
                        fontFamily: 'Roboto, Helvetica',
                        fontSize: '0.9rem',
                        fontWeight: 400,
                        lineHeight: 1.4,
                        color: 'rgba(0, 0, 0, 0.6)',
                        paddingLeft: '1.5rem',
                    }}
                >
                    <Box component="li">Set all event and fault tree node frequencies and probabilities to 0,</Box> 
                    <Box component="li">Set the pedigrees of the events and fault tree nodes to NOT_QUANTIFIED,</Box>
                    <Box component="li">Unlink all occurrences connected to the events and fault tree nodes in the {resourceType(ResourceType.EVENT_SEQUENCES)}.</Box>
                </Box>
                <br />
                <DialogContentText>
                    Please choose which elements of the {resourceType(ResourceType.EVENT_SEQUENCES)} to sanitize:
                </DialogContentText>
                
                <ListItem
                    style={{
                        fontFamily: 'Roboto, Helvetica',
                        fontSize: '0.8rem',
                        fontWeight: 400,
                        lineHeight: 1.4,
                        color: 'rgba(0, 0, 0, 0.6)',
                        paddingLeft: '0.2rem',
                    }}
                    
                    dense>
                    <ListItemButton role={undefined} onClick={handleSanitizeOptionChange(1)} dense>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={sanitizeQuantification}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText id='1' primary='Sanitize Quantification' />
                    </ListItemButton>
                    <ListItemButton role={undefined} onClick={handleSanitizeOptionChange(2)} dense>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={sanitizeOccurrenceDna}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <ListItemText id='2' primary='Sanitize Occurrence DNA' />
                    </ListItemButton>
                </ListItem>
                <br/>
                <DialogContentText color='red'>
                    Note: This action cannot be undone.
                </DialogContentText>
            </DialogContent>
        </OkCancelModal>
    );
}
