import DeleteIcon from '@mui/icons-material/Delete';

import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress  from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import React, { ReactElement, useEffect } from 'react';

import {  uuid } from '../../../../utils/utils';
import { toast } from 'react-toastify';
import { useWorkspaceContext } from '../../../../context/workspaceContext';
import { CanvasEnvironment } from '../../../../utils/canvas/canvas-environment';
import { ResourceType } from '../../../../@types/resource-type';
import EventSequenceService from '../../../../services/event-sequence.service';
import * as CanvasActions from '../../../../utils/canvas/canvas-actions';




function PaperComponent(props: PaperProps): ReactElement {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}

export default function DeleteFaultTreeNodeModal(props: { open: boolean, env: CanvasEnvironment | null, onClose: () => void }): ReactElement {
    const { open, env, onClose } = props;
    const [uniqueId, setUniqueId] = React.useState<string>('');    
    const { setCurrentWorkspaceObject } = useWorkspaceContext();
    
    const [isRunning, setIsRunning] = React.useState<boolean>(false);
    const uuidRef = React.useRef(uuid());


    useEffect(() => {
        if (open) {
            const [selectedNode] = env!.selection
            setUniqueId(selectedNode.get('data').uniqueId);
            setIsRunning(false);      
        }
    }, [open]);

    const handleSave = async (): Promise<any> => {
        const [selectedNode] = env!.selection
        return EventSequenceService.deleteFaultTreeNode(selectedNode.get('data').id);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            fullWidth
            maxWidth="sm">
            <DialogTitle style={{ cursor: 'move'}} id='draggable-dialog-title'>
                {'Delete Fault Tree Node ' + `${uniqueId}`}
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText id={`fault-tree-delete-description-${uuidRef.current}`}>
                    Are you sure you want to delete this fault tree node?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={isRunning ? <CircularProgress size={20} /> : <DeleteIcon />}
                    onClick={async (): Promise<any> => {
                        setIsRunning(true);
                        try {
                            const response = await handleSave();
                            env!.isDirty = true;
                            setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                        } catch (error: any) {
                            toast.error(error.message);
                        } finally {
                            setIsRunning(false);
                            onClose();
                        }                                    
                    }}
                    disabled={!CanvasActions.canAddOrEditResource(env) || isRunning}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    )
   
}