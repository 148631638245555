import * as React from 'react';
import Typography from '@mui/material/Typography';
import Group from '../../../@types/group';
import AreYouSureModal from '../../form-components/AreYouSureModal.component';

export default function LeaveGroupModal(props: {
    leaveGroup: boolean;
    group: Group,
    onClose: () => void;
    onConfirm: () => void;
    
}): React.ReactElement {
   
    const { leaveGroup, group, onConfirm, onClose } = props;

    return (
        <AreYouSureModal
            open={leaveGroup}
            title='Leave Group'
            onConfirm={onConfirm}
            onClose={onClose}
        >
            <Typography variant='body1'>
                Are you sure you want to leave group {group.name}?
            </Typography>
        </AreYouSureModal>
    );
}


    