import React, {useContext} from 'react';
import { useOktaAuth } from '@okta/okta-react';
import ErrorAlertComponent from '../components/ErrorAlert.component';
import {UserContext} from '../context/userContext';
import {useNavigate} from 'react-router-dom';
import AuthService from '../services/auth.service';
import {Token} from '@okta/okta-auth-js';

interface LoginCallbackProps {
  errorComponent?: React.ComponentType<{ error: Error }>;
  loadingElement?: React.ReactElement;
}

let handledRedirect = false;

const LoginCallback: React.FC<LoginCallbackProps> = ({ loadingElement = null }) => {
    const { oktaAuth, authState } = useOktaAuth();
    const [callbackError, setCallbackError] = React.useState(null);
    const { signinUser } = useContext(UserContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!handledRedirect) {
            handleOktaRedirect().then(() => {
                oktaAuth.tokenManager.get('accessToken').then((token: Token | undefined) => {
                    if (token) {
                        oktaAuth.tokenManager.get('refreshToken').then((refreshToken: Token | undefined) => {
                            oktaAuth.tokenManager.get('idToken').then((idToken: Token | undefined) => {
                                AuthService.signinWithTokens({
                                    // @ts-ignore
                                    accessToken: token.accessToken,
                                    // @ts-ignore
                                    idToken: idToken.idToken,
                                    // @ts-ignore
                                    refreshToken: refreshToken.refreshToken
                                }).then((response) => {
                                    signinUser(response.data);
                                    oktaAuth.tokenManager.clear();
                                    navigate('/react');
                                });
                            }).catch((e: any) => {
                                setCallbackError(e);
                            })
                        });
                    } else {
                        // eslint-disable-next-line no-console
                        console.log('oktaAuth is ', oktaAuth);
                        alert('Unable to retrieve tokens from Okta. Please try again');
                    }
                }).catch((e: any) => {
                    setCallbackError(e);
                });
                handledRedirect = true;
            });
        }}, [oktaAuth]);

    async function handleOktaRedirect(): Promise<void> {
        if (!handledRedirect) {
            await oktaAuth.handleRedirect().catch((e: any) => {
                setCallbackError(e);
            });
            handledRedirect = true;
        }
    }

    const authError = authState?.error;
    const displayError = callbackError || authError;
    if (displayError) {
        return <ErrorAlertComponent message={displayError?.message} />;
    }

    return loadingElement;
};

export default LoginCallback;