/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import * as joint from '@joint/plus';
import * as canvasActions from './canvas-actions';
import * as canvasUtils from './node-utils';
import EventSequenceService from '../../services/event-sequence.service';
import { CanvasEnvironment } from './canvas-environment';
import { CalloutPresentation } from '../../@types/callout';
import { setCalloutOpenForm } from '../isam-nodes'

export abstract class Controller extends joint.mvc.Listener<[CanvasEnvironment]> {

    constructor(public readonly service: CanvasEnvironment) {
        super(service);
        this.startListening();
    }

    abstract startListening(): void;
}

export class CanvasController extends Controller {

    startListening() {
        const { paper } = this.service;
        this.listenTo(paper, {
            'element:contextmenu': (service: CanvasEnvironment, elementView: joint.dia.ElementView, evt: joint.dia.Event) => {
                evt.preventDefault();
                evt.stopPropagation();
                if (elementView.model.get('nodeClass') === 'CALLOUTNODE') return;
                canvasActions.setSelection(service, [elementView.model]);
                service.contextMenuCallback(evt, elementView);
            },
            'element:pointerdown': (service, elementView) => {
                if (elementView.model.get('nodeClass') === 'CALLOUTNODE') return;
                canvasActions.setSelection(service, [elementView.model]);
            },
            'element:pointerup': (service, elementView) => {
                if (elementView.model.get('nodeClass') !== 'CALLOUTNODE') return;
                let [parent] = service.graph.getNeighbors(elementView.model, { inbound: true });
                
                if (parent !== undefined) {
                    let x_f = (elementView.model.position().x - parent.position().x) / parent.size().width;
                    let y_f = (elementView.model.position().y - parent.position().y) / parent.size().height;
                    
                    let callout = parent.get('data').callout;
                    if (callout) {
                        let calloutPresentation = JSON.parse(callout.presentation) as CalloutPresentation;
                        calloutPresentation.x_factor = x_f;
                        calloutPresentation.y_factor = y_f;
                        let updateCallout = {
                            id: parent.get('data').id,
                            uniqueId: parent.get('data').uniqueId,
                            name: parent.get('data').name,
                            callout: {
                                content: callout.content,
                                presentation: JSON.stringify(calloutPresentation)
                            }
                            
                        }
                        parent.get('data').callout.presentation = JSON.stringify(calloutPresentation);
                        return canvasUtils.isEvent(parent.get('data').type) ? EventSequenceService.updateEvent(updateCallout, false) : EventSequenceService.updateFaultTreeNode(updateCallout, false);
                    }
                }
                
                
            },
            'element:mouseenter': (service, elementView) => {
                if (elementView.model.get('nodeClass') === 'CALLOUTNODE') return;
                service.HighlighterFrame.add(elementView, 'body', 'frame', { padding: 10 });
            },
            'element:mouseleave': (service, elementView) => {
                if (elementView.model.get('nodeClass') === 'CALLOUTNODE') return;
                service.HighlighterFrame.remove(elementView, 'frame');
            },
            'blank:mousewheel': (service, evt, ox, oy, delta) => {
                const direction = delta < 0 ? -1 : 1;
                evt.preventDefault();
                evt.stopPropagation();
                service.paperScroller.zoom(direction*0.01, {  min:0.1, max: 4, grid: 0.01, ox, oy })
            },
            'blank:pointerdown': (service, evt) => {
                service.paperScroller.startPanning(evt);
            },
            'element:expand': (service, elementView: joint.dia.ElementView, _evt: joint.dia.Event, buttonView: joint.elementTools.Button) => {
                const element = elementView.model;
                if (elementView.model.get('nodeClass') === 'CALLOUTNODE') return;
                let { activeTopNode, graph, paper, paperScroller} = service;
                if (element.get('nodeClass') !== 'FAULTTREENODE') {
                    // collapse the previous ESD event fault tree branch if it is expanded
                    if (activeTopNode && activeTopNode.get('data') && activeTopNode.get('collapsed') === false) {
                        const [topFaultTreeNode] = graph.getNeighbors(activeTopNode).filter((cell) => cell.get('nodeClass') === 'FAULTTREENODE');
                        const faultTreeBranch = graph.getSubgraph([activeTopNode, topFaultTreeNode, ...graph.getSuccessors(topFaultTreeNode)]); 
                        
                        paper.freeze();
                        graph.removeCells(faultTreeBranch.slice(1));
                        canvasActions.setSelection(service, []);
                        paper.unfreeze();
                        
                        activeTopNode.set('collapsed', true);
                        if (activeTopNode === element) {
                            service.activeTopNode = null;
                            canvasActions.resizePaper(paper);
                            buttonView.update();
                            return;
                        }
                    }
                    service.activeTopNode = element;
                    const [faultTreeNode] = graph.getNeighbors(element).filter((cell) => cell.get('nodeClass') === 'FAULTTREENODE');
                    if (!faultTreeNode) {
                        let viewportOverlap = 50;
                        let viewportRect: any = paperScroller.getVisibleArea().inflate(viewportOverlap);
                        canvasActions.createFtGraphAndExpand(service, element, graph, paper, paperScroller, viewportRect);  
                        return;
                    }
                }
                let viewportOverlap = 50;
                let viewportRect: any = paperScroller.getVisibleArea().inflate(viewportOverlap);

                if (element.get('nodeClass') === 'FAULTTREENODE' && service.isCalloutsModeActive) {
                    graph.getElements().filter((cell) => cell.get('nodeClass') === 'CALLOUTNODE').forEach((callout) => {
                        callout.remove();
                    });
                }

                canvasActions.toggleBranch(element, graph, paper, paperScroller, viewportRect);

                if (element.get('nodeClass') === 'FAULTTREENODE' && service.isCalloutsModeActive) {
                    graph.getElements().forEach((el) => {
                        setCalloutOpenForm(graph, paper, el);
                    });
                }
            }

        })
    }
}
