import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import React, {ReactElement} from 'react';

export interface AreYouSureModalProps {
    open: boolean;
    title: string;
    children: React.ReactNode;
    onClose: () => void;
    onConfirm: () => void;
}
export default function AreYouSureModal(props: AreYouSureModalProps): ReactElement {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const { open, title, children, onConfirm, onClose } = props;

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            <DialogActions>
                <Button id='cancel' size='small' variant='outlined'  onClick={onClose}>No, Cancel</Button>
                <Button id='confirm' size='small' variant='outlined' color='error' onClick={onConfirm}>Yes</Button>
            </DialogActions>

        </Dialog>
    );
}
