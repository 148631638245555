import React, {ReactElement, useContext} from 'react';
import HeaderToolbarComponent from './HeaderToolbar.component';
import {BASE_PATH} from '../config/constants';
import {UserContext} from '../context/userContext';


export default function HeaderComponent(): ReactElement {

    const { user } = useContext(UserContext);

    return (
        <div>

            {/*top blue bar*/}
            <div className='devkit-top-bar'>
                <span className='devkit-top-bar-text'>My<span className='devkit-faa-text'>FAA</span></span>
            </div>

            {/*Main header section*/}
            <header>

                {/*the mobile menu*/}
                <div className='devkit-mobile-menu'>
                    <div className='devkit-icon-wrapper'>
                        <span className='fa fa-bars' aria-hidden='true' title='Menu' /><span className='devkit-menu-text' />
                    </div>
                </div>

                <div className='devkit-menu-overlay'>
                    <div className='devkit-mobile-menu'>
                        <div className='devkit-icon-wrapper'>
                            <span className='fa fa-bars' aria-hidden='true' title='Menu' /><span className='devkit-menu-text' />
                        </div>
                    </div>
                </div>

                {/*Application title*/}
                <div className='devkit-app-title'>
                    <a href={BASE_PATH + '/workspace'}>Integrated Safety Assessment Model</a>
                </div>

                {/*User Greeting OR login link*/}
                <div className='devkit-greeting-wrapper' id='devkit-greeting-wrapper'>
                    <p>
                        <span className='devkit-greeting'>Welcome, </span>
                        <span className='devkit-greeting-user'>
                            {user ? user.firstName + ' ' + user.lastName : 'Guest'}
                        </span>
                    </p>
                </div>

                {/*Toolbar*/}
                <HeaderToolbarComponent />

            </header>
        </div>
    );

}