import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Workspace from '../components/workspace/Workspace.component';
import AuthService from '../services/auth.service';
import 'split-pane-react/esm/themes/default.css';

export default function WorkspacePage(): ReactElement {
    const navigate = useNavigate();

    useEffect(() => {
        // If the user is not logged in, redirect them to the home page.
        if (!AuthService.getCurrentUser()) {
            navigate('/react/signin');
        }   
    }, []);

    return (
        <Workspace />
    );
    
}