import authService from '../../../services/auth.service';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import Chip from '@mui/material/Chip';
import CircularProgress  from '@mui/material/CircularProgress';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ErrorAlertComponent from '../../../components/ErrorAlert.component';
import FormGroup from '@mui/material/FormGroup';
import React, { ReactElement } from 'react';

import Stack from '@mui/material/Stack';
import SystemInfoService  from '../../../services/system-info.service';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { uuid } from '../../../utils/utils';
import { toast } from 'react-toastify';

export default function SystemSettingModal(props: { open: boolean, onClose: () => void }): ReactElement {
    const { open, onClose } = props;
    const [systemInfoName, setSystemInfoName] = React.useState<string>('');
    const [systemInfoVersion, setSystemInfoVersion] = React.useState<string>('');
    const [systemInfoDescription, setSystemInfoDescription] = React.useState<string>('');
    const [systemInfoReleaseNote, setSystemInfoReleaseNote] = React.useState<string>('');
    const [projectManagerName, setProjectManagerName] = React.useState<string>('');
    const [projectManagerEmail, setProjectManagerEmail] = React.useState<string>('');
    const [supportContactName, setSupportContactName] = React.useState<string>('');
    const [supportContactEmail, setSupportContactEmail] = React.useState<string>('');
    const [trainingMaterialLink, setTrainingMaterialLink] = React.useState<string>('');
    const [swaggerTitle, setSwaggerTitle] = React.useState<string>('');
    const [swaggerDescription, setSwaggerDescription] = React.useState<string>('');
    const [technicalContactName, setTechnicalContactName] = React.useState<string>('');
    const [technicalContactEmail, setTechnicalContactEmail] = React.useState<string>('');
    const [termsOfServiceLink, setTermsOfServiceLink] = React.useState<string>('');
    const [isRunnig, setIsRunning] = React.useState<boolean>(false);

    
    const [systemInfoId, setSystemInfoId] = React.useState<number>(0);
    const [supportInfoId, setSupportInfoId] = React.useState<number>(0);
    const [swaggerInfoId, setSwaggerInfoId] = React.useState<number>(0);

    const [error, setError] = React.useState<string | null>(null);

    const uuidRef = React.useRef(uuid());

    const onSystemInfoNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setSystemInfoName(event.target.value);
    };

    const onSystemInfoVersionChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setSystemInfoVersion(event.target.value);
    };

    const onSystemInfoDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setSystemInfoDescription(event.target.value);
    };

    const onSystemInfoReleaseNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setSystemInfoReleaseNote(event.target.value);
    };

    const onProjectManagerNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setProjectManagerName(event.target.value);
    };

    const onProjectManagerEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setProjectManagerEmail(event.target.value);
    };

    const onSupportContactNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setSupportContactName(event.target.value);
    };

    const onSupportContactEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setSupportContactEmail(event.target.value);
    };

    const onTrainingMaterialLinkChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setTrainingMaterialLink(event.target.value);
    };

    const onSwaggerTitleChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setSwaggerTitle(event.target.value);
    };

    const onSwaggerDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setSwaggerDescription(event.target.value);
    };

    const onTechnicalContactNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setTechnicalContactName(event.target.value);
    };

    const onTechnicalContactEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setTechnicalContactEmail(event.target.value);
    };

    const onTermsOfServiceLinkChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setTermsOfServiceLink(event.target.value);
    };

    const onClickSaveSettings = (_event: React.SyntheticEvent): void => {
        const systemInfo = {
            id: systemInfoId,
            name: systemInfoName,
            version: systemInfoVersion,
            description: systemInfoDescription,
            releaseNotes: systemInfoReleaseNote,
        };
        const supportInfo = {
            id: supportInfoId,
            projectManagerName: projectManagerName,
            projectManagerEmail: projectManagerEmail,
            supportPocName: supportContactName,
            supportPocEmail: supportContactEmail,
            trainingMaterialUrl: trainingMaterialLink,
        };
        const swaggerInfo = {
            id: swaggerInfoId,
            title: swaggerTitle,
            description: swaggerDescription,
            contactName: technicalContactName,
            contactEmail: technicalContactEmail,
            termsOfServiceUrl: termsOfServiceLink,
        };
        setIsRunning(true);
        SystemInfoService.saveSystemSettings(systemInfo, supportInfo, swaggerInfo).then(() => {
            setError(null);
            toast.success('System settings saved successfully');
        }).catch((err) => {
            toast.error('System settings failed to save - ' + err.message);
            setError(err.message);
        }).finally(() => {
            setIsRunning(false);
        });
    }

    React.useEffect(() => {
        setIsRunning(true);
        SystemInfoService.getSystemSettings().then((all) => {
            setSystemInfoId(all.systemInfo.id);
            setSystemInfoName(all.systemInfo.name);
            setSystemInfoVersion(all.systemInfo.version);
            setSystemInfoDescription(all.systemInfo.description);
            setSystemInfoReleaseNote(all.systemInfo.releaseNotes);

            setSupportInfoId(all.supportInfo.id);
            setProjectManagerName(all.supportInfo.projectManagerName);
            setProjectManagerEmail(all.supportInfo.projectManagerEmail);
            setSupportContactName(all.supportInfo.supportPocName);
            setSupportContactEmail(all.supportInfo.supportPocEmail);
            setTrainingMaterialLink(all.supportInfo.trainingMaterialUrl);

            setSwaggerInfoId(all.swaggerInfo.id);
            setSwaggerTitle(all.swaggerInfo.title);
            setSwaggerDescription(all.swaggerInfo.description);
            setTechnicalContactName(all.swaggerInfo.contactName);
            setTechnicalContactEmail(all.swaggerInfo.contactEmail);
            setTermsOfServiceLink(all.swaggerInfo.termsOfServiceUrl);
        }).catch((err) => {
            setError(err.message);
        }).finally(() => {
            setIsRunning(false);
        });
    }, []);


    
    return (
        <Dialog open={open} onClose={onClose} fullScreen>
            <DialogTitle>
                <Chip icon={<ArrowCircleLeftIcon />} size='small' color='primary' variant='outlined' label='Back to Workspace' onClick={onClose} />
                {isRunnig && <Stack sx={{ width: '100%', color: 'grey.500', justifyContent: 'center' }} spacing={2} direction='row'>
                    <CircularProgress color='inherit' />
                    <CircularProgress color='inherit' />
                    <CircularProgress color='inherit' />                    
                </Stack>
                }
            </DialogTitle>
            <DialogContent dividers>

                <FormGroup className='formStyle' sx={{ width: '100%' }}>
                    <Stack className='formControl' sx={{ width: '90%'}}>
                        <Typography variant='h2' color='primary' gutterBottom>
                            System Information
                        </Typography>
                        <Typography variant='body1' gutterBottom>
                            System information is shown on the About pages and it gives users information about the system name,
                            provides a description of the platform, identifies the version number of the system release, and
                            provides release notes about the release contents.
                        </Typography>
                        <TextField
                            autoFocus
                            margin='dense'
                            id={`system-info-name-${uuidRef.current}`}
                            label='System Name'
                            variant='outlined'
                            size='small'
                            value={systemInfoName}
                            onChange={onSystemInfoNameChange}
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `system-info-name-${uuidRef.current}`,
                                'aria-label': 'system name'
                            }}

                            fullWidth
                        />
                        <hr />
                        <TextField
                            margin='dense'
                            id={`system-info-Version-${uuidRef.current}`}
                            label='Version'
                            variant='outlined'
                            size='small'
                            value={systemInfoVersion}
                            onChange={onSystemInfoVersionChange}
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `system-info-version-${uuidRef.current}`,
                                'aria-label': 'system version'
                            }}

                            fullWidth
                        />
                        <hr />
                        <TextField
                            margin='dense'
                            id={`system-info-description-${uuidRef.current}`}
                            label='System Description'
                            variant='outlined'
                            size='small'
                            value={systemInfoDescription}
                            onChange={onSystemInfoDescriptionChange}
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `system-info-description-${uuidRef.current}`,
                                'aria-label': 'system description'
                            }}

                            fullWidth
                        />
                        <hr />
                        <TextField
                            margin='dense'
                            id={`system-info-release-note-${uuidRef.current}`}
                            label='Release Notes'
                            variant='outlined'
                            size='small'
                            value={systemInfoReleaseNote}
                            onChange={onSystemInfoReleaseNoteChange}
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `system-info-release-note-${uuidRef.current}`,
                                'aria-label': 'release notes'
                            }}

                            fullWidth
                        />
                    </Stack>
                
                </FormGroup>
                    
                <Stack direction='row'>
                    <FormGroup className='formStyle'>
                        <Stack className='formControl'>
                            <Typography variant='h2' color='primary' gutterBottom>
                                Support Info
                            </Typography>
                            <Typography variant='body1' gutterBottom>
                                Support information is shown on the about pages, and also will be used in issue reporting so that questions
                                get routed to the appropriate point of contact.
                            </Typography>
                            <Typography variant='h3' color='secondary' gutterBottom>
                                Project Manager
                            </Typography>
                            <Typography variant='body1' gutterBottom>
                                This should be the person who should be contacted with any questions relating to
                                the wider project management.
                            </Typography>
                            <TextField
                                margin='dense'
                                id={`system-info-project-manager-name-${uuidRef.current}`}
                                label='Name'
                                variant='outlined'
                                size='small'
                                value={projectManagerName}
                                onChange={onProjectManagerNameChange}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `system-info-project-manager-name-${uuidRef.current}`,
                                    'aria-label': 'project manager name'
                                }}

                                fullWidth
                            />
                            <hr />
                            <TextField
                                margin='dense'
                                id={`system-info-project-manager-email-${uuidRef.current}`}
                                label='Email Address'
                                variant='outlined'
                                size='small'
                                value={projectManagerEmail}
                                onChange={onProjectManagerEmailChange}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `system-info-project-manager-email-${uuidRef.current}`,
                                    'aria-label': 'project manager email'
                                }}

                                fullWidth
                            />
                            <Typography variant='h3' color='secondary' gutterBottom>
                                Support Point of Contact
                            </Typography>
                            <Typography variant='body1' gutterBottom>
                                The support technical point of contact should be a person who can answer usage/deployment questions about
                                the platform.
                            </Typography>
                            <TextField
                                margin='dense'
                                id={`system-info-support-contact-name-${uuidRef.current}`}
                                label='Name'
                                variant='outlined'
                                size='small'
                                value={supportContactName}
                                onChange={onSupportContactNameChange}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `system-info-support-contact-name-${uuidRef.current}`,
                                    'aria-label': 'support contact name'
                                }}

                                fullWidth
                            />
                            <hr />
                            <TextField
                                margin='dense'
                                id={`system-info-support-contact-email-${uuidRef.current}`}
                                label='Email Address'
                                variant='outlined'
                                size='small'
                                value={supportContactEmail}
                                onChange={onSupportContactEmailChange}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `system-info-support-contact-email-${uuidRef.current}`,
                                    'aria-label': 'project manager email'
                                }}

                                fullWidth
                            />
                            <Typography variant='h3' color='secondary' gutterBottom>
                                Training Material & Documentation
                            </Typography>
                            <Typography variant='body1' gutterBottom>
                                This link should direct users to the index page for supporting documentation that help users better
                                understand how to use the system.
                            </Typography>
                            <TextField
                                margin='dense'
                                id={`system-info-training-material-link-${uuidRef.current}`}
                                label='Link to Training Material'
                                variant='outlined'
                                size='small'
                                value={trainingMaterialLink}
                                onChange={onTrainingMaterialLinkChange}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `system-info-training-material-link-${uuidRef.current}`,
                                    'aria-label': 'training material link'
                                }}

                                fullWidth
                            />

                        </Stack>
                    </FormGroup>
                
                    <FormGroup className='formStyle'>
                        <Stack className='formControl'>
                            <Typography variant='h2'  color='primary' gutterBottom>
                                Swagger Info
                            </Typography>
                            <Typography variant='body1' gutterBottom>
                                Support information is used on the Swagger UI. This provides an up-to-date description
                                of the current platform API for use by third party clients.
                            </Typography>
                            <Typography variant='h3' color='secondary' gutterBottom>
                                API Documentation Title & Description
                            </Typography>
                            <Typography variant='body1' gutterBottom>
                                This information is shown at the top of the API documentation and can be used to outlined
                                the purpose of the Swagger documentation.
                            </Typography>
                            <TextField
                                margin='dense'
                                id={`system-info-swagger-title-${uuidRef.current}`}
                                label='Title'
                                variant='outlined'
                                size='small'
                                value={swaggerTitle}
                                onChange={onSwaggerTitleChange}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `system-info-swagger-title-${uuidRef.current}`,
                                    'aria-label': 'Swagger title'
                                }}

                                fullWidth
                            />
                            <hr />
                            <TextField
                                margin='dense'
                                id={`system-info-swagger-description-${uuidRef.current}`}
                                label='Description'
                                variant='outlined'
                                size='small'
                                value={swaggerDescription}
                                onChange={onSwaggerDescriptionChange}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `system-info-swagger-description-${uuidRef.current}`,
                                    'aria-label': 'Swagger description'
                                }}

                                fullWidth
                            />
                            <Typography variant='h3' color='secondary' gutterBottom>
                                Technical Point of Contact
                            </Typography>
                            <Typography variant='body1' gutterBottom>
                                This individual should be the primary point of contact for third party developers who want
                                to use the platform API to integrate an external system.
                            </Typography>
                            <TextField
                                margin='dense'
                                id={`system-info-technical-contact-name-${uuidRef.current}`}
                                label='Name'
                                variant='outlined'
                                size='small'
                                value={technicalContactName}
                                onChange={onTechnicalContactNameChange}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `system-info-technical-contact-name-${uuidRef.current}`,
                                    'aria-label': 'technical contact name'
                                }}

                                fullWidth
                            />
                            <hr />
                            <TextField
                                margin='dense'
                                id={`system-info-technical-contact-email-${uuidRef.current}`}
                                label='Email Address'
                                variant='outlined'
                                size='small'
                                value={technicalContactEmail}
                                onChange={onTechnicalContactEmailChange}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `system-info-technical-contact-email-${uuidRef.current}`,
                                    'aria-label': 'technical contact email'
                                }}

                                fullWidth
                            />
                            <Typography variant='h3' color='secondary' gutterBottom>
                                Terms of Service
                            </Typography>
                            <Typography variant='body1' gutterBottom>
                                A link to any terms of service or disclaimers that are applied to the usage of the API and
                                all data stored in the platform by external clients.
                            </Typography>
                            <TextField
                                margin='dense'
                                id={`system-info-terms-service-link-${uuidRef.current}`}
                                label='Link to Terms of Service'
                                variant='outlined'
                                size='small'
                                value={termsOfServiceLink}
                                onChange={onTermsOfServiceLinkChange}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `system-info-terms-service-link-${uuidRef.current}`,
                                    'aria-label': 'terms of service link'
                                }}

                                fullWidth
                            />

                        </Stack>
                            
                    </FormGroup>
                </Stack>
                {error ? <ErrorAlertComponent message={error} />  : null}

            </DialogContent>
            {authService.isSuperUser() &&
                <DialogActions sx={{ justifyContent: 'space-between', width: '60%', margin: 'auto' }}>
                    <Button id={`cancel-system-settings-${uuidRef.current}`}
                        size='small' variant='outlined'
                        aria-label='cancel system settings'
                        title='Cancel'
                        startIcon={<CancelIcon />}
                        onClick={onClose}
                        sx={{ textTransform: 'none'}}
                    >
                        Cancel
                    </Button>
                    <Button id={`save-system-settings-${uuidRef.current}`}
                        size='small' variant='contained'
                        aria-label='save system settings'
                        title='Save Settings'
                        onClick={onClickSaveSettings}
                        startIcon={<SaveAltIcon />}
                        sx={{ textTransform: 'none'}}
                    >
                        Save Settings
                    </Button>
                </DialogActions>
            }
        </Dialog>
    );
}