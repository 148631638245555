import axios from 'axios';
import { API_URL } from '../config/constants';


class OccurrenceCategoryService {
    static occurrenceCategoriesUrl = API_URL + 'safety/occurrenceCategories';
    

    getOccurrenceCategories(): Promise<any> {
        return axios.get(OccurrenceCategoryService.occurrenceCategoriesUrl, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(err => {
            throw new Error('Failed to get occurrence categories: ' + err);
        })
    }
}

export default new OccurrenceCategoryService();