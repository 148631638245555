import React, {FC, MouseEventHandler, ReactElement, useContext, useEffect, useState} from 'react';
import PageTitle from '../components/PageTitle.component';
import TextInputComponent from '../components/form-components/TextInput.component';
import PageContentWrapper from '../components/PageContentWrapper.component';
import {TextInputType} from '../@types/text-input-types';
import FormWrapper from '../components/form-components/FormWrapper';
import ButtonComponent from '../components/form-components/Button.component';
import {UserContext} from '../context/userContext';
import {useNavigate} from 'react-router-dom';
import AuthService from '../services/auth.service';
import ErrorAlertComponent from '../components/ErrorAlert.component';
import {AxiosError} from 'axios';
import {BASE_PATH} from '../config/constants';
import OktaAuth from '@okta/okta-auth-js';

const Signin: FC<any> = (): ReactElement => {

    const { signinUser, oktaAuthOptions } = useContext(UserContext);
    const navigate = useNavigate();

    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [error, setError] = useState<string|null>(null);

    // Occurs ONCE, AFTER the initial render.
    useEffect(() => {
        // If the user is already logged in, redirect them to the home page.
        if (AuthService.getCurrentUser()) {
            navigate('/react/workspace');
        }
        AuthService.fetchSystemInfo().then((response) => {
            if (response.data.eimMode && oktaAuthOptions) {
                const oktaAuth = new OktaAuth(oktaAuthOptions);
                oktaAuth.setOriginalUri('/isam/react/workspace');
                oktaAuth.token.getWithRedirect().then(
                    () => {}
                ).catch((e: any) => {
                    setError('Unable to retrieve tokens from Okta. Please try again. ' + e);
                });
            }
        });
    }, []);

    function onClickLogin(_ignored: MouseEventHandler<HTMLButtonElement>): void {
        if (username && password) {
            setError(null);
            AuthService.signin(username, password)
                .then((response) => {
                    signinUser(response.data);
                    navigate('/react/workspace');
                })
                .catch((err: AxiosError) => {
                    if (err.response && err.response.status === 401) {
                        setError('Invalid username or password. Please try again.');
                    } else {
                        setError('An unexpected error occurred. Please try again later.');
                    }
                });
        } else {
            setError('Username and password are required');
        }
    }

    return (
        <>
            <PageTitle title={'Sign in'} />

            <PageContentWrapper>
                <FormWrapper width={400} align={'center'}>
                    <TextInputComponent id={'username'} label={'Username'} type={TextInputType.email} value={username}
                        onChange={setUsername} required={true} />
                    <TextInputComponent id={'password'} label={'Password'} type={TextInputType.password}
                        value={password} onChange={setPassword} required={true} />
                    <div style={{margin: '5px 5px 5px', padding: 5, paddingTop: 0}}>
                        <span className='devkit-nav-link-nosub'>Forgot password? &nbsp;
                            <a className='devkit-main-menu-link' href={BASE_PATH + '/forgot-password'}>
                                Reset Password
                            </a>
                        </span>
                    </div>
                    <ButtonComponent id={'submit-login'} label={'Sign in'} size={'large'} onClick={onClickLogin} />
                    {error ? <ErrorAlertComponent message={error} />  : null}
                </FormWrapper>
            </PageContentWrapper>
        </>
    );

};

export default Signin;
