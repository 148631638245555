import SaveAltIcon from '@mui/icons-material/SaveAlt';

import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress  from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import React, { ReactElement, useEffect } from 'react';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { toast } from 'react-toastify';
import { useWorkspaceContext } from '../../../../context/workspaceContext';
import { CanvasEnvironment } from '../../../../utils/canvas/canvas-environment';
import { ResourceType } from '../../../../@types/resource-type';
import EventSequenceService from '../../../../services/event-sequence.service';
import Typography from '@mui/material/Typography';
import { uuid } from '../../../../utils/utils';

import { ReassignmentPoint, reassignParent } from '../../../../utils/canvas/reassign-parent';
import * as CanvasActions from '../../../../utils/canvas/canvas-actions';

function PaperComponent(props: PaperProps): ReactElement {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}

export default function ReassignParentEventModal(props: { open: boolean, env: CanvasEnvironment | null, onClose: () => void }): ReactElement {
    const { open, env, onClose } = props;
    const [uniqueId, setUniqueId] = React.useState<string>('');    
    const [hasLocations, setHasLocations] = React.useState<boolean>(false);
    const [locations, setLocations] = React.useState<ReassignmentPoint[]>([]);
    const [isRunning, setIsRunning] = React.useState<boolean>(false);
    const [value, setValue] = React.useState<string>('0');
    const {  setCurrentWorkspaceObject } = useWorkspaceContext();
    const uuidRef = React.useRef(uuid());

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setValue(event.target.value);
    }
    
    useEffect(() => {
        if (open) {
            const [selectedNode] = env!.selection
            setUniqueId(selectedNode.get('data').uniqueId);
            let reassignment_points = reassignParent(env!.graph, selectedNode.get('data').id);
            setLocations(reassignment_points);
            setHasLocations(reassignment_points.length > 0);
            setValue('0');
            setIsRunning(false);
        }
    }, [open]);

    const handleSave = async (): Promise<any> => {
        const [selectedNode] = env!.selection
        let eventId = selectedNode.get('data').id
        let [parentId, yesOrNo] = value.split(':');

        let data = {
            id: eventId,
            parentRelationType: yesOrNo,
            parentId: parentId
        }

        return EventSequenceService.updateEvent(data, false);
    }


    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            fullWidth
            maxWidth="sm">
            <DialogTitle style={{ cursor: 'move'}} id='draggable-dialog-title'>
                {'Reassign Parent ' + `${uniqueId}`} 
            </DialogTitle>
            <DialogContent dividers>
                <FormGroup>
                    <Typography>
                        {hasLocations ? 'Select the location you would like to place the selected event.' : 'There are no available locations to reassign the selected event.'}
                    </Typography>
                    <hr />
                    {
                        hasLocations &&
                        <FormControl>
                            <FormLabel id={`radio-group-label-${uuidRef.current}`}>Possible Parents</FormLabel>
                            <RadioGroup
                                aria-labelledby={`radio-group-label-${uuidRef.current}`}
                                value={value}
                                onChange={handleChange}
                            >
                                {locations.map((location) => (
                                    <FormControlLabel key={`${location.id}:${location.yesOrNo}`} value={`${location.id}:${location.yesOrNo}`} control={<Radio />} label={`${location.uniqueId} - Branch ${location.yesOrNo}`} />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    }
                </FormGroup>

            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={isRunning ? <CircularProgress size={20} /> : <SaveAltIcon />}
                    onClick={async (): Promise<any> => {
                        setIsRunning(true);
                        try {
                            const response = await handleSave();
                            env!.isDirty = true;
                            setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                        } catch (error: any) {
                            toast.error(error.message);
                        } finally {
                            setIsRunning(false);
                            onClose();
                        }                                    
                    }}
                    disabled={!CanvasActions.canAddOrEditResource(env) || isRunning || !hasLocations}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
   
}