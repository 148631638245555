import React, {ReactElement, useContext, useRef, useState} from 'react';
import FlightIcon from '@mui/icons-material/FlightTakeoff';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import {AppBar, Box, Toolbar, Typography} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import InputAdornment from '@mui/material/InputAdornment';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import RedoIcon from '@mui/icons-material/Redo';
import UndoIcon from '@mui/icons-material/Undo';
import DeleteIcon from '@mui/icons-material/Delete';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import Stack from '@mui/material/Stack';
import AdminMenu from './menus/Admin.menu';
import EditMenu from './menus/Edit.menu';
import ViewMenu from './menus/View.menu';
import HelpMenu from './menus/Help.menu';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import {UserContext} from '../../context/userContext';
import {useNavigate} from 'react-router-dom';
import {BASE_PATH} from '../../config/constants';
import {makeStyles} from '@mui/styles';
import FormGroup from '@mui/material/FormGroup';
import authService from '../../services/auth.service';
import {useWorkspaceContext} from '../../context/workspaceContext';
import {ResourceType} from '../../@types/resource-type';
import ResourceMenu from './menus/Resource.menu';
import {CanvasActionType} from '../../@types/canvas-action-type';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import * as CanvasUtils from '../../utils/canvas/node-utils';
import {hasRightToEdit} from '../../utils/role-to-rights';
import EventMenu from './menus/EventMenu';
import FaultTreeMenu from './menus/FaultTreeMenu';
import FaultTreeNodeMenu from './menus/FaultTreeNodeMenu';
import {getSingularResourceLabel} from '../../utils/resource-utils';
import {uuid} from '../../utils/utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTags, faMessage } from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles({
    searchTopBarStyle: {
        '& label.Mui-focused': {
            color: 'white',
        },
        '& .MuiInputLabel-root': {
            color: 'white',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                color: 'white',
                borderColor: '#B2BAC2',
            },
            '&:hover fieldset': {
                color: 'white',
                borderColor: '#B2BAC2',
            },
            '&.Mui-focused fieldset': {
                color: 'white',
                borderColor: '#B2BAC2',
            },
            '& input': {
                color: 'white',
                border: 'none',
            }
        }
    }
});
export default function WorkspaceHeader(props: { onSash: () => void, onTabClick: Function, addOrSelectResource: any, loadObjects: any }): ReactElement {
    const [searchText, setSearchText] = useState<string>('');
    const { workspaceObjects, currentWorkspaceObject, setCurrentCanvasAction, currentCanvasSelectedNode } = useWorkspaceContext();
    const { signoutUser } = useContext(UserContext);
    const navigate = useNavigate();
    const classes = useStyles();
    const uuidRef = useRef(uuid());

    const canEditCanvas = (): boolean => {
        if (!currentWorkspaceObject || currentWorkspaceObject.objectType !== ResourceType.EVENT_SEQUENCE_TREES ) {
            return false;
        }
        return hasRightToEdit(currentWorkspaceObject, undefined);
    }

    const canDeleteNode = (): boolean => {
        if (!currentCanvasSelectedNode) {
            return false;
        }
        if (CanvasUtils.isFaultTreeNode(currentCanvasSelectedNode.type)) {
            return canEditCanvas();
        }
        return canEditCanvas() && currentCanvasSelectedNode.type !== 'INITIATING';
    }

    function onSearchTextChange(event: React.ChangeEvent<HTMLInputElement>): void {
        setSearchText(event.target.value);
    }

    return (
        <AppBar position="static">
            <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box display="flex">
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="vertical split"
                        title='Vertical Split'
                        sx={{
                            mr: 2
                        }}
                        onClick={props.onSash}
                    >
                        <VerticalSplitIcon/>
                    </IconButton>
                    <Box display="flex" sx={{padding: '12px'}}>
                        <Typography
                            variant="h6"
                            component="a"
                            href={BASE_PATH}
                            noWrap
                            style={{
                                color: 'inherit', textDecoration: 'none',
                                letterSpacing: '0.2em',
                                fontFamily: 'monospace', fontWeight: 700
                            }}>
                            ISAM
                        </Typography>
                        <FlightIcon aria-label='flight icon' sx={{marginTop: '4px'}}/> 
                    </Box>
                </Box>
                <Stack direction="row" style={{ marginLeft: '20px', marginRight: '20px'}}>
                    {authService.isSuperUser() && <AdminMenu onTabClick={props.onTabClick}/>}
                    <ResourceMenu label={getSingularResourceLabel(currentWorkspaceObject ? currentWorkspaceObject.objectType : workspaceObjects?.objectType)} 
                        addOrSelectResource={props.addOrSelectResource} loadObjects={props.loadObjects} />
                    {currentWorkspaceObject?.objectType == ResourceType.GROUPS &&
                        <EditMenu />
                    }
                    {currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCE_TREES && currentCanvasSelectedNode && CanvasUtils.isEvent(currentCanvasSelectedNode.type) && <EventMenu /> }
                    {currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCE_TREES && currentCanvasSelectedNode && CanvasUtils.isEvent(currentCanvasSelectedNode.type) && <FaultTreeMenu /> }
                    {currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCE_TREES && currentCanvasSelectedNode && CanvasUtils.isFaultTreeNode(currentCanvasSelectedNode.type) && <FaultTreeNodeMenu /> }
                    {/*{currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCE_TREES && <ReportMenu /> }*/}
                    {currentWorkspaceObject?.objectType == ResourceType.EVENT_SEQUENCE_TREES && <ViewMenu /> }
                    <HelpMenu />
                </Stack>
                <Box display="flex" >
                    <Box sx={{ borderLeft: 0.5, borderColor: 'grey.500' }}>
                        <IconButton
                            color="inherit"
                            aria-label='center focus'
                            title='Center Focus'
                            onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_RECENTER })} }
                        >
                            <CenterFocusStrongIcon  />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label='zoom in'
                            title='Zoom In'
                            onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_ZOOM_IN })} }                        
                        >
                            <ZoomInIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label='zoom out'
                            title='Zoom Out'
                            onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_ZOOM_OUT })} }                        
                        >
                            <ZoomOutIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label='full screen'
                            title='Full Screen'
                            onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_FULLSCREEN })} }
                        >
                            <FullscreenIcon  />
                        </IconButton>

                    </Box>
                    <Box sx={{ borderLeft: 0.5, borderColor: 'grey.500' }}>
                        <IconButton
                            color="inherit"
                            aria-label='delete'
                            title='Delete'
                            onClick={
                                (): void => {
                                    if (!currentCanvasSelectedNode) return;
                                    let type = currentCanvasSelectedNode.type;
                                    if (CanvasUtils.isFaultTreeNode(type)) {
                                        setCurrentCanvasAction({
                                            actionType: CanvasActionType.FAULTTREENODE_DELETE_NODE
                                        });
                                    } else {
                                        setCurrentCanvasAction({
                                            actionType: CanvasActionType.EVENTNODE_DELETE
                                        });
                                    }
                                }}
                            disabled= {!canDeleteNode() } 
                        >
                            <DeleteIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label='redo'
                            title='Redo'
                            onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_REDO }) }}
                            disabled={!canEditCanvas()}
                        >
                            <RedoIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label='undo'
                            title='Undo'
                            onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_UNDO }) }}
                            disabled={!canEditCanvas()}
                        >
                            <UndoIcon />
                        </IconButton>
                    </Box>
                    <Box sx={{ borderLeft: 0.5, borderColor: 'grey.500' }}>
                        <IconButton
                            color="inherit"
                            aria-label='tags'
                            title='Toggle tags'
                            onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_TOGGLE_TAGS }) }}
                        >
                            <FontAwesomeIcon icon={faTags} />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label='callouts'
                            title='Toggle callouts'
                            onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_TOGGLE_CALLOUTS }) }}
                        >
                            <FontAwesomeIcon icon={faMessage} />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label='notifications'
                            title='Notifications'>
                            <NotificationsOutlinedIcon />
                        </IconButton>
                        <IconButton
                            color="inherit"
                            aria-label='properties'
                            title='Properties'
                            onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.NODE_PROPERTIES }) }}
                            disabled={!canEditCanvas() || !currentCanvasSelectedNode}
                        >
                            <SettingsOutlinedIcon />
                        </IconButton>
                    </Box>

                    <FormGroup sx={{ borderLeft: 0.5, borderColor: 'grey.500', color: 'white' }}>
                        <TextField
                            label='Search'
                            aria-label='search field'
                            type='text'
                            variant='outlined'
                            size='small'
                            id={`search-box-${uuidRef.current}`}
                            className={classes.searchTopBarStyle}
                            onChange={onSearchTextChange}
                            sx={{
                                ml: 2, mr: 2, mt: 1,
                            }}

                            InputProps={{
                                'role': 'searchbox',
                                'tabIndex': 0,
                                'aria-labelledby': `search-box-${uuidRef.current}`,
                                'aria-label': 'search',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton aria-label='search icon' onClick={():void  => {
                                            setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_SEARCH, query: searchText });
                                        }}>
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </FormGroup>
                    <Box sx={{ borderLeft: 0.5, borderColor: 'grey.500' }}>
                        <Button
                            variant="contained"
                            size="small"
                            sx={{ ml: 2 }}
                            aria-label='Sign Out'
                            startIcon={<PermIdentityIcon />}
                            onClick={(): void => {
                                signoutUser();
                                navigate('/react/signout');
                            }}
                        >
                            Sign Out
                        </Button>
                    </Box>

                </Box>
            </Toolbar>                
        </AppBar>
        
    );
}
