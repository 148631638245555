import { ReactElement } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { TreeView } from '@mui/x-tree-view/TreeView';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { resourceIcon } from '../../utils/resource-icons';
import { ResourceType } from '../../@types/resource-type';
import GroupActionsMenu from './menus/Group.actions.menu';
import { TreeViewItem, TreeViewProps } from '../../@types/tree-view-item';  
import UserActionsMenu from './menus/User.actions.menu';
import SafetyModelActionsMenu from './menus/SafetyModel.actions.menu';
import EventSequenceActionsMenu from './menus/EventSequence.actions.menu';
import { useWorkspaceContext } from '../../context/workspaceContext';

export function ExplorerTreeView(props: TreeViewProps): ReactElement {
    const { title, nodes, onClickItem, exploreMode, ...other } = props;
    const { workspaceObjects } = useWorkspaceContext();

    const handleSingleClick = (event: any): void => {
        if (exploreMode)
            onClickItem(event);
    }

    const handleDoubleClick = (event: any): void => {
        if (!exploreMode)
            onClickItem(event);
    }

    const renderTreeItem = (node: TreeViewItem): ReactElement => {
        return (
            <TreeItem tabIndex={0}  key={node.type + '-' + node.id} nodeId={ node.type + '-' + node.id} label={
                <Box tabIndex={0} sx={{ display: 'flex', justifyContent: 'space-between', p: 0.5, pr: 0 }}
                    onClick={handleSingleClick} onDoubleClick={handleDoubleClick}>
                    <Typography sx={{ mr: 1 }} component="div" >{resourceIcon(node.type)}</Typography>
                    <Typography id={'box-' + node.type + '-' + node.id} variant="body2" sx={{ flexGrow: 1 }}>{node.name}</Typography>
                    <Typography variant="caption" sx={{ mr: 1, mt: 0.5, color: '#606060' }}>
                        {node.groupPath ? node.groupPath : null}
                    </Typography>
                    <Typography variant="caption" sx={{ mr: 1 }}>
                        {node.groupRole ? <Chip label={node.groupRole.toUpperCase()} variant="outlined" size="small" sx={{ color: '#606060', fontSize: '8px' }}/> : null}
                    </Typography>
                    {exploreMode && node.type === ResourceType.GROUPS  && workspaceObjects?.objectType === ResourceType.GROUPS &&
                        <GroupActionsMenu group={node.object} />
                    }
                    {exploreMode && node.type === ResourceType.USERS &&
                        <UserActionsMenu user={node.object} />
                    }
                    {exploreMode && node.type === ResourceType.SAFETY_MODELS &&
                        <SafetyModelActionsMenu safetyModel={node.object} />
                    }
                    {exploreMode && node.type === ResourceType.EVENT_SEQUENCES &&
                        <EventSequenceActionsMenu eventSequenceSummary={node.object} />
                    }

                </Box>
            }
            icon={!node.children && node.hasChildren ? <ChevronRightIcon /> : null}
            >
                {Array.isArray(node.children) ? node.children?.map((child) => renderTreeItem(child)) : null}
            </TreeItem>
        );
    }

    return (
        <>
            {title? <Typography sx={{ mr: 1}} variant='h6' color='primary'>{title}</Typography> : null}
            <TreeView
                aria-label="tree-view"
                defaultCollapseIcon={<ExpandMoreIcon aria-label='collapse icon' />}
                defaultExpandIcon={<ChevronRightIcon aria-label='expand icon'/>}
                sx={{ mt: '20px', flexGrow: 1, overflowY: 'auto', width: '100%', overflowX: 'hidden' }}
                {...other}
            >
                {nodes.map((node) => renderTreeItem(node))}
            </TreeView>
        </>
    );
}