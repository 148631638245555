import Menu from '@mui/material/Menu';
import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {IconButton} from '@mui/material';



export default function DotdotdotButton(props: {children: React.ReactNode}): React.ReactElement {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <ul role='menu' aria-label='more actions' tabIndex={0} style={{padding: '0', margin: '-6px 10px 0' }}>
            <li role='menuitem' tabIndex={0} style={{listStyleType: 'none'}}
                onClick={(event: any): void => { handleClose(event); }}>
                <IconButton
                    aria-label='more actions'
                    aria-controls={open ? 'icon-button' : undefined}
                    size='small'
                    component='button'
                    edge='end'
                    onClick={handleClick}>
                    <MoreVertIcon sx={{width: 25, height: 30}}/>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'icon-button',
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {props.children}
                </Menu>
            </li>   
        </ul>

    );
}