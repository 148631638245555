import TabItem from '../@types/tab-item';
import { getFromLocalStorage, setToLocalStorage } from './local-storage-utils';
import { makeGroup } from './group-utils';

const TAB_ITEMS = 'TabItems';
const TAB_INDEX = 'TabIndex';

export function getTabItems(): TabItem[] {
    const items = getFromLocalStorage(TAB_ITEMS) ?? [];
    items.forEach((item: any) => {
        if (item.objectToEdit && item.objectToEdit.group) {
            item.objectToEdit.group = makeGroup(item.objectToEdit.group);
        }
    });
    return items;
}

export function updateTabItems(tabs: TabItem[]): void {
    setToLocalStorage(TAB_ITEMS, tabs);
}

export function getCurrentTabIndex (): number {
    return getFromLocalStorage(TAB_INDEX) != null ? parseInt(getFromLocalStorage(TAB_INDEX) as string) : 0;
}

export function updateCurrentTabIndex(index: number): void {
    setToLocalStorage(TAB_INDEX, index.toString());
}
