/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react';
import * as joint from '@joint/plus';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { NestedMenuItem } from 'mui-nested-menu';
import { CanvasEnvironment } from '../../../utils/canvas/canvas-environment';
import { useWorkspaceContext } from '../../../context/workspaceContext';
import { CanvasActionType } from '../../../@types/canvas-action-type';
import * as canvasActions from '../../../utils/canvas/canvas-actions'
import * as canvasUtils from '../../../utils/canvas/node-utils'

export default function FaultTreeContextMenu(props: {
    elementView: joint.dia.ElementView | null,
        open: boolean, handleClose: () => void, env: CanvasEnvironment | null
}): React.ReactElement {
    const { elementView, open, handleClose, env } = props;
    const { setCurrentCanvasAction } = useWorkspaceContext();
    const element = elementView ? elementView.model : null;
    const anchorEl = elementView ? elementView.el as SVGElement : null;

    const canAddOrEditResource = (): boolean => {
        return canvasActions.canAddOrEditResource(env);
    }

    const canPasteEvent = (): boolean => {
        return canvasActions.canPasteEvent(env, element?.get('data'));
    }

    const canPasteFaulTree = (): boolean => {
        return canAddOrEditResource() && canvasUtils.canPasteFaultTree(element?.get('data'));
    }

    const canPasteFaulTreeNode = (): boolean => {
        return canAddOrEditResource() && canvasUtils.canPasteFaultTreeNode(element?.get('data'));
    }


    const noFaultTree = (): boolean => {
        return !canvasUtils.hasFaultTree(element?.get('data'));
    }
    
    const notAllowedOnFaultTree = (): boolean => {
        return !canvasUtils.canCreateFaultTree(element?.get('data'));
    }
    const dnaRequestNotAllowed = (): boolean => {
        let type = element?.get('data').type;

        return !canAddOrEditResource() || (type === 'INPRECURSOR');
    }

    const isEvent = (type: string): boolean => {
        return canvasUtils.isEvent(type);
    }

    const isInitiating = (): boolean => {
        return canvasUtils.isInitiating(element?.get('data').type);
    }

    const isBarrierGroup = (): boolean => {
        return canvasUtils.isBarrierGroup(element?.get('data').nodeClass);
    }

    const cannotAddCallout = (): boolean => {
        let type = element?.get('data').type;
        let callout = element?.get('data').callout;
        return ((callout !== undefined) || (type === 'INPRECURSOR'));
    }
    
    const cannotUpdateCallout = (): boolean => {
        let type = element?.get('data').type;
        let callout = element?.get('data').callout;
        return ((callout === undefined) || (type === 'INPRECURSOR'));
    }

    const cannotRemoveCallout = (): boolean => {
        let type = element?.get('data').type;
        let callout = element?.get('data').callout;
        return ((callout === undefined) || (type === 'INPRECURSOR'));
    }


    const cannotCreateEvent = (): boolean => {
        let type = element?.get('data').type;
        return (!canAddOrEditResource() || (type === 'INPRECURSOR'));
    }

    const cannotCreateFaultTreeNode = (): boolean => {
        let type = element?.get('data').type;
        return (!canAddOrEditResource() || (type === 'BASE_EVENT'));
    }

    const cannotCreatePivotalEvent = (): boolean => {
        let type = element?.get('data').type;
        return !canAddOrEditResource() || ((type !== 'INITIATING') && (type !== 'PIVOTAL'));
    }

    const cannotCreateInPrecursorEvent = (): boolean => {
        let type = element?.get('data').type;
        let parent = element ? env?.graph.getNeighbors(element, { inbound: true }) : null;
        let parentType = parent ? parent[0]?.get('data').type : 'UNKNOWN';
        return !canAddOrEditResource() || (type !== 'PIVOTAL') || (parentType !== 'PIVOTAL');
    }

    const cannotCreateEndEvent = (): boolean => {
        let type = element?.get('data').type;
        let childIds = element?.get('data').childIds;
        return !canAddOrEditResource() || (type !== 'PIVOTAL') || (childIds && childIds.length === 2);
    }

    const cannotQuantify = (): boolean => {
        let type = element?.get('data').type;
        let nodeClass = element?.get('data').nodeClass;
        return (
            !canAddOrEditResource() || (type === 'BASE_EVENT') ||
            (isEvent(type) && type !== 'INITIATING') || (nodeClass === 'BARRIERGROUP') || (type === 'INPRECURSOR')
        );
    }

    const cannotReorderChildren = (): boolean => {
        let type = element?.get('data').type;
        let childIds = element?.get('data').childIds;
        return (!canAddOrEditResource() || (type === 'BASE_EVENT') || (isEvent(type)) ||
            (!childIds) || (childIds.length <= 1));
    }

    const handleProperties = () => {
        const type = element?.get('data').type;
        if (type === 'PIVOTAL') {
            setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_EDIT_PIVOTALEVENT });
        } else if (type === 'INITIATING') {
            setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_EDIT_INITIATINGEVENT });
        } else if (type === 'END') {
            setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_EDIT_ENDEVENT });
        } else if (type === 'INPRECURSOR') {
            setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_EDIT_INPRECURSOREVENT });
        } else if (canvasUtils.isFaultTreeNode(type)) {
            setCurrentCanvasAction({ actionType: CanvasActionType.FAULTTREENODE_EDIT_NODE });
        }
        handleClose();
    }

    return (
        <div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem
                    onClick={() => { handleProperties() }}
                    disabled={!canAddOrEditResource()}
                >
                    Properties
                </MenuItem>
                <NestedMenuItem label='Callout' parentMenuOpen={open}>
                    <MenuItem
                        onClick={() => {
                            setCurrentCanvasAction({ actionType: CanvasActionType.NODE_ADD_CALLOUT });
                            handleClose()
                        }}
                        disabled={cannotAddCallout()}
                    >
                        Add Callout
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setCurrentCanvasAction({ actionType: CanvasActionType.NODE_EDIT_CALLOUT });
                            handleClose()
                        }}
                        disabled={cannotUpdateCallout()}
                    >
                        Update Callout
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setCurrentCanvasAction({ actionType: CanvasActionType.NODE_DELETE_CALLOUT });
                            handleClose()
                        }}
                        disabled={cannotRemoveCallout()}
                    >
                        Remove Callout
                    </MenuItem>
                </NestedMenuItem>

                <MenuItem
                    onClick={() => {
                        setCurrentCanvasAction({ actionType: CanvasActionType.NODE_TAGS });
                        handleClose()
                    }}
                    disabled={!canAddOrEditResource()}
                >
                    Tags
                </MenuItem>
                <NestedMenuItem label='Quantify' parentMenuOpen={open && !cannotQuantify()} disabled={cannotQuantify()}>
                    <MenuItem
                        onClick={() => {
                            setCurrentCanvasAction({ actionType: CanvasActionType.NODE_QUANTIFY_TOP_DOWN });
                            handleClose()
                        }}
                        disabled={isInitiating()}
                    >
                        Top-Down
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setCurrentCanvasAction({ actionType: CanvasActionType.NODE_QUANTIFY_BOTTOM_UP });
                            handleClose()
                        }}
                        disabled={!isInitiating()}
                    >
                        Bottom-Up
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setCurrentCanvasAction({ actionType: CanvasActionType.NODE_QUANTIFY_CHILDREN });
                            handleClose()
                        }}
                        disabled={isInitiating()}
                    >
                        Children
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            setCurrentCanvasAction({ actionType: CanvasActionType.NODE_QUANTIFY_BOOTOM_UP_TO_HERE });
                            handleClose()
                        }}
                        disabled={isInitiating()}
                    >
                        Bottom-Up To Here
                    </MenuItem>
                </NestedMenuItem>
                { element && element.get('nodeClass') === 'FAULTTREENODE' && !cannotReorderChildren() &&
                    <MenuItem
                        onClick={() => {
                            setCurrentCanvasAction({ actionType: CanvasActionType.FAULTTREENODE_REORDER_CHILDREN });
                            handleClose()
                        }}
                        disabled={cannotReorderChildren()}
                    >
                        Reorder Children
                    </MenuItem>
                }
                <NestedMenuItem  label='Incident DNA' parentMenuOpen={open && !isBarrierGroup()} disabled={isBarrierGroup()}>
                    <MenuItem onClick={handleClose} disabled={dnaRequestNotAllowed()}>Failure</MenuItem>
                    <MenuItem onClick={handleClose} disabled={dnaRequestNotAllowed()}>Success</MenuItem>
                    <MenuItem onClick={handleClose} disabled={dnaRequestNotAllowed()}>Clear</MenuItem>
                    <MenuItem onClick={handleClose}>Occurrence List</MenuItem>
                    <MenuItem onClick={handleClose}>Statistics</MenuItem>
                    <MenuItem onClick={handleClose} disabled={!canAddOrEditResource()}>Performance Shaping Factors</MenuItem>
                    <MenuItem onClick={handleClose} disabled={!canAddOrEditResource()}>Comments</MenuItem>
                </NestedMenuItem>
                { element && element.get('nodeClass') === 'FAULTTREENODE' && 
                    <NestedMenuItem label='Fault Tree Node' parentMenuOpen={open && canAddOrEditResource()} disabled={env?.readonly}>
                        <MenuItem
                            onClick={() => {
                                setCurrentCanvasAction({ actionType: CanvasActionType.FAULTTREENODE_ADD_NODE });
                                handleClose()
                            }}
                            disabled={cannotCreateFaultTreeNode()}
                        >
                            Add Node
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setCurrentCanvasAction({ actionType: CanvasActionType.FAULTTREENODE_COPY_SUBTREE });
                                handleClose();
                            }}
                            disabled={!canAddOrEditResource()}
                        >
                            Copy SubTree
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setCurrentCanvasAction({ actionType: CanvasActionType.FAULTTREENODE_PASTE_SUBTREE });
                                handleClose();
                            }}
                            disabled={!canPasteFaulTreeNode()}
                        >
                            Paste SubTree
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setCurrentCanvasAction({ actionType: CanvasActionType.FAULTTREENODE_DELETE_NODE });
                                handleClose()
                            }}
                            disabled={!canAddOrEditResource()}
                        >
                            Delete Node
                        </MenuItem>
                    </NestedMenuItem>
                }
                {element && element.get('nodeClass') !== 'FAULTTREENODE' &&
                    <NestedMenuItem label='Event Node' parentMenuOpen={open && !cannotCreateEvent()} disabled={cannotCreateEvent()}>
                        <NestedMenuItem label='New' parentMenuOpen={open  && !cannotCreateEvent()}>
                            <MenuItem
                                onClick={() => {
                                    setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_ADD_PIVOTALEVENT });
                                    handleClose()
                                }}
                                disabled={cannotCreatePivotalEvent()}
                            >
                                Pivotal Event
                            </MenuItem>
                            {/*
                            <MenuItem
                                onClick={handleClose}
                                disabled={cannotCreateInPrecursorEvent()}
                            >
                                In-Precursor
                            </MenuItem>
                            */}
                            <MenuItem
                                onClick={() => {
                                    setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_ADD_ENDEVENT });
                                    handleClose()
                                }}
                                disabled={cannotCreateEndEvent()}
                            >
                                End Event
                            </MenuItem>
                        </NestedMenuItem>
                        <MenuItem
                            onClick={() => {
                                setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_COPY });
                                handleClose();
                            }}
                            disabled={!canAddOrEditResource() || element?.get('data').type === 'INPRECURSOR'}
                        >
                            Copy
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_PASTE });
                                handleClose();
                            }}
                            disabled={!canPasteEvent()}
                        >
                            Paste
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_DELETE });
                                handleClose();
                            }}
                            disabled={!canAddOrEditResource() || element?.get('data').type === 'INITIATING'}
                        >
                            Delete
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_REASSIGN_PARENT });
                                handleClose();
                            }}
                            disabled={!canAddOrEditResource() || element?.get('data').type === 'INITIATING' || element?.get('data').type === 'INPRECURSOR'}

                        >
                            Reassign Parent
                        </MenuItem>
                        <Divider />
                        <NestedMenuItem
                            label='Fault Tree'
                            parentMenuOpen={open && (element?.get('data').type === 'INITIATING' || element?.get('data').type === 'PIVOTAL')}
                            disabled={element?.get('data').type !== 'INITIATING' && element?.get('data').type !== 'PIVOTAL'}
                        >
                            <MenuItem
                                onClick={() => {
                                    setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_FAULTTREE_PROPERTIES });
                                    handleClose();
                                }}
                                disabled={!canAddOrEditResource() || noFaultTree()}
                            >
                                Properties
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_FAULTTREE_ADD });
                                    handleClose();
                                }}
                                disabled={!canAddOrEditResource() || notAllowedOnFaultTree()}
                            >
                                Add
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_FAULTTREE_COPY });
                                    handleClose();
                                }}
                                disabled={!canAddOrEditResource() || noFaultTree()}
                            >
                                Copy
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_FAULTTREE_PASTE });
                                    handleClose();
                                }}
                                disabled={!canPasteFaulTree()}
                            >
                                Paste
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setCurrentCanvasAction({ actionType: CanvasActionType.EVENTNODE_FAULTTREE_DELETE });
                                    handleClose();
                                }}
                                disabled={!canAddOrEditResource() || noFaultTree()}
                            >
                                Delete
                            </MenuItem>
                        </NestedMenuItem>
                    </NestedMenuItem>
                }
  
            </Menu>
        </div>
    );
}
