import { isEmpty } from './utils';

function encodeIt(key: string, value: any): string {
    if (Array.isArray(value)) {
        return value.map(item => `${key}=${encodeURIComponent(item)}`).join('&');
    }
    return `${key}=${encodeURIComponent(value)}`;
}

export function queryParamsToQueryString(queryParams: any): string {
    //This function take all of the fields of queryParams and then 
    // appends them to the end of the URL
    const keys = Object.keys(queryParams);
    const queryStringArray = keys.reduce((accumulator: string[], key: string) => isEmpty(queryParams[key]) ?
        accumulator :
        [
            ...accumulator,
            encodeIt(key, queryParams[key])
        ], []);
    
    return queryStringArray.length > 0 ? `?${queryStringArray.join('&')}` : '';
}
