import axios from 'axios';
import {User} from '../context/userContext';
import {API_URL, MEDIA_TYPE_V2} from '../config/constants';
import {queryParamsToQueryString} from '../utils/query-params';
import {makeUser} from '../utils/user-utils';
import {getErrorMessage} from '../utils/utils';
import SpringDataPage, {BasicPage} from '../@types/spring-data-page';

export class UserService {
    static userUrlV1 = API_URL + 'users';
    static userUrlV2 = API_URL + 'api/v2/users';

    formUser(user: any): User {
        return makeUser(user);
    }

    formUsersPagePromise(usersData: any): Promise<SpringDataPage<User>> {
        let usersPage: SpringDataPage<User> = {} as SpringDataPage<User>;
        if (usersData) {
            if (usersData._embedded?.isamUserDTOList) {
                usersPage.content = usersData._embedded.isamUserDTOList?.map((user: any) => this.formUser(user));
            } else {
                usersPage.content = [];
            }
            usersPage.page = usersData.page;
        }
        return Promise.resolve(usersPage);
    }

    createUser(user: User): Promise<User> {
        return axios.post(UserService.userUrlV2, user, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then((response: any) => {
            return this.formUser(response.data);
        }).catch((error: any) => {
            throw new Error(error.response.data?.description ?? 'Create user failed with error: ' + error.message);
        });
    }

    deleteUserById(id: number): Promise<void> {
        return axios.delete(UserService.userUrlV2 + '/' + id, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 },
        }).then(() => {
            return;
        }).catch((error: any) => {
            throw new Error(`Delete user ${id} failed with error: ` + getErrorMessage(error));
        });
    }

    getUserById(id: number): Promise<User> {
        return axios.get(UserService.userUrlV2 + '/' + id, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 },
        }).then((response: any) => {
            return this.formUser(response.data);
        }).catch((error: any) => {
            throw new Error(`Get user ${id} returned an error: ` + getErrorMessage(error));
        });
    }

    patchUser(user: User): Promise<User> {
        return axios.patch(UserService.userUrlV2 + '/' + user.id, user, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 },
        }).then((response: any) => {
            return this.formUser(response.data);
        }).catch((error: any) => {
            throw new Error(`Patch user ${user.id} failed with error: ` + getErrorMessage(error));
        });
    }

    getPagedUsers(page: BasicPage, isActive: boolean|null, searchTerm?: string): Promise<SpringDataPage<User>> {
        let url = `${UserService.userUrlV2}?`
            + `size=${page.size}&`
            + `page=${page.offset}&`
            + `sort=${page.sort},${page.direction}`;
        if (searchTerm && searchTerm !== '') url += `&searchTerm=${searchTerm}`;
        if (isActive !== null) url += `&isActive=${isActive}`;
        return axios
            .get(url, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/hal+json' }
            })
            .then(response => {
                return this.formUsersPagePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to get paged users: ' + getErrorMessage(err));
            })
    }

    getUsers(searchTerm?: string): Promise<User[]> {
        const queryParams = {
            searchTerm: searchTerm,
            size: 25,
            sort: 'lastName,asc',
            isActive: true
        }; 
        // axios does not seem to encode correctly the query params.
        // All my attempts failed to make it work. So I am doing it manually here.
        const queryString = queryParamsToQueryString(queryParams);
        
        return axios.get(UserService.userUrlV2 + queryString, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 },
        }).then((response: any) => {
            return response.data?._embedded?.isamUserDTOList ?? [];
        }).catch((error: any) => {
            throw new Error('Users returned an error: ' + error);
        });
    }

    getAllUsers(size?: number): Promise<User[]> {
        const guessedNumberOfUsers = size ?? 100;
        const searchTerm = undefined
        const queryParams = {
            searchTerm: searchTerm,
            size: guessedNumberOfUsers,
            sort: 'lastName,asc',
        }; 
        const queryString = queryParamsToQueryString(queryParams);
        
        return axios.get(UserService.userUrlV2 + queryString, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 },
        }).then((response: any) => {
            if (response.data.page.totalElements > guessedNumberOfUsers) {
                return this.getAllUsers(response.data.page.totalElements);
            }
            return response.data._embedded.isamUserDTOList.map((user: any) => this.formUser(user));
        }).catch((error: any) => {
            throw new Error('Failed to fetch all users: ' + getErrorMessage(error));
        });
    }

}

export default new UserService();
