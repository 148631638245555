import React, {ReactElement} from 'react';
import {Box, Tabs, Tab} from '@mui/material';
import { uuid } from '../../utils/utils';

function tabA11yProps(index: number, id: string): Record<string, string> {
    return {
        id: `vertical-tab-${id}-${index}`,
    };
}

interface VerticalIconMenuItem {
    icon: ReactElement;
    label: string;
}

export default function VerticalIconMenu(props: {menuItems: VerticalIconMenuItem[], onTabClick: Function, itemIndex: number}): ReactElement {

    const menuItems = props.menuItems;
    const onTabClick = props.onTabClick;
    const [value, setValue] = React.useState(props.itemIndex);
    const uuidRef = React.useRef(uuid());

    const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
        setValue(newValue);
        onTabClick(newValue);
    };

    React.useEffect(() => {
        setValue(props.itemIndex);
    }, [props.itemIndex]);

    return (
        <Box sx={{display: 'flex', height: '100%', width: '100%', backgroundColor: '#FAFEFE'}}>
            <Tabs
                id={`vertical-tabs-${uuidRef.current}`}
                orientation='vertical'
                variant='scrollable'
                value={value}
                onChange={handleChange}
                aria-label='Workspace Explorer Tabs'
                sx={{ borderRight: 1, borderColor: 'divider', width: '100%' }}
                TabScrollButtonProps={{
                    'tabIndex': 0,
                    'role': 'button',
                    'aria-label': 'scroll tabs',
                }}
            >
                {menuItems.map((item, index) => (
                    <Tab key={item.label} icon={item.icon} aria-label={item.label} label={item.label} {...tabA11yProps(index, uuidRef.current)} wrapped/>
                ))}

            </Tabs>
        </Box>
    );
}
