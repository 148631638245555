import Group from '../@types/group';
import {GroupMember} from '../@types/group-member';
import {makeGroup} from '../utils/group-utils';
import {API_BASE_URL, MEDIA_TYPE_V2} from '../config/constants';
import axios from 'axios';
import {getErrorMessage} from '../utils/utils';
import SpringDataPage, {BasicPage} from '../@types/spring-data-page';

class GroupService {

    static groupUrl = API_BASE_URL + 'groups';
    static groupMembersUrl = API_BASE_URL + 'groupMembers';
    static groupDuplicateUrl = API_BASE_URL + 'groups/copy-and-paste';
    static groupMemberByGroupIdUrl = API_BASE_URL + 'groups/{groupId}/members';

    formGroup(group: any): Group {
        return makeGroup(group);
    }

    formGroups(groupsData: any): Group[] {
        const groups: Group[] = [];
        if (groupsData) {
            groupsData.forEach((group: any) => {
                let grp: Group = this.formGroup(group);
                groups.push(grp);
            })
        }
        return groups;
    }

    formGroupsPagePromise(groupsData: any): Promise<SpringDataPage<Group>> {
        let groupsPage: SpringDataPage<Group> = {} as SpringDataPage<Group>;
        if (groupsData) {
            if (groupsData._embedded?.groupDTOList) {
                groupsPage.content = groupsData._embedded.groupDTOList?.map((grp: any) => this.formGroup(grp));
            } else {
                groupsPage.content = [];
            }
            groupsPage.page = groupsData.page;
        }
        return Promise.resolve(groupsPage);
    }

    formGroupMember(groupMemberData: any): GroupMember {
        let grpMember: GroupMember = {} as GroupMember;
        grpMember.id = groupMemberData.id;
        grpMember.role = groupMemberData.role;
        grpMember.isamUser = groupMemberData.isamUser;
        grpMember.group = this.formGroup(groupMemberData.group);
        return grpMember;
    }

    formGroupMembers(groupMembersData: any[]): GroupMember[] {
        const groupMembers: GroupMember[] = [];
        
        groupMembersData.forEach((groupMember: any) => {
            let grpMember: any = this.formGroupMember(groupMember);
            groupMembers.push(grpMember);
        })
        
        return groupMembers;
    }

    getGroups(): Promise<Group[]> {
        return axios.get(GroupService.groupUrl, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then((res: any) => {
            return res.data._embedded ? this.formGroups(res.data._embedded.groupDTOList) : [];
        }).catch((error: any) => {
            throw new Error('Groups returned an error: ' + getErrorMessage(error));
        });
    }

    fetchPagedGroups(page: BasicPage, groupId?: number|null, searchTerm?: string): Promise<SpringDataPage<Group>> {
        let url = `${GroupService.groupUrl}/paged?`
            + `size=${page.size}&`
            + `page=${page.offset}&`
            + `sort=${page.sort},${page.direction}`;
        if (searchTerm && searchTerm !== '') url += `&searchTerm=${searchTerm}`;
        if (groupId) url += `&groupId=${groupId}`;
        return axios
            .get(url, {
                withCredentials: true,
                headers: { 'Content-Type': 'application/json' }
            })
            .then(response => {
                return this.formGroupsPagePromise(response.data);
            })
            .catch(err => {
                throw new Error('Failed to get paged groups: ' + getErrorMessage(err));
            })
    }

    getGroupById(id: number): Promise<Group> {
        return axios.get(GroupService.groupUrl + '/' + id, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then((response: any) => {
            return this.formGroup(response.data);
        }).catch((error: any) => {
            throw new Error(`Get group ${id} returned an error: ` + getErrorMessage(error));
        });
    }

    createGroup(group: Group): Promise<Group> {
        return axios.post(GroupService.groupUrl, group, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then((response: any) => {
            return this.formGroup(response.data);
        }).catch((error: any) => {
            throw new Error(error.response.data?.description ?? 'Create group error caught: ' + error.message);
        });
    }

    patchGroup(group: Group): Promise<Group> {
        return axios.patch(GroupService.groupUrl + '/' + group.id, group, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then((response: any) => {
            return this.formGroup(response.data);
        }).catch((error: any) => {
            throw new Error(`Patch group ${group.id} failed with error: ` + getErrorMessage(error));
        });
    }

    deleteGroupById(id: number): Promise<void> {
        return axios.delete(GroupService.groupUrl + '/' + id, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then(() => {
            return;
        }).catch((error: any) => {
            throw new Error(`Delete group ${id} failed with error: ` + getErrorMessage(error));
        });
    }

    leaveGroupById(group: Group): Promise<void> {
        let groupMemberId = group.currentUser ? group.currentUser.groupMemberId : undefined;
        if (!groupMemberId) {
            throw new Error(`Leave group ${group.id} failed with error: groupMemberId is undefined`);
        }
        return axios.delete(GroupService.groupMembersUrl + '/' + groupMemberId, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then(() => {
            return;
        }).catch((error) => {
            if (error.response.status === 403) {
                throw new Error(`Cannot leave group ${group.name} - you may not have permission or you may be the last ADMIN of the group.`);
            } else {
                throw new Error(`Leave group ${group.name} failed with error: ` + getErrorMessage(error));
            }
        });
    }

    duplicateGroup(sourceResourceId: number | string, targetGroupId: number | string | undefined): Promise<any> {
        const data = targetGroupId !== undefined ? {
            sourceResourceId: sourceResourceId,
            targetGroupId: targetGroupId
        } : {
            sourceResourceId: sourceResourceId
        };
        return axios.post(GroupService.groupDuplicateUrl, data , {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then((response: any) => {
            return response;
        }).catch((error: any) => {
            throw new Error(`Duplicate group ${sourceResourceId} failed with error: ` + getErrorMessage(error));
        });
    }

    fetchGroupMembersByGroupId(groupId: number): Promise<any> {
        return axios.get(GroupService.groupMemberByGroupIdUrl.replace('{groupId}', groupId.toString()), {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then((response: any) => {
            return this.formGroupMembers(response.data._embedded.groupMemberDTOList);
        }).catch((error: any) => {
            throw new Error(`Fetch group members by group id ${groupId} failed with error: ` + getErrorMessage(error));
        });
    }

    joinGroup(groupMember: GroupMember): Promise<GroupMember> {
        return axios.post(GroupService.groupMembersUrl,  JSON.stringify(groupMember), {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then((response: any) => {
            return this.formGroupMember(response.data);
        }).catch((error: any) => {
            throw new Error('Create group member failed with error: ' + getErrorMessage(error));
        });
    }

    cancelGroupMembership(groupMember: GroupMember): Promise<void> {
        return axios.delete(GroupService.groupMembersUrl + '/' + groupMember.id, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then(() => {
            return;
        }).catch((error) => {
            if (error.response.status === 403) {
                throw new Error(`Cannot leave group ${groupMember.group.name} - you may not have permission or you may be the last ADMIN of the group.`);
            } else {
                throw new Error(`Leave group ${groupMember.group.name} failed with error: ` + getErrorMessage(error));
            }
        });
    }

    updateGroupMembershipRole(groupMember: GroupMember): Promise<GroupMember> {
        return axios.patch(GroupService.groupMembersUrl + '/' + groupMember.id, {role: groupMember.role}, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then(() => {
            return groupMember;
        }).catch((error: any) => {
            if (error.response.status === 403) {
                throw new Error(`Cannot change role - either you do not have permission or you are the last ADMIN of ${groupMember.group.name}.`);
            } else {
                throw new Error(`Change role for user ${groupMember.isamUser.firstName} failed with error: ` + getErrorMessage(error));
            }
        });
    }


    
}

export default new GroupService();