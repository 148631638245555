export enum ResourceType {
    GROUPS                       = 'groups',
    SAFETY_MODELS                = 'safetyModels',
    EVENT_SEQUENCE_HISTORY       = 'eventSequenceHistory',
    EVENT_SEQUENCES              = 'eventSequences',
    EVENT_SEQUENCE_TREES         = 'eventSequenceTrees',
    EVENT_SEQUENCE_HISTORY_TREES = 'eventSequenceHistoryTrees',
    OCCURRENCE_REPORTS           = 'occurrenceReports',
    TRAFFIC_MODELS               = 'trafficModels',
    OPERATIONAL_CHANGES          = 'operationalChanges',
    RISK_FORECASTS               = 'riskForecasts',
    WORKSHOPS                    = 'workshops',
    USERS                        = 'users',

    UNKNOWN                      = 'unknown',
}