import axios from 'axios';
import { API_URL } from '../config/constants';


class PhasesOfFlightService {
    static phasesOfFlightUrl = API_URL + 'phaseOfFlight';
    

    getPhasesOfFlight(): Promise<any> {
        return axios.get(PhasesOfFlightService.phasesOfFlightUrl + '/phasesOfFlight', {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(err => {
            throw new Error('Failed to get phases of flight: ' + err);
        })
    }
}

export default new PhasesOfFlightService();