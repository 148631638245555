import {Context} from 'react';
import Auth from '../services/auth.service'
import {createContext, useState} from 'react';
import { OktaAuthOptions } from '@okta/okta-auth-js';

export interface User {
    id: number,
    firstName: string,
    lastName: string,
    email: string,
    lastLogin: Date
    superUser: boolean,
    isActive: boolean,
    name?: string,
}

/**
 * User is used with context to be available 'globally'
 */
export type UserContextType = {
    user: User | null;
    oktaAuthOptions: OktaAuthOptions | null | undefined;
    saveOktaAuthOptions: (oktaAuthOptions: OktaAuthOptions) => void;
    signinUser: (user: User) => void;
    signoutUser: () => void;
}

// We cast an empty object as UserContextType here initially, knowing it
// is about to be replaced with a valid UserContextType. If you skip this,
// TypeScript will throw a TypeError while the object is temporarily null
export const UserContext = createContext<UserContextType>({} as UserContextType);

// @ts-ignore
const UserProvider = ({ children }): Context => {

    // State for user
    const [user, setUser] = useState< User|null >(Auth.getCurrentUser());

    // State for oktaAuth
    const [oktaAuthOptions, setOktaAuthOptions] = useState< OktaAuthOptions|null >();

    // Saves the oktaAuthOptions in state
    const saveOktaAuthOptions = (oktaAuthOptions: OktaAuthOptions): void => {
        localStorage.setItem('oktaAuthOptions', JSON.stringify(oktaAuthOptions));
        setOktaAuthOptions(oktaAuthOptions);
    };

    // Sets the current user in the local storage and updates the user state
    const signinUser = (user: User): void => {
        localStorage.setItem('userProfile', JSON.stringify(user));
        setUser(user);
    };

    // Removes the current user from the local storage and updates the user state
    const signoutUser = (): void => {
        localStorage.removeItem('userProfile');
        Auth.signout().then(() => {
            setUser(null);
        }).catch(() => {
            //Do nothing
        });
    };

    // Returns the user context provider
    return <UserContext.Provider value={{ user, oktaAuthOptions, saveOktaAuthOptions, signinUser, signoutUser }}>
        {children}
    </UserContext.Provider>;
};

export default UserProvider;
