import { Button, TextField, Typography } from '@mui/material';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

import React, {ReactElement} from 'react';
import {singularizeCamelCase} from '../../utils/utils';
import { ResourceType } from '../../@types/resource-type';

export interface StrictDeleteModalProps {
    open: boolean;
    resourceType: ResourceType;
    resourceName: string;
    subResources: string;
    onClose: () => void;
    handleDelete: () => void;
}
export default function StrictDeleteModal(props: StrictDeleteModalProps): ReactElement {
    const { open, resourceType, resourceName, subResources, handleDelete, onClose } = props;
    const [text, setText] = React.useState('');
    const onTextChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setText(event.target.value);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Delete {singularizeCamelCase(resourceType)} "{resourceName}" </DialogTitle>
            <DialogContent dividers>
                <DialogContentText color='red'>
                    You are going to delete {resourceName} and all {subResources}.
                    Deleted {subResources} CANNOT be restored! Are you ABSOLUTELY sure?
                </DialogContentText>
                <DialogContentText>
                    To prevent accidental actions, please confirm your intention by typing the following:
                </DialogContentText>
                <br/>
                <Typography variant='caption' color='red'>
                    <Chip label={resourceName} color='error' variant='outlined'/>
                </Typography>

                <TextField
                    autoFocus
                    margin='dense'
                    id='confirm-text-input'
                    variant='filled'
                    size='small'
                    onChange={onTextChange}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button id='delete-resource' size='small' variant='outlined' color='error' onClick={handleDelete} disabled={resourceName !== text}>Delete</Button>
            </DialogActions>

        </Dialog>
    );
}
