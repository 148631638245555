import Comparable from './comparable';
import {ResourceType} from './resource-type';

export default class ResourceBase implements Comparable<ResourceBase>{
    id?: number;
    parentId?: number;
    type: ResourceType;

    constructor(type: ResourceType, id?: number, parentId?: number) {
        this.id = id;
        this.parentId = parentId;
        this.type = type;
    }

    compareTo(other: ResourceBase): number {
        if (this.type < other.type || !this.id && other.id) {
            return -1;
        }
        if (this.type > other.type || this.id && !other.id) {
            return 1;
        }
        return this.id && other.id ? this.id - other.id : 0 ;
    }
}