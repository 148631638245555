import React from 'react';
import { uuid } from '../../../utils/utils';
import EventSequence from '../../../@types/event-sequence';
import EventSequenceService from '../../../services/event-sequence.service';
import JointJsCanvasEditor from './JointJsCanvasEditor';
import { toast } from 'react-toastify';
import {EventSequenceSummaryEditorProps} from '../../../@types/editor-props';
import {CanvasActionType} from '../../../@types/canvas-action-type';
import {useWorkspaceContext} from '../../../context/workspaceContext';

export default function EventSequenceTreeEditor(props: EventSequenceSummaryEditorProps): React.ReactElement {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { eventSequenceSummary, updateTab, isActive } = props;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [eventSequence, setEventSequence] = React.useState<EventSequence>({ id: 0 } as EventSequence);
    const uuidRef = React.useRef(uuid());
    const { setCurrentCanvasAction } = useWorkspaceContext();

    React.useEffect(() => {
        if (isActive) {
            setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_REFRESH });
        }
    }, [isActive]);

    React.useEffect(() => {
        EventSequenceService.getEventSequenceById(eventSequenceSummary.id).then((response) => {
            setEventSequence(response);
        }).catch((error: Error) => {
            toast.error(error.message);
        });
    
    }, [eventSequenceSummary]);

    return <div id={uuidRef.current} style={{ width: '100vw', height: 'calc(100vh - 250px)', maxWidth: '100%', maxHeight: '100%', overflow: 'hidden'}}>
        <JointJsCanvasEditor eventSequence={eventSequence} isActive={isActive} readonly={false} />
    </div>;
}

