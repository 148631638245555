import * as React from 'react';
import { ResourceType } from '../../../@types/resource-type';
import StrictDeleteModal from '../../form-components/StrictDeleteModal.component';

export default function DeleteResource(props: {
    open: boolean;
    resourceType: ResourceType;
    resourceName: string;
    subResources: string;
    onClose: () => void;
    handleDelete: () => void;
    
}): React.ReactElement {
   
    const { open, resourceType, resourceName, subResources, handleDelete, onClose } = props;

    
    return (
        <StrictDeleteModal
            open={open}
            resourceType={resourceType}
            resourceName={resourceName}
            subResources={subResources}
            onClose={onClose}
            handleDelete={handleDelete}
        />
    );
}


    