import React, {ReactElement, useEffect} from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import GroupEditor from './GroupEditor';
import {TabPanel} from '../../form-components/TabPanel.component';
import GroupMemberEditor from './GroupMemberEditor';
import {uuid} from '../../../utils/utils';
import {ResourceAction} from '../../../@types/resource-action';
import {useWorkspaceContext} from '../../../context/workspaceContext';
import {GroupEditorProps} from '../../../@types/editor-props';

export default function GroupEditorController(props: GroupEditorProps): ReactElement {
    const { group, isActive, updateTab } = props;
    const [value, setValue] = React.useState(0);
    const { currentAction } = useWorkspaceContext();
    const uuidRef = React.useRef(uuid());
    const handleChange = (event: React.SyntheticEvent, newValue: number): void => {
        setValue(newValue);
    };

    useEffect(() => {
        if (isActive && currentAction === ResourceAction.MEMBERS) {
            setValue(1);
        } else if (isActive && currentAction === ResourceAction.PROPERTIES) {
            setValue(0);
        }
    }, [currentAction]);

    return (   
        <>
            {group && group.id  ?
                <>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs
                            id={`group-editor-tabs-${uuidRef.current}`}
                            aria-label="Group editor tabs"
                            value={value}
                            onChange={handleChange}
                        >
                            <Tab
                                id={`group-tab-${uuidRef.current}`}
                                label="Group"
                                aria-label="Group tab"
                                aria-controls={`group-tab-${uuidRef.current}-tabpanel`}
                            />
                            <Tab
                                id={`group-members-tab-${uuidRef.current}`}
                                label="Members"
                                aria-label="Members tab"
                                aria-controls={`group-members-tab-${uuidRef.current}-tabpanel`}
                            />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0} ownerId={`group-tab-${uuidRef.current}`}>
                        {value === 0 && <GroupEditor group={group} isActive={isActive} updateTab={updateTab} />}
                    </TabPanel>
                    <TabPanel value={value} index={1} ownerId={`group-members-tab-${uuidRef.current}`}>
                        {value === 1 && <GroupMemberEditor group={group} />}
                    </TabPanel>
                </>
                :
                <GroupEditor group={group} isActive={isActive} updateTab={updateTab}/>
            }
        </>
    );
}