// This is a simple wrapper component that will add all children to the main content area. This is used to add the
// page title and page content to the main content area.
import {ReactElement} from 'react';

export default function PageContentWrapper(props: {leftNav?: boolean, children: any}): ReactElement {

    const { leftNav, children } = props;

    // This is the grid column for the main content area. If the left navigation is enabled, then the main content
    // area will start at column 7 and span 15 columns. If the left navigation is not enabled, then the main content
    // area will start at column 2 and span 15 columns.
    const gridColumns = leftNav ? {'gridColumn': '7 / span 15'} : {'gridColumn': '2 / span 21'};

    return (
        <div className={'devkit-page-content-wrapper'} style={{...gridColumns, 'textAlign': 'left'}}>
            {children}
        </div>
    );

}