import * as React from 'react';
import OkCancelModal from '../../form-components/OkCancelModal.component';
import {SelectedWorkspaceObject, WorkspaceResource} from '../../../@types/workspace-types';
import SelectList from '../../form-components/SelectList.component';
import Typography from '@mui/material/Typography';
import {filterOnGroupCanEditResources, getResourceNameWithAscendants} from '../../../utils/resource-utils';
import {ResourceType} from '../../../@types/resource-type';
import Group from '../../../@types/group';
import SafetyModel from '../../../@types/safety-model';
import {useWorkspaceContext} from '../../../context/workspaceContext';
import ResourceBase from '../../../@types/resource-base';
import AddIcon from '@mui/icons-material/Add';
import {Button} from '@mui/material';

export default function DuplicateResourceModal(props: {
    open: boolean;
    title: string;
    object: SelectedWorkspaceObject | null,
    data: WorkspaceResource;
    onClose: () => void;
    onConfirm: (id: number) => void;
    onClickNew?: any;
    
}): React.ReactElement {
   
    const { open, title, object, data, onConfirm, onClose, onClickNew } = props;
    const { getAdditionalResource } = useWorkspaceContext();
    const [selectedResource, setSelectedResource] = React.useState<number>(0);
    const [selectedGroupIndex, setSelectedGroupIndex] = React.useState<number>(0);
    const [dataItems, setDataItems] = React.useState<any>();

    React.useEffect(() => {
        setDataItems(resources2SelectListItems(data));
        if (dataItems?.length > 0) {
            if (object?.objectType === ResourceType.EVENT_SEQUENCES && selectedGroupIndex >= 0 && dataItems[selectedGroupIndex]?.children?.length === 1) {
                setSelectedResource(dataItems[selectedGroupIndex].children[0].id);
            }
            else if (object?.objectType === ResourceType.SAFETY_MODELS && dataItems.length === 1) {
                setSelectedResource(dataItems[0].id);
            }
        }
    } , [data]);

    const onSelectResourceChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        setSelectedResource(event.target.value as number);
    };    

    const onSelectGroupChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        setSelectedGroupIndex(dataItems.findIndex((item: { id: number; }) => item.id === event.target.value as number));
        if (selectedGroupIndex >= 0 && dataItems[selectedGroupIndex]?.children?.length === 1) {
            setSelectedResource(dataItems[selectedGroupIndex].children[0].id);
        }
    };

    const onClickNewGroup = (): void => {
        onClickNew(new ResourceBase(ResourceType.GROUPS));
    };

    const onClickNewSafetyModel = (): void => {
        onClickNew(new ResourceBase(ResourceType.SAFETY_MODELS, undefined, dataItems[selectedGroupIndex]?.id));
    };

    const isEventSequence = (resourceType: ResourceType|undefined): boolean => {
        return resourceType === ResourceType.EVENT_SEQUENCES || resourceType === ResourceType.EVENT_SEQUENCE_TREES || resourceType === ResourceType.EVENT_SEQUENCE_HISTORY;
    }

    const duplicateReady = (): boolean => {
        let ready = true;
        if (object?.objectType !== ResourceType.GROUPS) {
            ready = ready && dataItems?.length > 0 && selectedResource > 0;
            if (isEventSequence(object?.objectType)) {
                ready = ready && selectedGroupIndex >= 0 && dataItems[selectedGroupIndex]?.children?.length > 0;
            }
        }
        return ready;
    }

    const groupBy = (safetyModels: SafetyModel[]): {} => {
        return safetyModels.reduce((groupWithChildren: any, sm: SafetyModel) => {
            const groupId = sm.group.id;
            if (groupId !== undefined) {
                if (!groupWithChildren[groupId]) {
                    groupWithChildren[groupId] = [];
                }
                groupWithChildren[groupId].push(sm);
            }
            return groupWithChildren;
        }, {});
    };

    function resources2SelectListItems(data: WorkspaceResource): { id: number; name: string; children: { id: number; name: string; }[]; }[] {
        let items = [];
        if (data.resources) {
            if (data.objectType === ResourceType.GROUPS) {
                let resources = data.resources;
                resources = resources.filter((resource: Group) => resource.canAddOrEditResources());
                for (let i = 0; i < resources.length; i++) {
                    const resource = resources[i];
                    try {
                        items.push({
                            id: resource.id,
                            name: getResourceNameWithAscendants(resource, data.objectType),
                            children: [],
                        });
                    } catch (e) {
                        throw new Error('Error converting resources to group items: ' + e);
                    }
                }
            }
            else if (data.objectType === ResourceType.SAFETY_MODELS) {
                let resources = groupBy(data.resources);
                for (const [key, value] of Object.entries(resources)) {
                    let resource = value as SafetyModel[];
                    try {
                        items.push({
                            id: Number(key),
                            name: getResourceNameWithAscendants(resource[0].group, ResourceType.GROUPS),
                            children: resource.map(sm => { return { id: sm.id, name: sm.name }; }),
                        });
                    } catch (e) {
                        throw new Error('Error converting resources to select list items: ' + e);
                    }
                }
                //Add groups with no safety models, if any
                let groups = filterOnGroupCanEditResources(getAdditionalResource(ResourceType.GROUPS)).resources;
                groups?.forEach((group) => {
                    // @ts-ignore
                    if (!resources[group.id]) {
                        items.push({
                            id: group.id,
                            name: getResourceNameWithAscendants(group, ResourceType.GROUPS)
                        });
                    }
                });
            }
        }
        return items;
    }

    return (
        <OkCancelModal
            open={open}
            title={title}
            okButtonText='Duplicate'
            onClose={onClose}
            onConfirm={(): void => { onConfirm(selectedResource); }}
            okToConfirm={duplicateReady}
        >
            <Typography variant='body1'>
                Select a destination group
            </Typography>
            
            <hr />

            {(!dataItems || dataItems.length === 0) && object?.objectType !== ResourceType.GROUPS &&
                <Button
                    variant='contained'
                    title='New Group'
                    size='small'
                    component='button'
                    onClick={onClickNewGroup}
                    startIcon={<AddIcon />}
                >Create a Group</Button>
            }
            {(dataItems?.length > 0 || object?.objectType === ResourceType.GROUPS) &&
                <SelectList
                    value={isEventSequence(object?.objectType) && selectedGroupIndex >= 0 ? dataItems[selectedGroupIndex].id : selectedResource}
                    label='Target Group'
                    items={dataItems}
                    showTopLevel={true}
                    topLevelLabel={object?.objectType === ResourceType.GROUPS ? '--Top Level--' : '--Please Select--'}
                    onChange={isEventSequence(object?.objectType) ? onSelectGroupChange: onSelectResourceChange} />
            }
            <hr />

            {isEventSequence(object?.objectType) && dataItems?.length > 0 && selectedGroupIndex >= 0 && (!dataItems[selectedGroupIndex].children || dataItems[selectedGroupIndex]?.children?.length === 0) &&
                <Button
                    variant='contained'
                    title='New Safety Model'
                    size='small'
                    component='button'
                    onClick={onClickNewSafetyModel}
                    startIcon={<AddIcon />}
                >Create a Safety Model</Button>
            }
            {isEventSequence(object?.objectType) && dataItems?.length > 0 && selectedGroupIndex >= 0 && dataItems[selectedGroupIndex]?.children?.length > 0 &&
                <SelectList
                    value={selectedResource}
                    label='Target Safety Model'
                    items={dataItems[selectedGroupIndex].children}
                    showTopLevel={true}
                    onChange={onSelectResourceChange} />
            }
        </OkCancelModal>
    );
}


    