import React, {FC, ReactElement} from 'react';
import Typography from '@mui/material/Typography';
import PageTitle from '../components/PageTitle.component'
import PageContentWrapper from '../components/PageContentWrapper.component';
import FormWrapper from '../components/form-components/FormWrapper';

const ResetPasswordEmailSent: FC<any> = (): ReactElement => {

    return (
        <>
            <PageTitle title={'Reset Password Email Sent'} />

            <PageContentWrapper>
                <FormWrapper width={800} align={'center'}>
                    <div className="email-image email-image-file" />
                    <Typography variant='body1'>
                        <h1>Password Reset Email Sent</h1>
                        <p>You should receive an email within a few minutes.</p>
                        <p>Follow the directions in the email to reset your password.</p>
                        <p>If you did not receive an email, please contact the system administrator.</p>
                    </Typography>
                </FormWrapper>
            </PageContentWrapper>
        </>
    );
};

export default ResetPasswordEmailSent;