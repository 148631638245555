import { createContext, useState, useContext, ReactNode, ReactElement } from 'react';
import {DeletedWorkspaceObject, ISAMNode, SelectedWorkspaceObject, WorkspaceContextProps, WorkspaceResource} from '../@types/workspace-types';
import { ResourceType } from '../@types/resource-type';
import { PhaseOfFlight , OccurrenceCategory } from '../@types/event-sequence-summary';
import { ResourceAction } from '../@types/resource-action';
import { QueryCanvasAction } from '../@types/canvas-action-type';

export const WorkspaceContext = createContext<WorkspaceContextProps>({} as WorkspaceContextProps);
export const useWorkspaceContext = (): WorkspaceContextProps => useContext(WorkspaceContext);

export const WorkspaceProvider = ({ children }: { children: ReactNode }): ReactElement => {
    const [workspaceObjects, setWorkspaceObjects] = useState<WorkspaceResource|null>(null);
    const [currentWorkspaceObject, setCurrentWorkspaceObject] = useState<SelectedWorkspaceObject|null>(null);
    const [currentAction, setCurrentAction] = useState<ResourceAction|null>(null);
    const [additionalResources, setAdditionalResources] = useState<WorkspaceResource[]>([]);
    const [deletedWorkspaceObject, setDeletedWorkspaceObject] = useState<DeletedWorkspaceObject | null>(null);
    const [occurrenceCategories, setOccurrenceCategories] = useState<OccurrenceCategory[] | null>(null);
    const [pedigrees, setPedigrees] = useState<string[] | null>(null);
    const [phasesOfFlight, setPhasesOfFlight] = useState<PhaseOfFlight[] | null>(null);
    const [currentCanvasAction, setCurrentCanvasAction] = useState<QueryCanvasAction | null>(null);
    const [currentCanvasSelectedNode, setCurrentCanvasSelectedNode] = useState<ISAMNode | null>(null);
    

    function addOrUpdateAdditionalResource(resource: WorkspaceResource): void {
        setAdditionalResources(additionalResources.filter((r) => r.objectType !== resource.objectType).concat(resource));
    }

    function getAdditionalResource(objectType: ResourceType | undefined): WorkspaceResource {
        if (!objectType) {
            return { objectType: ResourceType.GROUPS };
        } else {
            return additionalResources.find((resource) => resource.objectType === objectType) || { objectType: objectType };
        }
        
    }

    function deleteAdditionalResource(objectType: ResourceType): void {
        setAdditionalResources(additionalResources.filter((resource) => resource.objectType !== objectType));
    }

    return (
        <WorkspaceContext.Provider value={{
            workspaceObjects, setWorkspaceObjects,
            currentWorkspaceObject, setCurrentWorkspaceObject,
            currentAction: currentAction, setCurrentAction: setCurrentAction,
            currentCanvasAction, setCurrentCanvasAction,
            currentCanvasSelectedNode, setCurrentCanvasSelectedNode,
            deletedWorkspaceObject, setDeletedWorkspaceObject,
            additionalResources, setAdditionalResources,
            addOrUpdateAdditionalResource, getAdditionalResource, deleteAdditionalResource,
            occurrenceCategories, setOccurrenceCategories,
            pedigrees, setPedigrees,
            phasesOfFlight, setPhasesOfFlight
        }}>
            {children}
        </WorkspaceContext.Provider>
    );
};
