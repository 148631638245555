
import React from 'react';
import { Button, TextField, List, ListItem, ListItemText, InputLabel, MenuItem, FormControl, Select, ListItemButton, ListItemIcon } from '@mui/material';
import EventSequenceSummary from '../../../@types/event-sequence-summary';
import { uuid } from '../../../utils/utils';
import { FormGroup, Stack } from '@mui/material';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ErrorAlertComponent from '../../../components/ErrorAlert.component';
import {useWorkspaceContext} from '../../../context/workspaceContext';
import Checkbox from '@mui/material/Checkbox';
import { PhaseOfFlight } from '../../../@types/event-sequence-summary';
import EventSequence from '../../../@types/event-sequence';
import {ResourceType} from '../../../@types/resource-type';
import SafetyModel from '../../../@types/safety-model';
import EventSequenceService from '../../../services/event-sequence.service';
import SafetyModelService from '../../../services/safety.service';
import {EventSequenceSummaryEditorProps} from '../../../@types/editor-props';
import {hasRightToEdit} from '../../../utils/role-to-rights';
import {ResourceAction} from '../../../@types/resource-action';

interface QuantificationMode {
    modeName: string;
    modeShortName: string;
}

const quantificationModes: QuantificationMode[] = [
    { modeName: 'Frequency Mode', modeShortName: 'MODE_FREQUENCY' },
    { modeName: 'Probability Mode', modeShortName: 'MODE_PROBABILITY' },
];

const EventSequenceSummaryEditor: React.FC<EventSequenceSummaryEditorProps> = (props: EventSequenceSummaryEditorProps) => {
    const { eventSequenceSummary, isActive, updateTab } = props;
    const [name, setName] = React.useState<string>('');
    const [uniqueId, setUniqueId] = React.useState<string>('');
    const [description, setDescription] = React.useState<string>('');
    const [checkedFlightPhases, setCheckedFlightPhases] = React.useState<PhaseOfFlight[]>([]);
    const [exposure, setExposure] = React.useState<string>('1');
    const [quantificationMode, setQuantificationMode] = React.useState<string>(quantificationModes[1].modeShortName);
    const [error, setError] = React.useState<string | null>(null);
    const [editMode, setEditMode] = React.useState(false);
    const uuidRef = React.useRef(uuid());
    const { workspaceObjects, setWorkspaceObjects, setCurrentWorkspaceObject, getAdditionalResource, currentAction, setCurrentAction, phasesOfFlight } = useWorkspaceContext();

    const onUniqueIdChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setUniqueId(event.target.value);
    };

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setName(event.target.value);
    };

    const onDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setDescription(event.target.value);
    };

    const onExposureChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setExposure(event.target.value);
    };

    const isNotSavable = (): boolean => {
        return eventSequenceSummary.id !== null && eventSequenceSummary.id !== undefined && !hasRightToEdit({objectType: ResourceType.EVENT_SEQUENCES, object: eventSequenceSummary}, undefined);
    }

    const onExposureChangeBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
        if (+event.target.value > 1) {
            // remove leading zeros
            setExposure(event.target.value.replace(/^0+/, ''));
        }
    }

    const onQuantificationModeChange = (event: any): void => {
        setQuantificationMode(event.target.value);
    };

    const handleFlightPhaseToggle = (phaseName: string) => (): void => {
        const currentIndex = checkedFlightPhases.findIndex(element => element.phaseName === phaseName);
        const newChecked = [...checkedFlightPhases];

        if (currentIndex === -1) {
            let currentItem = phasesOfFlight?.find(element => element.phaseName === phaseName);
            if (currentItem) {
                newChecked.push(currentItem);
            }
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setCheckedFlightPhases(newChecked);
    }

    const formEventSequence = (): EventSequence => {
        const eventSequenceSummaryData = {} as EventSequence;
        eventSequenceSummaryData.uniqueId = uniqueId ?? '';
        eventSequenceSummaryData.name = name ?? '';
        eventSequenceSummaryData.description = description;
        eventSequenceSummaryData.exposureFactor = +exposure > 0 ? +exposure : 1;
        eventSequenceSummaryData.quantificationMode = quantificationMode;
        eventSequenceSummaryData.phasesOfFlight = checkedFlightPhases;

        if (eventSequenceSummary && eventSequenceSummary.id) {
            eventSequenceSummaryData.id = eventSequenceSummary.id;
        }
        return eventSequenceSummaryData;
    }

    const updateEventSequence = (eventSequence: EventSequence): void => {
        let eventSequenceObject = { objectType: ResourceType.EVENT_SEQUENCES, object: eventSequence };
        setCurrentWorkspaceObject(eventSequenceObject);
        if (workspaceObjects?.objectType === ResourceType.SAFETY_MODELS) {
            let resourcesObject = getAdditionalResource(ResourceType.SAFETY_MODELS);
            if (resourcesObject && resourcesObject.resources) {
                let resources = [...resourcesObject.resources] as SafetyModel[];
                let sm = resources.find((resource) => resource.id == eventSequenceSummary.containerId);
                if (sm) {
                    if (editMode) {
                        let index = sm.children!.findIndex((es) => es.id === eventSequence.id);
                        if (index !== -1) {
                            sm.children![index] = eventSequence;
                        }
                        setWorkspaceObjects({ objectType: ResourceType.SAFETY_MODELS, resources: resources });
                    } else {
                        if (!sm.children) {
                            sm.children = [] as EventSequenceSummary[];
                            
                        }
                        sm.children.push(eventSequence);
                        sm.numberEventSequences = sm.children.length;
                        sm.children.sort((a, b) => a.uniqueId.localeCompare(b.uniqueId) || a.name.localeCompare(b.name));
                        setWorkspaceObjects({ objectType: ResourceType.SAFETY_MODELS, resources: resources });
                    }
                }
            } else {
                setWorkspaceObjects({ objectType: ResourceType.SAFETY_MODELS });
            }
        } 
        updateTab(eventSequenceObject);
    }

    const handleSave = (): void => {       
        if (name && uniqueId) {
            setError(null);
            if (editMode) {
                let eventSequence = formEventSequence();
                EventSequenceService.updateEventSequence(eventSequence)
                    .then((eventSequence: EventSequence) => {
                        updateEventSequence(eventSequence);
                    })
                    .catch((error) => {
                        setError(error.message);
                    });
            } else {
                let eventSequence = formEventSequence();
                SafetyModelService.addEventSequenceToSafetyModel(eventSequenceSummary.containerId, eventSequence)
                    .then((eventSequence: EventSequence) => {
                        updateEventSequence(eventSequence);
                        setEditMode(true);
                    })
                    .catch((error) => {
                        setError(error.message);
                    });
            }
        }
    };

    React.useEffect(() => {
        if (eventSequenceSummary && eventSequenceSummary.id) {
            setEditMode(true);
            setUniqueId(eventSequenceSummary.uniqueId ?? '');
            setName(eventSequenceSummary.name ?? '');
            setDescription(eventSequenceSummary.description);
            setExposure(eventSequenceSummary.exposureFactor.toString() ?? '1');
            if (eventSequenceSummary.quantificationMode) {
                setQuantificationMode(eventSequenceSummary.quantificationMode);
            }
            const phases = Array.isArray(eventSequenceSummary.phasesOfFlight) ?
                [...eventSequenceSummary.phasesOfFlight]
                : [] as PhaseOfFlight[];
            setCheckedFlightPhases(phases);
        }
        if (isActive && currentAction === ResourceAction.SAVE) {
            setCurrentAction(null);
            handleSave();
        }
    }, [eventSequenceSummary, currentAction]);

    return (
        <FormGroup className='formStyle'>
            <Stack className='formControl'>
                <TextField
                    label='Unique ID'
                    aria-label='unique id'
                    type='text'
                    variant="outlined"
                    size='small'
                    id={`unique-id-input-${uuidRef.current}`}
                    autoComplete='on'
                    value={uniqueId || ''}
                    onChange={onUniqueIdChange}
                    sx={{
                        '& input': {
                            border: 'none',
                        }
                    }}
                    InputProps={{
                        'role': 'textbox',
                        'tabIndex': 0,
                        'aria-labelledby': `unique-id-input-${uuidRef.current}`,
                        'aria-label': 'unique id'
                    }}
                    disabled={isNotSavable()}
                    fullWidth
                />
                <hr />
                <TextField
                    label='Name'
                    aria-label='name'
                    type='text'
                    variant="outlined"
                    size='small'
                    id={`name-input-${uuidRef.current}`}
                    autoComplete='on'
                    value={name || ''}
                    onChange={onNameChange}
                    sx={{
                        '& input': {
                            border: 'none',
                        }
                    }}
                    InputProps={{
                        'role': 'textbox',
                        'tabIndex': 0,
                        'aria-labelledby': `name-input-${uuidRef.current}`,
                        'aria-label': 'name'
                    }}
                    disabled={isNotSavable()}
                    fullWidth
                />                
                <hr />
                <TextField
                    label='Description'
                    aria-label='description'
                    type='text'
                    variant="outlined"
                    size='small'
                    id={`description-input-${uuidRef.current}`}
                    autoComplete='on'
                    value={description || ''}
                    onChange={onDescriptionChange}
                    minRows={3}
                    multiline
                    sx={{
                        '& input': {
                            border: 'none',
                        }
                    }}
                    InputProps={{
                        'role': 'textbox',
                        'tabIndex': 0,
                        'aria-labelledby': `description-input-${uuidRef.current}`,
                        'aria-label': 'description'
                    }}
                    disabled={isNotSavable()}
                    fullWidth
                />
                <hr />
                <TextField
                    error={exposure === '' || +exposure <= 0}
                    helperText={exposure === '' || +exposure <= 0 ? 'Exposure Factor must be greater than 0' : ''}
                    label='Exposure Factor'
                    aria-label='exposure'
                    type='number'
                    variant="outlined"
                    size='small'
                    id={`exposure-input-${uuidRef.current}`}
                    value={exposure}
                    onChange={onExposureChange}
                    onBlur={onExposureChangeBlur}
                    sx={{
                        '& input': {
                            border: 'none',
                        }
                    }}
                    inputProps={{
                        'step': '0.1',
                    }}
                    InputProps={{
                        'role': 'textbox',
                        'tabIndex': 0,
                        'aria-labelledby': `exposure-input-${uuidRef.current}`,
                        'aria-label': 'exposure',
                       
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={isNotSavable()}
                    fullWidth
                />
                <hr />
                <FormControl fullWidth>
                    <InputLabel tabIndex={-1} id={`select-mode-label-${uuidRef.current}`} htmlFor={`select-mode-${uuidRef.current}`}>Quantification Mode</InputLabel>
                    <Select
                        role='presentation'
                        labelId={`select-mode-label-${uuidRef.current}`}
                        id={`mode-${uuidRef.current}`}
                        size='small'
                        label='Quantification Mode'
                        value={quantificationMode}
                        onChange={onQuantificationModeChange}
                        inputProps={{
                            'role': 'textbox',
                            'tabIndex': 0,
                            'id': `select-mode-${uuidRef.current}`,
                        }}
                        disabled={isNotSavable()}
                    >
                        {quantificationModes.map((mode: QuantificationMode) => (
                            <MenuItem key={mode.modeShortName} value={mode.modeShortName}>{mode.modeName}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <hr />
                <fieldset style={{ border: '1px solid #ccc', borderRadius: '4px' }} disabled={isNotSavable()}>
                    <legend style={{
                        fontFamily: 'Roboto, Helvetica',
                        fontSize: '0.8rem',
                        fontWeight: 400,
                        lineHeight: 1.4,
                        color: 'rgba(0, 0, 0, 0.6)',
                        paddingLeft: '0.2rem',
                    }}>Phases of Flight</legend>                 
                    <List
                        role='presentation'
                        id={`select-list-${uuidRef.current}`}
                        aria-labelledby={`select-label-${uuidRef.current}`}
                        sx={{
                            maxHeight: '300px',
                            overflowY: 'auto',
                        }}
                        dense
                    >
                        {phasesOfFlight && phasesOfFlight.map((phase) => {
                            const labelId = `checkbox-list-label-${phase.phaseName}`;
                            return (
                                <ListItem
                                    key={phase.phaseName}
                                    dense
                                >
                                    <ListItemButton role={undefined} onClick={handleFlightPhaseToggle(phase.phaseName)} dense disabled={isNotSavable()}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={checkedFlightPhases.findIndex(element => element.phaseName === phase.phaseName) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': labelId }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={labelId} primary={phase.phaseName} />
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>           
                </fieldset>
                <hr />
                <div>
                    <Button className='formBtn' id={`save-safety-model-${uuidRef.current}`}
                        size='small' variant='contained'
                        aria-label='save safety model'
                        title='Save Safety Model'
                        onClick={handleSave}
                        startIcon={<SaveAltIcon />}
                        disabled={isNotSavable() || name.trim() === '' || uniqueId.trim() === ''}
                    >Save</Button>
                </div>
                {error ? <ErrorAlertComponent message={error} />  : null}
            </Stack>
        </FormGroup>
    );
};

export default EventSequenceSummaryEditor;
