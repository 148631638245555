enum JOB_STATUS_TYPES {
  completed = 'COMPLETED',
  started = 'STARTED',
  failed = 'FAILED',
  abandoned = 'ABANDONED',
  unknown = 'UNKNOWN',
  starting = 'STARTING',
  stopped = 'STOPPED',
  stopping = 'STOPPING'
}

export default JOB_STATUS_TYPES;

export function isComplete(status: string): boolean {
    return status === JOB_STATUS_TYPES.completed;
}

export function isStarted(status: string): boolean {
    return status === JOB_STATUS_TYPES.started;
}

export function isFailed(status: string): boolean {
    return status === JOB_STATUS_TYPES.failed;
}

export function isAbandoned(status: string): boolean {
    return status === JOB_STATUS_TYPES.abandoned;
}

export function isUnknown(status: string): boolean {
    return status === JOB_STATUS_TYPES.unknown;
}

export function isStarting(status: string): boolean {
    return status === JOB_STATUS_TYPES.starting;
}

export function isStopped(status: string): boolean {
    return status === JOB_STATUS_TYPES.stopped;
}

export function isStopping(status: string): boolean {
    return status === JOB_STATUS_TYPES.stopping;
}

export function isError(status: string): boolean {
    return status === JOB_STATUS_TYPES.failed
    || status === JOB_STATUS_TYPES.abandoned;
}