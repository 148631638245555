import AreYouSureModal from '../../form-components/AreYouSureModal.component';
import authService from '../../../services/auth.service';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress  from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';
import { getHighestEffectiveMembership, TieBreakerFn } from '../../../utils/role-to-rights';
import Group from '../../../@types/group';
import { GroupMember, GroupMemberModelWithMinRole, MinRole } from '../../../@types/group-member';
import GroupService from '../../../services/group.service';
import { isEmpty } from '../../../utils/utils';

import LogoutIcon from '@mui/icons-material/Logout';
import MemberInviter from './MemberInviter';
import Paper from '@mui/material/Paper';
import { PathToRootItem } from '../../../@types/group';
import React, { ReactElement } from 'react';
import RoleSelector from './RoleSelector';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { toast } from 'react-toastify';
import Typography from '@mui/material/Typography';
import {useWorkspaceContext} from '../../../context/workspaceContext';
import { getTreeItemDescendantIds } from '../../../utils/resource-utils';
import { ResourceType } from '../../../@types/resource-type';



const usersToMembershipsMap = (groupMembers: GroupMember[]): Record<string, GroupMember[]> => {
    const map: Record<string, GroupMember[]> = {};
    groupMembers.forEach((groupMember: GroupMember) => {
        const email = groupMember.isamUser.email;
        if (map[email]) {
            map[email].push(groupMember);
        } else {
            map[email] = [groupMember];
        }
    });

    return map;
}

const tieBreaker = (group: Group) => (member1: GroupMember, member2: GroupMember): GroupMember => {
    if (member2.group.id == group.id) {
        return member2;
    } else {
        return member1;
    }
};

const sortMemberships = function (tieBreakerFn: TieBreakerFn) {
    return function (a: GroupMember, b: GroupMember): number {
        const highestEffectiveMembership = getHighestEffectiveMembership(
            a,
            b,
            tieBreakerFn
        );
        if (highestEffectiveMembership === a) {
            return -1;
        } else {
            return 1;
        }
    };
};




// Formulates the source of the minimum role restriction
const formulateSource = function (member: GroupMember): PathToRootItem[] {
    // This is in the form of the group-path array item
    const id = member.group.id!;
    const groupMemberId = member.id!;
    const currentGroup: PathToRootItem = {
        id,
        name: member.group.name,
        currentUser: {
            groupMemberId,
            role: member.role,
        },
    };
    return (!member || !member.group || !member.group.pathToRoot)
        ? [currentGroup]
        : member.group.pathToRoot.concat(currentGroup);
};

const isDirectMember = function (currentGroup: Group, member: GroupMember): boolean {
    return member.group.id == currentGroup.id;
};

const findDirectMembership = function (memberships: GroupMember[], group: Group): GroupMember | undefined {
    return memberships.find((membership) => membership.group.id == group.id);
};


const getEffectiveMeberships = function (memberList: GroupMemberModelWithMinRole[], group: Group): GroupMemberModelWithMinRole {
    // If the Direct Membership is the highest effective role and there exists an inherited role
    const isDirect = isDirectMember(group, memberList[0]);
    let minRole: MinRole;
    if (isDirect && memberList.length > 1) {
        // Set the minRole property on the membership
        minRole = {
            role: memberList[1].role,
            isDirect,
            source: formulateSource(memberList[1]),
        };
    } else if (!isDirect) {
        // It is an inherited role
        minRole = {
            role: memberList[0].role,
            isDirect,
            source: formulateSource(memberList[0]),
        };
    } else {
        // It is a direct membership and there exists no inherited memberships
        minRole = {
            isDirect,
        };
    }
    // Return the highest effective role
    memberList[0].minRole = minRole;
    return memberList[0];
};

function mapWithEffectiveMembership(groupMembers: GroupMember[], group: Group): GroupMemberModelWithMinRole[] {
    return Object.values(usersToMembershipsMap(groupMembers)).map((memberList) =>
        getEffectiveMeberships(memberList.sort(sortMemberships(tieBreaker(group))), group)
    );
}

function groupMemberShip(groupMembers: GroupMember[], group: Group): GroupMemberModelWithMinRole[] {
    return mapWithEffectiveMembership(groupMembers, group)
        .sort((a, b) => a.isamUser.lastName.localeCompare(b.isamUser.lastName) ||
        a.isamUser.firstName.localeCompare(b.isamUser.firstName));
}
  
export default function GroupMemberEditor(props: { group: Group }): ReactElement {
    const { group } = props;
    const [groupMembers, setGroupMembers] = React.useState<GroupMember[]>([]);
    const [confirmRemoveUser, setConfirmRemoveUser] = React.useState<boolean>(false);
    const selectedGroupMemberRef = React.useRef<GroupMemberModelWithMinRole | null>(null);
    const [isRunnig, setIsRunning] = React.useState<boolean>(false);
    const { deletedWorkspaceObject, setDeletedWorkspaceObject,
        setWorkspaceObjects, workspaceObjects } = useWorkspaceContext();

    React.useEffect(() => {
        const fetch = async (): Promise<any> => {
            try {
                const groupMembers = await GroupService.fetchGroupMembersByGroupId(group.id!);
                setGroupMembers(groupMembers);
            } catch (error) {
                toast.error('Error fetching group members ');
            }
        }
        if (deletedWorkspaceObject && deletedWorkspaceObject.objectType == ResourceType.GROUPS) {
            let ids = [deletedWorkspaceObject.id, ...(deletedWorkspaceObject.children ?? [])];
            if (ids.findIndex(e => e == group.id!) != -1) {
                setGroupMembers([]);
            } else {
                fetch();
            }    
        } else {
            fetch();
        }            
    }, [group])

    const handleJoinGroup = async (groupMember: GroupMember): Promise<any> => {
        try {
            setIsRunning(true);
            const member = await GroupService.joinGroup(groupMember);
            setGroupMembers([...groupMembers, member]);
            toast.success(groupMember.isamUser.firstName + ' was added to the group. An email was sent to ' + groupMember.isamUser.firstName + ' to confirm.')
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsRunning(false);
        }
    }

    const handleExpelUser = async (groupMember: GroupMember): Promise<any> => {
        try {
            setIsRunning(true);
            await GroupService.cancelGroupMembership(groupMember);
            setGroupMembers(groupMembers.filter(member => member.id != groupMember.id));
            toast.success(groupMember.isamUser.firstName + ' has been removed from the group');
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsRunning(false);
        }
    }


    const handleLeaveGroup = async (groupMember: GroupMember): Promise<any> => {
        try {
            setIsRunning(true);
            let childrenIds = getTreeItemDescendantIds(group,
                ResourceType.GROUPS,
                workspaceObjects?.resources ?? []);

            await GroupService.cancelGroupMembership(groupMember);
            setDeletedWorkspaceObject({
                objectType: ResourceType.GROUPS,
                id: group.id!,
                children: childrenIds
            });
            setWorkspaceObjects({ objectType: ResourceType.GROUPS });
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsRunning(false);
        }
    }

    const handleRoleChange = async (groupMember: GroupMember, role: string): Promise<any> => {
        try {
            setIsRunning(true);
            const email = groupMember.isamUser.email;
            //const isLoggedIn = authService.getCurrentUser()?.email === email;
            const userMemberShip = usersToMembershipsMap(groupMembers)[email];
            // Find a direct membership if it exists
            const directMembership = findDirectMembership(
                userMemberShip,
                group
            );

            // If user is not already a direct member of this group
            if (isEmpty(directMembership)) {
                // Add a direct membership
                const groupMember2 = await GroupService.joinGroup({
                    isamUser: groupMember.isamUser,
                    group,
                    role,
                });
                setGroupMembers([...groupMembers, groupMember2]);
            } else {
                // If the user is already a direct member of this group
                // Update the role of the direct membership
                const updatedGroupMember = await GroupService.updateGroupMembershipRole({
                    id: groupMember.id,
                    isamUser: groupMember.isamUser,
                    group,
                    role,
                });
                setGroupMembers(groupMembers.map(member => member.id == updatedGroupMember.id ? updatedGroupMember : member));
            }
            toast.success('Role change successfully updated. An email was sent to ' + groupMember.isamUser.firstName + '.');
        } catch (error: any) {
            toast.error(error.message);
        } finally {
            setIsRunning(false);
        }
    }


    return (
        <Box sx={{height: '100%', p: 1}}>
            {group && group.canManageUser() &&
                <MemberInviter groupMembers={groupMembers} group={group} joinGroup={handleJoinGroup} />
            }
            
            <Box sx={{ width: '100%' }}>
                <Stack spacing={2} direction="column" justifyContent='space-evenly'>
                    <Typography variant="h6" component="div">
                        Existing Members
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">Member</TableCell>
                                    <TableCell align="left">Role</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {isRunnig && <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell  align="center">
                                        <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2} direction='row'>
                                            <CircularProgress />
                                            <CircularProgress />
                                            <CircularProgress />
                                        </Stack>
                                    </TableCell>
                                    <TableCell></TableCell>
                                </TableRow>}

                                {groupMemberShip(groupMembers, group).filter(member => !member.isamUser.superUser).map((member) => (
                                    <TableRow
                                        key={member.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            <div>
                                                {member.isamUser.firstName + ' ' + member.isamUser.lastName}
                                            </div>
                                            <a href="mailto:{{member.isamUser.email}}">
                                                {member.isamUser.email}
                                            </a>

                                        </TableCell>
                                        <TableCell align="left">{
                                            group.canManageUser() ?
                                                <RoleSelector groupMember={member}
                                                    onChange={(groupMember: GroupMember, role: string): void => {
                                                        handleRoleChange(groupMember, role);
                                                    }} />
                                                :
                                                member.role
                                        }
                                        {
                                            member.minRole && !member.minRole.isDirect && 
                                                <div>
                                                    Inherited from {member.group.name}
                                                </div>
                                        }

                                        </TableCell>
                                        <TableCell align="right">
                                            {member.minRole?.isDirect && member.isamUser.email === authService.getCurrentUser()?.email ?
                                                <Button variant="contained" color="error"
                                                    onClick={(): void => {
                                                        selectedGroupMemberRef.current = member;
                                                        setConfirmRemoveUser(true);
                                                    }}
                                                    startIcon={<LogoutIcon />}
                                                    title='Leave Group'
                                                    aria-label="Leave Group"></Button>
                                                : member.minRole?.isDirect && group.canManageUser() ?
                                                    <Button variant="contained" color="error"
                                                        onClick={(): void => {
                                                            selectedGroupMemberRef.current = member;
                                                            setConfirmRemoveUser(true);
                                                        }}
                                                        startIcon={<DeleteIcon />}
                                                        title='Remove Member'
                                                        aria-label="Remove Member"></Button> : null
                                            }
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                </Stack>
            </Box>
            <AreYouSureModal
                open={confirmRemoveUser && selectedGroupMemberRef.current != null}
                title={selectedGroupMemberRef.current ?
                    selectedGroupMemberRef.current.isamUser.firstName + ' ' + selectedGroupMemberRef.current.isamUser.lastName
                    : 'Leave Group'}
                onConfirm={(): void => {
                    setConfirmRemoveUser(false);
                    const groupMember = selectedGroupMemberRef.current!;
                    if (groupMember.minRole?.isDirect && groupMember.isamUser.email === authService.getCurrentUser()?.email) {
                        handleLeaveGroup(groupMember);
                    } else if (groupMember.minRole?.isDirect && group.canManageUser()) {
                        handleExpelUser(groupMember);
                    } 
                }}
                onClose={(): void => {
                    setConfirmRemoveUser(false);
                }}
            >
                <Typography variant='body1'>
                    Are you sure you want to remove {selectedGroupMemberRef.current ?
                        selectedGroupMemberRef.current.isamUser.firstName + ' ' + selectedGroupMemberRef.current.isamUser.lastName
                        : 'this user'} from the group?
                </Typography>

            </AreYouSureModal>

        </Box>
    );
}