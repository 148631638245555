import {BreadCrumbsProp} from '../@types/bread-crumb';
import { makeGroup, role, getPathToRootAsArrayOfString } from '../utils/group-utils';
import { TreeViewItem } from '../@types/tree-view-item';
import SafetyModel from '../@types/safety-model';
import { ResourceType } from '../@types/resource-type';
import EventSequenceSummary from '../@types/event-sequence-summary';
import Group from '../@types/group';
import { getResourceNameWithAscendants } from '../utils/resource-utils';

export function getEventSequencePathToRootAsArrayOfString(ess: any): Array<BreadCrumbsProp> {
    let path: Array<BreadCrumbsProp> = [];
    let safetyModel: SafetyModel = ess.safetyModel;
    
    if (ess.id) {
        path = getSafetyModelPathToRootAsArrayOfString(safetyModel);
    }
    

    
    return [...path, {
        id: ess.id,
        label: (!ess.name || ess.name === '') ? 'New Event Sequence' : ess.name,
        type: ResourceType.EVENT_SEQUENCES}];
}



export function getSafetyModelPathToRootAsArrayOfString(safetyModel: SafetyModel): Array<BreadCrumbsProp> {
    let path: Array<BreadCrumbsProp> = [];
    if (safetyModel.id) {
        path = getPathToRootAsArrayOfString(safetyModel.group);
    }

    
    return [...path, {
        id: safetyModel.id,
        label: safetyModel.name === '' ? 'New Safety Model' : safetyModel.name,
        type: ResourceType.SAFETY_MODELS}];
}

export function makeEventSequenceSummary(eventSequenceSummary: any): EventSequenceSummary {
    let ess: EventSequenceSummary = {} as EventSequenceSummary;
    ess.id = eventSequenceSummary.id;
    ess.uniqueId = eventSequenceSummary.uniqueId;
    ess.name = eventSequenceSummary.name;
    ess.description = eventSequenceSummary.description;
    ess.exposureFactor = eventSequenceSummary.exposureFactor;
    ess.phasesOfFlight = eventSequenceSummary.phasesOfFlight;
    ess.occurrenceCategory = eventSequenceSummary.occurrenceCategory;
    ess.containerId = eventSequenceSummary.containerId;
    ess.quantificationMode = eventSequenceSummary.quantificationMode;
    ess.role = eventSequenceSummary.role;
    return ess;
}

export function makeSafetyModel(safetyModel: any): SafetyModel {
    let sm: SafetyModel = {} as SafetyModel;

    sm.id = safetyModel.id;
    sm.name = safetyModel.name ?? '';
    sm.description = safetyModel.description ?? '';
    sm.numberEventSequences = safetyModel.numberEventSequences ?? 0;
    sm.group = safetyModel.group ? makeGroup(safetyModel.group) : new Group();
    sm.exposure = safetyModel.exposure ?? 0;
    return sm;
}

const safetyModelTreeItem = (sm: SafetyModel, role: string, path: string): TreeViewItem => {
    return {
        id: sm.id!.toString(),
        object: sm,
        name: sm.name,
        groupPath: path,
        groupRole: role,
        type: ResourceType.SAFETY_MODELS,
        hasChildren: sm.numberEventSequences > 0,
        children: sm.children?.map((ess: EventSequenceSummary) => {
            ess.role = sm.group.role();
            return {
                id: ess.id!.toString(),
                object: ess,
                name: ess.uniqueId + ' - ' + ess.name,
                type: ResourceType.EVENT_SEQUENCES
            }
        })
    };
}

export function safetyModelsTreeItems(safetyModels: SafetyModel[]): TreeViewItem[] {
    const obj = safetyModels.map((sm: SafetyModel) => {
        return {...sm, breadcrumb: getPathToRootAsArrayOfString(sm.group)} 
    }).reduce((acc: any, sm: SafetyModel) => {
        const path = sm.breadcrumb.map((bread: BreadCrumbsProp) => bread.label).join('>') + '-' + sm.group.id;
        if (!acc[path]) {
            acc[path] = [];
        }
        acc[path].push(sm);
        return acc;
    }, {});

    Object.keys(obj).forEach((key) => {
        obj[key].sort((a: SafetyModel, b: SafetyModel) => {
            return a.name.localeCompare(b.name);
        });
    });
    const treeItems: TreeViewItem[] = [];
    Object.keys(obj).sort().forEach((key) => {
        const fsm = obj[key][0];
        const sm_group = fsm.group;

        treeItems.push(...obj[key].map((sm: SafetyModel) => safetyModelTreeItem(sm, role(sm_group),
            getResourceNameWithAscendants(sm_group, ResourceType.GROUPS))));
    });

    
    return treeItems.sort((a: TreeViewItem, b: TreeViewItem) => a.name.localeCompare(b.name) || a.groupPath!.localeCompare(b.groupPath!));
}

export function addOrUpdateSafetyModel(safetyModel: SafetyModel, safetyModels: SafetyModel[]): SafetyModel[] {
    const newSafetyModelList: SafetyModel[] = [...safetyModels];

    let index = newSafetyModelList.findIndex((sm: SafetyModel) => sm.id == safetyModel.id);
    if (index === -1) {
        newSafetyModelList.push(safetyModel);
    } else {
        newSafetyModelList[index] = safetyModel;
    }

    return newSafetyModelList.sort((a: SafetyModel, b: SafetyModel) => a.name.localeCompare(b.name));
}

export function addEventSequence(safetyModel: SafetyModel|undefined, eventSequence: any): void {
    if (safetyModel) {
        if (!safetyModel.children || safetyModel.numberEventSequences == 0) {
            safetyModel.children = [] as EventSequenceSummary[];
        }
        safetyModel.children.push(eventSequence);
        safetyModel.numberEventSequences = safetyModel.children.length;
        if (safetyModel.children.length > 1) {
            safetyModel.children.sort((a, b) => a.uniqueId.localeCompare(b.uniqueId) || a.name.localeCompare(b.name));
        }
    }
}