import axios from 'axios';
import { API_URL, MEDIA_TYPE_V2 } from '../config/constants';
import Tag, {  TagLinks } from '../@types/tag';

class TagService {
    static tagUrl = API_URL + 'api/v2/tags';
    static tagGroupUrl = API_URL + 'api/v2/groups/{groupId}/tags';
    static tagEventSequenceUrl = API_URL + 'api/v2/eventSequences/{eventSequenceId}/tags';

    formTag(tagData: any): Tag {
        let tag: Tag = {} as Tag;
        tag.id = tagData.id;
        tag.name = tagData.name;
        tag.presentation = JSON.parse(tagData.presentation);
        tag.group = tagData.group;

        return tag;
    }

    formTags(tagsData: any): Tag[] {
        const tags: Tag[] = [];
        if (tagsData) {
            tagsData.forEach((tag: any) => {
                let t: Tag = this.formTag(tag);
                tags.push(t);
            })
        }
        return tags;
    }

    formTagLinks(tagsData: any): TagLinks {
        const emptyTagLinks = { eventTags: [], faultTreeNodeTags: [] };
        if (!tagsData) {
            return emptyTagLinks;
        }
        return { ...emptyTagLinks, ...tagsData };
    }


    getGroupTags(groupId: number): Promise<Tag[]> {
        let url = TagService.tagGroupUrl.replace('{groupId}', groupId.toString());
        return axios.get(url, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then((res: any) => {
            return res.data._embedded ? this.formTags(res.data._embedded.tagDTOList) : [];
        }).catch((error: any) => {
            throw new Error('Tags returned an error: ' + error);
        });
    }

    getEventSequenceTags(esdId: number): Promise<TagLinks> {
        let url = TagService.tagEventSequenceUrl.replace('{eventSequenceId}', esdId.toString());
        return axios.get(url, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then((res: any) => {
            return res.data._embedded ? this.formTagLinks(res.data._embedded.tagDTOList) : this.formTagLinks(res.data);
        }).catch((error: any) => {
            throw new Error('Event Sequence Tags returned an error: ' + error);
        });
    }

    addTag(tag: Tag): Promise<Tag> {
        return axios.post(TagService.tagUrl, { ...tag, presentation: JSON.stringify(tag.presentation) }, {
            withCredentials: true,
            headers: { 'Content-Type':MEDIA_TYPE_V2 }
        }).then((res: any) => {
            return this.formTag(res.data);
        }).catch((error: any) => {
            throw new Error('Tag returned an error: ' + error);
        });
    }

    updateNodeTagLinks(nodeId: number, tagIds: number[], isEvent: boolean): Promise<Tag[]> {
        let url = API_URL + 'safety/v1/safetyModelElement/' + (isEvent ? 'event' : 'fault_tree_node') + '/' + nodeId + '/tags';
        return axios.put(url, tagIds, {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json' }
        }).then((res: any) => {
            return this.formTags(res.data);
        }).catch((error: any) => {
            throw new Error('Tag returned an error: ' + error);
        });
    }

    updateTag(tag: Tag): Promise<void> {
        return axios.patch(TagService.tagUrl + '/' + tag.id, { ...tag, presentation: JSON.stringify(tag.presentation) }, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then(() => {
            return;
        }).catch((error: any) => {
            throw new Error('Tag returned an error: ' + error);
        });
    }

    deleteTag(tagId: number): Promise<void> {
        return axios.delete(TagService.tagUrl + '/' + tagId, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 }
        }).then(() => {
            return;
        }).catch((error: any) => {
            throw new Error('Tag returned an error: ' + error);
        });
    }
    
}

export default new TagService();