import { env } from '../env';

export const API_URL = env.REACT_APP_HOST ?? '/isam/';

// Base path for the website
export const BASE_PATH = env.PUBLIC_URL;

export const API_BASE_URL = API_URL + 'api/v2/';

// API constants
export const MEDIA_TYPE_V2 = 'application/hal+json';

// ISAM app specific constants
export const PLATFORM_NAME = 'ISAM';
export const PLATFORM_LONG_NAME = 'Integrated Safety Assessment Model';

// Website Contact Information
export const CONTACT_NAME = 'Dereck Wilson'
export const CONTACT_EMAIL = 'dereck.wilson@faa.gov'
