import axios from 'axios';
import { API_URL , MEDIA_TYPE_V2} from '../config/constants';

class SystemInfoService {
    static systemSettingsGetAllUrlV2 = API_URL + 'api/v2/systemInfo/all';
    static systemUrlV2  = API_URL + 'api/v2/systemInfo/system';
    static supportUrlV2 = API_URL + 'api/v2/systemInfo/support';
    static swaggerUrlV2 = API_URL + 'api/v2/systemInfo/swagger';


    // Get all current system settings
    getSystemSettings(): Promise<any> {
        return axios.get(SystemInfoService.systemSettingsGetAllUrlV2, {
            withCredentials: true,
            headers: { 'Content-Type': MEDIA_TYPE_V2 },
        }).then((response: any) => {
            return response.data;
        }).catch((error: any) => {
            throw new Error('Get system info returned an error: ' + error.message);
        });
    }

    // Patch system information attribute of the system settings
    async patchSystemInfo(systemInfo: any): Promise<any> {
        try {
            const response = await axios.patch(SystemInfoService.systemUrlV2, systemInfo, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 },
            });
            return response.data;
        } catch (error: any) {
            throw new Error('Patch system info returned an error: ' + error.message);
        }
    }

    // Patch support attribute of the system settings
    async patchSupportInfo(supportInfo: any): Promise<any> {
        try {
            const response = await axios.patch(SystemInfoService.supportUrlV2, supportInfo, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 },
            });
            return response.data;
        } catch (error: any) {
            throw new Error('Patch system info returned an error: ' + error.message);
        }
    }

    // Patch swagger attribute of the system settings
    async patchSwaggerInfo(swaggerInfo: any): Promise<any> {
        try {
            const response = await axios.patch(SystemInfoService.swaggerUrlV2, swaggerInfo, {
                withCredentials: true,
                headers: { 'Content-Type': MEDIA_TYPE_V2 },
            });
            return response.data;
        } catch (error: any) {
            throw new Error('Patch system info returned an error: ' + error.message);
        }
    }

    // Save all system settings
    saveSystemSettings(systemInfo: any, supportInfo: any, swaggerInfo: any): Promise<any> {
        const result = {
            systemInfo: {},
            supportInfo: {},
            swaggerInfo: {}
        };

        result.systemInfo = this.patchSystemInfo(systemInfo);
        result.supportInfo = this.patchSupportInfo(supportInfo);
        result.swaggerInfo = this.patchSwaggerInfo(swaggerInfo);
        return Promise.resolve(result);
    }       
}

export default new SystemInfoService();