import axios from 'axios';
import { API_URL } from '../config/constants';


class PedigreesService {
    static pedigreesUrl = API_URL + 'safety/pedigrees';
    

    getPedigrees(): Promise<any> {
        return axios.get(PedigreesService.pedigreesUrl, {
            withCredentials: true
        }).then(response => {
            return response.data;
        }).catch(err => {
            throw new Error('Failed to get pedigrees: ' + err);
        })
    }
}

export default new PedigreesService();