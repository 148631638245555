import Intro from './pages/intro.page';
import Signin from './pages/signin.page';
import LoginCallback from './pages/loginCallback.page';
import Workspace from './pages/workspace.page';
import ForgotPassword from './pages/forgotPassword.page';
import ResetPassword from './pages/resetPassword.page';
import ResetPasswordEmailSent from './pages/resetPasswordEmailSent.page';
import {Route} from './@types/route';
import Home from './pages/home.page';

export const routes: Array<Route> = [
    {
        key: 'default-route',
        title: 'ISAM',
        path: '/',
        enabled: false,
        requiresAuth: true,
        component: Home
    },
    {
        key: 'react-route',
        title: 'ISAM',
        path: '/react',
        enabled: false,
        requiresAuth: true,
        component: Home
    },
    {
        key: 'signin-route',
        title: 'Sign In',
        path: '/react/signin',
        enabled: true,
        requiresAuth: false,
        component: Signin
    },
    {
        key: 'login-callback-route',
        title: 'Log In Callback',
        path: '/react/login/callback',
        enabled: true,
        requiresAuth: false,
        component: LoginCallback
    },
    {
        key: 'signout-route',
        title: 'Sign Out',
        path: '/react/signout',
        enabled: true,
        requiresAuth: false,
        component: Intro
    },
    {
        key: 'forgot-password-route',
        title: 'Forgot Password',
        path: '/react/forgot-password',
        enabled: true,
        requiresAuth: false,
        component: ForgotPassword
    },
    {
        key: 'reset-password-route',
        title: 'Reset Password',
        path: '/react/password-reset',
        enabled: true,
        requiresAuth: false,
        component: ResetPassword
    },
    {
        key: 'reset-password-email-route',
        title: 'Reset Password Email Sent',
        path: '/react/reset-password-email-sent',
        enabled: true,
        requiresAuth: false,
        component: ResetPasswordEmailSent
    },
    {
        key: 'workspace',
        title: 'Workspace',
        path: '/react/workspace',
        enabled: true,
        requiresAuth: true,
        component: Workspace
    }    

]
