import {FC, ReactElement} from 'react';
import IntroComponent from '../components/Intro.component';

const Intro: FC<any> = (): ReactElement => {
    // @ts-ignore
    return (
        <IntroComponent />
    );
};

export default Intro;
