import SaveAltIcon from '@mui/icons-material/SaveAlt';

import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress  from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import React, { ReactElement, useEffect } from 'react';
import Select , { SelectChangeEvent } from '@mui/material/Select';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { isNumeric, uuid } from '../../../../utils/utils';
import { toast } from 'react-toastify';
import { OccurrenceCategory } from '../../../../@types/event-sequence-summary';
import { useWorkspaceContext } from '../../../../context/workspaceContext';
import { CanvasEnvironment } from '../../../../utils/canvas/canvas-environment';
import { ResourceType } from '../../../../@types/resource-type';
import EventSequenceService from '../../../../services/event-sequence.service';
import * as CanvasActions from '../../../../utils/canvas/canvas-actions';


interface SendObject {
    uniqueId: string;
    name: string;
    description: string;
    probability: number;
    units: string;
    pedigree: string;
    type: string;
    parentId: string;
    nodeClass: string;
    parentRelationType: string;
    occurrenceCategory: string | null;
    childIds: Array<number>;
    presentation: string;
}

function PaperComponent(props: PaperProps): ReactElement {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}

export default function AddPivotalEventModal(props: { open: boolean, env: CanvasEnvironment | null, onClose: () => void }): ReactElement {
    const { open, env, onClose } = props;
    const [name, setName] = React.useState<string | null>(null);
    const [description, setDescription] = React.useState<string | null>(null);
    const [probability, setProbability] = React.useState<number>(0);
    const [unit, setUnit] = React.useState<string | null>(null);
    const [pedigree, setPedigree] = React.useState<string | null>(null);
    const [nodeClass, setNodeClass] = React.useState<string | null>(null);
    const [parentRelation, setParentRelation] = React.useState<string | null>(null);
    const [occurrenceCategory, setOccurrenceCategory] = React.useState<string | null>(null);
    const [disableParentRelation, setDisableParentRelation] = React.useState<boolean>(false);
    
    const [isRunning, setIsRunning] = React.useState<boolean>(false);
    const { occurrenceCategories, pedigrees, setCurrentWorkspaceObject } = useWorkspaceContext();
    const uuidRef = React.useRef(uuid());

    const parentRelationTypes = ['YES', 'NO'];
    const nodeClasses = ['NODE', 'BARRIER', 'CIRCUMSTANTIAL'];


    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setName(event.target.value);
    };

    const onDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setDescription(event.target.value);
    };

    const onProbabilityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setProbability(parseFloat(event.target.value));
    };

    const onUnitChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setUnit(event.target.value);
    };

    const onPedigreeChange = (event: SelectChangeEvent): void => {
        setPedigree(event.target.value);
    };

    const onNodeClassChange = (event: SelectChangeEvent): void => {
        setNodeClass(event.target.value);
    };

    const onParentRelationChange = (event: SelectChangeEvent): void => {
        setParentRelation(event.target.value);
    };

    const onOccurrenceCategoryChange = (event: SelectChangeEvent): void => {
        setOccurrenceCategory(event.target.value);
    };

    const validInput = (): boolean => {
        return (name !== null && name.trim() !== '' && probability >= 0 && probability <= 1 && isNumeric(probability));
    }

    useEffect(() => {
        if (open) {
            setName('');
            setDescription('');
            setProbability(0);
            setUnit('');
            setPedigree('CALCULATED');
            setNodeClass('NODE');
            setOccurrenceCategory(null);
            setIsRunning(false);
            const [parentNode] = env!.selection
            const children = env!.graph.getNeighbors(parentNode, { outbound: true }).filter((cell) => cell.get('nodeClass') !== 'CALLOUTNODE');
            if (parentNode.get('data').type === 'INITIATING') {
                setDisableParentRelation(true);
            } else {
                setDisableParentRelation(false);
            }
            if ((parentNode.get('data').type === 'PIVOTAL') && (children.length === 1) && (children[0].get('data').parentRelationType === 'YES')) {
                setParentRelation('NO');
            } else {
                setParentRelation('YES');
            }
        }
    }, [open]);

    const handleSave = async (): Promise<any> => {
        let data = {} as SendObject;
        data['uniqueId'] = 'AUTO';
        data.name = name!;
        data.description = description!;
        data.probability = probability;
        data.units = unit!;
        data.pedigree = pedigree!;
        data.type = 'PIVOTAL';
        data.parentId = env!.selection[0].get('data').id.toString();
        data.nodeClass = nodeClass!;
        data.parentRelationType = parentRelation!;
        data.occurrenceCategory = occurrenceCategory;
        let childIds = env!.selection[0].get('data').childIds;
        data.childIds = childIds ?? [];
        // data.presentation = env!.selection[0].get('data').presentation;
        return EventSequenceService.addEventToEventSequence(env!.eventSequence!.id, data);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            fullWidth
            maxWidth="sm">
            <DialogTitle style={{ cursor: 'move'}} id='draggable-dialog-title'>
                Add Pivotal Event
            </DialogTitle>
            <DialogContent dividers>
                <FormGroup>
                    <Stack>
                        <TextField
                            margin='dense'
                            type='text'
                            size='small'
                            id={`pivotal-event-id-${uuidRef.current}`}
                            label='ID'
                            value='AUTO'
                            variant='outlined'
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                readOnly: true,
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `pivotal-event-id-${uuidRef.current}`,
                                'aria-label': 'ID',
                            }}
                            disabled
                        />
                        <hr />
                        <TextField
                            id={`pivotal-event-name-${uuidRef.current}`}
                            label='Name'
                            type='text'
                            margin='dense'
                            size='small'
                            value={name || ''}
                            variant="outlined"
                            onChange={onNameChange}
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `pivotal-event-name-${uuidRef.current}`,
                                'aria-label': 'name',
                            }}
                            fullWidth
                        />
                        <TextField
                            id={`pivotal-event-description-${uuidRef.current}`}
                            label='Description'
                            type='text'
                            margin='dense'
                            size='small'
                            variant='outlined'
                            value={description || ''}
                            multiline
                            minRows={3}
                            onChange={onDescriptionChange}
                            sx={{
                                '& input': {
                                    border: 'none',
                                }
                            }}
                            InputProps={{
                                'role': 'textbox',
                                'tabIndex': 0,
                                'aria-labelledby': `pivotal-event-description-${uuidRef.current}`,
                                'aria-label': 'description',
                            }}
                            fullWidth
                        />
                    </Stack>
                    <hr />
                    <Stack direction="row" spacing={2} justifyContent='start'>
                        <FormControl sx={{ width: '30%' }}>
                            <InputLabel tabIndex={-1} id={`pivotal-event-occurrence-category-label-${uuidRef.current}`} htmlFor={`pivotal-event-occurrence-category-list-${uuidRef.current}`}>Occurrence Category</InputLabel>
                            <Select
                                role='presentation'
                                labelId={`pivotal-event-occurrence-category-label-${uuidRef.current}`}
                                id={`pivotal-event-occurrence-category-select-label-${uuidRef.current}`}
                                size='small'
                                label='Occurrence Category'
                                value={occurrenceCategory || '0'}
                                onChange={onOccurrenceCategoryChange}
                                inputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'id': `pivotal-event-occurrence-category-list-${uuidRef.current}`,
                                }}
                            >
                                <MenuItem value={0} dense>-- None --</MenuItem>
                                {occurrenceCategories && occurrenceCategories.map((category: OccurrenceCategory) => (
                                    <MenuItem key={category.occurrenceCategoryShortName} value={category.occurrenceCategoryShortName} dense>{category.occurrenceCategoryName}</MenuItem>
                                ))}
                            </Select>                            
                        </FormControl>
                        <FormControl sx={{ width: '30%' }}>
                            <TextField
                                error={probability < 0 || probability > 1 || !isNumeric(probability)}
                                helperText={probability < 0 || probability > 1 || !isNumeric(probability) ? 'Probability must be between 0 and 1' : ''}
                                id={`pivotal-event-probability-${uuidRef.current}`}
                                label='Probability'
                                type='number'
                                size='small'
                                variant='outlined'
                                value={isNaN(probability) ? '' : probability}
                                onChange={onProbabilityChange}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                inputProps={{
                                    min: 0,
                                    max: 1,
                                    step: 0.000000001
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `pivotal-event-probability-${uuidRef.current}`,
                                    'aria-label': 'probability',
                                }}
                                fullWidth
                            />
                        </FormControl>
                        <FormControl sx={{ width: '30%' }}>
                            <TextField
                                id={`pivotal-event-unit-${uuidRef.current}`}
                                label='Unit'
                                type='text'
                                size='small'
                                variant='outlined'
                                value={unit || ''}
                                onChange={onUnitChange}
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }}
                                InputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'aria-labelledby': `pivotal-event-unit-${uuidRef.current}`,
                                    'aria-label': 'unit',
                                }}
                                fullWidth
                            />
                        </FormControl>

                    </Stack>
                    <hr />
                    <Stack direction="row" spacing={2} justifyContent='start'>
                        <FormControl sx={{ width: '30%' }}>
                            <InputLabel tabIndex={-1} id={`pivotal-event-pedigree-label-${uuidRef.current}`} htmlFor={`pivotal-event-pedigree-list-${uuidRef.current}`}>Pedigree</InputLabel>
                            <Select
                                role='presentation'
                                labelId={`pivotal-event-pedigree-label-${uuidRef.current}`}
                                id={`pivotal-event-pedigree-select-label-${uuidRef.current}`}
                                size='small'
                                label='Pedigree'
                                value={pedigree || '0'}
                                onChange={onPedigreeChange}
                                inputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'id': `pivotal-event-pedigree-list-${uuidRef.current}`,
                                }}
                            >
                                <MenuItem value={0} dense>-- None --</MenuItem>
                                {pedigrees && pedigrees.map((pedigree: string) => (
                                    <MenuItem key={pedigree} value={pedigree} dense>{pedigree}</MenuItem>
                                ))}
                            </Select>                            
                        </FormControl>
                        <FormControl sx={{ width: '30%' }}>
                            <InputLabel tabIndex={-1} id={`pivotal-event-node-class-label-${uuidRef.current}`} htmlFor={`pivotal-event-node-class-list-${uuidRef.current}`}>Node Class</InputLabel>
                            <Select
                                role='presentation'
                                labelId={`pivotal-event-node-class-label-${uuidRef.current}`}
                                id={`pivotal-event-node-class-select-label-${uuidRef.current}`}
                                size='small'
                                label='Node Class'
                                value={nodeClass || '0'}
                                onChange={onNodeClassChange}
                                inputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'id': `pivotal-event-node-class-list-${uuidRef.current}`,
                                }}
                            >
                                <MenuItem value={0} dense>-- None --</MenuItem>
                                {nodeClasses.map((nodeClass: string, index: number) => (
                                    <MenuItem key={index} value={nodeClass} dense>{nodeClass}</MenuItem>
                                ))}
                            </Select>                            
                        </FormControl>
                        <FormControl sx={{ width: '30%' }}>
                            <InputLabel tabIndex={-1} id={`pivotal-event-relation-label-${uuidRef.current}`} htmlFor={`pivotal-event-relation-list-${uuidRef.current}`}>Parent Relation Type</InputLabel>
                            <Select
                                role='presentation'
                                labelId={`pivotal-event-relation-label-${uuidRef.current}`}
                                id={`pivotal-event-relation-select-label-${uuidRef.current}`}
                                size='small'
                                label='Parent Relation Type'
                                value={parentRelation || 'YES'}
                                onChange={onParentRelationChange}
                                inputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'id': `pivotal-event-relation-list-${uuidRef.current}`,
                                }}
                                disabled={disableParentRelation}
                            >
                                {parentRelationTypes.map((relation: string, index: number) => (
                                    <MenuItem key={index} value={relation} dense>{relation}</MenuItem>
                                ))}
                            </Select>                            
                        </FormControl>

                    </Stack>

                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={isRunning ? <CircularProgress size={20} /> : <SaveAltIcon />}
                    onClick={async (): Promise<any> => {
                        setIsRunning(true);
                        try {
                            const response = await handleSave();
                            env!.isDirty = true;
                            setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                        } catch (error: any) {
                            toast.error(error.message);
                        } finally {
                            setIsRunning(false);
                            onClose();
                        }                                    
                    }}
                    disabled={!CanvasActions.canAddOrEditResource(env) || !validInput() || isRunning}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
   
}