import SaveAltIcon from '@mui/icons-material/SaveAlt';

import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress  from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import React, { ReactElement, useEffect } from 'react';

import { toast } from 'react-toastify';

import { ResourceType } from '../../../../@types/resource-type';

import { useWorkspaceContext } from '../../../../context/workspaceContext';
import { CanvasEnvironment } from '../../../../utils/canvas/canvas-environment';
import EventSequenceService from '../../../../services/event-sequence.service';
import * as canvasUtils from '../../../../utils/canvas/node-utils';
import * as CanvasActions from '../../../../utils/canvas/canvas-actions';

function PaperComponent(props: PaperProps): ReactElement {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}


export default function DeleteCalloutComponentModal(props: { open: boolean, env: CanvasEnvironment | null, onClose: () => void }): ReactElement {
    const { open, env, onClose } = props;

    const [uniqueId, setUniqueId] = React.useState<string>('');
    
    const { setCurrentWorkspaceObject } = useWorkspaceContext();
    
    const [isRunning, setIsRunning] = React.useState<boolean>(false);

    useEffect(() => {
        if (open) {
            const [selectedNode] = env!.selection
            setUniqueId(selectedNode.get('data').uniqueId);
            setIsRunning(false);
        }
    }, [open]);

    const handleSave = async (): Promise<any> => {
        const [selectedNode] = env!.selection

        let updateCallout = {
            id: selectedNode.get('data').id,
            uniqueId: selectedNode.get('data').uniqueId,
            name: selectedNode.get('data').name,
            callout: null
        }

        return canvasUtils.isEvent(selectedNode.get('data').type) ? EventSequenceService.updateEvent(updateCallout, false) : EventSequenceService.updateFaultTreeNode(updateCallout, false);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Delete Callout
            </DialogTitle>
            <DialogContent dividers>
                "Are you sure you want to delete the callout for <b>{uniqueId}</b>?"
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={isRunning ? <CircularProgress size={20} /> : <SaveAltIcon />}
                    onClick={async (): Promise<any> => {
                        setIsRunning(true);
                        try {
                            const response = await handleSave();
                            env!.isDirty = true;
                            setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                        } catch (error: any) {
                            toast.error(error.message);
                        } finally {
                            setIsRunning(false);
                            onClose();
                        }                                    
                    }}
                    disabled={!CanvasActions.canAddOrEditResource(env) || isRunning}
                >
                    Yes
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={onClose}
                    disabled={isRunning}
                >
                    No, Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

