import SaveAltIcon from '@mui/icons-material/SaveAlt';

import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress  from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormGroup from '@mui/material/FormGroup';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import React, { ReactElement, useEffect } from 'react';
import authService from '../../../../services/auth.service';
import { User } from '../../../../context/userContext';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Select , { SelectChangeEvent } from '@mui/material/Select';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

import {  uuid } from '../../../../utils/utils';
import { toast } from 'react-toastify';

import { ResourceType } from '../../../../@types/resource-type';

import { useWorkspaceContext } from '../../../../context/workspaceContext';
import { CanvasEnvironment } from '../../../../utils/canvas/canvas-environment';
import { CalloutPresentation } from '../../../../@types/callout';
import { FormControl,  InputLabel, MenuItem } from '@mui/material';
import EventSequenceService from '../../../../services/event-sequence.service';
import * as canvasUtils from '../../../../utils/canvas/node-utils';
import * as CanvasActions from '../../../../utils/canvas/canvas-actions';

const colorSwatches = [
    { color: '#ffffff', name: 'None' },
    { color: '#009966', name: 'Green Cyan' },
    { color: '#8fbc8f', name: 'Dark Sea Green' },
    { color: '#3cb371', name: 'Medium Sea Green' },
    { color: '#00b140', name: 'Green Screen' },
    { color: '#013220', name: 'Dark Green' },
    { color: '#6699cc', name: 'Blue Gray' },
    { color: '#0000ff', name: 'Blue' },
    { color: '#e6e6fa', name: 'Lavender' },
    { color: '#9400d3', name: 'Dark Violet' },
    { color: '#330066', name: 'Deep Violet' },
    { color: '#808080', name: 'Gray' },
    { color: '#36454f', name: 'Charcoal Gray' },
    { color: '#f7e7ce', name: 'Champagne' },
    { color: '#c21e56', name: 'Rose Red' },
    { color: '#cc338b', name: 'Magenta Pink' },
    { color: '#dc143c', name: 'Crimson' },
    { color: '#ff0000', name: 'Red' },
    { color: '#cd5b45', name: 'Dark Coral' },
    { color: '#eee600', name: 'Titanium Yellow' },
    { color: '#ed9121', name: 'Carrot Orange' },
    { color: '#c39953', name: 'Aztec Gold' },
];

const shapeSwatches = ['Cloud', 'Line Text', 'Rectangle'];

function PaperComponent(props: PaperProps): ReactElement {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}


export default function CalloutComponentModal(props: { open: boolean, env: CanvasEnvironment | null, onClose: () => void }): ReactElement {
    const { open, env, onClose } = props;
    const [calloutText, setCalloutText] = React.useState<string | undefined>(undefined);
    const [calloutColor, setCalloutColor] = React.useState<string | undefined>(undefined);
    const [calloutShape, setCalloutShape] = React.useState<string | undefined>(undefined);
    const [calloutShow, setCalloutShow] = React.useState<boolean | undefined>(undefined);
    const [calloutXFactor, setCalloutXFactor] = React.useState<number | undefined>(undefined);
    const [calloutYFactor, setCalloutYFactor] = React.useState<number | undefined>(undefined);
    const [calloutAuthorName, setCalloutAuthorName] = React.useState<string>('');
    const [calloutAuthorEmail, setCalloutAuthorEmail] = React.useState<string>('');
    
    const { setCurrentWorkspaceObject } = useWorkspaceContext();
    
    const [isRunning, setIsRunning] = React.useState<boolean>(false);
    const uuidRef = React.useRef(uuid());


    const onCalloutTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
        setCalloutText(event.target.value);
    };

    const onCalloutColorChange = (event: SelectChangeEvent): void => {
        setCalloutColor(event.target.value as string);
    };

    const onCalloutShapeChange = (event: SelectChangeEvent): void => {
        setCalloutShape(event.target.value as string);
    };

    const validInput = (): boolean => {
        return (calloutText !== undefined && calloutText.trim() !== '');
    }

    useEffect(() => {
        if (open) {
            const [selectedNode] = env!.selection
            setCalloutText(selectedNode.get('data').callout?.content);
            if (selectedNode.get('data').callout) {
                let calloutPresentation = JSON.parse(selectedNode.get('data').callout.presentation) as CalloutPresentation;
                setCalloutColor(calloutPresentation.color);
                setCalloutShape(calloutPresentation.shape);
                setCalloutShow(calloutPresentation.show);
                setCalloutXFactor(calloutPresentation.x_factor);
                setCalloutYFactor(calloutPresentation.y_factor);
                setCalloutAuthorName(calloutPresentation.authorName);
                setCalloutAuthorEmail(calloutPresentation.authorEmail);
            } else {
                let user: User | null = authService.getCurrentUser();
                setCalloutColor('#ffffff');
                setCalloutShape('Line Text');
                setCalloutShow(true);
                setCalloutXFactor(0.75);
                setCalloutYFactor(-1);
                setCalloutAuthorName(user ? user.firstName + ' ' + user.lastName : '');
                setCalloutAuthorEmail(user ? user.email : '');
            }
            setIsRunning(false);
        }
    }, [open]);

    const handleSave = async (): Promise<any> => {
        const [selectedNode] = env!.selection
        let calloutPresentaion = {
            color: calloutColor!,
            shape: calloutShape!,
            show: calloutShow!,
            x_factor: calloutXFactor!,
            y_factor: calloutYFactor!,
            authorName: calloutAuthorName,
            authorEmail: calloutAuthorEmail,
        } as CalloutPresentation;

        let updateCallout = {
            id: selectedNode.get('data').id,
            uniqueId: selectedNode.get('data').uniqueId,
            name: selectedNode.get('data').name,
            callout: {
                content: calloutText!,
                presentation: JSON.stringify(calloutPresentaion),
            }
        }

        return canvasUtils.isEvent(selectedNode.get('data').type) ? EventSequenceService.updateEvent(updateCallout, false) : EventSequenceService.updateFaultTreeNode(updateCallout, false);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Callout
            </DialogTitle>
            <DialogContent dividers>
                <FormGroup>
                    <TextField
                        id={`callout-text-id-${uuidRef.current}`}
                        label="Callout Text"
                        type='text'
                        margin='dense'
                        size='small'
                        variant='outlined'
                        multiline
                        rows={6}
                        value={calloutText || ''}
                        onChange={onCalloutTextChange}
                        sx={{
                            '& input': {
                                border: 'none',
                            }
                        }}
                        InputProps={{
                            'role': 'textbox',
                            'tabIndex': 0,
                            'aria-labelledby': `callout-text-${uuidRef.current}`,
                            'aria-label': 'description',
                        }}
                        inputRef={(input): void => input?.focus()}
                        fullWidth        
                    />
                    <hr />
                    <Stack direction="row" spacing={2} justifyContent='start'>
                        <FormControl sx={{ width: '30%' }}>
                            <InputLabel tabIndex={-1} id={`callout-shape-label-${uuidRef.current}`} htmlFor={`callout-shape-list-${uuidRef.current}`}>Shape</InputLabel>
                            <Select
                                labelId={`callout-shape-label-${uuidRef.current}`}
                                id={`callout-shape-list-${uuidRef.current}`}
                                value={calloutShape || 'Line Text'}
                                label="Shape"
                                onChange={onCalloutShapeChange}
                                inputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'id': `callout-shape-list-${uuidRef.current}`,
                                }}
                            >
                                {shapeSwatches.map((shape: string, index: number) => (
                                    <MenuItem key={index} value={shape} dense>{shape}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: '30%' }}>
                            <InputLabel tabIndex={-1} id={`callout-color-label-${uuidRef.current}`} htmlFor={`callout-color-list-${uuidRef.current}`}>Color</InputLabel>
                            <Select
                                labelId={`callout-color-label-${uuidRef.current}`}
                                id={`callout-color-list-${uuidRef.current}`}
                                value={calloutColor || '#ffffff'}
                                label="Color"
                                onChange={onCalloutColorChange}
                                inputProps={{
                                    'role': 'textbox',
                                    'tabIndex': 0,
                                    'id': `callout-color-list-${uuidRef.current}`,
                                }}
                                disabled={calloutShape === 'Line Text'}
                            >
                                {colorSwatches.map((color: { color: string, name: string }, index: number) => (
                                    <MenuItem key={index}
                                        value={color.color}
                                        dense
                                        sx={{ backgroundColor: color.color }}
                                    >
                                        {color.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ width: '30%' }}>
                            <FormControlLabel
                                id={`callout-show-${uuidRef.current}`}
                                
                                control={<Checkbox checked={!!calloutShow} onChange={(event: React.ChangeEvent<HTMLInputElement>): void => setCalloutShow(event.target.checked)} />}
                                label="Show callout"
                                labelPlacement="start"
                                sx={{
                                    '& input': {
                                        border: 'none',
                                    }
                                }} 
                                style={{
                                    fontFamily: 'Roboto, Helvetica',
                                    fontSize: '0.8rem',
                                    fontWeight: 400,
                                    lineHeight: 1.4,
                                    color: 'rgba(0, 0, 0, 0.6)',
                                    paddingLeft: '0.2rem',
                                }}
                            />
                        </FormControl>

                            
                    </Stack>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={isRunning ? <CircularProgress size={20} /> : <SaveAltIcon />}
                    onClick={async (): Promise<any> => {
                        setIsRunning(true);
                        try {
                            const response = await handleSave();
                            env!.isDirty = true;
                            setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                        } catch (error: any) {
                            toast.error(error.message);
                        } finally {
                            setIsRunning(false);
                            onClose();
                        }                                    
                    }}
                    disabled={!CanvasActions.canAddOrEditResource(env) || !validInput() || isRunning}
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={onClose}
                    disabled={isRunning}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

