import {FC, ReactElement} from 'react';
import PageContentWrapper from '../components/PageContentWrapper.component';
import {PLATFORM_LONG_NAME, PLATFORM_NAME} from '../config/constants'

const IntroComponent: FC<any> = (): ReactElement => {
    return (
        <>
            <PageContentWrapper>
                <div className="hero-image hero-image-file">
                    <section className="center-content splash semi-transparent-overlay">
                        <div className="section-title">
                            <h1 className="future-font">The {PLATFORM_LONG_NAME} ({PLATFORM_NAME})</h1>
                            <h3>A risk modeling framework to support your risk-informed decision making processes.</h3>
                        </div>
                        <span className="title-separator"></span>
                    </section>
                    <section className="center-content splash semi-transparent-overlay">
                        <div className="section-title">
                            What is {PLATFORM_NAME}?
                        </div>
                        <span className="title-separator"></span>
                        <div className="answer">
                            <p>
                                The {PLATFORM_LONG_NAME} is a framework to help you construct an air transportation
                                system
                                safety risk picture.
                            </p>
                            <br/>
                            <p>
                                {PLATFORM_NAME} provides tools to help you model, analyze, monitor, and predict safety
                                risk in the aviation system.
                            </p>
                        </div>
                    </section>
                </div>
                <section className="center-content what-can-isam-do-for-you">
                    <div className="section-title">
                        What can {PLATFORM_NAME} do for you?
                    </div>
                    <span className="title-separator"></span>
                    <div className="answer answer_right">
                        You can use {PLATFORM_NAME} to solve common safety analysis problems.
                    </div>
                    <div className="cards">
                        <div className="card">
                            <div className="card-icon">
                                <i className="fab fa-wpforms"></i>
                            </div>
                            <div className="card-title">
                                Descriptive Analytics
                            </div>
                            <div className="card-body">
                                Understand how often safety risks occur in the air transportation system from
                                analysis of safety data.
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-icon">
                                <i className="fas fa-sitemap"></i>
                            </div>
                            <div className="card-title">
                                Diagnostic Analytics
                            </div>
                            <div className="card-body">
                                Analyze the reasons why accidents and incidents occur. Identify common themes in the
                                risk picture that threaten safety.
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-icon">
                                <i className="fas fa-chart-bar"></i>
                            </div>
                            <div className="card-title">
                                Predictive Analytics
                            </div>
                            <div className="card-body">
                                Forecast how risk will evolve under different assumptions about the future of the
                                air transportation system.
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-icon">
                                <i className="fas fa-tachometer-alt"></i>
                            </div>
                            <div className="card-title">
                                Prescriptive Analytics
                            </div>
                            <div className="card-body">
                                Estimate the effectiveness of different strategies for mitigating predicted safety
                                risks in the air transportation system.
                            </div>
                        </div>
                    </div>
                </section>
            </PageContentWrapper>
        </>
    );
};

export default IntroComponent;
