import {ReactElement} from 'react';

export default function ErrorAlertComponent(props: {message: string}): ReactElement {

    return (
        <div className="devkit-error-message-wrapper" role="dialog" aria-labelledby="error-message-heading"
            aria-describedby="error-message-body">
            <div className="devkit-message-heading-wrapper">
                <span className="fa fa-times-circle" aria-hidden="true" title="error icon" />
            </div>
            <div className="devkit-message-body-wrapper">
                <span className="fa fa-times" aria-hidden="true" title="Close" aria-label="Close button" />
                <div className="devkit-message-heading" id="error-message-heading">
                    Error
                </div>
                <p className="devkit-message-body" id="error-message-body">
                    {props.message}
                </p>
            </div>
        </div>
    )
}