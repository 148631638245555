import {BreadCrumbsProp} from '../@types/bread-crumb';
import { TreeViewItem } from '../@types/tree-view-item';
import Group from '../@types/group';
import {addItemToArray} from './utils';
import {ResourceType} from '../@types/resource-type';
import {WorkspaceResource} from '../@types/workspace-types';
import {filterOnGroupCanEdit, getTreeItemDescendantIds} from './resource-utils';

/* convert the group read from storage to a real Group object */
export function makeGroup(group: any): Group {
    let grp: Group = new Group();
    grp.id = parseInt(group.id);
    grp.name = group.name;
    grp.description = group.description;
    grp.currentUser = group.currentUser;
    grp.pathToRoot = group.pathToRoot;
    grp.parent = group.parent;
    grp.children = group.children;
    return grp;
}

export function getGroupById(groups: Array<Group>, id: number): Group | undefined {
    return groups.find((group: Group) => group.id == id);
}

export function role(group: Group): string {
    return group.role() ?? 'none';
}

export function getPathToRootAsArrayOfString(group: Group): Array<BreadCrumbsProp> {
    let path: Array<BreadCrumbsProp> = [];
    if (group.pathToRoot) {
        path = group.pathToRoot.map((item) => {
            return { 
                id: item.id,
                label: item.name,
                type: ResourceType.GROUPS
            };
        });
    }
    return [...path, {
        id: group.id,
        label: group.name === '' ? 'New Group' : group.name,
        type: ResourceType.GROUPS}];
}

export function getGroupNameWithAscendants(group: Group): string {
    const path = getPathToRootAsArrayOfString(group);
    const name = path.pop()!.label;
    return path.length >= 1 ? name + ' (' + path.map((item) => item.label).join(' > ') + ')' : name ;
}

const groupTreeItem = (gNode: Group, groups: Group[]): TreeViewItem => {
    const findGroup = (id: number): Group => {
        return groups.find((group) => group.id === id)?? {} as Group;
    }

    return {
        id: gNode.id!.toString(),
        object: gNode,
        name: gNode.name,
        type: ResourceType.GROUPS,
        groupRole: role(gNode),
        children: gNode.children && gNode.children.length > 0 ?
            gNode.children.filter((id) => findGroup(id) !== null)
                .map((id) => groupTreeItem(findGroup(id), groups))
            : undefined,
    };
}

export function getGroupTreeItemDescendantIds(group: Group, groups: Group[]): number[] {
    const findGroup = (id: number): Group => {
        return groups.find((group) => group.id === id) ?? {} as Group;
    }

    const descendantIds: number[] = [];
    if (group.children && group.children.length > 0) {
        group.children.forEach((id) => {
            const child = findGroup(id);
            descendantIds.push(child.id!);
            if (child.children && child.children.length > 0) {
                descendantIds.push(...getGroupTreeItemDescendantIds(child, groups));
            }
        });
    }
    return descendantIds;
}

export function groupsTreeItems(groups: Group[]): TreeViewItem[] {
  
    const findTopLevelGroups = (groups: Group[]): Group[] => {    
        return groups.filter((group) => group.parent === undefined);
    }
    
    const topLevelGroups = findTopLevelGroups(groups).sort((a, b) => a.name.localeCompare(b.name));
    const treeItems: TreeViewItem[] = topLevelGroups.map((group) => groupTreeItem(group, groups));
    return treeItems;
}

export function addOrUpdateGroup(group: Group, groups: Group[]): Group[] {
    const newGroupList: Group[] = [];
    const oldGroup = getGroupById(groups, group.id!);
    const oldParent = oldGroup?.parent;
    groups.forEach((grp) => {
        if (group.parent && grp.id == group.parent) {
            var index = grp.children?.indexOf(group.id!);
            if (index == undefined || index < 0) {
                grp.children = addItemToArray(grp.children, group.id);
            }
        } else if (oldParent && grp.id == oldParent) {
            grp.children = grp.children?.filter(child => child != group.id);
        }
        if (grp.id != group.id) {
            newGroupList.push(grp);
        }
    });
    newGroupList.push(group);
    return newGroupList.sort((a: Group, b: Group) => a.name.localeCompare(b.name));
}

export function getTargetGroups(data: WorkspaceResource, object: any, workspaceObjects: WorkspaceResource|null): WorkspaceResource {
    if (object instanceof Group) {
        return excludeGroupAndDescendants(data, object, workspaceObjects);
    } else {
        return filterOnGroupCanEdit(data);
    }
}

export function excludeGroupAndDescendants(data: WorkspaceResource, group: Group, workspaceObjects: WorkspaceResource|null): WorkspaceResource {
    let resources = data.resources;
    if (resources) {
        let ids = [group.id, ...getTreeItemDescendantIds(group,
            ResourceType.GROUPS,
            workspaceObjects?.resources ?? [])];
        resources = resources.filter((resource: Group) => ids.indexOf(resource.id) === -1);
    }
    return { ...data, resources: resources };
}