import {ReactElement} from 'react';
import {useWorkspaceContext} from '../../context/workspaceContext';
import Explorer from './Explorer.component';
import ResourceBase from '../../@types/resource-base';
import {camelCaseToWords} from '../../utils/utils';
import {WorkspaceResource} from '../../@types/workspace-types';
import {ResourceType} from '../../@types/resource-type';

export default function ExplorerContainer(props: {
    loadObjects: any,
    objects: WorkspaceResource|null,
    addOrSelectResource: any,
    exploreMode: boolean,
    setObjects: (objects: WorkspaceResource|null) => void,
}): ReactElement {
    const { workspaceObjects, currentWorkspaceObject, getAdditionalResource } = useWorkspaceContext();
    const { loadObjects, exploreMode, objects, setObjects } = props;
    

    function onClickNew(): void {
        // @ts-ignore
        props.addOrSelectResource(new ResourceBase(workspaceObjects.objectType));
    }

    const onClickItem = (event: any): void => {
        let resourceInfo = event.target.id.split('-', 3);
        if (resourceInfo.length === 3) {
            props.addOrSelectResource(new ResourceBase(resourceInfo[1], resourceInfo[2]));
        }
    }

    function getExplorerType(): ResourceType {
        let currentType = workspaceObjects ? workspaceObjects.objectType : ResourceType.GROUPS;
        if (currentWorkspaceObject && !exploreMode) {
            currentType = currentWorkspaceObject.objectType;
            if (currentType === ResourceType.EVENT_SEQUENCE_TREES || currentType === ResourceType.EVENT_SEQUENCE_HISTORY || currentType === ResourceType.EVENT_SEQUENCES) {
                currentType = ResourceType.SAFETY_MODELS;
            }
        }
        return currentType;
    }

    return (
        <>
            <Explorer
                exploreMode={exploreMode}
                label={camelCaseToWords(getExplorerType())}
                type={getExplorerType()}
                objects={objects}
                additionalResources={getAdditionalResource(ResourceType.GROUPS)}
                onClickItem={onClickItem}
                onClickNew={onClickNew}
                loadObjects={loadObjects}
                setObjects={setObjects}
            />
        </>
    );
}