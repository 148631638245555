import React, { ReactElement, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import HelpAboutModal from '../modals/HelpAbout.Component';

export default function HelpMenu(): ReactElement {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openAbout, setOpenAbout] = useState<boolean>(false);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleAboutClick = (): void => {
        setOpenAbout(true);
        handleClose();     
    }

    return (
        <>
            <Button
                id="help-menu-button"
                aria-controls={open ? 'help-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                aria-label="Help Menu"
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                style={{ color: 'white', textTransform: 'none', }}
                sx={{
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                }}
            >
                Help
            </Button>
            <Menu
                id="help-menu"
                aria-labelledby="help-menu-button"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'help-menu-button',
                }}
            >
                <MenuItem onClick={handleAboutClick} dense>About</MenuItem>
            </Menu>

            {openAbout && <HelpAboutModal open={openAbout} onClose={(): void => setOpenAbout(false)} />}
        </>
    );
}