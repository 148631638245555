import Comparable from './comparable';
import roleToRights, { groupRights, roles } from '../utils/role-to-rights';
import authService from '../services/auth.service';
export interface CurrentUser {
    groupMemberId: number;
    role: string;
}

export interface PathToRootItem {
    id: number;
    name: string;
    currentUser?: CurrentUser;
}

export interface IdOnlyGroup {
    id: number;
}

export default class Group implements Comparable<Group>{
    id: number|undefined;
    name: string = '';
    description: string|undefined;
    parent: number|undefined;
    children?: Array<number>;
    pathToRoot?: Array<PathToRootItem>;
    currentUser?: CurrentUser;

    isDirectMember(): boolean {
        return this.currentUser !== undefined &&
            this.currentUser !== null &&
            this.currentUser.groupMemberId !== undefined &&
            this.currentUser.groupMemberId !== null;
    }

    setParent(parent: number|undefined): Group {
        this.parent = parent;
        return this;
    }

    getChildren(): Array<number> {
        return this.children ?? [];
    }

    role(): string {
        if (authService.isSuperUser()) {
            return roles.super;
        } else if (this.currentUser !== undefined && this.currentUser !== null) {
            return this.currentUser.role;
        } else {
            return '';
        }
    }

    rights(): Array<string> {
        return roleToRights(this.role(), 'group');
    }

    isRoot(): boolean {
        return this.parent === undefined;
    }

    canRead(): boolean {
        return this.rights().includes(groupRights.read);
    }  
    
    canEdit(): boolean {
        return this.rights().includes(groupRights.edit);
    }

    canAddOrEditResources(): boolean {
        return this.rights().includes(groupRights.addOrEditResources);
    }

    canLeave(): boolean {
        return this.isDirectMember() && this.rights().includes(groupRights.leave);
    }
    
    canManageUser(): boolean {
        return this.rights().includes(groupRights.manageUser);
    }
    
    compareTo(other: Group): number {
        if (this.name < other.name) {
            return -1;
        }
        if (this.name > other.name) {
            return 1;
        }
        return 0;
    }
}

