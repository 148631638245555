import React, {useContext, useState} from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import {useWorkspaceContext} from '../../../context/workspaceContext';
import {CanvasActionType} from '../../../@types/canvas-action-type';
import * as CanvasUtils from '../../../utils/canvas/node-utils'
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {EsdEvent} from '../../../@types/esdEvent';
import {hasRightToEdit} from '../../../utils/role-to-rights';
import {UserContext} from '../../../context/userContext';

export default function FaultTreeMenu(): React.ReactElement {
    const { currentWorkspaceObject, currentCanvasSelectedNode, setCurrentCanvasAction } = useWorkspaceContext();
    const { user } = useContext(UserContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const canPerform = (perform: (event: EsdEvent) => boolean): boolean => {
        return hasRightToEdit(currentWorkspaceObject!, user?.superUser) && currentCanvasSelectedNode !== null && perform(currentCanvasSelectedNode as EsdEvent);
    }

    const setCanvasAction = (type: CanvasActionType): void => {
        setCurrentCanvasAction({ actionType: type });
        handleClose();
    }

    return (
        <>
            <Button
                id="fault-tree-menu-button"
                aria-controls={open ? 'fault-tree-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                aria-label="Fault Tree Menu"
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                style={{ color: 'white', textTransform: 'none', }}
                sx={{
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                }}
            >
                Fault Tree
            </Button>
            <Menu
                id="fault-tree-menu"
                aria-labelledby="fault-tree-menu-button"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'fault-tree-menu-button',
                }}
            >
                <MenuItem onClick={(): void => { setCanvasAction(CanvasActionType.EVENTNODE_FAULTTREE_ADD) }} disabled={!(canPerform(CanvasUtils.canCreateFaultTree))} dense>Add Fault Tree</MenuItem>
                <MenuItem onClick={(): void => { setCanvasAction(CanvasActionType.EVENTNODE_FAULTTREE_COPY) }} disabled={!(canPerform(CanvasUtils.hasFaultTree))} dense>Copy Fault Tree</MenuItem>
                <MenuItem onClick={(): void => { setCanvasAction(CanvasActionType.EVENTNODE_FAULTTREE_PASTE) }} disabled={!(canPerform(CanvasUtils.canPasteFaultTree))} dense>Paste Fault Tree</MenuItem>
                <MenuItem onClick={(): void => { setCanvasAction(CanvasActionType.EVENTNODE_FAULTTREE_DELETE) }} disabled={!(canPerform(CanvasUtils.hasFaultTree))} dense>Delete Fault Tree</MenuItem>
                <Divider />
                <MenuItem onClick={(): void => { setCanvasAction(CanvasActionType.EVENTNODE_FAULTTREE_PROPERTIES) }} disabled={!(canPerform(CanvasUtils.hasFaultTree))} dense>Fault Tree Properties</MenuItem>
            </Menu>
        </>
    );
}
