import { ReactElement, useRef } from 'react';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { uuid } from '../../utils/utils';
import { pluralizeString } from '../../utils/utils';

export interface SelectListProps {
    label: string;
    items: SelectListItem[];
    value: number;
    showTopLevel?: boolean;
    skipInitialSelector?: boolean;
    topLevelLabel?: string;
    onChange: (event: any) => void;
}

export interface SelectListItem {
    id: number;
    name: string;
    children?: any[];
}

export default function SelectList(props: SelectListProps): ReactElement {
    const { label, items, value, onChange, showTopLevel, skipInitialSelector, topLevelLabel } = props;
    const uuidRef = useRef(uuid());

    return (
        <FormControl fullWidth>
            <InputLabel tabIndex={-1}  id={`select-label-${uuidRef.current}`} htmlFor={`select-list-${uuidRef.current}`}>{label}</InputLabel>
            <Select
                role='presentation'
                labelId={`select-label-${uuidRef.current}`}
                id={`select-${uuidRef.current}`}
                value={value}
                label={label}
                size='small'
                fullWidth={true}
                onChange={onChange}
                inputProps={
                    {
                        'role': 'textbox',
                        'tabIndex': 0,
                        'id': `select-list-${uuidRef.current}`,
                    }
                }
            >
                {
                    skipInitialSelector ? null :
                        showTopLevel ? <MenuItem value={0} dense>{topLevelLabel ? `${topLevelLabel}` : '--Please Select--'}</MenuItem> : <MenuItem value={0} dense>{`All ${pluralizeString(label)}`}</MenuItem>}
                {items.map((item) => (
                    <MenuItem key={item.id} value={item.id} dense>{item.name}</MenuItem>
                ))}
            </Select>

        </FormControl>
    );
}
