import {TextInputProps} from '../../@types/text-input-props';
import {ChangeEvent, ReactElement, useState} from 'react';


export default function TextInputComponent(props: TextInputProps): ReactElement {

    const [errorMsg, setErrorMsg] = useState(props.errorMsg);
    const [value, setValue] = useState(props.value ? props.value : '');

    const urlRegex = /^(http|https):\/\/[a-zA-Z0-9-_.]+\.[a-zA-Z]{2,5}(\/.*)?$/;
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;

    // Handle onChange event. Update the current value and call the parent's onChange callback (if any)
    function handleOnChange(event: ChangeEvent<HTMLInputElement>): void {
        const newValue = event.target.value;
        setValue(newValue);
        if (props.onChange) {
            props.onChange(newValue);
        }
    }

    // Handle onBlur event. Validate the input and update the error message
    function validate(): void {
        if (props.required && !value) {
            setErrorMsg('This field is required');
        } else {
            setErrorMsg(undefined);
            // switch statement based on type
            if (value) {
                switch (props.type) {
                    case 'text':
                        break;
                    // case 'password':
                    //     // Validate password has minimum 8 characters, at least one uppercase letter, one lowercase letter,
                    //     // one number and one special character
                    //     if (!value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)) {
                    //         setErrorMsg('Password must be at least 8 characters long and contain at least one ' +
                    //             'uppercase letter, one lowercase letter, one number and one special character');
                    //     }
                    //     break;
                    case 'url':
                        // Validate URL
                        if (!value.match(urlRegex)) {
                            setErrorMsg('Invalid URL');
                        }
                        break;
                    case 'email':
                        // Validate email
                        if (!value.match(emailRegex)) {
                            setErrorMsg('Invalid email address');
                        }
                        break;
                    case 'tel':
                        // Validate phone number
                        if (!value.match(phoneRegex)) {
                            setErrorMsg('Invalid phone number');
                        }
                        break;
                    // case 'date':
                    //     // Validate date
                    //     if (!value.match(/^\d{1:2}-\d{2}-\d{2}$/)) {
                    //         setErrorMsg('Invalid date. Date must be in YYYY/MM/DD format');
                    //     }
                    //     break;
                    // case 'time':
                    //     // Validate time
                    //     if (!value.match(/^\d{2}:\d{2}:\d{2}$/)) {
                    //         setErrorMsg('Invalid time. Time must be in the format HH:MM:SS, where HH is the hour' +
                    //             ' (00-23)')
                    //     }
                    //     break;
                    default:
                        break;
                }
            }
        }
    }

    return (
        <div className='devkit-form-row'>
            <label htmlFor={props.id}>
                {props.required ? <span className='devkit-required'>*</span> : null}
                {props.label}
            </label>

            {errorMsg ? <span className='devkit-input-error-message' style={{
                'textAlign': 'left', 'color': 'red', 'fontWeight': '600', 'backgroundColor': '#f9dede',
                'padding': '5px',}} role='alert'>
                {errorMsg}
            </span> : null}

            <input id={props.id} name={props.id} type={props.type} required={props.required} style={{width: '100%'}}
                aria-required={props.required} value={value} disabled={props.disabled ? props.disabled : false}
                aria-disabled={props.disabled ? props.disabled : false}
                readOnly={props.readonly ? props.readonly : false}
                aria-readonly={props.readonly ? props.readonly : false}
                onChange={handleOnChange}
                onBlur={validate}
            />
        </div>
    );

}