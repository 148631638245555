/**
 * Convert an RGB value to hex
 * @param r
 * @param g
 * @param b
 * @return {string}
 * @constructor
 */
export function RGBToHex(r: number, g: number,b: number): string {
    let r_str = r.toString(16);
    let g_str = g.toString(16);
    let b_str = b.toString(16);

    if (r_str.length === 1)
        r_str = '0' + r_str;
    if (g_str.length === 1)
        g_str = '0' + g_str;
    if (b_str.length === 1)
        b_str = '0' + b_str;
    return '#' + r_str + g_str + b_str;
}

export function RGBStringToHex(str: string): string {
    let [r,g,b] = str.match(/\d+/g)?.map(Number) ?? [0,0,0];
    return RGBToHex(r,g,b);
}

// Ripped from CSS Tricks
// https://css-tricks.com/converting-color-spaces-in-javascript/
export function hexToRGB(h: string): string {
    let r = '0', g = '0', b = '0';

    // 3 digits
    if (h.length == 4) {
        r = '0x' + h[1] + h[1];
        g = '0x' + h[2] + h[2];
        b = '0x' + h[3] + h[3];

    // 6 digits
    } else if (h.length == 7) {
        r = '0x' + h[1] + h[2];
        g = '0x' + h[3] + h[4];
        b = '0x' + h[5] + h[6];
    }
    return 'rgb(' + +r + ',' + +g + ',' + +b + ')';
}

// rgb is the background color

/**
 * Ripped from https://stackoverflow.com/questions/11867545/change-text-color-based-on-brightness-of-the-covered-background-area
 * @param {string} backgroundColor the background color in rgb format
 * @returns either white or black color that is best visible against the given background color
 */
export function pickContrastingTextColor(backgroundColor: string): string {
    if (typeof backgroundColor !== 'string') {
        return 'black';
    }
    const isHex = backgroundColor[0] === '#';
    const rgbString = isHex ? hexToRGB(backgroundColor) : backgroundColor;

    // Ripped from https://stackoverflow.com/questions/9585973/javascript-regular-expression-for-rgb-values
    var matchColors = /rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/;
    var rgb = matchColors.exec(rgbString);
    if (rgb === null) {
        return 'black';
    }
    // http://www.w3.org/TR/AERT#color-contrast
    const brightness = Math.round(
        (parseInt(rgb[1]) * 299 + parseInt(rgb[2]) * 587 + parseInt(rgb[3]) * 114) /
      1000
    );
    return brightness > 125 ? 'black' : 'white';
}
