import EventSequenceService from '../services/event-sequence.service';
import {toast} from 'react-toastify';
import EventSequenceSummary from '../@types/event-sequence-summary';

export function exportEventSequence(eventSequenceSummary: EventSequenceSummary): void {
    EventSequenceService.exportEventSequence(eventSequenceSummary.id).then((response) => {
        const url = window.URL.createObjectURL(new Blob([JSON.stringify(response.data)], {type: 'text/plain;charset=utf-8'}));
        const link = document.createElement('a');
        link.href = url;
        // The download attribute forces the browser to download the resource as a file
        link.setAttribute('download',
            eventSequenceSummary.uniqueId + '+' + eventSequenceSummary.name +
            '-Export-' + new Date(Date.now()).toISOString() + '.json');
        document.body.appendChild(link);
        // Simulate a click to start an automatic download
        link.click();
        // Release the blob allocated memory once the download is completed
        window.URL.revokeObjectURL(url);
        // finally remove the link from the DOM
        link.remove();
    }).catch((error) => {
        toast.error('Error exporting event sequence - ' + error.message);
    });
}

export function importEventSequence(safetyModelId: number, safetyModelName: string, updateSafetyModel: {(eventSequence: any): void}): void {
    // open the file selector dialog box and process the selected file
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.json';
    input.onchange = (event: any): void => {
        const file = event.target.files[0];
        EventSequenceService.importEventSequence(safetyModelId, file)
            .then((eventSequence) => {
                updateSafetyModel(eventSequence);
                toast.success('Event sequence imported successfully to ' + safetyModelName);
            }).catch((error) => {
                toast.error('Error importing event sequence - ' + error.message);
            });
    };
    input.click();  // open the file selector dialog box
}
