import { EsdEvent } from '../../@types/esdEvent';
import { FaultTreeNode } from '../../@types/faultTreeNode';
import EventSequence  from '../../@types/event-sequence';


export function canAddOrEditResource(eventSequence: EventSequence): boolean {
    return eventSequence.group.canAddOrEditResources();
}

export function canCreateFaultTree(node: EsdEvent): boolean {
    let type = node.type;
    if (type === 'INITIATING' || type === 'PIVOTAL') {
        let faultTree = node.faultTree;
        return !(faultTree && Object.getOwnPropertyNames(faultTree).length > 0);
    }
    return false;
}
/**
 * Determines if you can add an end event onto a node.
 * @param node {EsdEvent} the node
 * @return {boolean} true if you can add an end event
 */
export function canCreateEndEvent(node: EsdEvent): boolean {
    let type = node.type;
    let childIds = node.childIds;
    return type === 'PIVOTAL' && !(childIds && childIds.length === 2);
}

/**
 * Determines if you can add an event onto a node.
 * @param node {EsdEvent} the node
 * @return {boolean} true if you can add an event
 */
export function canCreateEvent(node: EsdEvent): boolean {
    return isEvent(node.type) && (node.type !== 'INPRECURSOR');
}

export function canEdit(eventSequence: EventSequence): boolean {
    return eventSequence.group.canEdit();
}

/**
 * Determines if you can add a pivotal event onto a node.
 * @param node {EsdEvent} the node
 * @return {boolean} true if you can add a pivotal event
 */
export function canCreatePivotalEvent(node: EsdEvent): boolean {
    return (node.type === 'INITIATING') || (node.type === 'PIVOTAL');
}

/**
 * Determines if you can paste a fault tree onto a node.
 * @param node {Object} the node
 * @return {boolean} true if you can paste a fault tree
 */
export function canPasteFaultTree(node: EsdEvent): boolean {
    let type = node.type;

    // If it has a fault tree already or the clipboard has no contents
    if (node.faultTree || !localStorage.copyContents) {
        return false;
    }

    // If the copied object is not a fault tree
    const copyContents = JSON.parse(localStorage.copyContents);
    if (copyContents.type !== 'fault_tree') {
        return false
    }

    if (type === 'INITIATING') {
        return true;
    }

    if (type === 'PIVOTAL') {
        // If the copied fault tree does not contain a precursor, return true
        return !copyContents.containPrecursor;
    }

    return false
}

/**
 * Determines if you can paste a fault tree node.
 * @param node {Object} the node
 * @return {boolean} true if you can paste a fault tree
 */
export function canPasteFaultTreeNode(node: FaultTreeNode): boolean {
    let type = node.type;

 
    if (type === 'BASE_EVENT' || !localStorage.copyContents || JSON.parse(localStorage.copyContents).type !== 'fault_tree_node') {
        return false;
    }

    return true;
}


/**
 * Determines if you can add an end event to a node.
 * @param event {EsdEvent} the node
 * @return {boolean} true if you can add an end event
*/
export function canPasteNodeEvent(event: EsdEvent): boolean {
    let type = event.type;
    let childIds = event.childIds;

    // If the user selects an END event, it can't be pasted here
    if (type === 'END') {
        return false;
    }

    // If the clipboard has no content then nothing can be pasted
    if (!localStorage.copyContents) {
        return false;
    }
    // If the copied object is something other than an event then it can't be pasted here
    const copyContents = JSON.parse(localStorage.copyContents);
    if (copyContents.type !== 'event') {
        return false
    }

    if (type === 'INITIATING') {
        // If the initiating event is full with 1 child then it can't be pasted here
        return !(childIds && childIds.length === 1);
    }

    if (type === 'PIVOTAL') {
        // If the pivotal event is full with 2 children then it can't be pasted here
        return !(childIds && childIds.length === 2);
    }

    return false
}

/**
 * Determines if you can reorder the children of an event node
 * @param node {Object} the node
 * @return {boolean} true if you can reorder the children of an event node
 */
export function canReorderChildren(node: EsdEvent): boolean {
    let type = node.type;
    return (isEvent(type))  && node.childIds && node.childIds.length > 1;
}

/**
 * Determines if a node is quantifiable.
 * @param node {EsdEvent} the node
 * @return {boolean} true if the node is quantifiable
 */
export function isQuantifiableNode(node: EsdEvent): boolean {
    return !['BASE_EVENT', 'BARRIERGROUP', 'INPRECURSOR', 'PIVOTAL', 'END', 'INPRECURSOR'].includes(node.type);
}


/**
 * Determines if you can apply dna request to a node.
 * @param node {EsdEvent} the node
 * @return {boolean} true if you can add a fault tree to the node
 */
export function dnaRequestAllowed(node: EsdEvent): boolean {
    return node.type !== 'INPRECURSOR'
}

/**
 * Determines if you can add a fault tree to a node.
 * @param node {EsdEvent} the node
 * @return {boolean} true if you can add a fault tree to the node
 */
export function hasFaultTree(node: EsdEvent): boolean {
    let type = node.type;
    if (type === 'INITIATING' || type === 'PIVOTAL') {
        let faultTree = node.faultTree;
        return faultTree && Object.getOwnPropertyNames(faultTree).length  > 0;
    }
    return false
}

/**
 * Determine if a node is a Barrier group event.
 * @param type {string} the type
 * @return {boolean} true if node is a Barrier group event
 */
export function isBarrierGroup(type: string): boolean {
    return type === 'BARRIERGROUP';
}

/**
 * Returns true if the given element type is PIVOTAL.
 *
 * @param elementType The type to test.
 * @returns {boolean} True if the given type is PIVOTAL.
 */
export function isPivotal(nodeClass: string): boolean {
    return nodeClass === 'PIVOTAL';
}

/**
 * Determine if a node is an event.
 * @param type {string} the type
 * @return {boolean} true if node is event
 */
export function isEvent(type: string): boolean {
    return type === 'PIVOTAL' || type === 'INITIATING' || type === 'END' || type === 'INPRECURSOR';
}

/**
 * Determine if a node is an initiating event.
 * @param type {string} the type
 * @return {boolean} true if node is an initiating event
 */
export function isInitiating(type: string): boolean {
    return type === 'INITIATING';
}

/**
 * Returns true if the given node class is an END event
 * @param nodeClass The node class to test
 * @returns {boolean} True if the given node class is an END event
 */
export const isEnd = (nodeClass: string): boolean => nodeClass === 'END';


/**
 * Returns true if the given mode is MODE_FREQUENCY.
 *
 * @param mode The mode to test.
 * @returns {boolean} True if the mode is MODE_FREQUENCY.
 */
export const isFrequencyMode = (mode: string): boolean => mode === 'MODE_FREQUENCY';


/**
 * Returns true if the given node class is INDUCED
 * @param nodeClass The node class to test
 * @returns {boolean} True if the given node class is INDUCED
 */
export const isInduced = (nodeClass: string): boolean => nodeClass === 'INDUCED';

/**
 * Returns true if the given  fault tree node class is a BASE_EVENT node
 * @param elementType The element type to test
 * @returns {boolean} True if the given node class is a BASE_EVENT node
 */
export const isBaseEvent = (elementType: string): boolean => elementType === 'BASE_EVENT';

/**
 * Returns true if the given node class is a PRECURSOR.
 *
 * @param nodeClass The node class to test.
 * @returns {boolean} True if the class is a PRECURSOR.
 */
export function isPrecursor(nodeClass: string): boolean {
    return nodeClass === 'PRECURSOR';
}



/**
 * Returns true if the given element type is of a fault tree node type (i.e. AND, OR, XOR, or BASE_EVENT).
 *
 * @param elementType The element type to test.
 * @returns {boolean} True if the given element type is a fault tree node type.
 */
export function isFaultTreeNode(elementType: string): boolean {
    return isBaseEvent(elementType) || elementType === 'AND'|| elementType === 'OR' || elementType === 'XOR';
}