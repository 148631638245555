import React, { ReactElement, useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CircularProgress  from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { CanvasEnvironment } from '../../../../utils/canvas/canvas-environment';
import { ResourceType } from '../../../../@types/resource-type';
import  { SortableGroup, Item } from './sortable-group/SortableGroup';
import update from 'immutability-helper'
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import * as CanvasActions from '../../../../utils/canvas/canvas-actions';
import { useWorkspaceContext } from '../../../../context/workspaceContext';
import { toast } from 'react-toastify';
import EventSequenceService from '../../../../services/event-sequence.service';

function PaperComponent(props: PaperProps): ReactElement {
    const nodeRef = React.useRef(null);
    return (
        <Draggable nodeRef={nodeRef} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}


export default function ReorderChildrenModal(props: { open: boolean, env: CanvasEnvironment | null, onClose: () => void }): ReactElement {
    const { open, env, onClose } = props;
    const [items, setItems] = React.useState<Item[]>([]);
    const [isRunning, setIsRunning] = React.useState<boolean>(false);
    const {  setCurrentWorkspaceObject } = useWorkspaceContext();
    
    const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
        setItems((prevCards: Item[]) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex] as Item],
                ],
            }),
        )
    }, [])

    
    useEffect(() => {
        if (open) {
            const [selectedNode] = env!.selection;
            const children = env!.graph.getNeighbors(selectedNode, { outbound: true }).filter((cell) => cell.get('nodeClass') !== 'CALLOUTNODE');
            setItems(children.map((node): Item => {
                return { id: node.get('data').id, text: node.get('data').uniqueId }
            }));
            setIsRunning(false);
        }
    }, [open]);
   

    const handleSave = async (): Promise<any> => {
        const [selectedNode] = env!.selection;
        const faultTreeNodeId = selectedNode.get('data').id;
        const childIds = items.map((item: Item) => item.id);
        
        return EventSequenceService.reorderFaultTreeNodeChildren(faultTreeNodeId, childIds);
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperComponent={PaperComponent}
            aria-labelledby='draggable-dialog-title'
            fullWidth
            maxWidth="sm">
            <DialogTitle style={{ cursor: 'move'}} id='draggable-dialog-title'>
                Reorder Children - Drag and Drop to reorder
            </DialogTitle>
            <DialogContent dividers>
                <DndProvider backend={HTML5Backend}>
                    <SortableGroup cards={items} moveCard={moveCard}/>
                </DndProvider>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<CancelIcon />}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={isRunning ? <CircularProgress size={20} /> : <SaveAltIcon />}
                    disabled={!CanvasActions.canAddOrEditResource(env) || isRunning}
                    onClick={async (): Promise<any> => {
                        setIsRunning(true);
                        try {
                            const response = await handleSave();
                            env!.isDirty = true;
                            setCurrentWorkspaceObject({ objectType: ResourceType.EVENT_SEQUENCE_TREES, object: response });
                        } catch (error: any) {
                            toast.error(error.message);
                        } finally {
                            setIsRunning(false);
                            onClose();
                        }                                    
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
   
}