import React, { ReactElement, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Divider from '@mui/material/Divider';
import {CanvasActionType} from '../../../@types/canvas-action-type';
import {useWorkspaceContext} from '../../../context/workspaceContext';

export default function ViewMenu(): ReactElement {
    const { setCurrentCanvasAction } = useWorkspaceContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                id="view-menu-button"
                aria-controls={open ? 'view-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                aria-label="View Menu"
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                style={{ color: 'white', textTransform: 'none', }}
                sx={{
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                }}                
            >
                View
            </Button>
            <Menu
                id="view-menu"
                aria-labelledby="view-menu-button"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'view-menu-button',
                }}
            >
                <MenuItem onClick={handleClose} dense>Standard</MenuItem>
                <Divider />
                <MenuItem onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_TOGGLE_CALLOUTS }); handleClose();} } dense>Toggle Callouts</MenuItem>
                <MenuItem onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_TOGGLE_TAGS }); handleClose();} } dense>Toggle Tags</MenuItem>
                <Divider />
                <MenuItem onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_RECENTER }); handleClose();} } dense>Recenter</MenuItem>
                <MenuItem onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_ZOOM_IN }); handleClose();} } dense>Zoom In</MenuItem>
                <MenuItem onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_ZOOM_OUT }); handleClose();} } dense>Zoom Out</MenuItem>
                <MenuItem onClick={(): void => { setCurrentCanvasAction({ actionType: CanvasActionType.CANVAS_FULLSCREEN }); handleClose();} } dense>Full Screen</MenuItem>
            </Menu>
        </>
    );
}