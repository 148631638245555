import { TreeViewItem } from '../@types/tree-view-item';
import { User } from '../context/userContext';
import {ResourceType} from '../@types/resource-type';

export function makeUser(user: any): User {
    let usr: User = {} as User;
    usr.id = user.id;
    usr.firstName = user.firstName;
    usr.lastName = user.lastName;
    usr.email = user.email;
    usr.isActive = user.isActive;
    usr.superUser = user.superUser;
    usr.name = user.firstName + ' ' + user.lastName;
    return usr;
}

export function userTreeItem(user: User): TreeViewItem {
    return {
        id: user.id!.toString(),
        object: user,
        name: user.name + ' (' + user.email + ')',
        type: ResourceType.USERS,
        groupRole: user.isActive ? 'ACTIVE' : 'EXPIRED'
    };
}

export function userTreeItems(users: User[]): TreeViewItem[] {
    return users.filter((user: User) => user.superUser === false)
        .sort((a: User, b: User) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName))
        .map((user: User) => userTreeItem(user));
}

export function addOrUpdateUser(user: User, users: User[]): User[] {
    const newUserList: User[] = [...users];

    let index = newUserList.findIndex((u: User) => u.id == user.id);
    if (index === -1) {
        newUserList.push(user);
    } else {
        newUserList[index] = user;
    }
    return newUserList.sort((a: User, b: User) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName));
}

