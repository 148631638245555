import pluralize from 'pluralize';

export function isEmpty(obj: any): boolean {
    if (obj === undefined || obj === null || obj === '') {
        return true;
    } else if (Array.isArray(obj)) {
        return obj.length === 0;
    } else if (typeof obj === 'object') {
        return Object.keys(obj).length === 0;
    } else {
        return false;
    }
}

export function addItemToArray(arr: any[] | undefined, x: any): any[] {
    if (Array.isArray(arr)) {
        arr.push(x);
    } else {
        arr = [x];
    }
    return arr;
}
export function singularize(str: string|undefined): string {
    return str ? pluralize.singular(str) : '';
}

export function pluralizeString(str: string|undefined): string {
    return str ? pluralize.plural(str) : '';
}

export function singularizeCamelCase(str: string|undefined): string {
    return singularize(camelCaseToWords(str));
}

export function camelCaseToWords (str: string|null|undefined) : string {
    return str ? str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()) : '';
}

export function newCamelCaseToWordWithNoS(str: string|null) : string {
    return str ? 'New ' + singularizeCamelCase(str) : '';
}

export function uuid(): string {
    // https://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c: string) => {
        // tslint:disable-next-line: no-bitwise
        const r = Math.random() * 16 | 0;
        // tslint:disable-next-line: no-bitwise
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}


export function isNumeric(n: string|number): boolean {
    return (typeof n === 'string' && !isNaN(parseFloat(n as string))) || (typeof n === 'number' && isFinite(n as number));
}

export function isInteger(value: number): boolean {
    return isFinite(value) && Math.floor(value) === value;
}

export function getErrorMessage(error: any): string {
    return error.response.data?.description ?? error.message;
}

export function trimString(str: string, strToTrim: string): string {
    return str.replace(strToTrim, '');
}

export function getValuesOnly(obj: any): string {
    return getValues(Object.values(obj));
}

function getValues(objects: any[]): string {
    return objects.map((obj) => {
        if (typeof obj === 'object') {
            return getValues(Object.values(obj));
        }
        return obj;
    }).join();
}