import React from 'react';

import authService from '../../../services/auth.service';

import AreYouSureModal from '../../form-components/AreYouSureModal.component';
import DotdotdotButton from '../../form-components/DotdotdotButton.component';
import Box from '@mui/material/Box';
import { ResourceType } from '../../../@types/resource-type';
import { toast } from 'react-toastify';

import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { User } from '../../../context/userContext';
import { useWorkspaceContext } from '../../../context/workspaceContext';
import usersService from '../../../services/users.service';
import { addOrUpdateUser } from '../../../utils/user-utils';
import Typography from '@mui/material/Typography';

export default function UserActionsMenu(props: {
    user: User
}): React.ReactElement {
    const [confirmDeleteUser, setConfirmDeleteUser] = React.useState<boolean>(false);
    const { setCurrentWorkspaceObject,
        setDeletedWorkspaceObject,
        setWorkspaceObjects, workspaceObjects } = useWorkspaceContext();
    
    const { user } = props;

    function onEdit(): void {
        setCurrentWorkspaceObject({ objectType: ResourceType.USERS, object: user });
    }

    function onDelete(): void {
        setConfirmDeleteUser(true);
    }

    function onActivate(): void {
        let activeUser = { ...user };
        activeUser.isActive = !activeUser.isActive;
        usersService.patchUser(activeUser).then((usr: User) => {
            let userObject = { objectType: ResourceType.USERS, object: usr };
            setCurrentWorkspaceObject(userObject);
            if (workspaceObjects?.objectType === ResourceType.USERS) {
                setWorkspaceObjects({ objectType: ResourceType.USERS, resources: addOrUpdateUser(usr, workspaceObjects.resources as User[]) });
            }

        }).catch((error) => {
            toast.error(`Error ${user.isActive ? 'deactivating' : 'activating'}  user - ` + error.message);
        });
    }



    return (
        <Box tabIndex={0} onClick={(event: any): void => {
            event.stopPropagation();
        }}>
            <DotdotdotButton>
                {
                    authService.isSuperUser() && <MenuItem id='edit' onClick={onEdit}>Edit</MenuItem>
                }
                <Divider />
                {
                    authService.isSuperUser() && <MenuItem id='edit' onClick={onActivate}>{user.isActive ? 'Deactivate' : 'Activate'}</MenuItem>
                }
                <Divider />
                {
                    authService.isSuperUser() && <MenuItem id='delete' onClick={onDelete}>Delete</MenuItem>
                }
            </DotdotdotButton>

            <AreYouSureModal
                open={confirmDeleteUser}
                title={`Delete User "${user.firstName} ${user.lastName}"?`}
                onConfirm={(): void => { 
                    usersService.deleteUserById(user.id).then(() => {
                        setDeletedWorkspaceObject({
                            objectType: ResourceType.USERS,
                            id: user.id,
                            children: []
                        });
                        setWorkspaceObjects({ objectType: ResourceType.USERS });
                        
                    }).catch((error) => {
                        toast.error('Error deleting user - ' + error.message);
                    }).finally(() => {
                        setConfirmDeleteUser(false);
                    });
                }}
                onClose={(): void => {
                    setConfirmDeleteUser(false);
                }}
            >
                <Typography variant='body1'>Are you sure you want to delete this user?</Typography>
            </AreYouSureModal>
        </Box>
    );
}

