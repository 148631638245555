import React, {ReactElement, useContext} from 'react';

import {UserContext} from '../context/userContext';
import {CONTACT_EMAIL, CONTACT_NAME} from '../config/constants';
import {useNavigate} from 'react-router-dom';

export default function HeaderToolbarComponent(): ReactElement {

    const { user, signoutUser } = useContext(UserContext);
    const navigate = useNavigate();

    const contactId = 'devkit-help-contact';

    function toggle_contact(): void {
        // eslint-disable-next-line no-undef
        let x = document.getElementById(contactId);
        if (x && x.style.display === 'block') {
            x.style.display = 'none';
        } else if (x) {
            x.style.display = 'block';
        }
    }

    return (

        <div className='devkit-toolbar-wrapper' id='devkit-toolbar-wrapper' style={{'justifyContent': 'right'}}>

            {/*This is the signin link*/}
            <div className='devkit-toolbar-icon' id='devkit-signin-wrapper'>
                {
                    user ?
                        <><a onClick={(): void => { navigate('react/workspace') }} tabIndex={0}>
                            <span className='far fa-user' aria-hidden='true' title='Workspace' aria-label='Workspace'/>
                            <span className='devkit-tool-bar-text' aria-label='Workspace'>Go to Workspace</span>
                        </a><a href='signout' tabIndex={1} onClick={signoutUser}>
                            <span className='far fa-user' aria-hidden='true' title='Sign Out' aria-label='Sign Out'/>
                            <span className='devkit-tool-bar-text' aria-label='Sign Out'>Sign Out</span>
                        </a></> :
                        <a onClick={(): void => { navigate('/react/signin') }} tabIndex={0}>
                            <span className='far fa-user' aria-hidden='true' title='Sign In' aria-label='Sign In' />
                            <span className='devkit-tool-bar-text' aria-label='Sign In'>Sign In</span>
                        </a>
                }
            </div>

            {/*This is the contact link*/}
            <div className='devkit-toolbar-icon' style={{'margin': '0 10px'}} id='devkit-help-icon-wrapper'>
                <button className='devkit-tool-bar-text' type='button' onClick={toggle_contact} style={{ 'border': 'none', backgroundColor: '#fff' }}>
                    <span className='far fa-question-circle' aria-hidden='true' title='Contact' aria-label='Contact' />
                    <span className='devkit-tool-bar-text' aria-label='Contact'>Contact</span>
                </button>
                <div id={contactId} className='devkit-help-content-wrapper ng-star-inserted'>
                    <div className='devkit-help-item-wrapper'>
                        <p>
                            For more information on the ISAM project and to request access and/or support, please
                            contact:
                        </p>
                        <span>
                            <span className='item-title'>Project Lead:</span><span className='item-content'>{CONTACT_NAME}</span><br/>
                            <span className='item-title'>Email:</span><span className='item-content'>{CONTACT_EMAIL}</span>
                        </span>
                    </div>
                    <span className="top"/>
                </div>
            </div>
        </div>

    );

}