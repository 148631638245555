import React from 'react';
import {uuid} from '../../../utils/utils';
import EventSequence from '../../../@types/event-sequence';
import JointJsCanvasEditor from './JointJsCanvasEditor';
import EventSequenceVersion from '../../../@types/esd-version-props';

export default function EventSequenceTreeViewer( props: {esv: EventSequenceVersion, isActive: boolean} ): React.ReactElement {
    const {esv, isActive} = props;
    const [eventSequence, setEventSequence] = React.useState<EventSequence>({ id: 0 } as EventSequence);
    const uuidRef = React.useRef(uuid());

    React.useEffect(() => {
        setEventSequence(esv.eventSequenceDTO);
    }, [esv]);

    return <div id={uuidRef.current} style={{ width: '100vw', height: 'calc(100vh - 250px)', maxWidth: '100%', maxHeight: '100%', overflow: 'hidden'}}>
        <JointJsCanvasEditor eventSequence={eventSequence} isActive={isActive} readonly={true} />
    </div>;
}

