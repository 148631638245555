import React, {FC, ReactElement, useContext, useEffect, useState} from 'react';
import PageTitle from '../components/PageTitle.component';
import PageContentWrapper from '../components/PageContentWrapper.component';
import {UserContext} from '../context/userContext';
import {useLocation, useNavigate} from 'react-router-dom';
import AuthService from '../services/auth.service';
import ErrorAlertComponent from '../components/ErrorAlert.component';
import {AxiosError} from 'axios';
import {validatePassword} from '../utils/validator';
import {BASE_PATH} from '../config/constants';
import {Button, FormGroup, IconButton, InputAdornment, Stack} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import TextField from '@mui/material/TextField';
import LockResetIcon from '@mui/icons-material/LockReset';

const ResetPassword: FC<any> = (): ReactElement => {

    const { signinUser } = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();

    const [email, setEmail] = useState<string>(new URLSearchParams(location.search).get('email') || '');
    const [token, setToken] = useState<string>(new URLSearchParams(location.search).get('token') || '');
    const [needCorrection, setNeedCorrection] = useState<boolean>(false);
    const [error, setError] = useState<string|null>(null);

    const [password, setPassword] = React.useState({
        passwordText: '',
        showPassword: false,
    });

    const [confirmedPassword, setConfirmedPassword] = React.useState({
        passwordText: '',
        showPassword: false,
    });

    useEffect(() => {
        if (email === '' || token === '') {
            setError('Invalid reset password link. Please try again.');
            return;
        }
        AuthService.verifyResetPasswordToken(email, token)
            .then((response) => {
                if (response.data.success) {
                    setError(null);
                    setEmail(email);
                    setToken(token);
                    setNeedCorrection(false);
                } else {
                    setError('Invalid reset password link. Please try again.');
                    setNeedCorrection(true);
                }
            })
            .catch(() => {
                setError('Invalid reset password link. Please verify your email and token and try again.');
                setNeedCorrection(true);
            });
    }, []);

    const handleClickShowPassword = (): void => {
        setPassword({ ...password, showPassword: !password.showPassword });
    };

    const handlePasswordChange = (prop: any) => (event: any) => {
        setPassword({ ...password, [prop]: event.target.value });
    };

    const handleClickShowConfirmedPassword = (): void => {
        setConfirmedPassword({ ...confirmedPassword, showPassword: !confirmedPassword.showPassword });
    };

    const handleConfirmedPasswordChange = (prop: any) => (event: any) => {
        setConfirmedPassword({ ...confirmedPassword, [prop]: event.target.value });
    };

    function onClickSet(_event: React.SyntheticEvent): void {
        if (password.passwordText && confirmedPassword.passwordText) {
            if (password.passwordText !== confirmedPassword.passwordText) {
                setError('New password and confirmed password do not match');
                return;
            }
            let validateResult = validatePassword(password.passwordText);
            if (!validateResult.isValid) {
                setError(validateResult.reason);
                return;
            }
            setError(null);

            AuthService.resetPassword(email!, token!, password.passwordText)
                .then(() => {
                    AuthService.signin(email!, password.passwordText)
                        .then((response) => {
                            signinUser(response.data);
                            navigate('/react/workspace');
                        })
                })
                .catch((err: AxiosError) => {
                    if (err.response && err.response.status === 403) {
                        setError('Your password reset token has expired. Click on the <a href=\'' + {BASE_PATH} + '/forgot-password\'>Reset Password</a> again and request a new password reset token.');
                    } else {
                        setError('An unexpected error occurred. Please try again later.');
                    }
                });
        } else {
            setError('New password and confirmed password are required');
        }
    }

    return (
        <>

            <PageTitle title={'Reset Password'} />

            <PageContentWrapper>
                <FormGroup className='formStyle'>
                    <Stack className='formControl'>
                        <TextField type={'text'} value={email} label={'Email Address'} disabled={true} />
                        <hr/>
                        <TextField
                            id={'new-password'}
                            label={'New Password'}
                            type={password.showPassword ? 'text' : 'password'}
                            variant='outlined'
                            onChange={handlePasswordChange('passwordText')}
                            value={password.passwordText}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton size={'small'}
                                            onClick={handleClickShowPassword}
                                        >
                                            {password.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <hr/>
                        <TextField
                            id={'confirm-password'}
                            label={'Confirm Password'}
                            type={confirmedPassword.showPassword ? 'text' : 'password'}
                            variant='outlined'
                            onChange={handleConfirmedPasswordChange('passwordText')}
                            value={confirmedPassword.passwordText}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        <IconButton size={'small'}
                                            onClick={handleClickShowConfirmedPassword}
                                        >
                                            {confirmedPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <hr />
                        <div>
                            <Button className='formBtn' id='reset-password'
                                size='small' variant='contained'
                                title='Reset Password'
                                onClick={onClickSet}
                                startIcon={<LockResetIcon />}
                                disabled={needCorrection}
                            >Reset Password</Button>
                        </div>
                        {error ? <ErrorAlertComponent message={error} />  : null}
                    </Stack>
                </FormGroup>
            </PageContentWrapper>
        </>
    );
};

export default ResetPassword;
