import { ReactElement, useRef } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { uuid } from '../../utils/utils';

export interface SearchFieldProps {
    label: string;
    value?: string;
    onTextChange: (event: any) => void;
    onClick: (event: any) => void;
}
export default function SearchField(props: SearchFieldProps): ReactElement {
    const {label, value, onClick, onTextChange } = props;
    const uuidRef = useRef(uuid());

    return (
        <div style={{padding: '10px 0 3px 0'}}>
            <TextField
                label={`Search ${label}`}
                aria-label='search'
                type='text'
                variant='outlined'
                size='small'
                id={`search-field-${uuidRef.current}`}
                onChange={onTextChange}
                sx={{
                    '& input': {
                        border: 'none',
                    }
                }}
                value={value}
                InputProps={{
                    'role': 'searchbox',
                    'tabIndex': 0,
                    'aria-labelledby': `search-field-${uuidRef.current}`,
                    'aria-label': `search ${label}`,

                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton aria-label='search icon' onClick={onClick}>
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
}