import SafetyModel from '../../@types/safety-model';
import EventSequenceSummary from '../../@types/event-sequence-summary';
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import SelectList from './SelectList.component';
import DialogActions from '@mui/material/DialogActions';
import {Button} from '@mui/material';

export function ExportEventSequenceSelectorDialogBox(props: {
    open: boolean;
    safetyModel: SafetyModel,
    eventSequenceList: EventSequenceSummary[];
    onClose: () => void,
    onExport: (eventSequence: EventSequenceSummary | undefined) => void
}): React.ReactElement {
    const { open, safetyModel, eventSequenceList, onClose, onExport } = props;
    const [selectedEventSequenceId, setSelectedEventSequenceId] = React.useState<number>(-1);

    function onSelectEventSequenceChange(event: React.ChangeEvent<{ value: unknown }>): void {
        setSelectedEventSequenceId(event.target.value as number);
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{'Export An Event Sequence from ' + safetyModel.name}</DialogTitle>
            <DialogContent dividers>
                <SelectList
                    skipInitialSelector={true}
                    label='Select An Event Sequence'
                    items={eventSequenceList.map((eventSequence) => ({id: eventSequence.id, name: eventSequence.uniqueId + ' - ' + eventSequence.name}))}
                    value={selectedEventSequenceId}
                    onChange={onSelectEventSequenceChange} />
            </DialogContent>
            <DialogActions>
                <Button id='cancel'  size='small' variant='outlined' onClick={onClose}>Cancel</Button>
                <Button id='confirm' size='small' variant='outlined' disabled={selectedEventSequenceId < 0} onClick={(): void => {
                    onExport(eventSequenceList.find(ess => ess.id == selectedEventSequenceId))
                }}>Export</Button>
            </DialogActions>
        </Dialog>
    );
}