import React, { ReactElement, useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { getWorkspaceObjectTypeAsNumber } from '../../../utils/resource-utils';
import { ResourceType } from '../../../@types/resource-type';
import { API_URL } from '../../../config/constants';

import SystemSettingModal from '../modals/SystemSetting.component';

export default function AdminMenu(props: {onTabClick: Function}): ReactElement {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openSystemSettings, setOpenSystemSettings] = useState<boolean>(false);

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const handleActuator = (): void => {
        handleClose();
        window.open(API_URL + 'actuator');
    };

    const handleSwagger = (): void => {
        handleClose();
        window.open(API_URL + 'swagger-ui/index.html');
    };

    const handleFeatureToggles = (): void => {
        handleClose();
        window.open(API_URL + 'togglz-console/index');
    }

    const handleSystemSettings = (): void => {
        setOpenSystemSettings(true);
        handleClose();     
    }


    return (
        <>
            <Button
                id="admin-menu-button"
                aria-controls={open ? 'admin-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={anchorEl ? 'true' : undefined}
                aria-label="Admin Menu"
                onClick={handleClick}
                endIcon={<ArrowDropDownIcon />}
                style={{ color: 'white', textTransform: 'none', }}
                sx={{
                    '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                }}
            >
                Admin
            </Button>
            <Menu
                id="admin-menu"
                aria-labelledby="admin-menu-button"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'admin-menu-button',
                }}
            >
                <MenuItem onClick={(): void => {
                    props.onTabClick(getWorkspaceObjectTypeAsNumber(ResourceType.USERS));
                    handleClose();
                }} dense>Users</MenuItem>

                <MenuItem onClick={handleSystemSettings} dense>System Settings</MenuItem>
                <MenuItem onClick={handleFeatureToggles} dense>Feature Toggles</MenuItem>
                <MenuItem onClick={handleActuator} dense>Actuator</MenuItem>
                <MenuItem onClick={handleSwagger} dense>API Docs</MenuItem>
            </Menu>

            {openSystemSettings && <SystemSettingModal open={openSystemSettings} onClose={(): void => { setOpenSystemSettings(false); }} />}

        </>
    );
}