import {TreeViewItem} from '../@types/tree-view-item';
import {getPathToRootAsArrayOfString, role} from './group-utils';
import {BreadCrumbsProp} from '../@types/bread-crumb';
import {getResourceNameWithAscendants} from './resource-utils';
import {ResourceType} from '../@types/resource-type';
import Occurrence from '../@types/occurrence';
import Group from '../@types/group';

export function occurrenceTreeItems(occurrences: Occurrence[], groups: Group[]|undefined): TreeViewItem[] {
    const obj = occurrences.map((occurrence: Occurrence) => {
        const group = groups?.find((grp) => grp.id === occurrence.group.id);
        return {...occurrence, breadcrumb: group ? getPathToRootAsArrayOfString(group) : [{label: occurrence.displayName, type: ResourceType.OCCURRENCE_REPORTS, id: occurrence.id}]};
    }).reduce((acc: any, occur: Occurrence) => {
        const path = occur.breadcrumb.map((bread: BreadCrumbsProp) => bread.label).join('>') + '-' + occur.group.id;
        if (!acc[path]) {
            acc[path] = [];
        }
        acc[path].push(occur);
        return acc;
    }, {});

    Object.keys(obj).forEach((key) => {
        obj[key].sort((a: Occurrence, b: Occurrence) => {
            return b.dateTime > a.dateTime;
        });
    });
    const treeItems: TreeViewItem[] = [];
    Object.keys(obj).sort().forEach((key) => {
        const occ = obj[key][0];
        const oc_group = groups?.find((grp) => grp.id === occ.group.id );
        if (oc_group) {
            treeItems.push(...obj[key].map((oc: Occurrence) => occurrenceTreeItem(oc, role(oc_group),
                getResourceNameWithAscendants(oc_group, ResourceType.GROUPS))));
        }
    });

    return treeItems.sort((a: TreeViewItem, b: TreeViewItem) => a.name.localeCompare(b.name) || a.groupPath!.localeCompare(b.groupPath!));
}

const occurrenceTreeItem = (occurrence: Occurrence, role: string, path: string): TreeViewItem => {
    return {
        id: occurrence.id!.toString(),
        object: occurrence,
        name: `${occurrence.displayName} (${new Date(occurrence.dateTime).toLocaleDateString()})`,
        groupPath: path,
        groupRole: role,
        type: ResourceType.OCCURRENCE_REPORTS,
        hasChildren: false
    };
}